import dayjs from 'dayjs';
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { useMutation, useQuery } from '@tanstack/react-query';
import { createError, throwError } from '../api/error';
import { editAccount, editPassword, getAccount } from '../api/member';
import { useAlert } from '../stores/alertStore';
import { useConfirm } from '../stores/confirmStore';
import { loadingState } from '../stores/loadingStore';
import { encrypt, decrypt } from '../assets/util/crypto';

// components
import Page from '../includes/page';
import FormItem from '../components/basic/formItem';

// img
import { icVisibility, icVisibilityOff } from '../components/aside/icon';

function AccountSettings() {
  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies(['XID', 'xclass-expires']);
  const { code } = useParams();
  const navigate = useNavigate();
  const { alertShow } = useAlert();
  const { confirmShow } = useConfirm();

  const [name, setName] = useState('');
  const [passwordOld, setPasswordOld] = useState('');
  const [passwordOldView, setPasswordOldView] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordView, setPasswordView] = useState(false);
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [passwordConfirmView, setPasswordConfirmView] = useState(false);
  const [waring, setWarning] = useState(true);
  const [gender, setGender] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [isChange, setIsChange] = useState({ name: '', gender: '', email: '', phone: '' });

  const { isFetching } = useQuery<{ data: IMemberDetail }>(['getAccount'], getAccount, {
    refetchOnWindowFocus: false,
    onSuccess: e => {
      setName(e.data.m_name);
      setGender(e.data.m_gender);
      setEmail(e.data.m_email);
      setPhone(e.data.m_phone);
      setIsChange({ name: e.data.m_name, gender: e.data.m_gender, email: e.data.m_email, phone: e.data.m_phone });
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (e: any) => alertShow(e.response.data.message),
  });

  const setLoading = useSetRecoilState(loadingState);
  const { mutate: edit } = useMutation(editAccount, {
    onSuccess: () => {
      if (name && name !== isChange.name) {
        const encryptCookie = encrypt({ ...decrypt(cookies.XID), m_name: name });
        setCookie('XID', encryptCookie, {
          domain: process.env.NODE_ENV === 'production' ? '.xclass.co.kr' : '.xcast.co.kr',
          path: '/',
          expires: dayjs(cookies['xclass-expires']).toDate(),
        });
      }
      setLoading(false);
      navigate(`/${code}`);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (e: any) => {
      setLoading(false);
      createError({
        type: '[ADMIN] account - editAccount',
        message: `message: "${e.message}",\nresponse: { status: ${e.response.status}, message: "${e.response.data.message}" }`,
      });
      throwError(e);
    },
  });

  const upload = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const obj: any = {};
    if (name && name !== isChange.name) obj.name = name;
    if (gender && gender !== isChange.gender) obj.gender = gender;
    if (email !== isChange.email) obj.email = email;
    if (phone !== isChange.phone) obj.phone = phone;
    setLoading(true);
    edit(obj);
  };

  const { mutate: editPwd } = useMutation(editPassword, {
    onSuccess: () => {
      setLoading(false);
      navigate(`/${code}`);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (e: any) => {
      setLoading(false);
      createError({
        type: '[ADMIN] account - editPassword',
        message: `message: "${e.message}",\nresponse: { status: ${e.response.status}, message: "${e.response.data.message}" }`,
      });
      throwError(e);
    },
  });

  const editPass = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const obj: any = {};
    if (passwordOld) obj.password = passwordOld;
    if (password && !waring) obj.newPassword = password;
    editPwd(obj);
  };
  return (
    <>
      <Page title="회원정보 변경" isLoading={isFetching}>
        <FormItem title="이름" essential>
          <div className="input">
            <input
              type="text"
              maxLength={24}
              placeholder={t('이름') || ''}
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </div>
        </FormItem>
        <FormItem title="성별">
          <div className="input">
            <select value={gender} onChange={e => setGender(e.target.value)}>
              <option value="N">{t('선택안함')}</option>
              <option value="M">{t('남자')}</option>
              <option value="F">{t('여자')}</option>
            </select>
            <div className="arrow"></div>
          </div>
        </FormItem>
        <FormItem title="이메일">
          <div className="input">
            <input type="text" placeholder={t('이메일') || ''} value={email} onChange={e => setEmail(e.target.value)} />
          </div>
        </FormItem>
        <FormItem title="연락처">
          <div className="input">
            <input
              type="text"
              maxLength={16}
              placeholder={t('연락처') || ''}
              value={phone}
              onChange={e => setPhone(e.target.value.replace(/[^0-9]/g, ''))}
            />
          </div>
        </FormItem>
        <div className="formSubmit">
          <button
            type="button"
            className="primary"
            onClick={() => {
              if (!name) {
                alertShow('이름을 입력하십시오.');
                return;
              }
              confirmShow('수정하시겠습니까?', upload);
            }}
          >
            {t('수정')}
          </button>
        </div>
      </Page>
      <Page title="비밀번호 변경">
        <FormItem title="비밀번호" essential>
          <div className="input">
            <input
              type={passwordOldView ? 'text' : 'password'}
              placeholder={t('비밀번호') || ''}
              value={passwordOld}
              onChange={e => setPasswordOld(e.target.value)}
            />
            <object onClick={() => setPasswordOldView(prev => !prev)}>
              {passwordOldView ? icVisibilityOff() : icVisibility()}
            </object>
          </div>
          <div className="input">
            <input
              type={passwordView ? 'text' : 'password'}
              placeholder={t('새 비밀번호') || ''}
              value={password}
              onChange={e => {
                setPassword(e.target.value);
                const numberCheck = /[0-9]/.test(e.target.value);
                const alphabetCheck = /[a-zA-Z]/.test(e.target.value);
                const specialCheck = /[~!@#$%^&*()_+|<>?:{}]/.test(e.target.value);
                const spaceCheck = e.target.value.search(/\s/) === -1;
                const double =
                  (numberCheck && alphabetCheck) || (numberCheck && specialCheck) || (alphabetCheck && specialCheck);
                const triple = numberCheck && alphabetCheck && specialCheck;
                setWarning(
                  !spaceCheck ||
                    e.target.value.length < 8 ||
                    (e.target.value.length > 7 && e.target.value.length < 10 && !triple) ||
                    (e.target.value.length > 9 && !double),
                );
              }}
            />
            <object onClick={() => setPasswordView(prev => !prev)}>
              {passwordView ? icVisibilityOff() : icVisibility()}
            </object>
          </div>
          <div className="input">
            <input
              type={passwordConfirmView ? 'text' : 'password'}
              placeholder={t('비밀번호 확인') || ''}
              value={passwordConfirm}
              onChange={e => {
                setPasswordConfirm(e.target.value);
                const numberCheck = /[0-9]/.test(e.target.value);
                const alphabetCheck = /[a-zA-Z]/.test(e.target.value);
                const specialCheck = /[~!@#$%^&*()_+|<>?:{}]/.test(e.target.value);
                const spaceCheck = e.target.value.search(/\s/) === -1;
                const double =
                  (numberCheck && alphabetCheck) || (numberCheck && specialCheck) || (alphabetCheck && specialCheck);
                const triple = numberCheck && alphabetCheck && specialCheck;
                setWarning(
                  !spaceCheck ||
                    e.target.value.length < 8 ||
                    (e.target.value.length > 7 && e.target.value.length < 10 && !triple) ||
                    (e.target.value.length > 9 && !double),
                );
              }}
            />
            <object onClick={() => setPasswordConfirmView(prev => !prev)}>
              {passwordConfirmView ? icVisibilityOff() : icVisibility()}
            </object>
          </div>
          {(password.length > 0 || passwordConfirm.length > 0) && (
            <div className="guide">
              {password !== passwordConfirm && (
                <div className="item">
                  <span>{t('비밀번호가 일치하지 않습니다.')}</span>
                </div>
              )}
              {waring && (
                <div className="item">
                  <span>
                    {t(
                      '비밀번호는 공백 없이 영문, 숫자, 특수문자를 모두 포함하여 8자 이상 또는 영문, 숫자, 특수문자 중 2가지를 조합하여 10자리 이상으로 입력하십시오.',
                    )}
                  </span>
                </div>
              )}
            </div>
          )}
        </FormItem>
        <div className="formSubmit">
          <button
            type="button"
            className="primary"
            onClick={() => {
              if (!passwordOld || !password) {
                alertShow('비밀번호를 입력하십시오.');
                return;
              }
              if (password && waring) {
                alertShow('비밀번호 주의사항을 확인하십시오.');
                return;
              }
              confirmShow('수정하시겠습니까?', editPass);
            }}
          >
            {t('수정')}
          </button>
        </div>
      </Page>
    </>
  );
}

export default AccountSettings;
