import axios from 'axios';
import classNames from 'classnames';
import ko from 'date-fns/locale/ko';
import queryString from 'query-string';
import DatePicker from 'react-datepicker';
import { useState, useEffect, useRef } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { Document, pdfjs } from 'react-pdf';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useMutation, useQuery } from '@tanstack/react-query';
import { createError, throwError } from '../../api/error';
import { getOrgList } from '../../api/member';
import { createSalestip, editSalestip, getSalestip } from '../../api/salestip';
import { useAlert } from '../../stores/alertStore';
import { useConfirm } from '../../stores/confirmStore';
import { loadingState } from '../../stores/loadingStore';
import { memberOrgMultyState } from '../../stores/memberStore';
import { categorySelectorState, imgState, useImg } from '../../stores/salestipStore';
import { flatOrg } from '../../assets/util/flatOrg';
import { editDateFormat } from '../../assets/util/dateFormat';

// components
import Page from '../../includes/page';
import FormItem from '../../components/basic/formItem';
import InspectionOrg from '../../components/inspection/org';
import ImgUploadLoading from '../../components/inspection/imgUploadLoading';
import SalestipCategorySelector from '../../components/salestip/categorySelector';

// img
import icDelete from '../../assets/img/icons/ic_delete.svg';
import icArrowCircleLeft from '../../assets/img/icons/ic_arrow_circle_left.svg';
import icArrowCircleRight from '../../assets/img/icons/ic_arrow_circle_right.svg';
import { icMovie } from '../../components/aside/icon';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const api = process.env.REACT_APP_API;

function SalestipEdit() {
  const { t } = useTranslation();
  const [cookies] = useCookies(['xclass-lang', 'xclass-cname']);
  const navigate = useNavigate();
  const { code } = useParams();
  const { search } = useLocation();
  const { key } = queryString.parse(search);

  // 파일 확장자
  const getExtension = (fileName: string) => {
    const fileLength = fileName.length;
    const lastDot = fileName.lastIndexOf('.');
    const fileExtension = `.${fileName.substring(lastDot + 1, fileLength)}`;
    return fileExtension.toLowerCase();
  };

  const { alertShow } = useAlert();
  const { confirmShow } = useConfirm();
  // 카테고리
  const setCategorySelector = useSetRecoilState(categorySelectorState);
  const [category, setCategory] = useState<{ key: number; name: string }>({ key: 0, name: '' });
  // 제목
  const [title, setTitle] = useState('');
  // 내용
  const [content, setContent] = useState('');
  // 기간
  const [sdate, setSdate] = useState<Date | null>(new Date());
  const [edate, setEdate] = useState<Date | null>(new Date());
  useEffect(() => {
    if (Number(edate) < Number(sdate)) setEdate(sdate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sdate, edate]);
  // 공개여부
  const [isPublic, setIsPublic] = useState(true);
  // 섬네일
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [uploadFile, setUploadFile] = useState<any>(null);
  const [uploadImg, setUploadImg] = useState<string | null>(null);
  const [uploadImgName, setUploadImgName] = useState('');
  const imgRef = useRef<HTMLImageElement>(null);
  const changeThumb = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event?.target?.files) {
      setUploadFile(null);
      setUploadImg(null);
      setUploadImgName('');
      return;
    }
    const maxSize = 1 * 1024 * 1024;
    if (event.target.files[0].size > maxSize) {
      alertShow('첨부 이미지의 사이즈는 1024KB 이내로 등록 가능합니다.');
      // eslint-disable-next-line no-param-reassign
      event.target.value = '';
      return;
    }
    if (
      getExtension(event.target.files[0].name || '') !== '.png' &&
      getExtension(event.target.files[0].name || '') !== '.jpg' &&
      getExtension(event.target.files[0].name || '') !== '.jpeg'
    ) {
      alertShow('이미지는 jpg, png 형식의 파일만 등록할 수 있습니다.');
      // eslint-disable-next-line no-param-reassign
      event.target.value = '';
      return;
    }
    setUploadFile(event.target.files[0]);
    setUploadImg(URL.createObjectURL(event.target.files[0]));
    setUploadImgName(event.target.files[0].name);
    try {
      const reader: FileReader = new FileReader();
      reader.onload = (event: ProgressEvent<FileReader>) => {
        console.log(event);
      };
      reader.onerror = error => {
        console.log(error);
      };
      reader.readAsDataURL(event.target.files[0]);
      // eslint-disable-next-line no-param-reassign
      event.target.value = '';
    } catch (error) {
      console.log(error);
    }
  };
  // 첨부파일
  const [img, setImg] = useRecoilState(imgState);
  const { initFile, setFile, setFileDelete, setFileMove, setImgAdd } = useImg();
  const changeImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (imgCount[1].total >= 30) {
      alertShow('첨부 파일은 최대 30개까지 등록할 수 있습니다.');
      return;
    }
    if (!event?.target?.files) {
      initFile();
      return;
    }
    const maxSize = 20 * 1024 * 1024;
    if (event.target.files[0].size > maxSize) {
      alertShow('첨부 파일의 사이즈는 20MB 이내로 등록 가능합니다.');
      return;
    }
    const extention = getExtension(event.target.files[0].name);
    if (extention !== '.png' && extention !== '.jpg' && extention !== '.jpeg') {
      alertShow('jpg, png 형식의 파일만 등록할 수 있습니다.');
      return;
    }
    const ele = event.target.files[0];
    setFile(ele, URL.createObjectURL(ele), ele.name);
    try {
      const reader: FileReader = new FileReader();
      reader.onload = (event: ProgressEvent<FileReader>) => {
        console.log(event);
      };
      reader.onerror = error => {
        console.log(error);
      };
      reader.readAsDataURL(event.target.files[0]);
      // eslint-disable-next-line no-param-reassign
      event.target.value = '';
    } catch (error) {
      console.log(error);
    }
    setImgCount(prev => prev.map(item => (item.id === 'file' ? { ...item, total: item.total + 1 } : item)));
  };
  const [pdf, setPdf] = useState('');
  const changePdf = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (imgCount[1].total >= 30) {
      alertShow('첨부 파일은 최대 30개까지 등록할 수 있습니다.');
      return;
    }
    setLoading(true);
    if (!event?.target?.files) {
      return;
    }
    const maxSize = 20 * 1024 * 1024;
    if (event.target.files[0].size > maxSize) {
      setLoading(false);
      alertShow('첨부 파일의 사이즈는 20MB 이내로 등록 가능합니다.');
      // eslint-disable-next-line no-param-reassign
      event.target.value = '';
      return;
    }
    try {
      const file = event?.target?.files[0];
      const uri = URL.createObjectURL(file);
      setPdf(uri);
      // eslint-disable-next-line no-param-reassign
      event.target.value = '';
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const dataURLtoFile = (dataurl: string, fileName: string) => {
    const arr = dataurl.split(',');
    const mime = 'image/png';
    console.log(arr);
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    // eslint-disable-next-line no-plusplus
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], fileName, { type: mime });
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onDocumentLoadSuccess = async (e: any) => {
    if (e.numPages > 30) {
      setLoading(false);
      alertShow('30장 이하의 파일만 가능합니다.');
      setPdf('');
      return;
    }
    const imagesList = [];
    const canvas = document.createElement('canvas');
    canvas.setAttribute('className', 'canv');

    for (let i = 1; i <= e.numPages; i += 1) {
      // eslint-disable-next-line no-await-in-loop
      const page = await e.getPage(i);
      const viewport = page.getViewport({ scale: 1 });
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      const render_context = {
        canvasContext: canvas.getContext('2d'),
        viewport,
      };
      // eslint-disable-next-line no-await-in-loop
      await page.render(render_context).promise;
      const img = canvas.toDataURL('image/png');
      const file = dataURLtoFile(img, `pdf_${i}.png`);
      imagesList.push(file);
    }
    imagesList.forEach(item => {
      setFile(item, URL.createObjectURL(item), item.name);
      try {
        const reader: FileReader = new FileReader();
        reader.onload = (event: ProgressEvent<FileReader>) => {
          console.log(event);
        };
        reader.onerror = error => {
          console.log(error);
        };
        reader.readAsDataURL(item);
      } catch (error) {
        console.log(error);
      }
    });
    setImgCount(prev =>
      prev.map(item => (item.id === 'file' ? { ...item, total: item.total + imagesList.length } : item)),
    );
    canvas.remove();
    setLoading(false);
  };

  // 조직
  const [orgAleady, setOrgAleady] = useState(false);
  const [memberOrg, setMemberOrg] = useRecoilState(memberOrgMultyState);
  const { isFetching: orgLoading } = useQuery<{ data: IOrg }>(['org'], getOrgList, {
    refetchOnWindowFocus: false,
    onSuccess: e => {
      setMemberOrg(prev => ({ ...prev, data: e.data }));
      setOrgAleady(true);
    },
  });

  const createUIO = (
    urls: {
      key: number;
      url: string;
      order: number;
    }[],
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const obj: any = {};
    // eslint-disable-next-line no-return-assign
    urls?.forEach(item => (obj[item.key] = item.order));
    return obj;
  };

  const { isFetching: getSalestipLoading } = useQuery<{ data: ISalestipDetail }>(
    ['getSalestip'],
    () => getSalestip(Number(key)),
    {
      refetchOnWindowFocus: false,
      enabled: orgAleady && key !== undefined,
      onSuccess: e => {
        setCategory(e.data.category);
        setTitle(e.data.title);
        setContent(e.data.content);
        setSdate(new Date(e.data.startDate));
        setEdate(new Date(e.data.endDate));
        setIsPublic(e.data.public === 1);
        setUploadFile(`uploaded_${e.data.thumbnail}`);
        setUploadImg(`${process.env.REACT_APP_UPLOADED_URL}/${e.data.thumbnail}`);
        setUploadImgName(`uploaded_${e.data.thumbnail}`);
        setImg(prev => ({
          ...prev,
          imageMaps: [],
          imgFile: {
            file: e.data.urls?.map(e => `uploaded_${e.key}_${e.url}`) || [],
            img: e.data.urls?.map(e => `${process.env.REACT_APP_UPLOADED_URL}/${e.url}`) || [],
            imgName: e.data.urls?.map(e => `uploaded_${e.url}`) || [],
          },
          updateImageOrders: e.data.urls.length > 0 ? createUIO(e.data.urls) : undefined,
        }));
        setMemberOrg(prev => ({ ...prev, selected: e.data.organizations.map(el => el.organization) }));
      },
    },
  );

  const { mutate: create } = useMutation(createSalestip, {
    onSuccess: () => {
      setUploadLoading(false);
      setLoading(false);
      navigate(`/${code}/salestip`);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (e: any) => {
      setUploadLoading(false);
      setLoading(false);
      createError({
        type: '[ADMIN] salestip - createSalestip',
        message: `message: "${e.message}",\nresponse: { status: ${e.response.status}, message: "${e.response.data.message}" }`,
      });
      throwError(e);
    },
  });

  const { mutate: update } = useMutation(editSalestip, {
    onSuccess: () => {
      setUploadLoading(false);
      setLoading(false);
      navigate(`/${code}/salestip`);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (e: any) => {
      setUploadLoading(false);
      setLoading(false);
      createError({
        type: '[ADMIN] salestip - editSalestip',
        message: `message: "${e.message}",\nresponse: { status: ${e.response.status}, message: "${e.response.data.message}" }`,
      });
      throwError(e);
    },
  });

  const setLoading = useSetRecoilState(loadingState);

  // 초기화
  const init = () => {
    setCategorySelector({ state: false, data: [], page: 1, categoryKey: [] });
    setCategory({ key: 0, name: '' });
    setTitle('');
    setContent('');
    setSdate(new Date());
    setEdate(new Date());
    setIsPublic(true);
    setUploadFile(null);
    setUploadImg(null);
    setUploadImgName('');
    initFile();
    setMemberOrg({
      state: false,
      selected: [],
      data: { mo_key: 0 },
    });
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => init(), [search]);

  const [imgCount, setImgCount] = useState<IImgUpload[]>([
    { id: 'thumbnail', total: 1, complete: 0 },
    { id: 'file', total: 0, complete: 0 },
  ]);
  const [uploadLoading, setUploadLoading] = useState(false);
  useEffect(() => {
    if (imgCount.filter(item => item.complete === item.total).length === imgCount.length) {
      if (key) {
        update({
          key: Number(key),
          data: {
            category: category?.key || 0,
            title,
            content,
            sdate: editDateFormat(sdate || new Date(), 'start'),
            edate: editDateFormat(edate || new Date(), 'end'),
            public: isPublic,
            thumbnail: uploadImgName.replaceAll('uploaded_', ''),
            imageMaps: img.imageMaps,
            groups: memberOrg.selected,
            updateImageOrders: img.updateImageOrders,
          },
        });
      } else {
        create({
          category: category?.key || 0,
          title,
          content,
          sdate: editDateFormat(sdate || new Date(), 'start'),
          edate: editDateFormat(edate || new Date(), 'end'),
          public: isPublic,
          thumbnail: uploadImgName,
          imageMaps: img.imageMaps,
          groups: memberOrg.selected,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imgCount]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formData = (_fields: any, _file: any) => {
    const data = new FormData();
    Object.keys(_fields).forEach(key => data.append(key, _fields[key]));
    if (_file) {
      data.append('file', _file);
    }
    return data;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fileUpload = async (url: string, form: any, idValue: string) => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data = (await axios.post(url, form)) as any;
      if (data.ok || data.status === 204) {
        setUploadLoading(true);
        setImgCount(prev => prev.map(item => (item.id === idValue ? { ...item, complete: item.complete + 1 } : item)));
      } else {
        console.log('error');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const createSalesPicture = async (type: string, extention: string[]) => {
    try {
      const { data } = await axios.post(
        `${api}/salestip/create/upload_picture`,
        type === 'thumbnail' ? { dir: 'thumbnail', extention } : { extention },
        { withCredentials: true },
      );
      if (type === 'thumbnail') {
        await setUploadImgName(data.data[0].name);
        await fileUpload(data.data[0].url.url, formData(data.data[0].url.fields, uploadFile), type);
      } else {
        const realIndex =
          img.imgFile.file
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .map((el: any, idx: number) => (typeof el === 'string' ? NaN : idx))
            // eslint-disable-next-line no-restricted-globals
            .filter((e: number) => !isNaN(e)) || false;

        await data.data.forEach((item: IUploadItem, idx: number) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const obj: any = {};
          if (!realIndex) {
            obj[idx + 1] = item.name;
          } else {
            obj[realIndex[idx] + 1] = item.name;
          }
          setImgAdd(obj);
        });

        await data.data.forEach((item: IUploadItem, idx: number) =>
          fileUpload(
            item.url.url,
            formData(
              item.url.fields,
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              img.imgFile.file.filter((e: any) => typeof e !== 'string')[idx],
            ),
            type,
          ),
        );
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      setLoading(false);
      console.log(e);
    }
  };

  const upload = async () => {
    setLoading(true);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (
      key &&
      uploadImgName.includes('uploaded_') &&
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      img.imgFile.file.filter((e: any) => typeof e !== 'string').length === 0
    ) {
      update({
        key: Number(key),
        data: {
          category: category?.key || 0,
          title,
          content,
          sdate: editDateFormat(sdate || new Date(), 'start'),
          edate: editDateFormat(edate || new Date(), 'end'),
          public: isPublic,
          thumbnail: uploadImgName.replaceAll('uploaded_', ''),
          imageMaps: img.imageMaps,
          groups: memberOrg.selected,
          updateImageOrders: img.updateImageOrders,
        },
      });
      return;
    }
    if (!uploadImgName.includes('uploaded_'))
      await createSalesPicture('thumbnail', [uploadImgName.split('.')[uploadImgName.split('.').length - 1]]);
    else setImgCount(prev => prev.map(item => (item.id === 'thumbnail' ? { ...item, complete: 1 } : item)));
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (img.imgFile.imgName.filter(e => !e.includes('uploaded_')).length > 0) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await createSalesPicture(
        'file',
        img.imgFile.imgName.filter(e => !e.includes('uploaded_')).map(el => el.split('.')[el.split('.').length - 1]),
      );
    }
  };
  const videoStatusCheck = async (hash: string) => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_FFMPEG_TRANS}/status/${hash}`, {
        withCredentials: true,
      });
      if (data.status === 'WAIT' || data.status === 'PROCESSING') setTimeout(() => videoStatusCheck(data.hash), 2000);
      else if (data.status === 'COMPLETE') downloadVideo(data.hash);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const downloadVideo = async (hash: string) => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_FFMPEG_TRANS}/download/${hash}`, {
        withCredentials: true,
        responseType: 'blob',
      });
      const randomId = Math.random().toString().split('.')[1];
      const file = new File([data], `${randomId}_video_${img.imgFile.img.length}.mp4`, { type: 'video/mp4' });
      setFile(file, file.name, file.name);
      try {
        const reader: FileReader = new FileReader();
        reader.onload = (event: ProgressEvent<FileReader>) => {
          console.log(event);
        };
        reader.onerror = error => {
          console.log(error);
        };
        reader.readAsDataURL(file);
        setImgCount(prev => prev.map(item => (item.id === 'file' ? { ...item, total: item.total + 1 } : item)));
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const changeVideo = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (imgCount[1].total >= 30) {
      alertShow('첨부 파일은 최대 30개까지 등록할 수 있습니다.');
      return;
    }
    if (!event?.target?.files) {
      initFile();
      return;
    }
    const maxSize = 50 * 1024 * 1024;
    if (event.target.files[0].size > maxSize) {
      alertShow('첨부 파일의 사이즈는 50MB 이내로 등록 가능합니다.');
      return;
    }
    const extention = getExtension(event.target.files[0].name);
    if (extention !== '.mp4' && extention !== '.mkv' && extention !== '.webm') {
      alertShow('mp4, mkv, webm 형식의 파일만 등록할 수 있습니다.');
      return;
    }
    if (extention === '.mp4') {
      const ele = event.target.files[0];
      const randomId = Math.random().toString().split('.')[1];
      setFile(ele, `${randomId}_video${extention}`, `${randomId}_video${extention}`);
      try {
        const reader: FileReader = new FileReader();
        reader.onload = (event: ProgressEvent<FileReader>) => {
          console.log(event);
        };
        reader.onerror = error => {
          console.log(error);
        };
        reader.readAsDataURL(event.target.files[0]);
        // eslint-disable-next-line no-param-reassign
        event.target.value = '';
      } catch (error) {
        console.log(error);
      }
      setImgCount(prev => prev.map(item => (item.id === 'file' ? { ...item, total: item.total + 1 } : item)));
      return;
    }
    const formData = new FormData();
    formData.append('file', event.target.files[0]);
    setLoading(true);
    try {
      const { data } = await axios.post(`${process.env.REACT_APP_FFMPEG_TRANS}/upload`, formData, {
        withCredentials: true,
      });
      videoStatusCheck(data.hash);
      // eslint-disable-next-line no-param-reassign
      event.target.value = '';
    } catch (error) {
      setLoading(false);
      console.log(error);
      // eslint-disable-next-line no-param-reassign
      event.target.value = '';
    }
  };

  return (
    <Page title={key ? '세일즈팁수정' : '세일즈팁등록'} isLoading={orgLoading || getSalestipLoading}>
      <FormItem title="카테고리" essential>
        <div className="input">
          <input
            type="text"
            placeholder={t('카테고리') || ''}
            value={category.name}
            readOnly
            className="combo"
            onClick={() => setCategorySelector(prev => ({ ...prev, state: true }))}
          />
          <div className="arrow"></div>
        </div>
      </FormItem>
      <FormItem title="제목" essential>
        <div className="input">
          <input type="text" placeholder={t('제목') || ''} value={title} onChange={e => setTitle(e.target.value)} />
        </div>
      </FormItem>
      <FormItem title="내용" essential>
        <div className="input">
          <textarea placeholder={t('내용') || ''} value={content} onChange={e => setContent(e.target.value)}></textarea>
        </div>
      </FormItem>
      <FormItem title="기간" essential>
        <div className="input" style={{ maxWidth: '608px' }}>
          <DatePicker
            selected={sdate}
            onChange={date => setSdate(date)}
            minDate={null}
            maxDate={edate}
            selectsStart
            startDate={sdate}
            endDate={edate}
            locale={cookies['xclass-lang'] === 'ko' ? ko : undefined}
            dateFormat="yyyy-MM-dd"
            disabledKeyboardNavigation
            onChangeRaw={(e: React.FormEvent<HTMLInputElement>) => e.preventDefault()}
          />
          <div style={{ margin: '0 8px', color: 'var(--greyish1)' }}>-</div>
          <DatePicker
            selected={edate}
            onChange={date => setEdate(date)}
            maxDate={null}
            selectsEnd
            startDate={sdate}
            endDate={edate}
            locale={cookies['xclass-lang'] === 'ko' ? ko : undefined}
            dateFormat="yyyy-MM-dd"
            disabledKeyboardNavigation
            onChangeRaw={(e: React.FormEvent<HTMLInputElement>) => e.preventDefault()}
          />
        </div>
      </FormItem>
      <FormItem title="공개여부" essential>
        <div className="switch">
          <div className={classNames('item', isPublic && 'active')} onClick={() => setIsPublic(prev => !prev)}>
            {t('공개')}
          </div>
          <div className={classNames('item', !isPublic && 'active')} onClick={() => setIsPublic(prev => !prev)}>
            {t('비공개')}
          </div>
        </div>
      </FormItem>
      <FormItem title="섬네일" essential>
        <div className="file">
          <div className="uploaded" style={{ display: uploadImg ? '' : 'none' }}>
            <img src={uploadImg || ''} alt="img" ref={imgRef} />
          </div>
          <label htmlFor="img-upload">
            {uploadImgName === '' ? (
              <div className="placeholder">
                <span>{t('섬네일 업로드 (용량 1024KB 이내의 jpg, png 파일)')}</span>
              </div>
            ) : (
              <div className="text">
                <span>{uploadImgName.replaceAll('uploaded_', '')}</span>
              </div>
            )}
            <input type="file" id="img-upload" onChange={e => changeThumb(e)} accept="image/*" />
          </label>
        </div>
      </FormItem>
      <FormItem title="첨부파일" essential>
        <div className="input">
          <label htmlFor="imgUpload" className="btn success">
            {t('이미지')}
            <input type="file" id="imgUpload" onChange={changeImage} accept="image/*" />
          </label>
          <label htmlFor="videoUpload" className="btn primary">
            {t('비디오')}
            <input type="file" id="videoUpload" onChange={changeVideo} accept="video/*,.mkv" />
          </label>
          <label htmlFor="pdfUpload" className="btn danger">
            PDF
            <input type="file" id="pdfUpload" onChange={changePdf} accept="application/pdf" />
          </label>
        </div>
      </FormItem>
      <div className="imgUpload">
        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
        {img.imgFile.img.map((el: string, idx: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className="item" key={idx}>
            {el.includes('video') || el.includes('.mp4') || el.includes('.mkv') || el.includes('webm') ? (
              <object style={{ display: el ? '' : 'none' }}>{icMovie()}</object>
            ) : (
              <img src={el} alt="logo" style={{ display: el ? '' : 'none' }} />
            )}
            <div className="tool">
              <div className="btns">
                <img src={icArrowCircleLeft} alt="icon" onClick={() => setFileMove(idx, 'prev')} />
                <img src={icArrowCircleRight} alt="icon" onClick={() => setFileMove(idx, 'next')} />
                <img
                  src={icDelete}
                  alt="icon"
                  onClick={() => confirmShow('이미지를 제거하시겠습니까?', () => setFileDelete(idx))}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
      <div style={{ display: 'none' }}>
        <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}></Document>
      </div>
      <FormItem title="조직" essential>
        <div className="input">
          <input
            type="text"
            placeholder={t('조직') || ''}
            value={
              flatOrg(memberOrg.data)
                .concat({ mo_key: memberOrg.data.mo_key })
                .filter(ele => memberOrg.selected.includes(ele.mo_key)).length > 0
                ? flatOrg(memberOrg.data)
                    .concat({ mo_key: memberOrg.data.mo_key })
                    .filter(ele => memberOrg.selected.includes(ele.mo_key))
                    .map(el => el.mo_name)
                    .join(', ').length === 0
                  ? cookies['xclass-cname'] || ''
                  : flatOrg(memberOrg.data)
                      .concat({ mo_key: memberOrg.data.mo_key })
                      .filter(ele => memberOrg.selected.includes(ele.mo_key))
                      .map(el => el.mo_name)
                      .join(', ')
                : ''
            }
            readOnly
            className="combo"
            onClick={() => setMemberOrg(prev => ({ ...prev, state: true }))}
          />
          <div className="arrow"></div>
        </div>
      </FormItem>
      {uploadLoading && (
        <ImgUploadLoading>
          {imgCount
            .filter(item => item.total !== 0)
            .map(item => (
              <div className="imgUploadLoadingItem" key={item.id}>
                <div className="gauge">
                  <div className="inner" style={{ width: `calc(${(item.complete / item.total) * 100}%)` }}></div>
                </div>
                <div className="text">
                  {item.complete} / {item.total}
                </div>
              </div>
            ))}
        </ImgUploadLoading>
      )}
      <div className="formSubmit">
        <button
          type="button"
          className="primary"
          onClick={() => {
            if (!category.name) {
              alertShow('카테고리를 선택하십시오.');
              return;
            }
            if (!title) {
              alertShow('제목을 입력하십시오.');
              return;
            }
            if (!content) {
              alertShow('내용을 입력하십시오.');
              return;
            }
            if (sdate && edate && sdate >= edate) {
              alertShow('기간을 확인하십시오.');
              return;
            }
            if (!uploadImgName) {
              alertShow('섬네일을 등록하십시오.');
              return;
            }
            if (img.imgFile.img.length === 0) {
              alertShow('첨부파일을 1개 이상 등록하십시오.');
              return;
            }
            confirmShow(key ? '수정하시겠습니까?' : '등록하시겠습니까?', upload);
          }}
        >
          {key ? t('수정') : t('등록')}
        </button>
      </div>
      <SalestipCategorySelector onSelect={setCategory} />
      <InspectionOrg />
    </Page>
  );
}

export default SalestipEdit;
