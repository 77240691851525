import { motion, AnimatePresence } from 'framer-motion';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { promptState, usePrompt } from '../../stores/promptStore';
import { icConfirm } from '../aside/icon';

// components
import Modal from './modal';

// style
import styles from './dialog.module.scss';

function Prompt() {
  const { t } = useTranslation();
  const prompt = useRecoilValue(promptState);
  const { promptCheck, promptClose } = usePrompt();
  const [value, setValue] = useState('');

  return (
    <AnimatePresence>
      {prompt.state && (
        <Modal close={() => promptClose()}>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            className={styles.modal}
          >
            <div className={styles.circle}>{icConfirm()}</div>
            {/* eslint-disable-next-line react/no-danger */}
            <div className={styles.message} dangerouslySetInnerHTML={{ __html: t(prompt.message) }}></div>
            <div className={styles.input}>
              <input
                type="text"
                placeholder={prompt.placeholder || ''}
                onChange={e => setValue(e.target.value)}
                onKeyPress={e => {
                  if (e.key === 'Enter' || e.code === 'Enter') promptCheck(value);
                }}
              />
            </div>
            <div className={styles.btns}>
              <div className={styles.btn} onClick={() => promptCheck(value)}>
                {t('확인')}
              </div>
              <div className={styles.btn} onClick={() => promptClose()}>
                {t('취소')}
              </div>
            </div>
          </motion.div>
        </Modal>
      )}
    </AnimatePresence>
  );
}

export default Prompt;
