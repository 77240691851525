import queryString from 'query-string';
import DatePicker from 'react-datepicker';
import ko from 'date-fns/locale/ko';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getOrgList } from '../../api/member';
import { createError, throwError } from '../../api/error';
import { createSurvey, getSurveyDetail, updateSurvey } from '../../api/survey';
import { useAlert } from '../../stores/alertStore';
import { useConfirm } from '../../stores/confirmStore';
import { surveyProbState, useSurvey } from '../../stores/surveyStore';
import { memberOrgMultyState } from '../../stores/memberStore';
import { flatOrg } from '../../assets/util/flatOrg';
import { editDateFormat } from '../../assets/util/dateFormat';

// components
import Page from '../../includes/page';
import FormItem from '../../components/basic/formItem';
import SurveyItem from '../../components/survey/item';
import DetailSurveyItem from '../../components/survey/detailItem';
import AddSurveyItem from '../../components/survey/addItem';
import InspectionOrg from '../../components/inspection/org';

function SurveyEdit() {
  const { t } = useTranslation();
  const [cookies] = useCookies(['xclass-lang', 'xclass-cname']);
  const { code } = useParams();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { surveyKey } = queryString.parse(search);

  const { alertShow } = useAlert();
  const { confirmShow } = useConfirm();
  const { initSurveyProb } = useSurvey();

  const [surveyProbs, setSurveyProbs] = useRecoilState(surveyProbState);

  // 조직
  const [orgAleady, setOrgAleady] = useState(false);
  const [memberOrg, setMemberOrg] = useRecoilState(memberOrgMultyState);
  const { isFetching: orgLoading } = useQuery<{ data: IOrg }>(['org'], getOrgList, {
    refetchOnWindowFocus: false,
    onSuccess: e => {
      setMemberOrg(prev => ({ ...prev, data: e.data }));
      setOrgAleady(true);
    },
  });

  const [title, setTitle] = useState('');
  useEffect(() => {
    setTitle('');
    initSurveyProb();
    setMemberOrg({
      state: false,
      selected: [],
      data: { mo_key: 0 },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  // 기간
  const [sdate, setSdate] = useState<Date | null>(new Date());
  const [edate, setEdate] = useState<Date | null>(new Date());
  useEffect(() => {
    if (Number(edate) < Number(sdate)) setEdate(sdate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sdate, edate]);

  const { isFetching: getLoading } = useQuery<{ data: { survey: ISurveyDetail } }>(
    ['surveyProbs'],
    () => getSurveyDetail(Number(surveyKey)),
    {
      refetchOnWindowFocus: false,
      enabled: orgAleady && surveyKey !== undefined,
      onSuccess: e => {
        setTitle(e.data.survey.sv_title);
        setSdate(new Date(e.data.survey.sv_start_date));
        setEdate(new Date(e.data.survey.sv_end_date));
        setSurveyProbs(
          e.data.survey.prob.map(item => ({
            surveyProbKey: item.survey_prob_key,
            surveyProbType: item.svpb_type,
            surveyProbEssential: item.svpb_essential,
            surveyProbQuestion: item.svpb_question,
            surveyProbSelect: item.svpb_select,
          })),
        );
        setMemberOrg(prev => ({ ...prev, selected: e.data.survey.organizations.map(el => el.organization) }));
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (e: any) => alertShow(e.response.data.message),
    },
  );

  const { mutate: create, isLoading: createLoading } = useMutation(createSurvey, {
    onSuccess: () => navigate(`/${code}/survey`),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (e: any) => {
      createError({
        type: '[ADMIN] survey - createSurvey',
        message: `message: "${e.message}",\nresponse: { status: ${e.response.status}, message: "${e.response.data.message}" }`,
      });
      throwError(e);
    },
  });

  const { mutate: update, isLoading: updateLoading } = useMutation(updateSurvey, {
    onSuccess: () => navigate(`/${code}/survey`),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (e: any) => {
      createError({
        type: '[ADMIN] survey - updateSurvey',
        message: `message: "${e.message}",\nresponse: { status: ${e.response.status}, message: "${e.response.data.message}" }`,
      });
      throwError(e);
    },
  });

  const onCreate = () => {
    if (memberOrg.selected.length === 0) {
      alertShow('조직을 선택하십시오.');
      return;
    }
    if (title.length === 0) {
      alertShow('설문 제목을 입력하십시오.');
      return;
    }
    if (surveyProbs.length === 0) {
      alertShow('설문 문항을 추가하십시오.');
      return;
    }
    if (surveyProbs.filter(item => item.surveyProbQuestion === '').length > 0) {
      alertShow('설문 문항 중 빈 항목이 있습니다.');
      return;
    }
    if (surveyKey) {
      confirmShow('수정하시겠습니까?', () =>
        update({
          sdate: editDateFormat(sdate || new Date(), 'start'),
          edate: editDateFormat(edate || new Date(), 'end'),
          surveyKey: Number(surveyKey),
          surveyTitle: title,
          surveyProbList: surveyProbs,
          groups: memberOrg.selected,
        }),
      );
      return;
    }
    confirmShow('등록하시겠습니까?', () =>
      create({
        sdate: editDateFormat(sdate || new Date(), 'start'),
        edate: editDateFormat(edate || new Date(), 'end'),
        surveyTitle: title,
        surveyProbList: surveyProbs,
        groups: memberOrg.selected,
      }),
    );
  };

  return (
    <Page
      title={surveyKey ? '설문조사수정' : '설문조사등록'}
      isLoading={orgLoading || getLoading || createLoading || updateLoading}
    >
      <FormItem title="조직" essential>
        <div className="input">
          <input
            type="text"
            placeholder={t('조직') || ''}
            value={
              flatOrg(memberOrg.data)
                .concat({ mo_key: memberOrg.data.mo_key })
                .filter(ele => memberOrg.selected.includes(ele.mo_key)).length > 0
                ? flatOrg(memberOrg.data)
                    .concat({ mo_key: memberOrg.data.mo_key })
                    .filter(ele => memberOrg.selected.includes(ele.mo_key))
                    .map(el => el.mo_name)
                    .join(', ').length === 0
                  ? cookies['xclass-cname'] || ''
                  : flatOrg(memberOrg.data)
                      .concat({ mo_key: memberOrg.data.mo_key })
                      .filter(ele => memberOrg.selected.includes(ele.mo_key))
                      .map(el => el.mo_name)
                      .join(', ')
                : ''
            }
            readOnly
            className="combo"
            onClick={() => setMemberOrg(prev => ({ ...prev, state: true }))}
          />
          <div className="arrow"></div>
        </div>
      </FormItem>
      <FormItem title="설문 제목" essential={false}>
        <div className="input">
          <input
            type="text"
            placeholder={t('설문 제목') || ''}
            value={title || ''}
            onChange={e => setTitle(e.target.value)}
          />
        </div>
      </FormItem>

      <FormItem title="기간" essential>
        <div className="input" style={{ maxWidth: '608px' }}>
          <DatePicker
            selected={sdate}
            onChange={date => setSdate(date)}
            minDate={null}
            maxDate={edate}
            selectsStart
            startDate={sdate}
            endDate={edate}
            locale={cookies['xclass-lang'] === 'ko' ? ko : undefined}
            dateFormat="yyyy-MM-dd"
            disabledKeyboardNavigation
            onChangeRaw={(e: React.FormEvent<HTMLInputElement>) => e.preventDefault()}
          />
          <div style={{ margin: '0 8px', color: 'var(--greyish1)' }}>-</div>
          <DatePicker
            selected={edate}
            onChange={date => setEdate(date)}
            maxDate={null}
            selectsEnd
            startDate={sdate}
            endDate={edate}
            locale={cookies['xclass-lang'] === 'ko' ? ko : undefined}
            dateFormat="yyyy-MM-dd"
            disabledKeyboardNavigation
            onChangeRaw={(e: React.FormEvent<HTMLInputElement>) => e.preventDefault()}
          />
        </div>
      </FormItem>
      {!surveyKey &&
        surveyProbs.map((item: ISurveyProps, idx: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <SurveyItem item={item} idx={idx} key={idx}></SurveyItem>
        ))}
      {surveyKey &&
        surveyProbs.map((item: ISurveyProps, idx: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <DetailSurveyItem item={item} key={idx} idx={idx}></DetailSurveyItem>
        ))}
      <AddSurveyItem />
      <div className="formSubmit">
        <button type="button" className="primary" onClick={onCreate}>
          {surveyKey ? t('수정') : t('등록')}
        </button>
      </div>
      <InspectionOrg />
    </Page>
  );
}

export default SurveyEdit;
