import { useInspection } from '../../stores/inspectionStore';

// style
import styles from './item.module.scss';

// img
import { icAdd } from '../aside/icon';

function InspectionAddItem({ isEdit }: { isEdit: boolean }) {
  const { setInspectionAdd } = useInspection();
  return (
    <div className={styles.addItem} onClick={() => setInspectionAdd(isEdit)}>
      {icAdd()}
    </div>
  );
}

export default InspectionAddItem;
