import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { alertState, useAlert } from '../../stores/alertStore';
import { icAlert } from '../aside/icon';

// components
import Modal from './modal';

// style
import styles from './dialog.module.scss';

function Alert() {
  const { t } = useTranslation();
  const alert = useRecoilValue(alertState);
  const { alertClose } = useAlert();
  return (
    <AnimatePresence>
      {alert.state && (
        <Modal close={() => alertClose()}>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            className={styles.modal}
          >
            <div className={styles.circle}>{icAlert()}</div>
            {alert.messageList.length === 0 ? (
              // eslint-disable-next-line react/no-danger
              <div className={styles.message} dangerouslySetInnerHTML={{ __html: t(alert.message) }}></div>
            ) : (
              <div
                className={styles.message}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: alert.messageList
                    .map(item => {
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      const obj: any = {};
                      if (item.messageKey && item.messageVar) {
                        obj[item.messageKey] = item.messageVar;
                        return t(item.message, obj);
                      }
                      return t(item.message);
                    })
                    .join('\n'),
                }}
              ></div>
            )}
            <div className={styles.btns}>
              <div className={styles.btn} onClick={() => alertClose()}>
                {t('확인')}
              </div>
            </div>
          </motion.div>
        </Modal>
      )}
    </AnimatePresence>
  );
}
export default Alert;
