import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { useConfirm } from '../../stores/confirmStore';
import { categorySelectorState } from '../../stores/salestipStore';

// style
import styles from '../member/orgItem.module.scss';

// eslint-disable-next-line react/require-default-props
function Child({ item, onSelect }: { item: ISC; onSelect?: (element: { key: number; name: string }) => void }) {
  const [categorySelector, setCategorySelector] = useRecoilState(categorySelectorState);
  return (
    <div className={styles.container}>
      <div className={styles.org}>
        <div className={styles.arrow} style={{ opacity: 0 }}></div>
        <div className={styles.inner}>
          <div className={styles.name}>{item.name}</div>
        </div>
        <div
          className={classNames(
            styles.select,
            categorySelector.categoryKey.map(el => el.key).includes(item.key) && styles.active,
          )}
          onClick={() => {
            if (onSelect) {
              if (categorySelector.categoryKey.map(el => el.key).includes(item.key)) {
                onSelect({ key: 0, name: '' });
              } else {
                onSelect({ key: item.key, name: item.name });
              }
            }
            setCategorySelector(prev => {
              if (categorySelector.categoryKey.map(el => el.key).includes(item.key)) {
                return { ...prev, categoryKey: prev.categoryKey.filter(el => el.key !== item.key) };
              }
              if (onSelect)
                return {
                  ...prev,
                  groupKey: undefined,
                  categoryKey: [{ key: item.key, name: item.name, group: item.groupKey }],
                };
              return {
                ...prev,
                groupKey: undefined,
                categoryKey: prev.categoryKey
                  .filter(el => el.group === item.groupKey)
                  .concat({ key: item.key, name: item.name, group: item.groupKey }),
              };
            });
          }}
        ></div>
      </div>
    </div>
  );
}

function SalestipCategorySelectorItem({
  item,
  getChild,
  onSelect,
}: {
  item: ISCG;
  getChild: (e: number) => void;
  // eslint-disable-next-line react/require-default-props
  onSelect?: (element: { key: number; name: string }) => void;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { code } = useParams();
  const { confirmShow } = useConfirm();

  const [open, setOpen] = useState(false);

  const [categorySelector, setCategorySelector] = useRecoilState(categorySelectorState);

  return (
    <div className={styles.container}>
      <div className={styles.org}>
        <div className={classNames(styles.arrow, open && styles.active)}></div>
        <div
          className={styles.inner}
          onClick={() => {
            if (!open) getChild(item.key);
            setOpen(prev => !prev);
          }}
        >
          <div className={styles.name}>{item.name}</div>
        </div>
        {!onSelect && (
          <div
            className={classNames(styles.select, categorySelector.groupKey?.key === item.key && styles.active)}
            onClick={() => {
              setCategorySelector(prev => {
                if (categorySelector.groupKey?.key === item.key) return { ...prev, groupKey: undefined };
                return {
                  ...prev,
                  groupKey: { key: item.key, name: item.name },
                  categoryKey: prev.categoryKey.filter(el => el.group === item.key),
                };
              });
            }}
          ></div>
        )}
      </div>
      {open && (
        <div className={styles.children}>
          {item.children?.map(child => (
            <Child key={child.key} item={child} onSelect={onSelect} />
          ))}
          {item.children?.length === 0 && (
            <div className={styles.container}>
              <div className={styles.org}>
                <div className={styles.arrow} style={{ opacity: 0 }}></div>
                <div className={styles.inner}>
                  <div className={styles.name} style={{ width: 'unset' }}>
                    {t('하위 카테고리를 생성하십시오.')}
                  </div>
                </div>
                <div className={classNames(styles.select, styles.link)} onClick={() => confirmShow('수정하시겠습니까?', () => navigate(`/${code}/salestip/category`))}></div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default SalestipCategorySelectorItem;
