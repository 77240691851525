import classNames from 'classnames';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { createError, throwError } from '../../api/error';
import {
  deleteSalestip,
  getSalestipCategoryGroupList,
  getSalestipCategoryList,
  getSalestipList,
} from '../../api/salestip';
import { useAlert } from '../../stores/alertStore';
import { useConfirm } from '../../stores/confirmStore';
import { decrypt } from '../../assets/util/crypto';

// components
import Page from '../../includes/page';
import SalestipCategoryChoice from '../../components/salestip/categoryChoice';

// img
import Pagination from '../../includes/pagination copy';

function Salestip() {
  const { t } = useTranslation();
  const { pathname, search } = useLocation();
  const { key, page } = queryString.parse(search);
  const navigate = useNavigate();
  const { code } = useParams();
  const { alertShow } = useAlert();
  const { confirmShow } = useConfirm();
  const [cookies] = useCookies(['XID']);

  // 카테고리
  const [categoryStatus, setCategoryStatus] = useState(false);
  const [cg, setCg] = useState<number[]>([]);
  const [category, setCategory] = useState<ISCG[]>([]);
  const [categoryReady, setCategoryReady] = useState(false);
  const { isFetching: getCategoryGroupLoading } = useQuery<{ data: ISCG[] }>(
    ['categoryGroup'],
    getSalestipCategoryGroupList,
    {
      refetchOnWindowFocus: false,
      onSuccess: e => {
        setCategory(e.data.map(item => ({ ...item, categoryKey: [] })));
        setCg(e.data.map(item => item.key));
        setCategoryReady(true);
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (e: any) => alertShow(e.response.data.message),
    },
  );

  const { isFetching: getSalestipCategoryListLoading } = useQuery<{ data: ISC[] }>(
    ['category', cg],
    () => getSalestipCategoryList(cg),
    {
      refetchOnWindowFocus: false,
      enabled: cg.length !== 0 && categoryReady,
      onSuccess: e => {
        setCategory(prev =>
          prev.map(item =>
            cg.includes(item.key) ? { ...item, children: e.data.filter(el => el.groupKey === item.key) } : item,
          ),
        );
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (e: any) => alertShow(e.response.data.message),
    },
  );
  const [count, setCount] = useState(0);
  const [data, setData] = useState<ISalestip[]>([]);
  const { refetch } = useQuery<{ count: number; data: ISalestip[] }>(
    ['salestip', page, key],
    () =>
      getSalestipList({
        page: page === undefined ? 1 : Number(page),
        categoryKeys:
          key === undefined
            ? []
            : String(key)
                .split(',')
                .map(e => Number(e)),
      }),
    {
      refetchOnWindowFocus: false,
      onSuccess: e => {
        if (Number(page) > 1 && e.data.length < 1)
          navigate({ pathname, search: `${key !== undefined ? `key=${key}&` : ''}page=1` });
        if (key) setCategoryStatus(true);
        setCount(e.count || 1);
        setData(e.data.map(item => ({ ...item, checked: false })));
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (e: any) => alertShow(e.response.data.message),
    },
  );
  const { mutate: remove, isLoading: deleteLoading } = useMutation(deleteSalestip, {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (e: any) => {
      createError({
        type: '[ADMIN] salestip - deleteSalestip',
        message: `message: "${e.message}",\nresponse: { status: ${e.response.status}, message: "${e.response.data.message}" }`,
      });
      throwError(e);
    },
  });

  const selectDelete = () =>
    confirmShow('선택한 항목을 삭제하시겠습니까?', () => {
      const checked = data.filter(item => item.checked);
      for (let i = 0; i < checked.length; i += 1) remove(checked[i].key);
    });

  useEffect(() => {
    if (!deleteLoading) refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteLoading]);

  const allSelect = () =>
    setData(
      data.map(item => ({
        ...item,
        checked: data.filter(item => item.checked).length !== data.length,
      })),
    );

  return (
    <Page title="세일즈팁목록" isLoading={getSalestipCategoryListLoading || deleteLoading}>
      <div className="table-tools">
        <div className="btns">
          <button type="button" className="danger" onClick={allSelect}>
            <span>{t('전체선택')}</span>
          </button>
          <button type="button" className="primary" onClick={() => navigate(`/${code}/salestip/edit`)}>
            <span>{t('등록')}</span>
          </button>
          <button
            type="button"
            className={classNames('secondary', data.filter(item => item.checked).length === 0 && 'disabled')}
            onClick={selectDelete}
          >
            <span>{t('선택삭제')}</span>
          </button>
        </div>
        <div className="btns">
          <button type="button" className="info" onClick={() => setCategoryStatus(prev => !prev)}>
            {t('카테고리')}
          </button>
        </div>
      </div>
      <div style={{ display: !categoryStatus ? 'none' : '' }}>
        <SalestipCategoryChoice
          category={category}
          categoryKeys={
            key === undefined
              ? []
              : String(key)
                  .split(',')
                  .map(e => Number(e))
          }
          setCategoryKeys={
            e =>
              navigate(
                `?${
                  key === undefined
                    ? `key=${e}&`
                    : String(key)
                        .split(',')
                        .map(el => Number(el))
                        .includes(e)
                    ? String(key)
                        .split(',')
                        .map(el => Number(el)).length === 1
                      ? ''
                      : `key=${String(key)
                          .split(',')
                          .filter(el => Number(el) !== e)
                          .join(',')}&`
                    : `key=${String(key)
                        .split(',')
                        .map(el => Number(el))
                        .concat(e)
                        .join(',')}&`
                }page=1`,
              )
            // setCategoryKeys(prev => (prev.includes(e) ? prev.filter(el => el !== e) : [...prev, e]))
          }
        />
      </div>
      <div className="table-tools">
        <div className="btns"></div>
        {data.filter(item => item.checked).length > 0 && (
          <div className="selected">
            {t('개의 항목을 선택했습니다.', { amount: data.filter(item => item.checked).length })}
          </div>
        )}
      </div>
      <div className="table-container">
        <table className="table">
          <thead>
            <tr>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <th></th>
              <th>{t('섬네일')}</th>
              <th>{t('카테고리')}</th>
              <th>{t('작성자')}</th>
              <th>{t('제목')}</th>
              <th>{t('상태')}</th>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {getCategoryGroupLoading && (
              <tr>
                <td colSpan={9}>
                  <div>
                    <div className="lds-spinner">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </td>
              </tr>
            )}
            {!getCategoryGroupLoading && data.length === 0 && (
              <tr>
                <td colSpan={7}>
                  <div>{t('검색된 목록이 없습니다.')}</div>
                </td>
              </tr>
            )}
            {data.map(item => (
              <tr key={item.key}>
                <td>
                  <div>
                    <div
                      className={classNames(
                        'checkbox',
                        data.filter(ele => ele.key === item?.key)[0]?.checked && 'active',
                      )}
                      onClick={() =>
                        setData(
                          data.map(ele => {
                            if (ele.key === item.key) return { ...ele, checked: !ele.checked };
                            return ele;
                          }),
                        )
                      }
                    ></div>
                  </div>
                </td>
                <td>
                  <div>
                    <div className="thumbnail">
                      <div className="thumbnail__inner">
                        <img src={`${process.env.REACT_APP_UPLOADED_URL}/${item.thumbnail}`} alt="img" />
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <span>{item.category.name}</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>{item.creatorName}</span>
                  </div>
                </td>
                <td>
                  <div className="title">
                    <span className="link" onClick={() => navigate(`/${code}/salestip/detail?key=${item.key}`)}>
                      {item.title}
                    </span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>{item.public ? t('공개') : t('비공개')}</span>
                  </div>
                </td>
                <td>
                  <div>
                    {(decrypt(cookies.XID).mg_code === 'ADMIN' || decrypt(cookies.XID).m_id === item.creatorName) && (
                      <>
                        <button
                          type="button"
                          className="primary"
                          onClick={() => navigate(`/${code}/salestip/edit?key=${item.key}`)}
                        >
                          <span>{t('수정')}</span>
                        </button>
                        <button
                          type="button"
                          className="secondary"
                          onClick={() => confirmShow('삭제하시겠습니까?', () => remove(item.key))}
                        >
                          <span>{t('삭제')}</span>
                        </button>
                      </>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        total={count || 0}
        curr={page === undefined ? 1 : Number(page)}
        movePage={e => navigate(`?${key === undefined ? '' : `key=${key}&`}page=${e}`)}
      />
    </Page>
  );
}

export default Salestip;
