import { atom, useRecoilState } from 'recoil';

export const promptState = atom<IPrompt>({
  key: 'prompt',
  default: {
    state: false,
    message: '',
    func: null,
    placeholder: '',
  },
});

export function usePrompt() {
  const [prompt, setPrompt] = useRecoilState(promptState);

  const promptShow = (msg: string, fnc: ((e: string) => void) | null, placeholder?: string) =>
    setPrompt({ state: true, message: msg, func: fnc, placeholder });

  const promptCheck = (value: string) => {
    if (prompt.func) prompt.func(value);
    setPrompt({ state: false, message: '', func: null, placeholder: '' });
  };

  const promptClose = () => setPrompt({ state: false, message: '', func: null, placeholder: '' });

  return { promptShow, promptCheck, promptClose };
}
