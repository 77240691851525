import { atom, useRecoilState } from 'recoil';

export const alertState = atom<IAlert>({
  key: 'alert',
  default: {
    state: false,
    message: '',
    func: null,
    messageList: [],
  },
});

export function useAlert() {
  const [alert, setAlert] = useRecoilState(alertState);

  const alertShow = (msg: string, fnc?: (() => void) | null) =>
    setAlert({ state: true, message: msg, func: fnc ?? null, messageList: [] });

  const alertTransShow = (
    msg: { message: string; messageKey?: string; messageVar?: string }[],
    fnc?: (() => void) | null,
  ) => setAlert({ state: true, message: '', func: fnc ?? null, messageList: msg });

  const alertClose = () => {
    if (alert.func) alert.func();
    setAlert({ state: false, message: '', func: null, messageList: [] });
  };

  return { alertShow, alertTransShow, alertClose };
}
