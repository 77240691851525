import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useRecoilState } from 'recoil';
import { memberOrgState } from '../../stores/memberStore';

// style
import styles from './orgItem.module.scss';

function OrgItem({ item }: { item: IOrg }) {
  const [cookies] = useCookies(['xclass-cname']);
  const [memberOrg, setMemberOrg] = useRecoilState(memberOrgState);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (!item.mo_name) setOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={styles.container}>
      <div className={styles.org}>
        <div className={classNames(styles.arrow, open && styles.active)} onClick={() => setOpen(prev => !prev)}></div>
        <div className={styles.inner} onClick={() => setOpen(prev => !prev)}>
          <div className={styles.name}>{item.mo_name || cookies['xclass-cname'] || ''}</div>
        </div>
        <div
          className={classNames(styles.select, memberOrg.selected === item.mo_key && styles.active)}
          onClick={() => setMemberOrg(prev => ({ ...prev, selected: prev.selected === item.mo_key ? 0 : item.mo_key }))}
        ></div>
      </div>
      {open && (
        <div className={styles.children}>
          {item.children?.map(child => (
            <OrgItem item={child} key={child.mo_key} />
          ))}
        </div>
      )}
    </div>
  );
}

export default OrgItem;
