import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { codeValidation } from '../../api/customer';
import { login } from '../../api/member';
import { useAlert } from '../../stores/alertStore';
import { encrypt } from '../../assets/util/crypto';
import { existImg } from '../../assets/util/existImg';

// style
import styles from './login.module.scss';

// img
import faviconEmpty from '../../assets/img/favicon_empty.svg';
import logo from '../../assets/img/logo.svg';
import logoDark from '../../assets/img/logo_dark.svg';
import logoEmpty from '../../assets/img/logo_empty.svg';
import icVisibility from '../../assets/img/icons/ic_visibility.svg';
import icVisibilityOff from '../../assets/img/icons/ic_visibility_off.svg';

function Login() {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { code } = useParams();
  const navigate = useNavigate();
  const { alertShow } = useAlert();

  const logoRef = useRef<HTMLImageElement>(null);
  const [noImage, setNoImage] = useState(false);

  const [idValue, setIdValue] = useState('');
  const [passValue, setPassValue] = useState('');
  const [passView, setPassView] = useState(false);

  const idRef = useRef<HTMLInputElement>(null);
  const passRef = useRef<HTMLInputElement>(null);

  const [cookies, setCookie, removeCookie] = useCookies([
    'xclass-theme',
    'xclass-ccode',
    'xclass-cname',
    'xclass-clight',
    'xclass-cdark',
    'xclass-cwatermark',
    'xclass-expires',
    'XID',
  ]);
  const { mutate: loginMutate } = useMutation(
    () => login({ code: code?.toUpperCase() || '', id: idValue, password: passValue }),
    {
      onSuccess: e => {
        navigate(`/${code}${e.data.mg_code === 'ADMIN' && noImage ? '/settings' : ''}`);
        const encryptCookie = encrypt(e.data);
        const option = { domain: process.env.NODE_ENV === 'production' ? '.xclass.co.kr' : '.xcast.co.kr', path: '/' };
        const expires = dayjs().add(6, 'hour').toDate();
        setCookie('xclass-clight', e.data.c_light_color, { ...option });
        setCookie('xclass-cdark', e.data.c_dark_color, { ...option });
        setCookie('xclass-cname', e.data.c_name, { ...option });
        setCookie('xclass-ccode', e.data.c_code, { ...option });
        setCookie('XID', encryptCookie, { ...option, expires });
        setCookie('xclass-expires', expires, { ...option, expires });
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (e: any) => {
        if (e.response.status === 401)
          alertShow('아이디 또는 비밀번호를 잘못 입력했습니다.\n입력하신 내용을 다시 확인해주세요.');
        else if (e.response.status === 403) alertShow('권한이 없기 때문에 액세스할 수 없습니다.');
        else if (e.response.status === 500) alertShow('서버에서 오류가 발생하여 작업을 수행할 수 없습니다.');
        else alertShow(e.response.data.message);
      },
    },
  );
  useEffect(() => {
    if (code?.toUpperCase() !== code) navigate(`/${code?.toUpperCase()}/login`);
    if (pathname === `/${code}/login`) {
      const option = { domain: process.env.NODE_ENV === 'production' ? '.xclass.co.kr' : '.xcast.co.kr', path: '/' };
      removeCookie('xclass-expires', option);
      removeCookie('XID', option);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const setCompanyInit = (light?: string, dark?: string, title?: string) => {
    const setColor = (color: string) => {
      if (color && color !== 'null' && code?.toUpperCase() !== 'SUPER')
        document.documentElement.style.setProperty('--primary', color);
    };
    if (cookies['xclass-theme'] === 'light') {
      setColor(light || cookies['xclass-clight']);
    } else if (cookies['xclass-theme'] === 'dark') {
      setColor(dark || cookies['xclass-cdark']);
    } else if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      setCookie('xclass-theme', cookies['xclass-theme'] === 'dark' ? 'light' : 'dark', {
        domain: process.env.NODE_ENV === 'production' ? '.xclass.co.kr' : '.xcast.co.kr',
        path: '/',
      });
      setColor(dark || cookies['xclass-cdark']);
    } else {
      setCookie('xclass-theme', cookies['xclass-theme'] === 'dark' ? 'light' : 'dark', {
        domain: process.env.NODE_ENV === 'production' ? '.xclass.co.kr' : '.xcast.co.kr',
        path: '/',
      });
      setColor(light || cookies['xclass-clight']);
    }
    if (code?.toUpperCase() !== 'SUPER') {
      const htmlTitle = document.querySelector('title') as HTMLElement;
      htmlTitle.innerHTML = `${title || cookies['xclass-cname']} - Admin`;
      const customFavicon = `${
        process.env.REACT_APP_UPLOADED_URL
      }/xclass/image/customer/${code?.toUpperCase()}_FAVICON`;
      existImg(customFavicon, (result: boolean) => {
        if (result) {
          document.querySelector('#favicon')?.setAttribute('href', customFavicon);
          return;
        }
        document.querySelector('#favicon')?.setAttribute('href', faviconEmpty);
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setCompanyInit(), []);

  useQuery<{ data?: CodeValidation }>(['codeValidation'], () => codeValidation(code?.toUpperCase() || ''), {
    refetchOnWindowFocus: false,
    enabled: code !== undefined && code.toUpperCase() !== 'SUPER' && code.toUpperCase() !== cookies['xclass-ccode'],
    onSuccess: e => {
      if (e.data) {
        const option = { domain: process.env.NODE_ENV === 'production' ? '.xclass.co.kr' : '.xcast.co.kr', path: '/' };
        setCookie('xclass-ccode', code, option);
        setCookie('xclass-cname', e.data.c_name, option);
        setCookie('xclass-clight', e.data.c_light_color, option);
        setCookie('xclass-cdark', e.data.c_dark_color, option);
        setCookie('xclass-cwatermark', e.data.c_watermark, option);
        setCompanyInit(e.data.c_light_color, e.data.c_dark_color, e.data.c_name);
      } else {
        navigate('/404');
      }
    },
  });
  return (
    <div className={styles.container} data-theme={cookies['xclass-theme']}>
      <form className={styles.inner}>
        <div className={styles.logo}>
          {code?.toUpperCase() !== 'SUPER' ? (
            <img
              src={`${process.env.REACT_APP_UPLOADED_URL}/xclass/image/customer/${code?.toUpperCase()}_${
                cookies['xclass-theme'] === 'dark' ? 'DARK' : 'LIGHT'
              }_LOGO`}
              onError={() => {
                if (logoRef.current) logoRef.current.src = logoEmpty;
                setNoImage(true);
              }}
              alt="logo"
              ref={logoRef}
            />
          ) : (
            <img src={cookies['xclass-theme'] === 'dark' ? logoDark : logo} alt="logo" />
          )}
        </div>
        <div className={styles.form}>
          <div className={styles.input}>
            <input type="hidden" name="id" />
            <input type="hidden" name="password" />
          </div>
          <div className={styles.input}>
            <input
              type="text"
              value={idValue}
              onChange={e => setIdValue(e.target.value)}
              name="ID"
              autoComplete="off"
              placeholder={t('아이디') || ''}
              ref={idRef}
              onKeyPress={e => {
                if (e.key === 'Enter' || e.code === 'Enter') {
                  if (passValue) {
                    passRef.current?.blur();
                    loginMutate();
                  } else {
                    passRef.current?.focus();
                  }
                }
              }}
            />
          </div>
          <div className={styles.input}>
            <input
              type={passView ? 'text' : 'password'}
              value={passValue}
              onChange={e => setPassValue(e.target.value)}
              onKeyPress={e => {
                if (e.key === 'Enter' || e.code === 'Enter') {
                  passRef.current?.blur();
                  loginMutate();
                }
              }}
              name="PASSWORD"
              autoComplete="off"
              placeholder={t('비밀번호') || ''}
              ref={passRef}
              className={styles.password}
            />
            <img
              src={passView ? icVisibilityOff : icVisibility}
              alt="icon"
              className={styles.view}
              onClick={() => setPassView(prev => !prev)}
            />
          </div>
          <div
            className={styles.btn}
            style={{
              backgroundColor: 'var(--primary)',
              border: '1px solid var(--primary)',
            }}
            onClick={() => {
              passRef.current?.blur();
              loginMutate();
            }}
          >
            {t('로그인')}
          </div>
          {/* <div className={styles.etc}>
                <div className={styles.label} onClick={() => loginStore.setKeepState(!loginStore.keepState)}>
                  <div className={classNames(styles.checkbox, loginStore.keepState && styles.selected)}></div>
                  <div className={styles.text}>로그인 상태 유지</div>
                </div>
                <div className={styles.item}>
                  <div className={styles.element}>
                    <span>아이디/비밀번호 찾기</span>
                  </div>
                </div>
              </div> */}
        </div>
      </form>
    </div>
  );
}

export default Login;
