import queryString from 'query-string';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getHistoryList } from '../../api/evaluation';
import { useAlert } from '../../stores/alertStore';

// components
import Page from '../../includes/page';

// img
import Pagination from '../../includes/pagination copy';

function EvaluationHistoryList() {
  const { t } = useTranslation();
  const { code } = useParams();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { e_key, page } = queryString.parse(search);
  const { alertShow } = useAlert();
  const [count, setCount] = useState(0);
  const [data, setData] = useState<IEvaluationHistory[]>([]);
  const { isFetching: getHistoryListLoading } = useQuery<{ count: number; data: IEvaluationHistory[] }>(
    ['getHistoryList', page],
    () => getHistoryList({ e_key: Number(e_key), page: page === undefined ? 1 : Number(page) }),
    {
      refetchOnWindowFocus: false,
      onSuccess: e => {
        setCount(e.count);
        setData(e.data.map(item => ({ ...item, checked: false })));
        if (e.count > 0 && e.data.length === 0 && page !== undefined && Number(page) > 1)
          navigate(`${e_key === undefined ? '' : `?e_key=${Number(e_key)}`}`);
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (e: any) => alertShow(e.response.data.message),
    },
  );

  return (
    <Page title="평가기록목록">
      <div className="table-tools"></div>
      <div className="table-container">
        <table className="table">
          <thead>
            <tr>
              <th>{t('제목')}</th>
              <th>{t('점수')}</th>
              <th>{t('작성자')}</th>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {getHistoryListLoading && (
              <tr>
                <td colSpan={9}>
                  <div>
                    <div className="lds-spinner">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </td>
              </tr>
            )}
            {!getHistoryListLoading && data.length === 0 && (
              <tr>
                <td colSpan={4}>
                  <div>{t('검색된 목록이 없습니다.')}</div>
                </td>
              </tr>
            )}
            {data.map(item => (
              <tr key={item.eh_key}>
                <td>
                  <div className="title">
                    <span>{item.e_title}</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>{item.eh_score}</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>{item.m_name}</span>
                  </div>
                </td>
                <td>
                  <div>
                    <button
                      type="button"
                      className="info"
                      onClick={() =>
                        navigate(`/${code}/evaluation/historyDetail?eh_key=${item.eh_key}&title=${item.e_title}`)
                      }
                    >
                      <span>{t('상세보기')}</span>
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        total={count || 0}
        curr={page === undefined ? 1 : Number(page)}
        movePage={e => navigate(`?e_key=${Number(e_key)}&page=${e}`)}
      />
    </Page>
  );
}

export default EvaluationHistoryList;
