import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

// style
import styles from './resultItem.module.scss';

// img
import imgO from '../../assets/img/images/img_o.svg';
import imgOActive from '../../assets/img/images/img_o_active.svg';
import imgX from '../../assets/img/images/img_x.svg';
import imgXActive from '../../assets/img/images/img_x_active.svg';

function ResultSurveyItem({ item, idx }: { item: ISurveyResponseDetail; idx: string }) {
  const { t } = useTranslation();
  const questionForm = (e: string) => {
    if (e === 'subjective') return styles.input;
    if (e === 'long') return styles.long;
    if (e === 'multiple') return styles.multiple;
    if (e === 'single') return styles.single;
    if (e === 'ox') return styles.ox;
    if (e === 'scope') return styles.scope;
    if (e === 'select') return styles.select;
    if (e === 'score') return styles.score;
    return '';
  };

  interface prob {
    key: number;
    text: string;
    checked: boolean | string;
  }
  const [probs, setProbs] = useState<prob[]>([]);

  const setAnswer = () => {
    const setObject = (key: number, text: string, checked: boolean | string) => ({ key, text, checked });
    const split = item.svpb_key.svpb_select.split('$');
    const resopnse = item.svrspb_response.split('$');
    const responseArray = resopnse.map((item: string) =>
      setObject(parseInt(item.split('#')[0], 10), item.split('#')[1], item.split('#')[2]),
    );
    const array = split.map((item: string) =>
      setObject(parseInt(item.split('#')[0], 10), item.split('#')[1], resopnse.includes(item)),
    );
    setProbs(item.svpb_key.svpb_type === 'scope' ? responseArray : array);
  };

  useEffect(() => {
    if (
      item.svpb_key.svpb_type === 'single' ||
      item.svpb_key.svpb_type === 'multiple' ||
      item.svpb_key.svpb_type === 'scope' ||
      item.svpb_key.svpb_type === 'select' ||
      item.svpb_key.svpb_type === 'score'
    )
      setAnswer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scoreList = (e: number): JSX.Element[] => {
    const array = [];
    for (let i = 0; i < e; i += 1) {
      array.push(
        <div key={i} className={classNames(styles.score, parseInt(item.svrspb_response, 10) === i && styles.active)}>
          {i}
        </div>,
      );
    }
    return array;
  };

  return (
    <div className={styles.container}>
      <div className={styles.question}>
        <div className={styles.num}>
          <span>{idx}</span>
        </div>
        <div className={styles.text}>
          <span>{item.svpb_key.svpb_question}</span>
        </div>
      </div>
      <div className={questionForm(item.svpb_key.svpb_type)}>
        {item.svpb_key.svpb_type === 'subjective' && (
          <div className={styles.subjective}>
            <div className={styles.answer}>{item.svrspb_response}</div>
          </div>
        )}
        {item.svpb_key.svpb_type === 'long' && <div className={styles.answer}>{item.svrspb_response}</div>}
        {item.svpb_key.svpb_type === 'single' && (
          <div className={styles.single}>
            {probs.map(ele => (
              <div className={styles.item} key={ele.key}>
                <div className={classNames(styles.radio, ele.checked && styles.active)}></div>
                <div className={classNames(styles.item, ele.checked && styles.active)}>
                  <span>{ele.text}</span>
                </div>
              </div>
            ))}
          </div>
        )}
        {item.svpb_key.svpb_type === 'multiple' && (
          <div className={styles.multiple}>
            {probs.map(ele => (
              <div className={styles.item} key={ele.key}>
                <div className={classNames(styles.checkbox, ele.checked && styles.active)}></div>
                <div className={classNames(styles.item, ele.checked && styles.active)}>
                  <span>{ele.text}</span>
                </div>
              </div>
            ))}
          </div>
        )}
        {item.svpb_key.svpb_type === 'select' && (
          <div className={styles.answer}>
            <select value={item.svrspb_response.split('#')[1]} disabled onChange={e => e.preventDefault()}>
              {probs.map(ele => (
                <option className={styles.item} key={ele.key}>
                  {ele.text}
                </option>
              ))}
            </select>
          </div>
        )}
        {item.svpb_key.svpb_type === 'ox' && (
          <div className={styles.ox}>
            <div className={classNames(styles.item, item.svrspb_response === 'o' && styles.active)}>
              <img src={imgO} alt="icon" />
              <img src={imgOActive} alt="icon" />
            </div>
            <div className={classNames(styles.item, item.svrspb_response === 'x' && styles.active)}>
              <img src={imgX} alt="icon" />
              <img src={imgXActive} alt="icon" />
            </div>
          </div>
        )}
        {item.svpb_key.svpb_type === 'scope' && (
          <div className={styles.scope}>
            <div className={styles.item}>
              <div className={styles.subject}></div>
              <div className={styles.scores}>
                <div className={styles.score}>
                  <span>1</span>
                </div>
                <div className={styles.score}>
                  <span>2</span>
                </div>
                <div className={styles.score}>
                  <span>3</span>
                </div>
                <div className={styles.score}>
                  <span>4</span>
                </div>
                <div className={styles.score}>
                  <span>5</span>
                </div>
              </div>
            </div>
            {probs.map(ele => (
              <div className={styles.item} key={ele.key}>
                <div className={styles.subject}>{ele.text}</div>
                <div className={styles.scores}>
                  <div className={styles.score}>
                    <div className={classNames(styles.radio, ele.checked === '1' && styles.active)}></div>
                    <div className={styles.num}>{t('매우 나쁨')}</div>
                  </div>
                  <div className={styles.score}>
                    <div className={classNames(styles.radio, ele.checked === '2' && styles.active)}></div>
                    <div className={styles.num}>{t('나쁨')}</div>
                  </div>
                  <div className={styles.score}>
                    <div className={classNames(styles.radio, ele.checked === '3' && styles.active)}></div>
                    <div className={styles.num}>{t('보통')}</div>
                  </div>
                  <div className={styles.score}>
                    <div className={classNames(styles.radio, ele.checked === '4' && styles.active)}></div>
                    <div className={styles.num}>{t('좋음')}</div>
                  </div>
                  <div className={styles.score}>
                    <div className={classNames(styles.radio, ele.checked === '5' && styles.active)}></div>
                    <div className={styles.num}>{t('매우 좋음')}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        {item.svpb_key.svpb_type === 'score' && (
          <div className={styles.item}>{scoreList(parseInt(probs[0]?.text, 10))}</div>
        )}
      </div>
    </div>
  );
}

export default ResultSurveyItem;
