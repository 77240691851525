import { motion } from 'framer-motion';
import { useRef } from 'react';
import { useCookies } from 'react-cookie';

// components
import ModalPortal from '../../ModalPortal';
import Delay from './delay';

// style
import styles from './modal.module.scss';

// eslint-disable-next-line react/require-default-props
function Modal({ close, children }: { close?: () => void; children: React.ReactNode }) {
  const [cookies] = useCookies(['xclass-theme']);
  const bgRef = useRef<HTMLDivElement>(null);
  return (
    <ModalPortal>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ type: 'linear' }}
        exit={{ opacity: 0 }}
        className={styles.container}
        data-theme={cookies['xclass-theme']}
        ref={bgRef}
        onClick={(e: React.MouseEvent<HTMLDivElement>) => {
          if (e.target === bgRef.current && close) close();
        }}
      >
        <div className={styles.wrapper}>
          <div className={styles.inner}>
            <div className={styles.content}>
              <Delay delay={200}>{children}</Delay>
            </div>
          </div>
        </div>
      </motion.div>
    </ModalPortal>
  );
}

export default Modal;
