import axios from 'axios';
import { createError, throwError } from './error';

const api = process.env.REACT_APP_API;

// 러닝톡 채널 정보 조회
export const getLearningTalkChannel = async (lt_key: number) => {
  try {
    const { data } = await axios.get(`${api}/admin/learningtalk/${lt_key}`, {
      withCredentials: true,
    });
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    createError({
      type: '[ADMIN] learningtalk - getLearningTalkReportList',
      message: `message: "${error.message}",\nresponse: { status: ${error.response.status}, message: "${error.response.data.message}" }`,
    });
    throwError(error);
    return null;
  }
};

// 러닝톡 콘텐츠 정보 조회
export const getLearningTalkContents = async (ltc_key: number) => {
  try {
    const { data } = await axios.get(`${api}/admin/learningtalk/contents/${ltc_key}`, {
      withCredentials: true,
    });
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    createError({
      type: '[ADMIN] learningtalk - getLearningTalkReportList',
      message: `message: "${error.message}",\nresponse: { status: ${error.response.status}, message: "${error.response.data.message}" }`,
    });
    throwError(error);
    return null;
  }
};

// 러닝톡 댓글 정보 조회
export const getLearningTalkComment = async (ltcc_key: number) => {
  try {
    const { data } = await axios.get(`${api}/admin/learningtalk/contents/comment/${ltcc_key}`, {
      withCredentials: true,
    });
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    createError({
      type: '[ADMIN] learningtalk - getLearningTalkReportList',
      message: `message: "${error.message}",\nresponse: { status: ${error.response.status}, message: "${error.response.data.message}" }`,
    });
    throwError(error);
    return null;
  }
};

// 러닝톡 신고 목록 조회
export const getLearningTalkReportList = async (page: number) => {
  try {
    const { data } = await axios.get(`${api}/admin/learningtalk/report/list?page=${page}&limit=10`, {
      withCredentials: true,
    });
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    createError({
      type: '[ADMIN] learningtalk - getLearningTalkReportList',
      message: `message: "${error.message}",\nresponse: { status: ${error.response.status}, message: "${error.response.data.message}" }`,
    });
    throwError(error);
    return null;
  }
};

// 신고처리
export const updateReport = async (element: { lrKey: number; status: string }) => {
  await axios.patch(`${api}/admin/learningtalk/report`, { ...element }, { withCredentials: true });
};
