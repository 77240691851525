import axios from 'axios';
import { t } from 'i18next';

export const logDownload = async (element: {
  sdate: string;
  edate: string;
  fileName: string;
  roomId?: string;
  chat?: boolean;
  surveyKey?: number;
  vodKey?: number;
  ckKey?: number;
  evaluationKey?: number;
}) => {
  try {
    await axios.get(
      `${process.env.REACT_APP_LOG}/${
        element.roomId
          ? element.chat
            ? 'chattinglog'
            : 'userlog'
          : element.surveyKey
          ? 'survey'
          : element.vodKey
          ? 'vod'
          : element.ckKey
          ? 'checklist'
          : element.evaluationKey
          ? 'evaluation'
          : ''
      }/excel?${
        element.roomId
          ? `roomId=${element.roomId}`
          : element.surveyKey
          ? `surveyKey=${element.surveyKey}`
          : element.vodKey
          ? `vodKey=${element.vodKey}`
          : element.ckKey
          ? `ckKey=${element.ckKey}`
          : element.evaluationKey
          ? `evaluationKey=${element.evaluationKey}`
          : ''
      }&sdate=${element.sdate}&edate=${element.edate}`,
      { withCredentials: true },
    );
    window.open(
      `${process.env.REACT_APP_LOG}/${
        element.roomId
          ? element.chat
            ? 'chattinglog'
            : 'userlog'
          : element.surveyKey
          ? 'survey'
          : element.vodKey
          ? 'vod'
          : element.ckKey
          ? 'checklist'
          : element.evaluationKey
          ? 'evaluation'
          : ''
      }/excel?${
        element.roomId
          ? `roomId=${element.roomId}`
          : element.surveyKey
          ? `surveyKey=${element.surveyKey}`
          : element.vodKey
          ? `vodKey=${element.vodKey}`
          : element.ckKey
          ? `ckKey=${element.ckKey}`
          : element.evaluationKey
          ? `evaluationKey=${element.evaluationKey}`
          : ''
      }&sdate=${element.sdate}&edate=${element.edate}`,
    );
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    window.postMessage({ type: 'alert', message: t('검색된 목록이 없습니다.') });
  }
};
