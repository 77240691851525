import axios from 'axios';
import { createError, throwError } from './error';

const api = process.env.REACT_APP_API;

// VOD 사진 업로드 url 생성
export const createVodUploadUrl = async (order: { count: number }) => {
  await axios.post(`${api}/vod/uploadurl`, { ...order }, { withCredentials: true });
};

// VOD 등록
export const createVod = async (element: IVodEdit) => {
  await axios.post(`${api}/vod`, { ...element }, { withCredentials: true });
};

// VOD 수정
export const editVod = async (element: { key: number; data: IVodEdit }) => {
  await axios.patch(`${api}/vod/${element.key}`, { ...element.data }, { withCredentials: true });
};

// VOD 삭제
export const deleteVod = async (key: number) => {
  await axios.delete(`${api}/vod/${key}`, { withCredentials: true });
};

// VOD 목록 조회
export const getVodList = async (element: { str: string; page: number }) => {
  try {
    const { data } = await axios.get(`${api}/vod?str=${element.str}&page=${element.page}&limit=10`, {
      withCredentials: true,
    });
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    createError({
      type: '[ADMIN] vod - getVodList',
      message: `message: "${error.message}",\nresponse: { status: ${error.response.status}, message: "${error.response.data.message}" }`,
    });
    throwError(error);
    return null;
  }
};

// VOD 조회
export const getVod = async (key: number) => {
  try {
    const { data } = await axios.get(`${api}/vod/${key}`, { withCredentials: true });
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    createError({
      type: '[ADMIN] vod - getVod',
      message: `message: "${error.message}",\nresponse: { status: ${error.response.status}, message: "${error.response.data.message}" }`,
    });
    throwError(error);
    return null;
  }
};

// VOD 댓글 등록
export const createVodComment = async (element: { key: number; comment: string }) => {
  await axios.post(`${api}/vod/comment`, { ...element }, { withCredentials: true });
};

// VOD 댓글 수정
export const editVodComment = async (element: { key: number; comment: string }) => {
  await axios.patch(`${api}/vod/comment/${element.key}`, { comment: element.comment }, { withCredentials: true });
};

// VOD 댓글 삭제
export const deleteVodComment = async (key: number) => {
  await axios.delete(`${api}/vod/comment/${key}`, { withCredentials: true });
};

// VOD 댓글 목록 조회
export const getVodComment = async (element: { key: number; page: number }) => {
  try {
    const { data } = await axios.get(`${api}/vod/comment?key=${element.key}&page=${element.page}&limit=10`, {
      withCredentials: true,
    });
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    createError({
      type: '[ADMIN] vod - getVodComment',
      message: `message: "${error.message}",\nresponse: { status: ${error.response.status}, message: "${error.response.data.message}" }`,
    });
    throwError(error);
    return null;
  }
};
