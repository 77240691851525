import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import Chart from 'react-apexcharts';

// style
import styles from './analysisItem.module.scss';

// img
import imgOWhite from '../../assets/img/images/img_o_white.svg';
import imgXWhite from '../../assets/img/images/img_x_white.svg';
import icStar from '../../assets/img/icons/ic_star.svg';

function AnalysisItem({ item, idx }: { item: ISurveyAnalysis; idx: number }) {
  const { t } = useTranslation();
  const [cookies] = useCookies(['xclass-theme']);
  const [seriesArray, setSeriesArray] = useState<number[]>(item.select.split('$').map(() => 0));
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [seriesScope, setSeriesScope] = useState<any[]>(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    item.select.split('$').map((ele: any) => ({ name: ele.split('#')[1], data: [0, 0, 0, 0, 0] })),
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [scoreList, setScoreList] = useState<any>({ 0: 1, 1: 0, 2: 0 });
  const [seriesScopeLine, setSeriesScopeLine] = useState<number[]>([]);
  useEffect(() => {
    if (item.type === 'single' || item.type === 'select') {
      setSeriesArray(() => {
        const response = item.response.split(',');
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const responseObject: any = {};
        response.forEach((key: string) => {
          responseObject[key] = (responseObject[key] || 0) + 1;
        });
        for (let i = 0; i < item.select.split('$').length; i += 1)
          if (responseObject[item.select.split('$')[i]] === undefined) responseObject[item.select.split('$')[i]] = 0;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const resultObject: any = {};
        Object.keys(responseObject)
          .sort()
          .forEach(key => {
            resultObject[key] = responseObject[key];
          });
        return Object.values(resultObject);
      });
    }
    if (item.type === 'multiple') {
      setSeriesArray(() => {
        const response = item.response
          .split('$')
          .map((ele: string) => ele.split(','))
          .flat();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const responseObject: any = {};
        response.forEach((key: string) => {
          responseObject[key] = (responseObject[key] || 0) + 1;
        });
        for (let i = 0; i < item.select.split('$').length; i += 1)
          if (responseObject[item.select.split('$')[i]] === undefined) responseObject[item.select.split('$')[i]] = 0;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const resultObject: any = {};
        Object.keys(responseObject)
          .sort()
          .forEach(key => {
            resultObject[key] = responseObject[key];
          });
        return Object.values(resultObject);
      });
    }
    if (item.type === 'ox') {
      setSeriesArray(() => {
        const response = item.response.split(',');
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const responseObject: any = {};
        response.forEach((key: string) => {
          responseObject[key] = (responseObject[key] || 0) + 1;
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const resultObject: any = {};
        Object.keys(responseObject)
          .sort()
          .forEach(key => {
            resultObject[key] = responseObject[key];
          });
        return Object.values(resultObject);
      });
    }
    if (item.type === 'scope') {
      setSeriesScopeLine(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const selectObject: any = {};
        item.select.split('$').forEach((key: string) => {
          selectObject[key] = [0, 0, 0, 0, 0];
        });
        item.response
          .split(',')
          .map((ele: string) => ele.split('$'))
          .forEach((ele: string[]) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ele.forEach((key: any) => {
              selectObject[`${key.split('#')[0]}#${key.split('#')[1]}`][Number(key.split('#')[2] - 1)] += 1;
            });
          });

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const newArray: any = [];
        Object.keys(selectObject).forEach(key => {
          newArray.push({ name: key, data: selectObject[key] });
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const resultArray = newArray.map((element: any) => {
          let sum = 0;
          // eslint-disable-next-line no-return-assign
          element.data.forEach((el: number, idx: number) => (sum += el * (idx + 1)));
          return sum / Number(item.count);
        });
        return resultArray;
      });
      setSeriesScope(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const selectObject: any = {};
        item.select.split('$').forEach((key: string) => {
          selectObject[key] = [0, 0, 0, 0, 0];
        });
        item.response
          .split(',')
          .map((ele: string) => ele.split('$'))
          .forEach((ele: string[]) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ele.forEach((key: any) => {
              selectObject[`${key.split('#')[0]}#${key.split('#')[1]}`][Number(key.split('#')[2] - 1)] += 1;
            });
          });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const newArray: any = [
          { name: t('점', { point: 1 }), data: [], type: 'column' },
          { name: t('점', { point: 2 }), data: [], type: 'column' },
          { name: t('점', { point: 3 }), data: [], type: 'column' },
          { name: t('점', { point: 4 }), data: [], type: 'column' },
          { name: t('점', { point: 5 }), data: [], type: 'column' },
        ];
        Object.keys(selectObject).forEach(key => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          newArray.forEach((ele: { data: any[] }, index: number) => ele.data.push(selectObject[key][index]));
        });
        return newArray;
      });
    }
    if (item.type === 'score') {
      const scoreList = [];
      for (let i = 0; i < Number(item.select.split('#')[1]); i += 1) {
        scoreList.push(i);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const resultObj: any = {};
      const responsArray = item.response.split(',').map((item: string) => Number(item));
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      responsArray.forEach((key: any) => {
        resultObj[key] = (resultObj[key] || 0) + 1;
      });
      for (let i = 0; i < scoreList.length; i += 1)
        if (resultObj[scoreList[i]] === undefined) resultObj[scoreList[i]] = 0;
      setScoreList(resultObj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [chartShow, setChartShow] = useState(false);
  return (
    <div className={styles.container}>
      <div className={styles.question}>
        <div className={styles.num}>
          <span>{idx}</span>
        </div>
        <div className={styles.text}>
          <span className={styles.type}>
            {item.type === 'subjective'
              ? t('단답형')
              : item.type === 'long'
              ? t('장문형')
              : item.type === 'single'
              ? t('객관식')
              : item.type === 'multiple'
              ? t('체크박스')
              : item.type === 'select'
              ? t('드롭다운')
              : item.type === 'ox'
              ? 'OX'
              : item.type === 'scope'
              ? t('선형배율')
              : item.type === 'score'
              ? t('점수')
              : ''}
          </span>
          <span>{item.question}</span>
        </div>
      </div>
      <div className={styles.chart}>
        {(item.type === 'subjective' || item.type === 'long') && (
          <div className={styles.subjective}>
            <table>
              <tbody>
                {!chartShow &&
                  item.response
                    .split(',')
                    .slice(0, 5)
                    .map((ele: string, idx: number) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <tr key={idx}>
                        <td>{ele}</td>
                      </tr>
                    ))}
                {chartShow &&
                  item.response.split(',').map((ele: string, idx: number) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <tr key={idx}>
                      <td>{ele}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {chartShow && item.response.split(',').length > 4 && (
              <button type="button" className="info" onClick={() => setChartShow(prev => !prev)}>
                {chartShow ? '접기' : t('총 개 확인', { text: item.response.split(',').length })}
              </button>
            )}
          </div>
        )}
        {(item.type === 'single' || item.type === 'select') && (
          <div className={styles.small}>
            <Chart
              type={item.type === 'select' ? 'pie' : 'donut'}
              series={seriesArray}
              options={{
                labels: item.select.split('$').map((ele: string) => ele.split('#')[1]),
                theme: {
                  mode: cookies['xclass-theme'] === 'dark' ? 'dark' : 'light',
                },
              }}
            />
          </div>
        )}
        {item.type === 'multiple' && (
          <Chart
            type="bar"
            series={[
              {
                data: [...seriesArray],
              },
            ]}
            options={{
              chart: {
                type: 'bar',
                height: 380,
              },
              plotOptions: {
                bar: {
                  borderRadius: 4,
                  distributed: true,
                  horizontal: true,
                  dataLabels: {
                    position: 'bottom',
                  },
                },
              },
              dataLabels: {
                enabled: true,
                textAnchor: 'start',
                style: {
                  colors: ['var(--greyish11)'],
                },
                formatter: val => String(val),
                offsetX: 0,
                dropShadow: {
                  enabled: true,
                },
              },
              xaxis: {
                categories: item.select.split('$').map((ele: string) => ele.split('#')[1]),
                labels: {
                  formatter: val => val.toString(),
                },
                max: Math.ceil(Math.max(...seriesArray) / 10) * 10,
              },
              tooltip: {
                y: {
                  formatter(val) {
                    return t('명', { count: val });
                  },
                },
              },
              theme: {
                mode: cookies['xclass-theme'] === 'dark' ? 'dark' : 'light',
              },
            }}
          />
        )}
        {item.type === 'ox' && (
          <div className={styles.small}>
            {/* <div className={styles.total}>총 인원 : {(seriesArray[0] || 0) + (seriesArray[1] || 0)}명</div> */}
            <div className={styles.ox}>
              <div className={styles.item}>
                <div className={styles.gauge}>
                  <div
                    className={styles.inner}
                    style={{
                      height: `calc(100% * ${(seriesArray[0] || 0) / ((seriesArray[0] || 0) + (seriesArray[1] || 0))})`,
                    }}
                  >
                    <img src={imgOWhite} alt="icon" />
                  </div>
                </div>
                <span>{seriesArray[0]}명</span>
              </div>
              <div className={styles.item}>
                <div className={styles.gauge}>
                  <div
                    className={styles.inner}
                    style={{
                      height: `calc(100% * ${(seriesArray[1] || 0) / ((seriesArray[0] || 0) + (seriesArray[1] || 0))})`,
                    }}
                  >
                    <img src={imgXWhite} alt="icon" />
                  </div>
                </div>
                <span>{t('명', { count: seriesArray[1] || 0 })}</span>
              </div>
            </div>
          </div>
        )}
        {item.type === 'scope' && (
          <Chart
            series={seriesScope.concat({
              data: seriesScopeLine,
              name: t('평균'),
              type: 'line',
            })}
            options={{
              labels: item.select.split('$').map((ele: string) => ele.split('#')[1]),
              chart: {
                background: 'var(--greyish11)',
                height: 400,
                redrawOnParentResize: true,
                stacked: true,
                toolbar: {
                  show: false,
                },
                zoom: {
                  enabled: true,
                },
                type: 'line',
              },
              plotOptions: {
                bar: {
                  horizontal: false,
                },
              },
              stroke: {
                width: [0, 0, 0, 0, 0, 3],
                colors: ['', '', '', '', '', 'var(--danger)'],
              },
              yaxis: [
                {
                  labels: {
                    formatter: val => String(Number.isInteger(val) ? val : Number(val.toFixed(2))),
                  },
                },
              ],
              theme: {
                mode: cookies['xclass-theme'] === 'dark' ? 'dark' : 'light',
              },
            }}
          />
        )}
        {item.type === 'score' && (
          <div className={styles.scores}>
            {Object.keys(scoreList)
              .reverse()
              .map(ele => (
                <div className={styles.item} key={ele}>
                  <div className={styles.cate}>
                    <img src={icStar} alt="icon" />
                    <span>{ele}</span>
                  </div>
                  <div className={styles.gauge}>
                    <div
                      className={styles.gaugeInner}
                      style={{
                        width: `calc(100% * ${scoreList[ele] / Number(item.count)})`,
                      }}
                    ></div>
                  </div>
                  <div className={styles.count}>{scoreList[ele]}명</div>
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default AnalysisItem;
