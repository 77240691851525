import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { memberOrgState, useMemberOrg } from '../../stores/memberStore';

// components
import Modal from '../modal/modal';

// style
import styles from '../modal/selector.module.scss';
import OrgItem from './orgItem';

function MemberOrg() {
  const { t } = useTranslation();
  const memberOrg = useRecoilValue(memberOrgState);
  const { setMemberOrgModal } = useMemberOrg();

  return (
    <AnimatePresence>
      {memberOrg.state && (
        <Modal close={() => setMemberOrgModal(false)}>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            className={styles.modal}
          >
            <div className={styles.header} style={{ marginBottom: '8px' }}>
              <div className={styles.title}>{t('조직을 선택하십시오.')}</div>
              <button type="button" className="primary" onClick={() => setMemberOrgModal(false)}>
                {t('확인')}
              </button>
            </div>
            <OrgItem item={memberOrg.data} />
          </motion.div>
        </Modal>
      )}
    </AnimatePresence>
  );
}

export default MemberOrg;
