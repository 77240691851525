import axios from 'axios';
import classNames from 'classnames';
import ko from 'date-fns/locale/ko';
import queryString from 'query-string';
import DatePicker from 'react-datepicker';
import { useState, useEffect, useRef } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useMutation, useQuery } from '@tanstack/react-query';
import { createError, throwError } from '../../api/error';
import { createLive, editLive, getLive } from '../../api/live';
import { getOrgList } from '../../api/member';
import { useAlert } from '../../stores/alertStore';
import { useConfirm } from '../../stores/confirmStore';
import { loadingState } from '../../stores/loadingStore';
import { memberOrgMultyState } from '../../stores/memberStore';
import { flatOrg } from '../../assets/util/flatOrg';
import { editDateFormat } from '../../assets/util/dateFormat';

// components
import Page from '../../includes/page';
import FormItem from '../../components/basic/formItem';
import InspectionOrg from '../../components/inspection/org';

const api = process.env.REACT_APP_API;

function LiveEdit() {
  const { t } = useTranslation();
  const [cookies] = useCookies(['xclass-lang', 'xclass-cname']);
  const { code } = useParams();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { l_key } = queryString.parse(search);
  const { alertShow } = useAlert();
  const { confirmShow } = useConfirm();

  const [title, setTitle] = useState('');
  const [contents, setContents] = useState('');
  const [pin, setPin] = useState('');
  // 섬네일
  const [thumbnail, setThumbnail] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [uploadFile, setUploadFile] = useState<any>(null);
  const [uploadImg, setUploadImg] = useState<string | null>(null);
  const [uploadImgName, setUploadImgName] = useState('');
  const imgRef = useRef<HTMLImageElement>(null);
  const changeThumb = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event?.target?.files) {
      setUploadFile(null);
      setUploadImg(null);
      setUploadImgName('');
      return;
    }
    const maxSize = 1 * 1024 * 1024;
    if (event.target.files[0].size > maxSize) {
      alertShow('첨부 이미지의 사이즈는 1024KB 이내로 등록 가능합니다.');
      return;
    }
    const getExtension = (fileName: string) => {
      const fileLength = fileName.length;
      const lastDot = fileName.lastIndexOf('.');
      const fileExtension = `.${fileName.substring(lastDot + 1, fileLength)}`;
      return fileExtension.toLowerCase();
    };
    if (
      getExtension(event.target.files[0].name) !== '.png' &&
      getExtension(event.target.files[0].name) !== '.jpg' &&
      getExtension(event.target.files[0].name) !== '.jpeg'
    ) {
      alertShow('이미지는 jpg, png 형식의 파일만 등록할 수 있습니다.');
      return;
    }
    setUploadFile(event.target.files[0]);
    setUploadImg(URL.createObjectURL(event.target.files[0]));
    setUploadImgName(event.target.files[0].name);
    try {
      const reader: FileReader = new FileReader();
      reader.onload = (event: ProgressEvent<FileReader>) => {
        console.log(event);
      };
      reader.onerror = error => {
        console.log(error);
      };
      reader.readAsDataURL(event.target.files[0]);
    } catch (error) {
      console.log(error);
    }
  };
  // 공개시간
  const [openTime, setOpenTime] = useState<Date | null>(new Date());
  const [closeTime, setCloseTime] = useState<Date | null>(new Date());
  useEffect(() => {
    if (Number(closeTime) < Number(openTime)) setCloseTime(openTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openTime, closeTime]);
  // 방송시간
  const [startTime, setStartTime] = useState<Date | null>(new Date());
  const [endTime, setEndTime] = useState<Date | null>(new Date());
  useEffect(() => {
    if (Number(endTime) < Number(startTime)) setEndTime(startTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTime, endTime]);
  const [beforeEnterTime, setBeforeEnterTime] = useState(0);
  const [maxUser, setMaxUser] = useState(100);
  // 조직
  const [orgAleady, setOrgAleady] = useState(false);
  const [memberOrg, setMemberOrg] = useRecoilState(memberOrgMultyState);
  const { isFetching: orgLoading } = useQuery<{ data: IOrg }>(['org'], getOrgList, {
    refetchOnWindowFocus: false,
    onSuccess: e => {
      setMemberOrg(prev => ({ ...prev, data: e.data }));
      setOrgAleady(true);
    },
  });

  // 다시보기
  const [replay, setReplay] = useState(false);
  const [replayTitle, setReplayTitle] = useState('');
  const [replayDescription, setReplayDescription] = useState('');
  // 시청 가능 시간
  const [replayCanDate, setReplayCanDate] = useState(0);
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const numberMap = (e: number) => {
    const result = [];
    for (let i = 0; i < e; i += 1) result.push(i);
    return result;
  };
  const [replayThumbnail, setReplayThumbnail] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [replayFile, setReplayFile] = useState<any>(null);
  const [replayImg, setReplayImg] = useState<string | null>(null);
  const [replayImgName, setReplayImgName] = useState('');
  const replayImgRef = useRef<HTMLImageElement>(null);
  const changeReplayThumb = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event?.target?.files) {
      setReplayFile(null);
      setReplayImg(null);
      setReplayImgName('');
      return;
    }
    const maxSize = 1 * 1024 * 1024;
    if (event.target.files[0].size > maxSize) {
      alertShow('첨부 이미지의 사이즈는 1024KB 이내로 등록 가능합니다.');
      return;
    }
    const getExtension = (fileName: string) => {
      const fileLength = fileName.length;
      const lastDot = fileName.lastIndexOf('.');
      const fileExtension = `.${fileName.substring(lastDot + 1, fileLength)}`;
      return fileExtension.toLowerCase();
    };
    if (
      getExtension(event.target.files[0].name) !== '.png' &&
      getExtension(event.target.files[0].name) !== '.jpg' &&
      getExtension(event.target.files[0].name) !== '.jpeg'
    ) {
      alertShow('이미지는 jpg, png 형식의 파일만 등록할 수 있습니다.');
      return;
    }
    setReplayFile(event.target.files[0]);
    setReplayImg(URL.createObjectURL(event.target.files[0]));
    setReplayImgName(event.target.files[0].name);
    try {
      const reader: FileReader = new FileReader();
      reader.onload = (event: ProgressEvent<FileReader>) => {
        console.log(event);
      };
      reader.onerror = error => {
        console.log(error);
      };
      reader.readAsDataURL(event.target.files[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const { isFetching: getLiveLoading } = useQuery<{ data: ILive }>(['getLive'], () => getLive(Number(l_key)), {
    refetchOnWindowFocus: false,
    enabled: l_key !== undefined && orgAleady,
    onSuccess: e => {
      setTitle(e.data.l_title);
      setContents(e.data.l_contents);
      setPin(e.data.l_pin || '');
      setThumbnail(e.data.l_thumbnail);
      setUploadFile(`uploaded_${e.data.l_thumbnail}`);
      setUploadImg(`${process.env.REACT_APP_UPLOADED_URL}/${e.data.l_thumbnail}`);
      setUploadImgName(`uploaded_${e.data.l_thumbnail}`);
      setOpenTime(new Date(e.data.l_open_time));
      setCloseTime(new Date(e.data.l_close_time));
      setStartTime(new Date(e.data.l_start_time));
      setEndTime(new Date(e.data.l_end_time));
      setBeforeEnterTime(e.data.l_before_enter_time);
      setMaxUser(e.data.l_max_user);
      setMemberOrg(prev => ({ ...prev, selected: e.data.organizations.map(el => el.mo_key) }));
      setReplay(e.data.l_replay_upload);
      setReplayTitle(e.data.l_replay_upload_title || '');
      setReplayDescription(e.data.l_replay_upload_description || '');
      setReplayCanDate(e.data.l_replay_upload_can_date || 0);
      setDays(e.data.l_replay_upload_can_date ? Math.floor(e.data.l_replay_upload_can_date / 86400) : 0);
      setHours(
        e.data.l_replay_upload_can_date
          ? Math.floor(e.data.l_replay_upload_can_date - Math.floor(e.data.l_replay_upload_can_date / 86400)) / 3600
          : 0,
      );
      setMinutes(
        e.data.l_replay_upload_can_date
          ? Math.floor(e.data.l_replay_upload_can_date - Math.floor(e.data.l_replay_upload_can_date / 3600)) / 60
          : 0,
      );
      setReplayThumbnail(e.data.l_replay_upload_thumbnail || '');
      setReplayFile(e.data.l_replay_upload_thumbnail ? `uploaded_${e.data.l_replay_upload_thumbnail}` : null);
      setReplayImg(
        e.data.l_replay_upload_thumbnail
          ? `${process.env.REACT_APP_UPLOADED_URL}/${e.data.l_replay_upload_thumbnail}`
          : null,
      );
      setReplayImgName(e.data.l_replay_upload_thumbnail ? `uploaded_${e.data.l_replay_upload_thumbnail}` : '');
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (e: any) => alertShow(e.response.data.message),
  });

  // 초기화
  const init = () => {
    setTitle('');
    setContents('');
    setPin('');
    setThumbnail('');
    setUploadFile(null);
    setUploadImg(null);
    setUploadImgName('');
    setOpenTime(new Date());
    setCloseTime(new Date());
    setStartTime(new Date());
    setEndTime(new Date());
    setBeforeEnterTime(0);
    setMaxUser(100);
    setMemberOrg({
      state: false,
      selected: [],
      data: { mo_key: 0 },
    });
    setReplay(false);
    setReplayTitle('');
    setReplayDescription('');
    setReplayCanDate(0);
    setDays(0);
    setHours(0);
    setMinutes(0);
    setReplayFile(null);
    setReplayImg(null);
  };
  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const { mutate: create } = useMutation(createLive, {
    onSuccess: () => {
      setLoading(false);
      navigate(`/${code}/live`);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (e: any) => {
      setLoading(false);
      createError({
        type: '[ADMIN] live - createLive',
        message: `message: "${e.message}",\nresponse: { status: ${e.response.status}, message: "${e.response.data.message}" }`,
      });
      throwError(e);
    },
  });

  const { mutate: update } = useMutation(editLive, {
    onSuccess: () => {
      setLoading(false);
      navigate(`/${code}/live`);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (e: any) => {
      setLoading(false);
      createError({
        type: '[ADMIN] live - editLive',
        message: `message: "${e.message}",\nresponse: { status: ${e.response.status}, message: "${e.response.data.message}" }`,
      });
      throwError(e);
    },
  });

  const setLoading = useSetRecoilState(loadingState);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formData = (_fields: any, _file: any) => {
    const data = new FormData();
    Object.keys(_fields).forEach(key => data.append(key, _fields[key]));
    if (_file) {
      data.append('file', _file);
    }
    return data;
  };

  const [thumbComplete, setThumbComplete] = useState(false);
  const [replayThumbComplete, setReplayThumbComplete] = useState(false);
  const complete = () => {
    const data = {
      title,
      contents,
      pin: !pin ? undefined : pin,
      thumbnail,
      openTime: editDateFormat(openTime || new Date()),
      closeTime: editDateFormat(closeTime || new Date()),
      startTime: editDateFormat(startTime || new Date()),
      endTime: editDateFormat(endTime || new Date()),
      beforeEnterTime,
      maxUser,
      organizationKeys: memberOrg.selected,
      replay,
      replayTitle,
      replayDescription,
      replayCanDate,
      replayThumbnail,
    };
    if (l_key) {
      update({
        l_key: Number(l_key),
        data,
      });
    } else {
      create(data);
    }
  };

  useEffect(() => {
    if (thumbComplete && replayThumbComplete) complete();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thumbComplete, replayThumbComplete]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fileUpload = async (url: string, form: any, type: string) => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data = (await axios.post(url, form)) as any;
      if (data.ok || data.status === 204) {
        if (type === 'thumb') setThumbComplete(true);
        if (type === 'replay') setReplayThumbComplete(true);
      } else {
        console.log('error');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const uploadLiveThumbnail = async (type: string) => {
    if (type === 'replay' && !replay) {
      setReplayThumbComplete(true);
      return;
    }
    try {
      const { data } = await axios.post(
        `${api}/admin/live/upload_${type === 'replay' ? 'replay_' : ''}thumbnail`,
        {},
        { withCredentials: true },
      );
      if (type === 'thumb') setThumbnail(data.data.name);
      if (type === 'replay') setReplayThumbnail(data.data.name);
      await fileUpload(
        data.data.url.url,
        formData(data.data.url.fields, type === 'replay' ? replayFile : uploadFile),
        type,
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      setLoading(false);
      console.log(e);
    }
  };

  const upload = () => {
    setLoading(true);
    if (l_key && uploadImgName.includes('uploaded_') && replayImgName.includes('uploaded_')) {
      update({
        l_key: Number(l_key),
        data: {
          title,
          contents,
          pin: !pin ? undefined : pin,
          thumbnail,
          openTime: editDateFormat(openTime || new Date()),
          closeTime: editDateFormat(closeTime || new Date()),
          startTime: editDateFormat(startTime || new Date()),
          endTime: editDateFormat(endTime || new Date()),
          beforeEnterTime,
          maxUser,
          organizationKeys: memberOrg.selected,
          replay,
          replayTitle,
          replayDescription,
          replayCanDate,
          replayThumbnail,
        },
      });
      return;
    }
    if (l_key && uploadImgName.includes('uploaded_') && !replayImgName.includes('uploaded_')) {
      setThumbComplete(true);
      uploadLiveThumbnail('replay');
      return;
    }
    if (l_key && !uploadImgName.includes('uploaded_') && replayImgName.includes('uploaded_')) {
      setReplayThumbComplete(true);
      uploadLiveThumbnail('thumb');
      return;
    }
    uploadLiveThumbnail('thumb');
    uploadLiveThumbnail('replay');
  };

  return (
    <Page title={l_key ? t('라이브수정') : t('라이브등록')} isLoading={orgLoading || getLiveLoading}>
      <FormItem title="제목" essential>
        <div className="input">
          <input
            type="text"
            maxLength={50}
            placeholder={t('제목') || ''}
            value={title}
            onChange={e => setTitle(e.target.value)}
          />
        </div>
      </FormItem>
      <FormItem title="내용" essential>
        <div className="input">
          <textarea
            placeholder={t('내용') || ''}
            maxLength={200}
            value={contents}
            onChange={e => setContents(e.target.value)}
          ></textarea>
          <div className="count">{contents.length} / 200</div>
        </div>
      </FormItem>
      {/* <FormItem title="PIN">
        <div className="input">
          <input type="text" maxLength={24} placeholder="PIN" value={pin} onChange={e => setPin(e.target.value)} />
        </div>
      </FormItem> */}
      <FormItem title="섬네일" essential>
        <div className="file">
          <div className="uploaded" style={{ display: uploadImg ? '' : 'none' }}>
            <img src={uploadImg || ''} alt="img" ref={imgRef} />
          </div>
          <label htmlFor="img-upload">
            {uploadImgName === '' ? (
              <div className="placeholder">
                <span>{t('섬네일 업로드 (용량 1024KB 이내의 jpg, png 파일)')}</span>
              </div>
            ) : (
              <div className="text">
                <span>{uploadImgName.replace('uploaded_', '')}</span>
              </div>
            )}
            <input type="file" id="img-upload" onChange={e => changeThumb(e)} accept="image/*" />
          </label>
        </div>
      </FormItem>
      <FormItem title="공개시간" essential>
        <div className="input" style={{ maxWidth: '608px' }}>
          <DatePicker
            selected={openTime}
            onChange={date => setOpenTime(date)}
            minDate={null}
            maxDate={closeTime}
            selectsStart
            startDate={openTime}
            endDate={closeTime}
            locale={cookies['xclass-lang'] === 'ko' ? ko : undefined}
            dateFormat="yyyy-MM-dd hh:mm aa"
            timeInputLabel={`${t('시각')}:`}
            showTimeInput
            disabledKeyboardNavigation
            onChangeRaw={(e: React.FormEvent<HTMLInputElement>) => e.preventDefault()}
          />
          <div style={{ margin: '0 8px', color: 'var(--greyish1)' }}>-</div>
          <DatePicker
            selected={closeTime}
            onChange={date => setCloseTime(date)}
            maxDate={null}
            selectsEnd
            startDate={openTime}
            endDate={closeTime}
            locale={cookies['xclass-lang'] === 'ko' ? ko : undefined}
            dateFormat="yyyy-MM-dd hh:mm aa"
            timeInputLabel={`${t('시각')}:`}
            showTimeInput
            disabledKeyboardNavigation
            onChangeRaw={(e: React.FormEvent<HTMLInputElement>) => e.preventDefault()}
          />
        </div>
      </FormItem>
      <FormItem title="진행기간" essential>
        <div className="input" style={{ maxWidth: '608px' }}>
          <DatePicker
            selected={startTime}
            onChange={date => setStartTime(date)}
            minDate={null}
            maxDate={endTime}
            selectsStart
            startDate={startTime}
            endDate={endTime}
            locale={cookies['xclass-lang'] === 'ko' ? ko : undefined}
            dateFormat="yyyy-MM-dd hh:mm aa"
            timeInputLabel={`${t('시각')}:`}
            showTimeInput
            disabledKeyboardNavigation
            onChangeRaw={(e: React.FormEvent<HTMLInputElement>) => e.preventDefault()}
          />
          <div style={{ margin: '0 8px', color: 'var(--greyish1)' }}>-</div>
          <DatePicker
            selected={endTime}
            onChange={date => setEndTime(date)}
            maxDate={null}
            selectsEnd
            startDate={startTime}
            endDate={endTime}
            locale={cookies['xclass-lang'] === 'ko' ? ko : undefined}
            dateFormat="yyyy-MM-dd hh:mm aa"
            timeInputLabel={`${t('시각')}:`}
            showTimeInput
            disabledKeyboardNavigation
            onChangeRaw={(e: React.FormEvent<HTMLInputElement>) => e.preventDefault()}
          />
        </div>
      </FormItem>
      <FormItem title="입장시간" essential>
        <div className="input">
          <select value={beforeEnterTime} onChange={e => setBeforeEnterTime(Number(e.target.value))}>
            <option value={0}>{t('시작시간 분 전부터', { minutes: 0 })}</option>
            <option value={5}>{t('시작시간 분 전부터', { minutes: 5 })}</option>
            <option value={15}>{t('시작시간 분 전부터', { minutes: 15 })}</option>
            <option value={30}>{t('시작시간 분 전부터', { minutes: 30 })}</option>
            <option value={60}>{t('시작시간 분 전부터', { minutes: 60 })}</option>
          </select>
          <div className="arrow"></div>
        </div>
      </FormItem>
      <FormItem title="참가인원" essential>
        <div className="input">
          <input
            type="text"
            placeholder={t('참가인원') || ''}
            value={maxUser}
            onChange={e => {
              setMaxUser(Number(e.target.value.replace(/[^0-9]/g, '')));
              if (Number(e.target.value) > 10000) setMaxUser(10000);
            }}
          />
        </div>
      </FormItem>
      <FormItem title="조직" essential>
        <div className="input">
          <input
            type="text"
            placeholder={t('조직') || ''}
            value={
              flatOrg(memberOrg.data)
                .concat({ mo_key: memberOrg.data.mo_key })
                .filter(ele => memberOrg.selected.includes(ele.mo_key)).length > 0
                ? flatOrg(memberOrg.data)
                    .concat({ mo_key: memberOrg.data.mo_key })
                    .filter(ele => memberOrg.selected.includes(ele.mo_key))
                    .map(el => el.mo_name)
                    .join(', ').length === 0
                  ? cookies['xclass-cname'] || ''
                  : flatOrg(memberOrg.data)
                      .concat({ mo_key: memberOrg.data.mo_key })
                      .filter(ele => memberOrg.selected.includes(ele.mo_key))
                      .map(el => el.mo_name)
                      .join(', ')
                : ''
            }
            readOnly
            className="combo"
            onClick={() => setMemberOrg(prev => ({ ...prev, state: true }))}
          />
          <div className="arrow"></div>
        </div>
      </FormItem>
      <FormItem title="다시보기" essential>
        <div className="switch">
          <div className={classNames('item', replay && 'active')} onClick={() => setReplay(prev => !prev)}>
            {t('활성화')}
          </div>
          <div className={classNames('item', !replay && 'active')} onClick={() => setReplay(prev => !prev)}>
            {t('비활성화')}
          </div>
        </div>
        <div className="wrap" style={{ display: !replay ? 'none' : '' }}>
          <FormItem title="제목" essential>
            <div className="input">
              <input
                type="text"
                maxLength={50}
                placeholder={t('제목') || ''}
                value={replayTitle}
                onChange={e => setReplayTitle(e.target.value)}
              />
            </div>
          </FormItem>
          <FormItem title="내용" essential>
            <div className="input">
              <textarea
                placeholder={t('내용') || ''}
                maxLength={200}
                value={replayDescription}
                onChange={e => setReplayDescription(e.target.value)}
              ></textarea>
              <div className="count">{replayDescription.length} / 200</div>
            </div>
          </FormItem>
          <FormItem title="시청가능기간" essential>
            <div className="input">
              <div className="element">
                <input
                  type="text"
                  placeholder="일"
                  value={days}
                  onChange={e => {
                    setDays(Number(e.target.value.replace(/[^0-9]/g, '')));
                    if (Number(e.target.value) > 365) {
                      setDays(365);
                      setReplayCanDate(365 * 86400 + hours * 3600 + minutes * 60);
                    } else {
                      setReplayCanDate(Number(e.target.value) * 86400 + hours * 3600 + minutes * 60);
                    }
                  }}
                />
                <div className="unit">{t('일', { days: undefined })}</div>
              </div>
              <div style={{ margin: '0 8px', color: 'var(--greyish1)' }}>:</div>
              <div className="element">
                <select
                  value={hours}
                  onChange={e => {
                    setHours(Number(e.target.value));
                    setReplayCanDate(days * 86400 + Number(e.target.value) * 3600 + minutes * 60);
                  }}
                >
                  {numberMap(24).map(item => (
                    <option key={`${item}시간`} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
                <div className="unit">{t('시간', { hours: undefined })}</div>
              </div>
              <div style={{ margin: '0 8px', color: 'var(--greyish1)' }}>:</div>
              <div className="element">
                <select
                  value={minutes}
                  onChange={e => {
                    setMinutes(Number(e.target.value));
                    setReplayCanDate(days * 86400 + hours * 3600 + Number(e.target.value) * 60);
                  }}
                >
                  {numberMap(60).map(item => (
                    <option key={`${item}분`} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
                <div className="unit">{t('분', { minutes: undefined })}</div>
              </div>
            </div>
          </FormItem>
          <FormItem title="섬네일" essential>
            <div className="file">
              <div className="uploaded" style={{ display: replayImg ? '' : 'none' }}>
                <img src={replayImg || ''} alt="img" ref={replayImgRef} />
              </div>
              <label htmlFor="replayImg-upload">
                {replayImgName === '' ? (
                  <div className="placeholder">{t('섬네일 업로드 (용량 1024KB 이내의 jpg, png 파일)')}</div>
                ) : (
                  <div className="text">{replayImgName.replace('uploaded_', '')}</div>
                )}
                <input type="file" id="replayImg-upload" onChange={e => changeReplayThumb(e)} accept="image/*" />
              </label>
            </div>
          </FormItem>
        </div>
      </FormItem>
      <div className="formSubmit">
        <button
          type="button"
          className="primary"
          onClick={() => {
            if (!title) {
              alertShow('제목을 입력하십시오.');
              return;
            }
            if (!contents) {
              alertShow('내용을 입력하십시오.');
              return;
            }
            if (!uploadImgName) {
              alertShow('섬네일을 등록하십시오.');
              return;
            }
            if (openTime && closeTime && openTime >= closeTime) {
              alertShow('공개시간을 확인하십시오.');
              return;
            }
            if (startTime && endTime && startTime >= endTime) {
              alertShow('방송시간을 확인하십시오.');
              return;
            }
            if (memberOrg.selected.length === 0) {
              alertShow('조직을 선택하십시오.');
              return;
            }
            if (replay) {
              if (!replayImgName) {
                alertShow('섬네일을 등록하십시오.');
                return;
              }
              if (replayCanDate === 0) {
                alertShow('시청 가능 시간을 확인하십시오.');
                return;
              }
            }
            confirmShow(l_key ? t('수정하시겠습니까?') : t('등록하시겠습니까?'), upload);
          }}
        >
          {l_key ? t('수정') : t('등록')}
        </button>
      </div>
      <InspectionOrg />
    </Page>
  );
}

export default LiveEdit;
