import { useTranslation } from 'react-i18next';
import { useConfirm } from '../../stores/confirmStore';

// style
import styles from './comment.module.scss';

// img
import { icDelete } from '../aside/icon';

function VodComment({ item, remove }: { item: IVodComment; remove: (key: number) => void }) {
  const { t } = useTranslation();
  const { confirmShow } = useConfirm();
  return (
    <div key={item.key} className={styles.container}>
      <div className={styles.header}>
        <div className={styles.writer}>
          <span>{item.writerName}</span>
        </div>
        <div className={styles.tools}>
          <object onClick={() => confirmShow(t('삭제하시겠습니까?'), () => remove(item.key))}>{icDelete()}</object>
        </div>
      </div>
      <div className={styles.text}>{item.comment}</div>
    </div>
  );
}

export default VodComment;
