import axios from 'axios';
import { createError, throwError } from './error';

const api = process.env.REACT_APP_API;

// 푸시기록조회
export const getPushList = async (page: number) => {
  try {
    const { data } = await axios.get(`${api}/admin/push/history/list?page=${page}&limit=10`, {
      withCredentials: true,
    });
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    createError({
      type: '[ADMIN] pushalarm - getPushList',
      message: `message: "${error.message}",\nresponse: { status: ${error.response.status}, message: "${error.response.data.message}" }`,
    });
    throwError(error);
    return null;
  }
};

// 푸시등록
export const setPush = async (element: {
  title: string;
  body: string;
  type: string;
  target?: string;
  image?: string;
}) => {
  await axios.post(`${api}/admin/push`, { ...element }, { withCredentials: true });
};
