import { atom, useSetRecoilState } from 'recoil';

export const customerInfoState = atom<{ state: boolean; data: ICustomer }>({
  key: 'customerInfo',
  default: {
    state: false,
    data: {
      c_key: 0,
      c_code: '',
      c_name: '',
      c_favicon_path: '',
      c_light_color: '',
      c_dark_color: '',
      c_watermark: '',
      c_modules: '',
      c_post_code: '',
      c_address: '',
      c_address_detail: '',
      c_admin_id: '',
      c_admin_name: '',
      c_admin_phone: '',
      c_admin_email: '',
      c_start_at: '',
      c_end_at: '',
      c_active: '',
      c_api_key: null,
      c_user_limit: 0,
      c_traffic_limit: 0,
      c_storage_limit: 0,
      c_delete_at: null,
      created_at: '',
      updated_at: '',
    },
  },
});

export function useCustomerInfo() {
  const setCustomerInfoState = useSetRecoilState(customerInfoState);

  const setCustomerInfo = (data: { state: boolean; data: ICustomer }) => setCustomerInfoState(data);

  return { setCustomerInfo };
}

export const addressState = atom<{ state: boolean; postCode: string; address: string }>({
  key: 'address',
  default: {
    state: false,
    postCode: '',
    address: '',
  },
});

export const refundState = atom<{ state: boolean; data: IRefund }>({
  key: 'refund',
  default: {
    state: false,
    data: {
      c_key: 0,
      c_modules: '',
      c_start_at: null,
      c_end_at: null,
      c_user_limit: 0,
      c_traffic_limit: 0,
      c_storage_limit: 0,
    },
  },
});