import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getEvaluationAnalysis } from '../../api/evaluation';
import { useAlert } from '../../stores/alertStore';

// components
import Page from '../../includes/page';
import AnalysisItem from '../../components/evaluation/analysisItem';

// style
import 'react-datepicker/dist/react-datepicker.css';
import styles from './Evaluation.module.scss';

function EvaluationAnalysis() {
  const { t } = useTranslation();
  const { alertShow } = useAlert();
  const { search } = useLocation();
  const { e_key } = queryString.parse(search);

  const { isFetching, data } = useQuery<{ data: IEvaluationDetail }>(
    ['getEvaluationAnalysis'],
    () => getEvaluationAnalysis(Number(e_key)),
    {
      refetchOnWindowFocus: false,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (e: any) => alertShow(e.response.data.message),
      cacheTime: 0,
    },
  );

  return (
    <Page title="평가통계" isLoading={isFetching}>
      <div className={styles.title}>{data?.data.e_title}</div>
      {data?.data.evaluationQuestionList.length === 0 && (
        <div style={{ fontSize: '14px', color: 'var(--greyish5)' }}>{t('검색된 목록이 없습니다.')}</div>
      )}
      {data?.data.evaluationQuestionList.map((item, idx) => (
        <AnalysisItem key={item.eq_key} item={item} idx={idx + 1} />
      ))}
    </Page>
  );
}

export default EvaluationAnalysis;
