import axios from 'axios';
import { createError, throwError } from './error';

const api = process.env.REACT_APP_API;

// 회의 등록
export const createMeeting = async (element: IMeetingEdit) => {
  await axios.post(`${api}/admin/meeting`, { ...element }, { withCredentials: true });
};

// 회의 수정
export const editMeeting = async (element: { mt_key: number; data: IMeetingEdit }) => {
  await axios.patch(`${api}/admin/meeting/${element.mt_key}`, { ...element.data }, { withCredentials: true });
};

// 회의 삭제
export const deleteMeeting = async (mt_key: number) => {
  await axios.delete(`${api}/admin/meeting/${mt_key}`, { withCredentials: true });
};

// 회의 목록 조회
export const getMeetingList = async (page: number) => {
  try {
    const { data } = await axios.get(`${api}/admin/meeting/list?page=${page}&limit=10`, { withCredentials: true });
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    createError({
      type: '[ADMIN] meeting - getMeetingList',
      message: `message: "${error.message}",\nresponse: { status: ${error.response.status}, message: "${error.response.data.message}" }`,
    });
    throwError(error);
    return null;
  }
};

// 회의 조회
export const getMeeting = async (mt_key: number) => {
  try {
    const { data } = await axios.get(`${api}/admin/meeting/${mt_key}`, { withCredentials: true });
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    createError({
      type: '[ADMIN] meeting - getMeeting',
      message: `message: "${error.message}",\nresponse: { status: ${error.response.status}, message: "${error.response.data.message}" }`,
    });
    throwError(error);
    return null;
  }
};
