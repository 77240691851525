import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { confirmState, useConfirm } from '../../stores/confirmStore';
import { icConfirm } from '../aside/icon';

// components
import Modal from './modal';

// style
import styles from './dialog.module.scss';

function Confirm() {
  const { t } = useTranslation();
  const confirm = useRecoilValue(confirmState);
  const { confirmCheck, confirmClose } = useConfirm();
  return (
    <AnimatePresence>
      {confirm.state && (
        <Modal close={() => confirmClose()}>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            className={styles.modal}
          >
            <div className={styles.circle}>{icConfirm()}</div>
            {/* eslint-disable-next-line react/no-danger */}
            <div className={styles.message} dangerouslySetInnerHTML={{ __html: t(confirm.message) }}></div>
            <div className={styles.btns}>
              <div className={styles.btn} onClick={() => confirmCheck()}>
                {t('확인')}
              </div>
              <div className={styles.btn} onClick={() => confirmClose()}>
                {t('취소')}
              </div>
            </div>
          </motion.div>
        </Modal>
      )}
    </AnimatePresence>
  );
}

export default Confirm;
