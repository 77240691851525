import axios from 'axios';
import { createError, throwError } from './error';

const api = process.env.REACT_APP_API;

// 사진 업로드 url 생성
export const createInspectionPicture = async (order: { count: number }) => {
  await axios.post(`${api}/inspection/create/upload_picture`, { ...order }, { withCredentials: true });
};

// 체크리스트 생성
export const createInspection = async (element: IInspectionEdit) => {
  await axios.post(`${api}/inspection`, { ...element }, { withCredentials: true });
};

// 체크리스트 수정
export const editInspection = async (element: { key: number; data: IInspectionEdit }) => {
  await axios.patch(`${api}/inspection/${element.key}`, { ...element.data }, { withCredentials: true });
};

// 체크리스트 삭제
export const deleteInspection = async (key: number) => {
  await axios.delete(`${api}/inspection/${key}`, { withCredentials: true });
};

// 체크리스트 목록 조회
export const getInspectionList = async (page: number) => {
  try {
    const { data } = await axios.get(`${api}/inspection/list?page=${page}&limit=10`, { withCredentials: true });
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    createError({
      type: '[ADMIN] inspection - getInspectionList',
      message: `message: "${error.message}",\nresponse: { status: ${error.response.status}, message: "${error.response.data.message}" }`,
    });
    throwError(error);
    return null;
  }
};

// 내 체크리스트 목록 조회
export const getInspectionMylist = async (page: number) => {
  try {
    const { data } = await axios.get(`${api}/inspection/mylist?page=${page}&limit=10`, { withCredentials: true });
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    createError({
      type: '[ADMIN] inspection - getInspectionMylist',
      message: `message: "${error.message}",\nresponse: { status: ${error.response.status}, message: "${error.response.data.message}" }`,
    });
    throwError(error);
    return null;
  }
};

// 체크리스트 조회
export const getInspection = async (key: number) => {
  try {
    const { data } = await axios.get(`${api}/inspection/detail/${key}`, { withCredentials: true });
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    createError({
      type: '[ADMIN] inspection - getInspection',
      message: `message: "${error.message}",\nresponse: { status: ${error.response.status}, message: "${error.response.data.message}" }`,
    });
    throwError(error);
    return null;
  }
};
