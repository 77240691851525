import axios from 'axios';
import classNames from 'classnames';
import ko from 'date-fns/locale/ko';
import dayjs from 'dayjs';
import queryString from 'query-string';
import DatePicker from 'react-datepicker';
import { useState, useEffect, useRef } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Editor } from '@toast-ui/react-editor';
import { createError, throwError } from '../../api/error';
import { getOrgList } from '../../api/member';
import { editReplay, getReplay } from '../../api/replay';
import { useAlert } from '../../stores/alertStore';
import { useConfirm } from '../../stores/confirmStore';
import { loadingState } from '../../stores/loadingStore';
import { memberOrgMultyState } from '../../stores/memberStore';
import { flatOrg } from '../../assets/util/flatOrg';

// components
import Page from '../../includes/page';
import FormItem from '../../components/basic/formItem';
import InspectionOrg from '../../components/inspection/org';

// img
import icClose from '../../assets/img/icons/ic_close.svg';

const api = process.env.REACT_APP_API;

function LiveReplayEdit() {
  const { t } = useTranslation();
  const [cookies] = useCookies(['xclass-theme', 'xclass-lang', 'xclass-cname']);
  const navigate = useNavigate();
  const { code } = useParams();
  const { search } = useLocation();
  const { key } = queryString.parse(search);
  const { alertShow } = useAlert();
  const { confirmShow } = useConfirm();

  const [isPublic, setIsPublic] = useState(true);
  const [title, setTitle] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const editorRef = useRef<any>(null);
  // 섬네일
  const [thumbnail, setThumbnail] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [imgFile, setImgFile] = useState<any>(null);
  const [img, setImg] = useState<string | null>(null);
  const [imgName, setImgName] = useState('');
  const imgRef = useRef<HTMLImageElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const changeThumb = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event?.target?.files) {
      setImgFile(null);
      setImg(null);
      setImgName('');
      return;
    }
    const maxSize = 1 * 1024 * 1024;
    if (event.target.files[0].size > maxSize) {
      alertShow('첨부 이미지의 사이즈는 1024KB 이내로 등록 가능합니다.');
      return;
    }
    const getExtension = (fileName: string) => {
      const fileLength = fileName.length;
      const lastDot = fileName.lastIndexOf('.');
      const fileExtension = `.${fileName.substring(lastDot + 1, fileLength)}`;
      return fileExtension.toLowerCase();
    };
    if (
      getExtension(event.target.files[0].name) !== '.png' &&
      getExtension(event.target.files[0].name) !== '.jpg' &&
      getExtension(event.target.files[0].name) !== '.jpeg'
    ) {
      alertShow('이미지는 jpg, png 형식의 파일만 등록할 수 있습니다.');
      return;
    }
    setImgFile(event.target.files[0]);
    setImg(URL.createObjectURL(event.target.files[0]));
    setImgName(event.target.files[0].name);
    try {
      const reader: FileReader = new FileReader();
      reader.onload = (event: ProgressEvent<FileReader>) => {
        console.log(event);
      };
      reader.onerror = error => {
        console.log(error);
      };
      reader.readAsDataURL(event.target.files[0]);
    } catch (error) {
      console.log(error);
    }
  };
  // 태그
  const [tags, setTags] = useState<string[]>([]);
  const [tag, setTag] = useState('');
  // 기간
  const [startTime, setStartTime] = useState<Date | null>(new Date());
  const [endTime, setEndTime] = useState<Date | null>(new Date());
  useEffect(() => {
    if (Number(endTime) < Number(startTime)) setEndTime(startTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTime, endTime]);
  // 조직
  const [orgAleady, setOrgAleady] = useState(false);
  const [memberOrg, setMemberOrg] = useRecoilState(memberOrgMultyState);
  const { isFetching: orgLoading } = useQuery<{ data: IOrg }>(['org'], getOrgList, {
    refetchOnWindowFocus: false,
    onSuccess: e => {
      setMemberOrg(prev => ({ ...prev, data: e.data }));
      setOrgAleady(true);
    },
  });

  const { isFetching: getReplayLoading } = useQuery<{ data: IReplayDetail }>(
    ['getReplay'],
    () => getReplay(Number(key)),
    {
      refetchOnWindowFocus: false,
      enabled: key !== undefined && orgAleady,
      onSuccess: e => {
        setIsPublic(e.data.public === 1);
        setTitle(e.data.title);
        editorRef.current.getInstance().setHTML(e.data.description);
        setThumbnail(e.data.thumbnail);
        setImgFile(e.data.thumbnail ? `uploaded_${e.data.thumbnail}` : null);
        setImg(
          !e.data.thumbnail
            ? null
            : e.data.thumbnail.includes('thumbnail')
            ? `${process.env.REACT_APP_S3_API}/asset/${e.data.thumbnail}`
            : `${process.env.REACT_APP_UPLOADED_URL}/xclass/image/replay/thumbnail/${e.data.thumbnail}`,
        );
        setImgName(e.data.thumbnail ? `uploaded_${e.data.thumbnail}` : '');
        setMemberOrg(prev => ({ ...prev, selected: e.data.organizations.map(el => el.lom_key) }));
        setStartTime(
          new Date(e.data.startDate).toString() === 'Invalid Date' ? new Date() : new Date(e.data.startDate),
        );
        setEndTime(new Date(e.data.endDate).toString() === 'Invalid Date' ? new Date() : new Date(e.data.endDate));
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (e: any) => alertShow(e.response.data.message),
    },
  );

  // 초기화
  const init = () => {
    setIsPublic(true);
    setTitle('');
    editorRef.current.getInstance().setHTML('');
    setThumbnail('');
    setImgFile(null);
    setImg(null);
    setImgName('');
    setTags([]);
    setOrgAleady(false);
    setMemberOrg({
      state: false,
      selected: [],
      data: { mo_key: 0 },
    });
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => init(), [search]);

  const { mutate: update, isLoading: updateLoading } = useMutation(editReplay, {
    onSuccess: () => navigate(`/${code}/live/replay`),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (e: any) => {
      createError({
        type: '[ADMIN] live - editReplay',
        message: `message: "${e.message}",\nresponse: { status: ${e.response.status}, message: "${e.response.data.message}" }`,
      });
      throwError(e);
    },
  });

  const setLoading = useSetRecoilState(loadingState);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formData = (_fields: any, _file: any) => {
    const data = new FormData();
    Object.keys(_fields).forEach(key => data.append(key, _fields[key]));
    if (_file) {
      data.append('file', _file);
    }
    return data;
  };

  const complete = () => {
    update({
      key: Number(key),
      data: {
        public: isPublic,
        title,
        description: editorRef.current?.getInstance().getHTML(),
        thumbnail,
        sdate: dayjs(startTime).format('YYYY-MM-DD HH:mm'),
        edate: dayjs(endTime).format('YYYY-MM-DD HH:mm'),
        tags,
        groups: memberOrg.selected,
      },
    });
    setLoading(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fileUpload = async (url: string, form: any) => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data = (await axios.post(url, form)) as any;
      if (data.ok || data.status === 204) {
        complete();
      } else {
        console.log('error');
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const uploadVodThumbnail = async () => {
    try {
      const { data } = await axios.post(`${api}/admin/live/upload_thumbnail`, {}, { withCredentials: true });
      await setThumbnail(data.data.name);
      await fileUpload(data.data.url.url, formData(data.data.url.fields, imgFile));
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(e);
      setLoading(false);
    }
  };

  const upload = () => {
    setLoading(true);
    if (img) {
      uploadVodThumbnail();
      return;
    }
    complete();
  };

  return (
    <Page title="다시보기수정" isLoading={orgLoading || getReplayLoading || updateLoading}>
      <FormItem title="공개여부" essential>
        <div className="switch">
          <div className={classNames('item', isPublic && 'active')} onClick={() => setIsPublic(prev => !prev)}>
            {t('공개')}
          </div>
          <div className={classNames('item', !isPublic && 'active')} onClick={() => setIsPublic(prev => !prev)}>
            {t('비공개')}
          </div>
        </div>
      </FormItem>
      <FormItem title="제목" essential>
        <div className="input">
          <input
            type="text"
            maxLength={50}
            placeholder={t('제목') || ''}
            value={title}
            onChange={e => setTitle(e.target.value)}
          />
        </div>
      </FormItem>
      <FormItem title="설명" essential>
        <div className="input"></div>
      </FormItem>
      <Editor
        previewStyle="vertical"
        height="400px"
        useCommandShortcut
        ref={editorRef}
        initialEditType="wysiwyg"
        toolbarItems={[
          ['heading', 'bold', 'italic', 'strike'],
          ['hr', 'quote'],
          ['ul', 'ol', 'task', 'indent', 'outdent'],
          [
            'table',
            // 'image',
            'link',
          ],
          ['code', 'codeblock'],
        ]}
        theme={cookies['xclass-theme']}
      />
      <FormItem title="섬네일">
        <div className="file">
          <div className="uploaded" style={{ display: img ? '' : 'none' }}>
            <img src={img || ''} alt="img" ref={imgRef} />
            <img
              src={icClose}
              alt="icon"
              className="remove"
              onClick={() =>
                confirmShow('삭제하시겠습니까?', () => {
                  if (inputRef.current) inputRef.current.value = '';
                  setThumbnail('');
                  setImgFile(null);
                  setImg(null);
                  setImgName('');
                })
              }
            />
          </div>
          <label htmlFor="img-upload">
            {imgName === '' ? (
              <div className="placeholder">
                <span>{t('섬네일 업로드 (용량 1024KB 이내의 jpg, png 파일)')}</span>
              </div>
            ) : (
              <div className="text">
                <span>{imgName.replace('uploaded_', '')}</span>
              </div>
            )}
            <input type="file" id="img-upload" onChange={e => changeThumb(e)} accept="image/*" ref={inputRef} />
          </label>
        </div>
      </FormItem>
      <FormItem title="태그">
        <div className="tags">
          {tags.map((item, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className="tag" key={idx}>
              <span>{item}</span>
              <img src={icClose} alt="icon" onClick={() => setTags(prev => prev.filter(ele => ele !== item))} />
            </div>
          ))}
          <input
            type="text"
            maxLength={20}
            placeholder={t('태그') || ''}
            value={tag}
            onChange={e => setTag(e.target.value)}
            onKeyPress={e => {
              if ((e.key === 'Enter' || e.code === 'Enter') && !tags.includes(tag) && tag.length > 0) {
                setTags(prev => prev.concat(tag));
                setTag('');
              }
            }}
          />
        </div>
        <div className="guide">
          <div className="item">{t('태그를 추가하려면 Enter 키를 누르세요.')}</div>
        </div>
      </FormItem>
      <FormItem title="기간" essential>
        <div className="input" style={{ maxWidth: '608px' }}>
          <DatePicker
            selected={startTime}
            onChange={date => setStartTime(date)}
            minDate={null}
            maxDate={endTime}
            selectsStart
            startDate={startTime}
            endDate={endTime}
            dateFormat="yyyy-MM-dd hh:mm aa"
            timeInputLabel={`${t('시각')}:`}
            showTimeInput
            disabledKeyboardNavigation
            onChangeRaw={(e: React.FormEvent<HTMLInputElement>) => e.preventDefault()}
            locale={cookies['xclass-lang'] === 'ko' ? ko : undefined}
          />
          <div style={{ margin: '0 8px', color: 'var(--greyish1)' }}>-</div>
          <DatePicker
            selected={endTime}
            onChange={date => setEndTime(date)}
            maxDate={null}
            selectsEnd
            startDate={startTime}
            endDate={endTime}
            timeInputLabel={`${t('시각')}:`}
            dateFormat="yyyy-MM-dd hh:mm aa"
            showTimeInput
            disabledKeyboardNavigation
            onChangeRaw={(e: React.FormEvent<HTMLInputElement>) => e.preventDefault()}
            locale={cookies['xclass-lang'] === 'ko' ? ko : undefined}
          />
        </div>
      </FormItem>
      <FormItem title="조직" essential>
        <div className="input">
          <input
            type="text"
            placeholder={t('조직') || ''}
            value={
              flatOrg(memberOrg.data)
                .concat({ mo_key: memberOrg.data.mo_key })
                .filter(ele => memberOrg.selected.includes(ele.mo_key)).length > 0
                ? flatOrg(memberOrg.data)
                    .concat({ mo_key: memberOrg.data.mo_key })
                    .filter(ele => memberOrg.selected.includes(ele.mo_key))
                    .map(el => el.mo_name)
                    .join(', ').length === 0
                  ? cookies['xclass-cname'] || ''
                  : flatOrg(memberOrg.data)
                      .concat({ mo_key: memberOrg.data.mo_key })
                      .filter(ele => memberOrg.selected.includes(ele.mo_key))
                      .map(el => el.mo_name)
                      .join(', ')
                : ''
            }
            readOnly
            className="combo"
            onClick={() => setMemberOrg(prev => ({ ...prev, state: true }))}
          />
          <div className="arrow"></div>
        </div>
      </FormItem>
      <div className="formSubmit">
        <button
          type="button"
          className="primary"
          onClick={() => {
            if (!title) {
              alertShow('제목을 입력하십시오.');
              return;
            }
            if (
              editorRef.current
                ?.getInstance()
                .getHTML()
                .replace(/<[^>]*>?/g, '').length === 0
            ) {
              alertShow('설명을 입력하십시오.');
              return;
            }
            if (memberOrg.selected.length === 0) {
              alertShow('조직을 선택하십시오.');
              return;
            }
            confirmShow(key ? '수정하시겠습니까?' : '등록하시겠습니까?', upload);
          }}
        >
          {key ? t('수정') : t('등록')}
        </button>
      </div>
      <InspectionOrg />
    </Page>
  );
}

export default LiveReplayEdit;
