import axios from 'axios';
import { createError, throwError } from './error';

const api = process.env.REACT_APP_API;

export const getSurveyList = async (page: number) => {
  try {
    const { data } = await axios.get(`${api}/survey/admin/list?page=${page}&limit=10&admin=true`, {
      withCredentials: true,
    });
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    createError({
      type: '[ADMIN] survey - getSurveyList',
      message: `message: "${error.message}",\nresponse: { status: ${error.response.status}, message: "${error.response.data.message}" }`,
    });
    throwError(error);
    return null;
  }
};

export const getSurveyProb = async (surveyKey: number) => {
  try {
    const { data } = await axios.get(`${api}/survey/admin/prob/list?surveyKey=${surveyKey}`, {
      withCredentials: true,
    });
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    createError({
      type: '[ADMIN] survey - getSurveyProb',
      message: `message: "${error.message}",\nresponse: { status: ${error.response.status}, message: "${error.response.data.message}" }`,
    });
    throwError(error);
    return null;
  }
};

export const getSurveyDetail = async (surveyKey: number) => {
  try {
    const { data } = await axios.get(`${api}/survey/admin/detail?surveyKey=${surveyKey}`, {
      withCredentials: true,
    });
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    createError({
      type: '[ADMIN] survey - getSurveyDetail',
      message: `message: "${error.message}",\nresponse: { status: ${error.response.status}, message: "${error.response.data.message}" }`,
    });
    throwError(error);
    return null;
  }
};

export const getSurveyResponseList = async (element: { surveyKey: number; page: number }) => {
  try {
    const { data } = await axios.get(
      `${api}/survey/admin/response/list?surveyKey=${element.surveyKey}&page=${element.page}&limit=10`,
      {
        withCredentials: true,
      },
    );
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    createError({
      type: '[ADMIN] survey - getSurveyResponseList',
      message: `message: "${error.message}",\nresponse: { status: ${error.response.status}, message: "${error.response.data.message}" }`,
    });
    throwError(error);
    return null;
  }
};

export const getSurveyResponseDetail = async (surveyResponseKey: number) => {
  try {
    const { data } = await axios.get(`${api}/survey/admin/response/detail?surveyResponseKey=${surveyResponseKey}`, {
      withCredentials: true,
    });
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    createError({
      type: '[ADMIN] survey - getSurveyResponseDetail',
      message: `message: "${error.message}",\nresponse: { status: ${error.response.status}, message: "${error.response.data.message}" }`,
    });
    throwError(error);
    return null;
  }
};

export const getSurveyAnalysis = async (surveyKey: number, fdate: string, edate: string) => {
  try {
    const { data } = await axios.get(
      `${api}/survey/statistic/responselog/stat?fdate=${fdate}&edate=${edate}&surveyKey=${surveyKey}`,
      {
        withCredentials: true,
      },
    );
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    createError({
      type: '[ADMIN] survey - getSurveyAnalysis',
      message: `message: "${error.message}",\nresponse: { status: ${error.response.status}, message: "${error.response.data.message}" }`,
    });
    throwError(error);
    return null;
  }
};

// 설문 생성
export const createSurvey = async (element: {
  surveyTitle: string;
  surveyProbList: ISurveyProps[];
  groups: number[];
  sdate: string;
  edate: string;
}) => {
  await axios.post(
    `${api}/survey/admin/create`,
    { ...element },
    {
      withCredentials: true,
    },
  );
};

// 설문 수정
export const updateSurvey = async (element: {
  surveyKey: number;
  surveyTitle: string;
  surveyProbList: ISurveyProps[];
  groups: number[];
  sdate: string;
  edate: string;
}) => {
  await axios.post(`${api}/survey/admin/update`, { ...element }, { withCredentials: true });
};

// 설문 삭제
export const deleteSurvey = async (surveyKey: number) => {
  await axios.delete(`${api}/survey/admin/delete/${surveyKey}`, { withCredentials: true });
};
