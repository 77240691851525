import dayjs from 'dayjs';
import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { reportInfoState } from '../../stores/learningtalkStore';

// components
import Modal from '../modal/modal';

// style
import styles from '../modal/selector.module.scss';

function ReportInfo({ init }: { init: () => void }) {
  const { t } = useTranslation();
  const [info, setInfo] = useRecoilState(reportInfoState);

  return (
    <AnimatePresence>
      {info.state && (
        <Modal
          close={() => {
            setInfo({
              state: false,
              type: '',
            });
            init();
          }}
        >
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            className={styles.modal}
          >
            <div className={styles.title}>
              {t('러닝톡톡 정보조회', {
                text: info.type === 'channel' ? t('채널') : info.type === 'contents' ? t('콘텐츠') : t('댓글'),
              })}
            </div>
            <table className="table">
              <tbody>
                {info.channelData && (
                  <>
                    <tr>
                      <td>
                        <div>
                          <span style={{ fontWeight: '500', minWidth: '100px' }}>{t('제목')}</span>
                        </div>
                      </td>
                      <td>
                        <div>
                          <span>{info.channelData.lt_title}</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <span style={{ fontWeight: '500', minWidth: '100px' }}>{t('설명')}</span>
                        </div>
                      </td>
                      <td>
                        <div style={{ textAlign: 'unset' }}>
                          <span>{info.channelData.lt_description}</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <span style={{ fontWeight: '500', minWidth: '100px' }}>{t('프로필')}</span>
                        </div>
                      </td>
                      <td>
                        <div>
                          {info.channelData.lt_profile_url && (
                            <img
                              src={`${process.env.REACT_APP_UPLOADED_URL}/${info.channelData.lt_profile_url}`}
                              alt="img"
                              style={{ maxWidth: '100%' }}
                            />
                          )}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <span style={{ fontWeight: '500', minWidth: '100px' }}>{t('배너')}</span>
                        </div>
                      </td>
                      <td>
                        <div>
                          {info.channelData.lt_banner_url && (
                            <img
                              src={`${process.env.REACT_APP_UPLOADED_URL}/${info.channelData.lt_banner_url}`}
                              alt="img"
                              style={{ maxWidth: '100%' }}
                            />
                          )}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <span style={{ fontWeight: '500', minWidth: '100px' }}>{t('구독자 수')}</span>
                        </div>
                      </td>
                      <td>
                        <div>
                          <span>{info.channelData.lt_subscribe_count}</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <span style={{ fontWeight: '500', minWidth: '100px' }}>{t('공개여부')}</span>
                        </div>
                      </td>
                      <td>
                        <div>
                          <span>
                            {info.channelData.lt_visibility === 'INTERNAL' && '사내공개'}
                            {info.channelData.lt_visibility === 'PUBLIC' && '전체공개'}
                            {info.channelData.lt_visibility === 'PRIVATE' && '비공개'}
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <span style={{ fontWeight: '500', minWidth: '100px' }}>{t('삭제')}</span>
                        </div>
                      </td>
                      <td>
                        <div>
                          <span>
                            {info.channelData.deleted_at &&
                              dayjs(info.channelData.deleted_at).format('YYYY-MM-DD HH:mm')}
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <span style={{ fontWeight: '500', minWidth: '100px' }}>{t('생성')}</span>
                        </div>
                      </td>
                      <td>
                        <div>
                          <span>{dayjs(info.channelData.created_at).format('YYYY-MM-DD HH:mm')}</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <span style={{ fontWeight: '500', minWidth: '100px' }}>{t('수정')}</span>
                        </div>
                      </td>
                      <td>
                        <div>
                          <span>{dayjs(info.channelData.updated_at).format('YYYY-MM-DD HH:mm')}</span>
                        </div>
                      </td>
                    </tr>
                  </>
                )}
                {info.contentsData && (
                  <>
                    <tr>
                      <td>
                        <div>
                          <span style={{ fontWeight: '500', minWidth: '100px' }}>{t('제목')}</span>
                        </div>
                      </td>
                      <td>
                        <div>
                          <span>{info.contentsData.ltc_title}</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <span style={{ fontWeight: '500', minWidth: '100px' }}>{t('설명')}</span>
                        </div>
                      </td>
                      <td>
                        <div>
                          <span>{info.contentsData.ltc_description}</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <span style={{ fontWeight: '500', minWidth: '100px' }}>{t('유형')}</span>
                        </div>
                      </td>
                      <td>
                        <div>
                          <span>{info.contentsData.ltc_type}</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <span style={{ fontWeight: '500', minWidth: '100px' }}>{t('공개여부')}</span>
                        </div>
                      </td>
                      <td>
                        <div>
                          <span>
                            {info.contentsData.ltc_visibility === 'INTERNAL' && '사내공개'}
                            {info.contentsData.ltc_visibility === 'PUBLIC' && '전체공개'}
                            {info.contentsData.ltc_visibility === 'PRIVATE' && '비공개'}
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <span style={{ fontWeight: '500', minWidth: '100px' }}>{t('상태')}</span>
                        </div>
                      </td>
                      <td>
                        <div>
                          <span>
                            {info.contentsData.ltc_status === 'complete' ? '완료' : info.contentsData.ltc_status}
                          </span>
                        </div>
                      </td>
                    </tr>
                    {info.contentsData.ltc_error && (
                      <tr>
                        <td>
                          <div>
                            <span style={{ fontWeight: '500', minWidth: '100px' }}>{t('오류')}</span>
                          </div>
                        </td>
                        <td>
                          <div>
                            <span>{info.contentsData.ltc_error}</span>
                          </div>
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td>
                        <div>
                          <span style={{ fontWeight: '500', minWidth: '100px' }}>{t('컨텐츠')}</span>
                        </div>
                      </td>
                      <td>
                        <div>
                          <button
                            className="success"
                            type="button"
                            onClick={() =>
                              window.open(
                                `${process.env.REACT_APP_VOD}/tp?vod_key=${info.contentsData?.ltc_origin_key}`,
                              )
                            }
                          >
                            보기
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <span style={{ fontWeight: '500', minWidth: '100px' }}>{t('섬네일')}</span>
                        </div>
                      </td>
                      <td>
                        <div>
                          {info.contentsData.ltc_thumbnail && (
                            <div className="preview">
                              <div className="preview__inner">
                                <img
                                  src={
                                    info.contentsData.ltc_type === 'CARD'
                                      ? `${process.env.REACT_APP_UPLOADED_URL}/${info.contentsData.ltc_thumbnail}`
                                      : `${process.env.REACT_APP_S3_API}/asset/${info.contentsData.ltc_thumbnail}`
                                  }
                                  alt="img"
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <span style={{ fontWeight: '500', minWidth: '100px' }}>{t('미리보기')}</span>
                        </div>
                      </td>
                      <td>
                        <div>
                          {info.contentsData.ltc_preview && (
                            <div className="preview">
                              <div className="preview__inner">
                                {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                                <video
                                  src={`${process.env.REACT_APP_S3_API}/asset/${info.contentsData.ltc_preview}`}
                                  controls
                                ></video>
                              </div>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <span style={{ fontWeight: '500', minWidth: '100px' }}>{t('기간')}</span>
                        </div>
                      </td>
                      <td>
                        <div>
                          <span>{info.contentsData.ltc_duration}</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <span style={{ fontWeight: '500', minWidth: '100px' }}>{t('좋아요 수')}</span>
                        </div>
                      </td>
                      <td>
                        <div>
                          <span>{info.contentsData.ltc_like_count}</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <span style={{ fontWeight: '500', minWidth: '100px' }}>{t('조회 수')}</span>
                        </div>
                      </td>
                      <td>
                        <div>
                          <span>{info.contentsData.ltc_view_count}</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <span style={{ fontWeight: '500', minWidth: '100px' }}>{t('댓글 수')}</span>
                        </div>
                      </td>
                      <td>
                        <div>
                          <span>{info.contentsData.ltc_comment_count}</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <span style={{ fontWeight: '500', minWidth: '100px' }}>{t('삭제')}</span>
                        </div>
                      </td>
                      <td>
                        <div>
                          <span>
                            {info.contentsData.deleted_at &&
                              dayjs(info.contentsData.deleted_at).format('YYYY-MM-DD HH:mm')}
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <span style={{ fontWeight: '500', minWidth: '100px' }}>{t('생성')}</span>
                        </div>
                      </td>
                      <td>
                        <div>
                          <span>{dayjs(info.contentsData.created_at).format('YYYY-MM-DD HH:mm')}</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <span style={{ fontWeight: '500', minWidth: '100px' }}>{t('수정')}</span>
                        </div>
                      </td>
                      <td>
                        <div>
                          <span>{dayjs(info.contentsData.updated_at).format('YYYY-MM-DD HH:mm')}</span>
                        </div>
                      </td>
                    </tr>
                  </>
                )}
                {info.commentData && (
                  <>
                    <tr>
                      <td>
                        <div>
                          <span style={{ fontWeight: '500', minWidth: '100px' }}>{t('내용')}</span>
                        </div>
                      </td>
                      <td>
                        <div>
                          <span>{info.commentData.ltcc_contents}</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <span style={{ fontWeight: '500', minWidth: '100px' }}>{t('답글 수')}</span>
                        </div>
                      </td>
                      <td>
                        <div>
                          <span>{info.commentData.ltcc_reply_count}</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <span style={{ fontWeight: '500', minWidth: '100px' }}>{t('좋아요 수')}</span>
                        </div>
                      </td>
                      <td>
                        <div>
                          <span>{info.commentData.ltcc_like_count}</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <span style={{ fontWeight: '500', minWidth: '100px' }}>{t('삭제')}</span>
                        </div>
                      </td>
                      <td>
                        <div>
                          <span>
                            {info.commentData.deleted_at &&
                              dayjs(info.commentData.deleted_at).format('YYYY-MM-DD HH:mm')}
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <span style={{ fontWeight: '500', minWidth: '100px' }}>{t('생성')}</span>
                        </div>
                      </td>
                      <td>
                        <div>
                          <span>{dayjs(info.commentData.created_at).format('YYYY-MM-DD HH:mm')}</span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <span style={{ fontWeight: '500', minWidth: '100px' }}>{t('수정')}</span>
                        </div>
                      </td>
                      <td>
                        <div>
                          <span>{dayjs(info.commentData.updated_at).format('YYYY-MM-DD HH:mm')}</span>
                        </div>
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </motion.div>
        </Modal>
      )}
    </AnimatePresence>
  );
}

export default ReportInfo;
