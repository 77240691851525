import classNames from 'classnames';
import ko from 'date-fns/locale/ko';
import dayjs from 'dayjs';
import DatePicker from 'react-datepicker';
import { motion, AnimatePresence } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { logModalState } from '../../stores/logStore';
import { icDownload } from '../aside/icon';

// components
import Modal from '../modal/modal';

// style
import styles from '../modal/dialog.module.scss';
import { logDownload } from '../../api/log';

function LogModal() {
  const { t } = useTranslation();
  const [cookies] = useCookies(['xclass-lang']);
  const [logModal, setLogModal] = useRecoilState(logModalState);

  const [chat, setChat] = useState(false);

  useEffect(() => setChat(false), []);

  return (
    <AnimatePresence>
      {logModal.state && (
        <Modal close={() => setLogModal(prev => ({ ...prev, state: false }))}>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            className={styles.modal}
          >
            <div className={styles.circle}>{icDownload()}</div>
            <div className={styles.message}></div>
            {logModal.element?.roomId && (
              <div className={styles.switch}>
                <div className={classNames(styles.item, !chat && styles.active)} onClick={() => setChat(prev => !prev)}>
                  {t('유저로그')}
                </div>
                <div className={classNames(styles.item, chat && styles.active)} onClick={() => setChat(prev => !prev)}>
                  {t('채팅로그')}
                </div>
              </div>
            )}
            <div className={styles.date}>
              <DatePicker
                selected={new Date(logModal.sdate)}
                onChange={date => setLogModal(prev => ({ ...prev, sdate: dayjs(date).format('YYYY-MM-DD') }))}
                minDate={null}
                maxDate={new Date(logModal.edate)}
                selectsStart
                startDate={new Date(logModal.sdate)}
                endDate={new Date(logModal.edate)}
                locale={cookies['xclass-lang'] === 'ko' ? ko : undefined}
                dateFormat="yyyy-MM-dd"
                disabledKeyboardNavigation
                onChangeRaw={(e: React.FormEvent<HTMLInputElement>) => e.preventDefault()}
              />
              <div style={{ margin: '0 8px', color: 'var(--greyish1)' }}>-</div>
              <DatePicker
                selected={new Date(logModal.edate)}
                onChange={date => setLogModal(prev => ({ ...prev, edate: dayjs(date).format('YYYY-MM-DD') }))}
                maxDate={null}
                selectsEnd
                startDate={new Date(logModal.sdate)}
                endDate={new Date(logModal.edate)}
                locale={cookies['xclass-lang'] === 'ko' ? ko : undefined}
                dateFormat="yyyy-MM-dd"
                disabledKeyboardNavigation
                onChangeRaw={(e: React.FormEvent<HTMLInputElement>) => e.preventDefault()}
              />
            </div>
            <div className={styles.btns}>
              <div
                className={styles.btn}
                onClick={() => {
                  if (logModal.element) {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    const data: any = {
                      sdate: logModal.sdate,
                      edate: logModal.edate,
                      fileName: logModal.element.fileName,
                    };
                    if (logModal.element.roomId) data.roomId = logModal.element.roomId;
                    if (chat) {
                      data.fileName = `${logModal.element.fileName}_chat`;
                      data.chat = true;
                    }
                    if (logModal.element.ckKey) data.ckKey = logModal.element.ckKey;
                    if (logModal.element.surveyKey) data.surveyKey = logModal.element.surveyKey;
                    if (logModal.element.vodKey) data.vodKey = logModal.element.vodKey;
                    if (logModal.element.evaluationKey) data.evaluationKey = logModal.element.evaluationKey;
                    logDownload(data);
                  }
                  setLogModal(prev => ({ ...prev, state: false }));
                }}
              >
                {t('다운로드')}
              </div>
              <div className={styles.btn} onClick={() => setLogModal(prev => ({ ...prev, state: false }))}>
                {t('취소')}
              </div>
            </div>
          </motion.div>
        </Modal>
      )}
    </AnimatePresence>
  );
}

export default LogModal;
