import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useAlert } from '../../stores/alertStore';
import { evalStore } from '../../stores/evaluationZustandStore';

// img
import icClose from '../../assets/img/icons/ic_close.svg';
import { icDelete, icImage } from '../aside/icon';

function QuestionAnswerItem({
  idx,
  item,
}: {
  idx: number;
  item: {
    eqa_key: number;
    eq_key?: number;
    eqa_text: string;
    eqa_image_path: string;
    eqa_correct: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    img?: any;
  };
}) {
  const { t } = useTranslation();
  const { uploadList, setUploadList, answerList, editAnswerList, removeAnswerList, setCorrectAnswer } = evalStore();
  const { alertShow } = useAlert();

  const [imgInit, setImgInit] = useState(false);
  const [imgPreview, setImgPreview] = useState('');

  useEffect(() => {
    if (!imgInit && item.eqa_image_path) {
      setImgInit(true);
      setImgPreview(`${process.env.REACT_APP_UPLOADED_URL}/${item.eqa_image_path}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  return (
    <div
      className={classNames(
        'item',
        answerList.filter(item => item.img || item.eqa_image_path).length > 0 && 'img-type',
      )}
    >
      {answerList.filter(item => item.img || item.eqa_image_path).length > 0 && (
        <div className="preview">
          <div className="preview-inner">
            {imgPreview && (
              <>
                <img src={imgPreview} alt="preview" className="img" />
                <img
                  src={icClose}
                  alt="close"
                  className="close"
                  onClick={() => {
                    setImgPreview('');
                    editAnswerList(item.eqa_key, { img: undefined });
                    setUploadList(uploadList.filter(e => e.key !== item.eqa_key));
                  }}
                />
              </>
            )}
          </div>
        </div>
      )}
      <label htmlFor={`image-${idx}`} className="image">
        <input
          type="file"
          id={`image-${idx}`}
          accept="image/png, image/jpeg, image/jpg"
          onChange={e => {
            if (e.target.files && e.target.files.length > 0) {
              const file = e.target.files[0];
              if (file?.size > 1000000) {
                alertShow('이미지는 최대 1MB까지만 등록가능합니다.');
                return;
              }
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onloadend = () => {
                setImgPreview(reader.result ? String(reader.result) : '');
                editAnswerList(item.eqa_key, { img: file });
                setUploadList(uploadList.concat({ type: 'choice', key: item.eqa_key, state: false }));
              };
            }
          }}
        />
        {icImage()}
      </label>
      <input
        type="text"
        placeholder={t('선택지를 입력하십시오.') || ''}
        value={item.eqa_text}
        className={classNames(idx <= 1 && 'expand')}
        onChange={e => editAnswerList(item.eqa_key, { eqa_text: e.target.value })}
      />
      <div
        className={classNames('answer', item.eqa_correct === 'Y' && 'active')}
        onClick={() => setCorrectAnswer(item.eqa_key)}
      >
        <span>{t('정답')}</span>
      </div>
      <object
        className={classNames('remove', idx <= 1 && 'hide')}
        onClick={() => {
          if (idx > 1) removeAnswerList(item.eqa_key);
        }}
      >
        {icDelete()}
      </object>
    </div>
  );
}

export default QuestionAnswerItem;
