import ko from 'date-fns/locale/ko';
import dayjs from 'dayjs';
import queryString from 'query-string';
import DatePicker from 'react-datepicker';
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getSurveyAnalysis } from '../../api/survey';
import { useAlert } from '../../stores/alertStore';

// style
import 'react-datepicker/dist/react-datepicker.css';

// components
import Page from '../../includes/page';
import FormItem from '../../components/basic/formItem';
import AnalysisItem from '../../components/survey/analysisItem';

function SurveyAnalysis() {
  const { t } = useTranslation();
  const [cookies] = useCookies(['xclass-lang']);
  const { alertShow } = useAlert();
  const { search } = useLocation();
  const { surveyKey } = queryString.parse(search);

  const [startDate, setStartDate] = useState<Date | null>(new Date(new Date().setMonth(new Date().getMonth() - 1)));
  const [endDate, setEndDate] = useState<Date | null>(new Date());

  const { isFetching, data, refetch } = useQuery<ISurveyAnalysis[]>(
    ['analysis'],
    () =>
      getSurveyAnalysis(
        Number(surveyKey),
        dayjs(startDate).format('YYYY-MM-DD 00:00:00'),
        dayjs(endDate).format('YYYY-MM-DD 23:59:59'),
      ),
    {
      refetchOnWindowFocus: false,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (e: any) => alertShow(e.response.data.message),
    },
  );

  // 마지막 날인지 확인하는 법
  function isLastDayOfMonth(date: Date): boolean {
    const nextDay = new Date(date);
    nextDay.setDate(date.getDate() + 1);
    return nextDay.getDate() === 1;
  }

  return (
    <Page title="설문통계" isLoading={isFetching}>
      <FormItem title="기간" essential={false}>
        <div className="input">
          <DatePicker
            selected={startDate}
            onChange={date => {
              setStartDate(date);
              if (date) {
                const endDate = new Date(date);
                if (endDate.getDate() === 1) setEndDate(new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0));
                else {
                  endDate.setMonth(endDate.getMonth() + 1);
                  setEndDate(endDate);
                }
              }
            }}
            maxDate={endDate}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            locale={cookies['xclass-lang'] === 'ko' ? ko : undefined}
            dateFormat="yyyy-MM-dd"
            disabledKeyboardNavigation
            onChangeRaw={(e: React.FormEvent<HTMLInputElement>) => e.preventDefault()}
          />
          <div style={{ margin: '0 8px', color: 'var(--greyish1)' }}>-</div>
          <DatePicker
            selected={endDate}
            onChange={date => {
              setEndDate(date);
              if (date) {
                const startDate = new Date(date);
                if (isLastDayOfMonth(startDate)) {
                  setStartDate(new Date(startDate.getFullYear(), startDate.getMonth(), 1));
                } else {
                  startDate.setMonth(startDate.getMonth() - 1);
                  setStartDate(startDate);
                }
              }
            }}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            locale={cookies['xclass-lang'] === 'ko' ? ko : undefined}
            dateFormat="yyyy-MM-dd"
            disabledKeyboardNavigation
            onChangeRaw={(e: React.FormEvent<HTMLInputElement>) => e.preventDefault()}
          />
          <div style={{ margin: '0 4px', color: 'var(--greyish1)' }}></div>
          <button type="button" className="btn info" onClick={() => refetch()}>
            {t('조회')}
          </button>
        </div>
      </FormItem>
      <div className="analysis">
        {data?.map((item, idx) => (
          <AnalysisItem key={item.svpb_key} item={item} idx={idx + 1} />
        ))}
      </div>
      {data?.length === 0 && (
        <div className="analysis__empty">
          <span>{t('설문 응답내역이 존재하지 않습니다.')}</span>
        </div>
      )}
    </Page>
  );
}

export default SurveyAnalysis;
