import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { categoryEditState } from '../../stores/boardStore';
import { icDone } from '../aside/icon';

// components
import Modal from '../modal/modal';

// style
import styles from '../modal/dialog.module.scss';

function CategoryEdit({
  create,
  update,
}: {
  create: (element: { type: string; name: string }) => void;
  update: (element: { bc_key: number; type: string; name: string }) => void;
}) {
  const { t } = useTranslation();
  const [categoryEdit, setCategoryEdit] = useRecoilState(categoryEditState);

  return (
    <AnimatePresence>
      {categoryEdit.state && (
        <Modal close={() => setCategoryEdit({ state: false, bcKey: 0, type: '', name: '' })}>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            className={styles.modal}
          >
            <div className={styles.circle}>{icDone()}</div>
            <div className={styles.message}>
              {/* eslint-disable-next-line no-restricted-globals */}
              {isNaN(categoryEdit.bcKey) ? t('카테고리를 등록합니다.') : t('카테고리를 수정합니다.')}
            </div>
            <div className={styles.input}>
              <select
                value={categoryEdit.type}
                onChange={e => setCategoryEdit(prev => ({ ...prev, type: e.target.value }))}
              >
                <option value="NOTICE">{t('공지사항')}</option>
                <option value="FREE">{t('자유게시판')}</option>
                <option value="QNA">Q&A</option>
              </select>
              <div className={styles.arrow}></div>
            </div>
            <div className={styles.input}>
              <input
                type="text"
                placeholder={t('이름') || ''}
                value={categoryEdit.name}
                onChange={e => setCategoryEdit(prev => ({ ...prev, name: e.target.value }))}
              />
            </div>
            <div className={styles.btns}>
              <div
                className={styles.btn}
                onClick={() => {
                  // eslint-disable-next-line no-restricted-globals
                  if (isNaN(categoryEdit.bcKey)) create({ type: categoryEdit.type, name: categoryEdit.name });
                  else update({ bc_key: categoryEdit.bcKey, type: categoryEdit.type, name: categoryEdit.name });
                  setCategoryEdit({ state: false, bcKey: 0, type: '', name: '' });
                }}
              >
                {t('확인')}
              </div>
              <div
                className={styles.btn}
                onClick={() => setCategoryEdit({ state: false, bcKey: 0, type: '', name: '' })}
              >
                {t('취소')}
              </div>
            </div>
          </motion.div>
        </Modal>
      )}
    </AnimatePresence>
  );
}

export default CategoryEdit;
