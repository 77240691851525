import dayjs from 'dayjs';
import queryString from 'query-string';
import { motion, AnimatePresence } from 'framer-motion';
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { Viewer } from '@toast-ui/react-editor';
import { getNotice } from '../../api/board';

// components
import Page from '../../includes/page';

function NoticeDetail() {
  const { t } = useTranslation();
  const [cookies] = useCookies(['xclass-theme']);
  const { search } = useLocation();
  const { bn_key } = queryString.parse(search);

  const [title, setTitle] = useState('');
  const [name, setName] = useState('');
  const [date, setDate] = useState('');
  const [description, setDescription] = useState('');
  // 이미지
  const [img, setImg] = useState<{ path1: string; path2: string; path3: string }>({
    path1: '',
    path2: '',
    path3: '',
  });

  const { isFetching: getNoticeLoading } = useQuery<{ data: INotice }>(['getNotice'], () => getNotice(Number(bn_key)), {
    refetchOnWindowFocus: false,
    enabled: bn_key !== undefined,
    onSuccess: e => {
      setTitle(e.data.bn_title);
      setName(e.data.m_name);
      setDate(e.data.updated_at);
      setDescription(e.data.bn_contents);
      setImg({
        path1: e.data.bn_path_1 ? `${process.env.REACT_APP_UPLOADED_URL}/${e.data.bn_path_1}` : '',
        path2: e.data.bn_path_2 ? `${process.env.REACT_APP_UPLOADED_URL}/${e.data.bn_path_2}` : '',
        path3: e.data.bn_path_3 ? `${process.env.REACT_APP_UPLOADED_URL}/${e.data.bn_path_3}` : '',
      });
    },
  });

  const [selectedId, setSelectedId] = useState<string | null>(null);
  return (
    <>
      <Page title={title} goBack="/board/notice" isLoading={getNoticeLoading}>
        <div className="etc-info">
          <div className="item">
            <span>{t('작성자')}: </span>
            {name}
          </div>
          <div className="item">{dayjs(date).format('YYYY-MM-DD HH:mm')}</div>
        </div>
        <div className="viewer">
          {description && <Viewer initialValue={description} theme={cookies['xclass-theme']} />}
          <div className="imgUpload">
            {img.path1 && (
              <div className="item">
                <motion.div layoutId={img.path1} className="zoom" onClick={() => setSelectedId(img.path1)}>
                  <img src={img.path1} alt="img" />
                </motion.div>
              </div>
            )}
            {img.path2 && (
              <div className="item">
                <motion.div layoutId={img.path2} className="zoom" onClick={() => setSelectedId(img.path2)}>
                  <img src={img.path2} alt="img" />
                </motion.div>
              </div>
            )}
            {img.path3 && (
              <div className="item">
                <motion.div layoutId={img.path3} className="zoom" onClick={() => setSelectedId(img.path3)}>
                  <img src={img.path3} alt="img" />
                </motion.div>
              </div>
            )}
          </div>
        </div>
      </Page>
      <AnimatePresence>
        {selectedId && (
          <motion.div
            animate="visible"
            exit="hidden"
            className="imgZoom"
            layoutId={selectedId}
            onClick={() => setSelectedId(null)}
          >
            <img src={selectedId} alt="img" />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}

export default NoticeDetail;
