import React, { useState, useEffect } from 'react';

function Delay({ delay, children }: { delay: number; children: React.ReactNode }) {
  const [done, setDone] = useState(false);

  useEffect(() => {
    const showTimer = setTimeout(() => setDone(true), delay);
    return () => clearTimeout(showTimer);
  });

  if (!done) return null;
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}

export default Delay;
