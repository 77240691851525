import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getSurveyResponseDetail } from '../../api/survey';
import { useAlert } from '../../stores/alertStore';

// components
import Page from '../../includes/page';
import ResultSurveyItem from '../../components/survey/resultItem';

function SurveyResponseDetail() {
  const { alertShow } = useAlert();
  const { search } = useLocation();
  const { surveyResponseKey } = queryString.parse(search);

  const { data, isFetching } = useQuery<{ data: ISurveyResponseDetail[] }>(
    ['survey'],
    () => getSurveyResponseDetail(Number(surveyResponseKey)),
    {
      refetchOnWindowFocus: false,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (e: any) => alertShow(e.response.data.message),
    },
  );

  return (
    <Page title="설문응답" isLoading={isFetching}>
      {data?.data.map((item, idx) => (
        <ResultSurveyItem
          key={item.survey_response_prob_key}
          item={item}
          idx={(idx + 1).toString().length < 2 ? `0${idx + 1}` : `${idx + 1}`}
        />
      ))}
    </Page>
  );
}

export default SurveyResponseDetail;
