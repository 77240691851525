import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

// style
import styles from './evaluationHistoryItem.module.scss';

// img
import imgRight from '../../assets/img/images/img_right.svg';
import imgWrong from '../../assets/img/images/img_wrong.svg';
import imgO from '../../assets/img/images/img_o.svg';
import imgOActive from '../../assets/img/images/img_o_white.svg';
import imgX from '../../assets/img/images/img_x.svg';
import imgXActive from '../../assets/img/images/img_x_white.svg';
import { icFullscreen, icFullscreenExit } from '../aside/icon';

function EvaluationHistoryItem({ item, idx }: { item: IEvaluationHistoryQuestion; idx: string }) {
  const { t } = useTranslation();

  const [titleImgFull, setTitleImgFull] = useState(false);
  const [imgFull, setImgFull] = useState<number | null>(null);

  return (
    <div className={styles.container}>
      <div className={styles.question}>
        <img src={item.ehq_correct === 'Y' ? imgRight : imgWrong} alt="img" className={styles.check} />
        <div className={styles.num}>
          <span>{idx}</span>
        </div>
        {item.eq_type === 'MC' && <div className={classNames(styles.type, styles.mc)}>{t('객관식')}</div>}
        {item.eq_type === 'OX' && <div className={classNames(styles.type, styles.ox)}>OX</div>}
        {item.eq_type === 'SA' && <div className={classNames(styles.type, styles.sa)}>{t('주관식')}</div>}
        <div className={styles.text}>
          <span>{item.eq_title}</span>
        </div>
      </div>
      {(item.eq_image_path || item.eq_video_path) && (
        <div className={classNames(styles.media, titleImgFull && styles.full)}>
          {item.eq_image_path && (
            <>
              <img src={`${process.env.REACT_APP_UPLOADED_URL}/${item.eq_image_path}`} alt="img" />
              <object className={styles.fullscreen} onClick={() => setTitleImgFull(prev => !prev)}>
                {titleImgFull ? icFullscreenExit('#ffffff') : icFullscreen('#ffffff')}
              </object>
            </>
          )}
          {item.eq_video_path && (
            <video
              src={`${process.env.REACT_APP_UPLOADED_URL}/${item.eq_video_path}`}
              playsInline
              controls
              muted
            ></video>
          )}
        </div>
      )}
      <div className={styles.content}>
        <span>{item.eq_contents}</span>
      </div>
      {item.eq_type === 'SA' && (
        <div className={styles.subjective}>
          <div className={styles.answer}>{item.eq_answer}</div>
        </div>
      )}
      {item.eq_type === 'MC' && (
        <div className={styles.single}>
          {item.evaluationQuestionAnswerList.map((ele, index) => (
            <div className={styles.item} key={ele.eqa_key}>
              <div
                className={classNames(
                  styles.radio,
                  ele.eqa_correct === 'Y' && styles.origin,
                  ele.eqa_correct === 'Y' &&
                    item.ehq_selected_answer.split('|').includes(`${index + 1}`) &&
                    styles.right,
                  ele.eqa_correct === 'N' &&
                    item.ehq_selected_answer.split('|').includes(`${index + 1}`) &&
                    styles.wrong,
                )}
              ></div>
              {ele.eqa_image_path && (
                <>
                  <div className={classNames(styles.box, ele.eqa_correct === 'Y' && styles.right)}>
                    <div className={classNames(styles.img, imgFull === ele.eqa_key && styles.full)}>
                      <img src={`${process.env.REACT_APP_UPLOADED_URL}/${ele.eqa_image_path}`} alt="img" />
                      <object
                        className={styles.fullscreen}
                        onClick={() => setImgFull(prev => (prev === ele.eqa_key ? null : ele.eqa_key || null))}
                      >
                        {imgFull === ele.eqa_key ? icFullscreenExit('#ffffff') : icFullscreen('#ffffff')}
                      </object>
                    </div>
                    <div className={styles.text}>
                      <span>{ele.eqa_text}</span>
                    </div>
                  </div>
                  <div className={styles['box-count']}>
                    <u style={{ color: ele.eqa_correct === 'Y' ? 'var(--primary)' : '' }}>
                      {t('명', { count: ele.count || 0 })}
                    </u>
                  </div>
                </>
              )}
              <div
                className={classNames(
                  styles.item,
                  ele.eqa_correct === 'Y' && styles.origin,
                  ele.eqa_correct === 'Y' &&
                    item.ehq_selected_answer.split('|').includes(`${index + 1}`) &&
                    styles.right,
                  ele.eqa_correct === 'N' &&
                    item.ehq_selected_answer.split('|').includes(`${index + 1}`) &&
                    styles.wrong,
                )}
              >
                <span>{ele.eqa_text}</span>
              </div>
            </div>
          ))}
        </div>
      )}
      {item.eq_type === 'OX' && (
        <div className={styles.ox}>
          <div
            className={classNames(
              styles.item,
              item.evaluationQuestionAnswerList[0].eqa_correct === 'Y' && styles.origin,
              item.ehq_selected_answer === item.eq_answer &&
                item.evaluationQuestionAnswerList[0].eqa_correct === 'Y' &&
                styles.right,
              item.ehq_selected_answer !== item.eq_answer &&
                item.eq_answer === item.evaluationQuestionAnswerList[0].text &&
                styles.wrong,
            )}
          >
            <img src={imgO} alt="icon" />
            <img src={imgOActive} alt="icon" />
          </div>
          <div
            className={classNames(
              styles.item,
              item.evaluationQuestionAnswerList[1].eqa_correct === 'Y' && styles.origin,
              item.ehq_selected_answer === item.eq_answer &&
                item.evaluationQuestionAnswerList[1].eqa_correct === 'Y' &&
                styles.right,
              item.ehq_selected_answer !== item.eq_answer &&
                item.eq_answer === item.evaluationQuestionAnswerList[1].text &&
                styles.wrong,
            )}
          >
            <img src={imgX} alt="icon" />
            <img src={imgXActive} alt="icon" />
          </div>
        </div>
      )}
    </div>
  );
}

export default EvaluationHistoryItem;
