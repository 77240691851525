import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

function ColorItem({
  lightColor,
  darkColor,
  color,
  setColor,
  custom,
}: {
  lightColor: string;
  darkColor: string;
  color: { lightColor: string; darkColor: string };
  setColor: (lightColor: string, darkColor: string) => void;
  // eslint-disable-next-line react/require-default-props
  custom?: boolean;
}) {
  const { t } = useTranslation();
  return (
    <tr className={classNames(!custom && color.lightColor === lightColor && color.darkColor === darkColor && 'active')}>
      {custom ? (
        <>
          <td>
            <input type="color" value={lightColor} onChange={e => setColor(e.target.value, darkColor)} />
          </td>
          <td>
            <input type="color" value={darkColor} onChange={e => setColor(lightColor, e.target.value)} />
          </td>
        </>
      ) : (
        <>
          <td>
            <div className="item" style={{ backgroundColor: lightColor }}></div>
          </td>
          <td>
            <div className="item" style={{ backgroundColor: darkColor }}></div>
          </td>
        </>
      )}
      {!custom && (
        <td>
          <button
            type="button"
            className={classNames(
              'btn',
              'info',
              color.lightColor === lightColor && color.darkColor === darkColor && 'disabled',
            )}
            onClick={() => setColor(lightColor, darkColor)}
          >
            {t('선택')}
          </button>
        </td>
      )}
    </tr>
  );
}

export default ColorItem;
