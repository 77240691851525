import classNames from 'classnames';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { createError, throwError } from '../../api/error';
import { getNoticeList, deleteNotice, getCategoryList } from '../../api/board';
import { useAlert } from '../../stores/alertStore';
import { useConfirm } from '../../stores/confirmStore';

// components
import Page from '../../includes/page';

// img
import Pagination from '../../includes/pagination copy';

function NoticeList() {
  const { t } = useTranslation();
  const { code } = useParams();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { page, cate } = queryString.parse(search);
  const { alertShow } = useAlert();
  const { confirmShow } = useConfirm();
  const [categoryAlready, setCateogyAlready] = useState(false);
  const { data: category } = useQuery<{ count: number; data: ICategory[] }>(
    ['categoryList'],
    () => getCategoryList('NOTICE'),
    {
      refetchOnWindowFocus: false,
      onSuccess: () => {
        setCateogyAlready(true);
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (e: any) => alertShow(e.response.data.message),
    },
  );
  const [count, setCount] = useState(0);
  const [data, setData] = useState<INotice[]>([]);
  const { isFetching: getNoticeListLoading, refetch } = useQuery<{ count: number; data: INotice[] }>(
    ['noticeList', page, cate],
    () =>
      getNoticeList({ page: page === undefined ? 1 : Number(page), category: cate === undefined ? 0 : Number(cate) }),
    {
      refetchOnWindowFocus: false,
      enabled: categoryAlready,
      onSuccess: e => {
        setCount(e.count);
        setData(e.data.map(item => ({ ...item, checked: false })));
        if (e.count > 0 && e.data.length === 0 && page !== undefined && Number(page) > 1)
          navigate(`${cate === undefined ? '' : `?cate=${Number(cate)}`}`);
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (e: any) => alertShow(e.response.data.message),
    },
  );

  const allSelect = () => {
    setData(
      data.map(item => ({
        ...item,
        checked: data.filter(item => item.checked).length !== data.length,
      })),
    );
  };

  const { mutate: remove, isLoading: deleteLoading } = useMutation(deleteNotice, {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (e: any) => {
      createError({
        type: '[ADMIN] board - deleteNotice',
        message: `message: "${e.message}",\nresponse: { status: ${e.response.status}, message: "${e.response.data.message}" }`,
      });
      throwError(e);
    },
  });

  const selectDelete = () =>
    confirmShow('선택한 항목을 삭제하시겠습니까?', () => {
      const checked = data.filter(item => item.checked);
      for (let i = 0; i < checked.length; i += 1) remove(checked[i].bn_key);
    });

  useEffect(() => {
    if (!deleteLoading && categoryAlready) refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteLoading]);

  return (
    <Page title="공지사항목록" isLoading={deleteLoading}>
      <div className="table-tools">
        <div className="btns">
          <button type="button" className="danger" onClick={allSelect}>
            <span>{t('전체선택')}</span>
          </button>
          <button type="button" className="primary" onClick={() => navigate(`/${code}/board/notice/edit`)}>
            <span>{t('등록')}</span>
          </button>
          <button
            type="button"
            className={classNames('secondary', data.filter(item => item.checked).length === 0 && 'disabled')}
            onClick={selectDelete}
          >
            <span>{t('선택삭제')}</span>
          </button>
        </div>
        <div className="filter">
          <select
            value={cate === undefined ? 0 : Number(cate)}
            onChange={e =>
              navigate(
                `?page=${page === undefined ? 1 : Number(page)}${
                  e.target.value === '0' ? '' : `&cate=${e.target.value}`
                }`,
              )
            }
          >
            <option value={0}>{t('전체')}</option>
            {category?.data.map(item => (
              <option value={item.bc_key} key={item.bc_key}>
                {item.bc_name}
              </option>
            ))}
          </select>
          <div className="arrow"></div>
        </div>
      </div>
      <div className="table-tools">
        <div className="btns"></div>
        {data.filter(item => item.checked).length > 0 && (
          <div className="selected">
            {t('개의 항목을 선택했습니다.', { amount: data.filter(item => item.checked).length })}
          </div>
        )}
      </div>
      <div className="table-container">
        <table className="table">
          <thead>
            <tr>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <th></th>
              <th>{t('제목')}</th>
              <th>{t('작성자')}</th>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {getNoticeListLoading && (
              <tr>
                <td colSpan={9}>
                  <div>
                    <div className="lds-spinner">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </td>
              </tr>
            )}
            {!getNoticeListLoading && data.length === 0 && (
              <tr>
                <td colSpan={4}>
                  <div>{t('검색된 목록이 없습니다.')}</div>
                </td>
              </tr>
            )}
            {data.map(item => (
              <tr key={item.bn_key}>
                <td>
                  <div>
                    <div
                      className={classNames(
                        'checkbox',
                        data.filter(ele => ele.bn_key === item?.bn_key)[0]?.checked && 'active',
                      )}
                      onClick={() => {
                        setData(
                          data.map(ele => {
                            if (ele.bn_key === item.bn_key) return { ...ele, checked: !ele.checked };
                            return ele;
                          }),
                        );
                      }}
                    ></div>
                  </div>
                </td>
                <td>
                  <div className="title">
                    <span
                      className="link"
                      onClick={() => navigate(`/${code}/board/notice/detail?bn_key=${item.bn_key}`)}
                    >
                      {item.bn_title}
                    </span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>{item.m_name}</span>
                  </div>
                </td>
                <td>
                  <div>
                    <button
                      type="button"
                      className="primary"
                      onClick={() => navigate(`/${code}/board/notice/edit?bn_key=${item.bn_key}`)}
                    >
                      <span>{t('수정')}</span>
                    </button>
                    <button
                      type="button"
                      className="secondary"
                      onClick={() => confirmShow('삭제하시겠습니까?', () => remove(item.bn_key))}
                    >
                      <span>{t('삭제')}</span>
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        total={count || 0}
        curr={page === undefined ? 1 : Number(page)}
        movePage={e => navigate(`?page=${e}${cate === undefined ? '' : `&cate=${Number(cate)}`}`)}
      />
    </Page>
  );
}

export default NoticeList;
