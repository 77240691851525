import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useMutation } from '@tanstack/react-query';
import { createError, throwError } from '../../api/error';
import { createOrg, deleteOrg, editOrg } from '../../api/member';
import { useConfirm } from '../../stores/confirmStore';
import { usePrompt } from '../../stores/promptStore';

// style
import styles from './orgItem.module.scss';

// img
import { icEdit, icAdd, icDelete } from '../aside/icon';

function OrgTree({ item, refetch }: { item: IOrg; refetch: () => void }) {
  const [cookies] = useCookies(['xclass-cname']);
  const { confirmShow } = useConfirm();
  const { promptShow } = usePrompt();
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (!item.mo_name) setOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { mutate: create } = useMutation(createOrg, {
    onSuccess: () => {
      refetch();
      setOpen(true);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (e: any) => {
      createError({
        type: '[ADMIN] member - createOrg',
        message: `message: "${e.message}",\nresponse: { status: ${e.response.status}, message: "${e.response.data.message}" }`,
      });
      throwError(e);
    },
  });

  const { mutate: deleteorg } = useMutation(deleteOrg, {
    onSuccess: () => refetch(),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (e: any) => {
      createError({
        type: '[ADMIN] member - deleteOrg',
        message: `message: "${e.message}",\nresponse: { status: ${e.response.status}, message: "${e.response.data.message}" }`,
      });
      throwError(e);
    },
  });

  const { mutate: editorg } = useMutation(editOrg, {
    onSuccess: () => refetch(),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (e: any) => {
      createError({
        type: '[ADMIN] member - editOrg',
        message: `message: "${e.message}",\nresponse: { status: ${e.response.status}, message: "${e.response.data.message}" }`,
      });
      throwError(e);
    },
  });

  return (
    <div className={styles.container}>
      <div className={styles.org}>
        <div
          className={classNames(
            styles.arrow,
            open && styles.active,
            item.mo_name && item.children && item.children.length === 0 && styles.disabled,
          )}
          onClick={() => {
            if (item.mo_name && item.children && item.children.length === 0) return;
            setOpen(prev => !prev);
          }}
        ></div>
        <div className={styles.inner} onClick={() => setOpen(prev => !prev)}>
          <div className={styles.name}>{item.mo_name || cookies['xclass-cname'] || ''}</div>
          <div className={styles.btns}>
            {item.mo_name !== undefined && (
              <object
                onClick={e => {
                  e.stopPropagation();
                  promptShow(
                    '변경할 조직명을 입력하십시오.',
                    e => editorg({ name: e, parent: item.mo_key }),
                    item.mo_name,
                  );
                }}
              >
                {icEdit()}
              </object>
            )}
            <object
              onClick={e => {
                e.stopPropagation();
                promptShow('생성할 조직의 이름을 입력하십시오.', e => create({ name: e, parent: item.mo_key }));
              }}
            >
              {icAdd()}
            </object>
            {item.mo_name && item.children && item.children.length === 0 && (
              <object
                onClick={e => {
                  e.stopPropagation();
                  confirmShow('삭제하시겠습니까?', () => deleteorg(item.mo_key));
                }}
              >
                {icDelete()}
              </object>
            )}
          </div>
        </div>
      </div>
      {open && (
        <div className={styles.children}>
          {item.children?.map(child => (
            <OrgTree item={child} key={child.mo_key} refetch={refetch} />
          ))}
        </div>
      )}
    </div>
  );
}

export default OrgTree;
