import classNames from 'classnames';
import queryString from 'query-string';
import { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { useMutation, useQuery } from '@tanstack/react-query';
import { decrypt } from '../../assets/util/crypto';
import { createError, throwError } from '../../api/error';
import { createMember, editMember, getMember, getMemberGrade, getMemberRole, getOrgList } from '../../api/member';
import { useAlert } from '../../stores/alertStore';
import { useConfirm } from '../../stores/confirmStore';
import { memberGradeState, memberOrgState, useMemberGrade, useMemberOrg } from '../../stores/memberStore';
import { icVisibility, icVisibilityOff } from '../../components/aside/icon';

// components
import Page from '../../includes/page';
import FormItem from '../../components/basic/formItem';
import MemberOrg from '../../components/member/memberOrg';
import MemberGrade from '../../components/member/memberGrade';
import { flatOrg } from '../../assets/util/flatOrg';

function MemberEdit() {
  const { t } = useTranslation();
  const [cookies] = useCookies(['xclass-lang', 'xclass-cname', 'XID']);
  const { code } = useParams();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { m_key } = queryString.parse(search);
  const { alertShow } = useAlert();
  const { confirmShow } = useConfirm();

  const [idValue, setIdValue] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [passwordView, setPasswordView] = useState(false);
  const [gender, setGender] = useState('N');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [memo, setMemo] = useState('');

  const [gradeAleady, setGradeAleady] = useState(false);
  const { setMemberGradeModal, setMemberGradeData } = useMemberGrade();
  const [memberGrade, setMemberGrade] = useRecoilState(memberGradeState);
  const { isFetching: gradeLoading } = useQuery<{ data: IGrade[] }>(['grade'], getMemberGrade, {
    refetchOnWindowFocus: false,
    onSuccess: e => {
      setMemberGrade(prev => ({ ...prev, data: e.data.map(item => ({ ...item, checked: false })) }));
      setGradeAleady(true);
    },
  });

  // 회원역할
  const [role, setRole] = useState<IRole[]>([]);
  const [roleReady, setRoleReady] = useState(false);
  const { isFetching: getMemberRoleLoading } = useQuery<{ data: IRole[] }>(['getMemberRole'], getMemberRole, {
    refetchOnWindowFocus: false,
    onSuccess: e => {
      setRoleReady(true);
      setRole(e.data.map(item => ({ ...item, checked: false })));
    },
  });

  const [orgAleady, setOrgAleady] = useState(false);
  const { setMemberOrgModal, setMemberOrgSelect } = useMemberOrg();
  const [memberOrg, setMemberOrg] = useRecoilState(memberOrgState);
  const { isFetching: orgLoading } = useQuery<{ data: IOrg }>(['org'], getOrgList, {
    refetchOnWindowFocus: false,
    onSuccess: e => {
      setMemberOrg(prev => ({ ...prev, data: e.data }));
      setOrgAleady(true);
    },
  });

  const { isFetching: getMemberLoading } = useQuery<{ data: IMemberDetail }>(
    ['member'],
    () => getMember(Number(m_key)),
    {
      refetchOnWindowFocus: false,
      enabled: roleReady && gradeAleady && orgAleady && m_key !== undefined,
      onSuccess: e => {
        setMemberGradeData(e.data.mg_code);
        setRole(prev =>
          prev.map(el => (e.data?.mr_code?.split(',').includes(el.mr_code) ? { ...el, checked: true } : el)),
        );
        setMemberOrgSelect(e.data.mo_key);
        setIdValue(e.data.m_id);
        setName(e.data.m_name);
        setGender(e.data.m_gender);
        setEmail(e.data.m_email);
        setPhone(e.data.m_phone);
        setMemo(e.data.m_memo);
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (e: any) => alertShow(e.response.data.message),
    },
  );

  const init = () => {
    setMemberGrade({ state: false, data: [] });
    setIdValue('');
    setName('');
    setPassword('');
    setGender('N');
    setEmail('');
    setPhone('');
    setMemo('');
    setMemberOrg({
      state: false,
      selected: 0,
      data: { mo_key: 0 },
    });
  };
  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const { mutate: create, isLoading: createLoading } = useMutation(createMember, {
    onSuccess: () => navigate(`/${code}/member`),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (e: any) => {
      createError({
        type: '[ADMIN] member - createMember',
        message: `message: "${e.message}",\nresponse: { status: ${e.response.status}, message: "${e.response.data.message}" }`,
      });
      throwError(e);
    },
  });

  const { mutate: update, isLoading: updateLoading } = useMutation(editMember, {
    onSuccess: () => navigate(`/${code}/member`),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (e: any) => {
      createError({
        type: '[ADMIN] member - editMember',
        message: `message: "${e.message}",\nresponse: { status: ${e.response.status}, message: "${e.response.data.message}" }`,
      });
      throwError(e);
    },
  });

  return (
    <Page
      title={m_key ? '회원수정' : '회원등록'}
      isLoading={
        getMemberLoading || gradeLoading || getMemberRoleLoading || orgLoading || createLoading || updateLoading
      }
    >
      <FormItem title="회원등급" essential>
        <div className="input">
          <input
            type="text"
            placeholder={t('회원등급') || ''}
            value={
              cookies['xclass-lang'] === 'ko'
                ? memberGrade.data.find(item => item.checked)?.mg_name || ''
                : memberGrade.data.find(item => item.checked)?.mg_code || ''
            }
            readOnly
            className="combo"
            onClick={() => setMemberGradeModal(true)}
          />
          <div className="arrow"></div>
        </div>
      </FormItem>
      {memberGrade.data.find(item => item.checked)?.mg_code === 'MANAGER' && (
        <FormItem title="권한" essential>
          <div className="list">
            {role
              .filter(item => decrypt(cookies.XID).c_modules.concat(',BOARD,MEMBER,PUSH').includes(item.mr_code))
              .map(item => (
                <div
                  className={classNames('item', item.checked && 'active')}
                  key={item.mr_code}
                  onClick={() => setRole(prev => prev.map(el => (item === el ? { ...el, checked: !el.checked } : el)))}
                >
                  <div className="text">{t(item.mr_name === '푸시' ? '푸시알림' : item.mr_name)}</div>
                  <div className="check"></div>
                </div>
              ))}
          </div>
        </FormItem>
      )}
      <FormItem title="조직" essential>
        <div className="input">
          <input
            type="text"
            placeholder={t('조직') || ''}
            value={
              memberOrg.selected === memberOrg.data.mo_key
                ? cookies['xclass-cname'] || ''
                : flatOrg(memberOrg.data).find(ele => ele.mo_key === memberOrg.selected)?.mo_name || ''
            }
            readOnly
            className="combo"
            onClick={() => setMemberOrgModal(true)}
          />
          <div className="arrow"></div>
        </div>
      </FormItem>
      <FormItem title="아이디" essential>
        <div className="input">
          <input
            type="text"
            maxLength={24}
            placeholder={t('아이디') || ''}
            value={idValue}
            disabled={m_key !== undefined}
            onChange={e => setIdValue(e.target.value)}
          />
        </div>
      </FormItem>
      <FormItem title="이름" essential>
        <div className="input">
          <input
            type="text"
            maxLength={24}
            placeholder={t('이름') || ''}
            value={name}
            onChange={e => setName(e.target.value)}
          />
        </div>
      </FormItem>
      <FormItem title="비밀번호" essential>
        <div className="input">
          <input
            type={passwordView ? 'text' : 'password'}
            placeholder={t('비밀번호') || ''}
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
          <object onClick={() => setPasswordView(prev => !prev)}>
            {passwordView ? icVisibilityOff() : icVisibility()}
          </object>
        </div>
      </FormItem>
      <FormItem title="성별">
        <div className="input">
          <select value={gender} onChange={e => setGender(e.target.value)}>
            <option value="N">{t('선택안함')}</option>
            <option value="M">{t('남자')}</option>
            <option value="F">{t('여자')}</option>
          </select>
          <div className="arrow"></div>
        </div>
      </FormItem>
      <FormItem title="이메일">
        <div className="input">
          <input type="text" placeholder={t('이메일') || ''} value={email} onChange={e => setEmail(e.target.value)} />
        </div>
      </FormItem>
      <FormItem title="연락처">
        <div className="input">
          <input
            type="text"
            maxLength={16}
            placeholder={t('연락처') || ''}
            value={phone}
            onChange={e => setPhone(e.target.value.replace(/[^0-9]/g, ''))}
          />
        </div>
      </FormItem>
      <FormItem title="메모">
        <div className="input">
          <textarea
            placeholder={t('메모') || ''}
            maxLength={200}
            value={memo || ''}
            onChange={e => setMemo(e.target.value)}
          ></textarea>
          <div className="count">{memo?.length || 0} / 200</div>
        </div>
      </FormItem>
      <div className="formSubmit">
        <button
          type="button"
          className="primary"
          onClick={() => {
            if (!memberGrade.data.find(item => item.checked)) {
              alertShow('회원등급을 선택하십시오.');
              return;
            }
            if (memberOrg.selected === 0) {
              alertShow('조직을 선택하십시오.');
              return;
            }
            if (!idValue) {
              alertShow('아이디를 입력하십시오.');
              return;
            }
            if (!name) {
              alertShow('이름을 입력하십시오.');
              return;
            }
            if (!m_key && !password) {
              alertShow('비밀번호를 입력하십시오.');
              return;
            }
            confirmShow(m_key ? t('수정하시겠습니까?') : t('등록하시겠습니까?'), () => {
              if (m_key) {
                update({
                  m_key: Number(m_key),
                  data: {
                    grade: memberGrade.data.find(item => item.checked)?.mg_code || '',
                    organization: memberOrg.selected,
                    id: idValue,
                    name,
                    password: password || undefined,
                    gender,
                    email,
                    phone,
                    memo,
                    role:
                      memberGrade.data.find(item => item.checked)?.mg_code === 'MANAGER'
                        ? role
                            .filter(item => item.checked)
                            .map(el => el.mr_code)
                            .join(',')
                        : undefined,
                  },
                });
              } else {
                create({
                  grade: memberGrade.data.find(item => item.checked)?.mg_code || '',
                  organization: memberOrg.selected,
                  id: idValue,
                  name,
                  password,
                  gender,
                  email,
                  phone,
                  memo,
                  role:
                    memberGrade.data.find(item => item.checked)?.mg_code === 'MANAGER'
                      ? role
                          .filter(item => item.checked)
                          .map(el => el.mr_code)
                          .join(',')
                      : undefined,
                });
              }
            });
          }}
        >
          {m_key ? t('수정') : t('등록')}
        </button>
      </div>
      <MemberGrade />
      <MemberOrg />
    </Page>
  );
}

export default MemberEdit;
