import classNames from 'classnames';
import ko from 'date-fns/locale/ko';
import queryString from 'query-string';
import DatePicker from 'react-datepicker';
import { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { useMutation, useQuery } from '@tanstack/react-query';
import { createError, throwError } from '../../api/error';
import { getOrgList } from '../../api/member';
import { createEvaluation, editEvaluation, getCategoryList, getEvaluation } from '../../api/evaluation';
import { useAlert } from '../../stores/alertStore';
import { useConfirm } from '../../stores/confirmStore';
import { memberOrgMultyState } from '../../stores/memberStore';
import { categoryState, questionListState, useCategory } from '../../stores/evaluationStore';
import { flatOrg } from '../../assets/util/flatOrg';
import { editDateFormat } from '../../assets/util/dateFormat';

// components
import Page from '../../includes/page';
import FormItem from '../../components/basic/formItem';
import EvaluationCategory from '../../components/evaluation/category';
import EvaluationQuestionList from '../../components/evaluation/questionList';
import InspectionOrg from '../../components/inspection/org';

// style
import 'react-datepicker/dist/react-datepicker.css';

// img
import { icPlus, icMinus, icDelete, icAdd } from '../../components/aside/icon';

function EvaluationEdit() {
  const { t } = useTranslation();
  const [cookies] = useCookies(['xclass-lang', 'xclass-cname']);
  const { code } = useParams();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { e_key } = queryString.parse(search);
  const { alertShow } = useAlert();
  const { confirmShow } = useConfirm();

  const [categoryAleady, setCategoryAleady] = useState(false);
  const { setCategoryModal, setCategoryData } = useCategory();
  const [category, setCategory] = useRecoilState(categoryState);
  const { isFetching: categoryLoading } = useQuery<{ data: IEvaluationCategory[] }>(
    ['getCategoryList'],
    () => getCategoryList(),
    {
      refetchOnWindowFocus: false,
      onSuccess: e => {
        setCategory(prev => ({ ...prev, data: e.data.map(item => ({ ...item, checked: false })) }));
        setCategoryAleady(true);
      },
    },
  );

  const [title, setTitle] = useState('');
  const [contents, setContents] = useState('');
  // 기간
  const [startTime, setStartTime] = useState<Date | null>(new Date());
  const [endTime, setEndTime] = useState<Date | null>(new Date());
  useEffect(() => {
    if (Number(endTime) < Number(startTime)) setEndTime(startTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTime, endTime]);
  const [timeLimit, setTimeLimit] = useState(0);
  const [questionShuffle, setQuestionShuffle] = useState('Y');
  const [answerShuffle, setAnswerShuffle] = useState('Y');
  const [questionList, setQuestionList] = useRecoilState(questionListState);
  // 조직
  const [orgAleady, setOrgAleady] = useState(false);
  const [memberOrg, setMemberOrg] = useRecoilState(memberOrgMultyState);
  const { isFetching: orgLoading } = useQuery<{ data: IOrg }>(['getOrgList'], getOrgList, {
    refetchOnWindowFocus: false,
    onSuccess: e => {
      setMemberOrg(prev => ({ ...prev, data: e.data }));
      setOrgAleady(true);
    },
  });

  const { isFetching: getEvaluationLoading } = useQuery<{ data: IEvaluationDetail }>(
    ['getEvaluation'],
    () => getEvaluation(Number(e_key)),
    {
      refetchOnWindowFocus: false,
      enabled: orgAleady && categoryAleady && e_key !== undefined,
      onSuccess: e => {
        setCategoryData(e.data.ec_key);
        setTitle(e.data.e_title);
        setContents(e.data.e_contents);
        setStartTime(
          String(new Date(e.data.e_start_time)) === 'Invalid Date' ? new Date() : new Date(e.data.e_start_time),
        );
        setEndTime(String(new Date(e.data.e_end_time)) === 'Invalid Date' ? new Date() : new Date(e.data.e_end_time));
        setTimeLimit(e.data.e_time_limit / 60);
        setQuestionShuffle(e.data.e_question_shuffle);
        setAnswerShuffle(e.data.e_answer_shuffle);
        setQuestionList(prev => ({
          ...prev,
          data:
            e.data.evaluationQuestionList?.map(item => {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const obj: any = { ...item };
              if (item.eq_key !== undefined) obj.questionKey = item.eq_key;
              if (item.eqm_score !== undefined) obj.score = item.eqm_score;
              return obj;
            }) || [],
        }));
        setMemberOrg(prev => ({ ...prev, selected: e.data.organizations.map(item => item.mo_key) }));
      },
    },
  );

  const init = () => {
    setTitle('');
    setContents('');
    setStartTime(new Date());
    setEndTime(new Date());
    setTimeLimit(0);
    setQuestionShuffle('Y');
    setAnswerShuffle('Y');
    setQuestionList({ state: false, data: [] });
    setMemberOrg({
      state: false,
      selected: [],
      data: { mo_key: 0 },
    });
  };
  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const { mutate: create, isLoading: createLoading } = useMutation(createEvaluation, {
    onSuccess: () => navigate(`/${code}/evaluation`),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (e: any) => {
      createError({
        type: '[ADMIN] evaluation - createEvaluation',
        message: `message: "${e.message}",\nresponse: { status: ${e.response.status}, message: "${e.response.data.message}" }`,
      });
      throwError(e);
    },
  });

  const { mutate: update, isLoading: updateLoading } = useMutation(editEvaluation, {
    onSuccess: () => navigate(`/${code}/evaluation`),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (e: any) => {
      createError({
        type: '[ADMIN] evaluation - editEvaluation',
        message: `message: "${e.message}",\nresponse: { status: ${e.response.status}, message: "${e.response.data.message}" }`,
      });
      throwError(e);
    },
  });

  return (
    <Page
      title={e_key ? '평가수정' : '평가등록'}
      isLoading={getEvaluationLoading || categoryLoading || orgLoading || createLoading || updateLoading}
    >
      <FormItem title="카테고리" essential>
        <div className="input">
          <input
            type="text"
            placeholder={t('카테고리') || ''}
            value={category.data.find(item => item.checked)?.ec_name || ''}
            readOnly
            className="combo"
            onClick={() => setCategoryModal(true)}
          />
          <div className="arrow"></div>
        </div>
      </FormItem>
      <FormItem title="제목" essential>
        <div className="input">
          <input
            type="text"
            maxLength={50}
            placeholder={t('제목') || ''}
            value={title}
            onChange={e => setTitle(e.target.value)}
          />
        </div>
      </FormItem>
      <FormItem title="내용" essential>
        <div className="input">
          <textarea
            placeholder={t('내용') || ''}
            maxLength={200}
            value={contents}
            onChange={e => setContents(e.target.value)}
          ></textarea>
          <div className="count">{contents.length} / 200</div>
        </div>
      </FormItem>
      <FormItem title="기간" essential>
        <div className="input" style={{ maxWidth: '608px' }}>
          <DatePicker
            selected={startTime}
            onChange={date => setStartTime(date)}
            minDate={null}
            maxDate={endTime}
            selectsStart
            startDate={startTime}
            endDate={endTime}
            dateFormat="yyyy-MM-dd hh:mm aa"
            timeInputLabel={`${t('시각')}:`}
            showTimeInput
            disabledKeyboardNavigation
            onChangeRaw={(e: React.FormEvent<HTMLInputElement>) => e.preventDefault()}
            locale={cookies['xclass-lang'] === 'ko' ? ko : undefined}
          />
          <div style={{ margin: '0 8px', color: 'var(--greyish1)' }}>-</div>
          <DatePicker
            selected={endTime}
            onChange={date => setEndTime(date)}
            maxDate={null}
            selectsEnd
            startDate={startTime}
            endDate={endTime}
            timeInputLabel={`${t('시각')}:`}
            dateFormat="yyyy-MM-dd hh:mm aa"
            showTimeInput
            disabledKeyboardNavigation
            onChangeRaw={(e: React.FormEvent<HTMLInputElement>) => e.preventDefault()}
            locale={cookies['xclass-lang'] === 'ko' ? ko : undefined}
          />
        </div>
      </FormItem>
      <FormItem title="제한시간" essential>
        <div className="timer">
          <div className="text">
            <input
              type="text"
              value={timeLimit}
              onChange={e => {
                setTimeLimit(Number(e.target.value.replace(/[^0-9]/g, '')));
                if (Number(e.target.value) > 240) setTimeLimit(240);
              }}
            />
            {t('분', { minutes: '' })}
          </div>
          <div className="btns">
            <object onClick={() => setTimeLimit(prev => (prev + 10 > 240 ? 240 : prev + 10))}>{icPlus()}</object>
            <object onClick={() => setTimeLimit(prev => (prev === 0 ? prev : prev - 10))}>{icMinus()}</object>
          </div>
        </div>
      </FormItem>
      <FormItem title="문제셔플" essential>
        <div className="switch">
          <div
            className={classNames('item', questionShuffle === 'Y' && 'active')}
            onClick={() => setQuestionShuffle(prev => (prev === 'Y' ? 'N' : 'Y'))}
          >
            On
          </div>
          <div
            className={classNames('item', questionShuffle === 'N' && 'active')}
            onClick={() => setQuestionShuffle(prev => (prev === 'Y' ? 'N' : 'Y'))}
          >
            Off
          </div>
        </div>
      </FormItem>
      <FormItem title="보기셔플" essential>
        <div className="switch">
          <div
            className={classNames('item', answerShuffle === 'Y' && 'active')}
            onClick={() => setAnswerShuffle(prev => (prev === 'Y' ? 'N' : 'Y'))}
          >
            On
          </div>
          <div
            className={classNames('item', answerShuffle === 'N' && 'active')}
            onClick={() => setAnswerShuffle(prev => (prev === 'Y' ? 'N' : 'Y'))}
          >
            Off
          </div>
        </div>
      </FormItem>
      <FormItem title="보기" essential>
        <div className="questionList">
          <div className="head">
            <div className="type">{t('유형')}</div>
            <div className="title">{t('제목')}</div>
            <div className="diff">{t('난이도')}</div>
            <div className="score">{t('점수')}</div>
            <div className="etc"></div>
          </div>
          {questionList.data.map(item => (
            <div className="item" key={item.questionKey}>
              <div className="type">
                {(item.eq_type || item.type) === 'MC' && <span className="mc">{t('객관식')}</span>}
                {(item.eq_type || item.type) === 'OX' && <span className="ox">OX</span>}
                {(item.eq_type || item.type) === 'SA' && <span className="sa">{t('주관식')}</span>}
              </div>
              <div className="title">{item.eq_title || item.title}</div>
              <div className="diff">
                {(item.eq_difficulty || item.difficulty) === 1 && t('하')}
                {(item.eq_difficulty || item.difficulty) === 2 && t('중')}
                {(item.eq_difficulty || item.difficulty) === 3 && t('상')}
              </div>
              <input
                type="text"
                value={item.score}
                className="score"
                onChange={e => {
                  setQuestionList(prev => ({
                    ...prev,
                    data: prev.data.map(el =>
                      el.questionKey === item.questionKey
                        ? { ...el, score: Number(e.target.value.replace(/[^0-9]/g, '')) }
                        : el,
                    ),
                  }));
                }}
              />
              <object
                className="remove"
                onClick={() =>
                  setQuestionList(prev => ({
                    ...prev,
                    data: prev.data.filter(el => el.questionKey !== item.questionKey),
                  }))
                }
              >
                {icDelete()}
              </object>
            </div>
          ))}
          <div className="item">
            <div className="add">
              <object onClick={() => setQuestionList(prev => ({ ...prev, state: true }))}>{icAdd()}</object>
            </div>
          </div>
        </div>
      </FormItem>
      <FormItem title="조직" essential>
        <div className="input">
          <input
            type="text"
            placeholder={t('조직') || ''}
            value={
              flatOrg(memberOrg.data)
                .concat({ mo_key: memberOrg.data.mo_key })
                .filter(ele => memberOrg.selected.includes(ele.mo_key)).length > 0
                ? flatOrg(memberOrg.data)
                    .concat({ mo_key: memberOrg.data.mo_key })
                    .filter(ele => memberOrg.selected.includes(ele.mo_key))
                    .map(el => el.mo_name)
                    .join(', ').length === 0
                  ? cookies['xclass-cname'] || ''
                  : flatOrg(memberOrg.data)
                      .concat({ mo_key: memberOrg.data.mo_key })
                      .filter(ele => memberOrg.selected.includes(ele.mo_key))
                      .map(el => el.mo_name)
                      .join(', ')
                : ''
            }
            readOnly
            className="combo"
            onClick={() => setMemberOrg(prev => ({ ...prev, state: true }))}
          />
          <div className="arrow"></div>
        </div>
      </FormItem>
      <div className="formSubmit">
        <button
          type="button"
          className="primary"
          onClick={() => {
            if (!category.data.find(item => item.checked)?.ec_name) {
              alertShow('카테고리를 선택하십시오.');
              return;
            }
            if (!title) {
              alertShow('제목을 입력하십시오.');
              return;
            }
            if (!contents) {
              alertShow('내용을 입력하십시오.');
              return;
            }
            if (startTime && endTime && startTime >= endTime) {
              alertShow('시작시간과 종료시간을 확인하십시오.');
              return;
            }
            if (timeLimit === 0) {
              alertShow('제한시간을 설정하십시오.');
              return;
            }
            if (questionList.data.length === 0) {
              alertShow('평가문제를 추가하십시오.');
              return;
            }
            if (questionList.data.find(item => item.score === 0)) {
              alertShow('평가문제의 점수를 설정하십시오');
              return;
            }
            confirmShow(e_key ? '수정하시겠습니까?' : '등록하시겠습니까?', () => {
              if (e_key) {
                update({
                  e_key: Number(e_key),
                  data: {
                    category: category.data.find(item => item.checked)?.ec_key || 0,
                    title,
                    contents,
                    startTime: editDateFormat(startTime),
                    endTime: editDateFormat(endTime),
                    timeLimit: timeLimit * 60,
                    questionShuffle,
                    answerShuffle,
                    questionList: questionList.data.map(item => ({
                      questionKey: item.questionKey || 0,
                      score: item.score || 0,
                    })),
                    organizationKeys: memberOrg.selected,
                  },
                });
              } else {
                create({
                  category: category.data.find(item => item.checked)?.ec_key || 0,
                  title,
                  contents,
                  startTime: editDateFormat(startTime),
                  endTime: editDateFormat(endTime),
                  timeLimit: timeLimit * 60,
                  questionShuffle,
                  answerShuffle,
                  questionList: questionList.data.map(item => ({
                    questionKey: item.questionKey || 0,
                    score: item.score || 0,
                  })),
                  organizationKeys: memberOrg.selected,
                });
              }
            });
          }}
        >
          {e_key ? t('수정') : t('등록')}
        </button>
      </div>
      <EvaluationCategory />
      <EvaluationQuestionList />
      <InspectionOrg />
    </Page>
  );
}

export default EvaluationEdit;
