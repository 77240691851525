import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { categoryEditState } from '../../stores/evaluationStore';
import { icDone } from '../aside/icon';

// components
import Modal from '../modal/modal';

// style
import styles from '../modal/dialog.module.scss';

function EvaluationCategoryEdit({
  create,
  update,
}: {
  create: (name: string) => void;
  update: (element: { ec_key: number; name: string }) => void;
}) {
  const { t } = useTranslation();
  const [categoryEdit, setCategoryEdit] = useRecoilState(categoryEditState);

  return (
    <AnimatePresence>
      {categoryEdit.state && (
        <Modal close={() => setCategoryEdit({ state: false, type: 'create', ecKey: 0, name: '' })}>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            className={styles.modal}
          >
            <div className={styles.circle}>{icDone()}</div>
            <div className={styles.message}>
              {categoryEdit.type === 'edit' ? t('카테고리를 수정합니다.') : t('카테고리를 등록합니다.')}
            </div>
            <div className={styles.input}>
              <input
                type="text"
                placeholder={t('이름') || ''}
                value={categoryEdit.name}
                onChange={e => setCategoryEdit(prev => ({ ...prev, name: e.target.value }))}
              />
            </div>
            <div className={styles.btns}>
              <div
                className={styles.btn}
                onClick={() => {
                  if (categoryEdit.type === 'edit') update({ ec_key: categoryEdit.ecKey, name: categoryEdit.name });
                  else create(categoryEdit.name);
                  setCategoryEdit({ state: false, type: 'create', ecKey: 0, name: '' });
                }}
              >
                {t('확인')}
              </div>
              <div
                className={styles.btn}
                onClick={() => setCategoryEdit({ state: false, type: 'create', ecKey: 0, name: '' })}
              >
                {t('취소')}
              </div>
            </div>
          </motion.div>
        </Modal>
      )}
    </AnimatePresence>
  );
}

export default EvaluationCategoryEdit;
