import classNames from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { useMutation, useQuery } from '@tanstack/react-query';
import { editManagerRole, getMemberRole } from '../../api/member';
import { memberRoleState } from '../../stores/memberStore';

// components
import Modal from '../modal/modal';

// style
import styles from '../modal/selector.module.scss';
import ModalLoading from '../modal/modalLoading';

function MemberRole({ refresh }: { refresh: () => void }) {
  const { t } = useTranslation();
  const [memberRole, setMemberRole] = useRecoilState(memberRoleState);
  const [role, setRole] = useState<IRole[]>([]);
  const { isFetching: getMemberRoleLoading } = useQuery<{ data: IRole[] }>(['getMemberRole'], getMemberRole, {
    refetchOnWindowFocus: false,
    onSuccess: e => {
      setRole(e.data.map(item => ({ ...item, checked: false })));
    },
  });
  const { mutate: edit, isLoading: editManagerRoleLoading } = useMutation(editManagerRole, {
    onSuccess: () => {
      setMemberRole(() => ({ state: false, m_key: 0, data: [] }));
      refresh();
    },
  });

  return (
    <AnimatePresence>
      {memberRole.state && (
        <Modal close={() => setMemberRole(() => ({ state: false, m_key: 0, data: [] }))}>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            className={styles.modal}
          >
            <div className={styles.header}>
              <div className={styles.title}>{t('권한을 선택하십시오.')}</div>
              <button
                type="button"
                className="primary"
                onClick={() => edit({ m_key: memberRole.m_key, role: memberRole.data.join(',') })}
              >
                {t('확인')}
              </button>
            </div>
            <div className={styles.role}>
              {role.map(item => (
                <div
                  className={classNames(styles.item, memberRole.data.includes(item.mr_code) && styles.active)}
                  key={item.mr_code}
                  onClick={() =>
                    setMemberRole(prev => ({
                      ...prev,
                      data: prev.data.includes(item.mr_code)
                        ? prev.data.filter(el => el !== item.mr_code)
                        : [...prev.data, item.mr_code],
                    }))
                  }
                >
                  <div className={styles.text}>{t(item.mr_name)}</div>
                  <div className={styles.check}></div>
                </div>
              ))}
            </div>
            <ModalLoading isLoading={getMemberRoleLoading || editManagerRoleLoading} />
          </motion.div>
        </Modal>
      )}
    </AnimatePresence>
  );
}

export default MemberRole;
