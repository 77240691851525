import { useRef } from 'react';
import { useCookies } from 'react-cookie';
import { useParams } from 'react-router-dom';

// style
import styles from './index.module.scss';

// img
import logo from '../assets/img/logo.svg';
import logoDark from '../assets/img/logo_dark.svg';
import logoEmpty from '../assets/img/logo_empty.svg';

function Home() {
  const [cookies] = useCookies(['xclass-theme']);
  const { code } = useParams();

  const logoRef = useRef<HTMLImageElement>(null);

  return (
    <div className={styles.container}>
      <div className={styles.bg}></div>
      {code?.toUpperCase() !== 'SUPER' ? (
        <img
          src={`${process.env.REACT_APP_UPLOADED_URL}/xclass/image/customer/${code?.toUpperCase()}_${
            cookies['xclass-theme'] === 'dark' ? 'DARK' : 'LIGHT'
          }_LOGO`}
          alt="logo"
          className={styles.logo}
          onError={() => {
            if (logoRef.current) logoRef.current.src = logoEmpty;
          }}
          ref={logoRef}
        />
      ) : (
        <img src={cookies['xclass-theme'] === 'dark' ? logoDark : logo} alt="logo" className={styles.logo} />
      )}
    </div>
  );
}

export default Home;
