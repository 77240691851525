export const flatOrg = (item: IOrg) => {
  const flatten = (child: IOrg[], extractChildren: (org: IOrg) => IOrg[]): IOrg[] =>
    Array.prototype.concat.apply(
      child,
      child.map((ele: IOrg) => flatten(extractChildren(ele), extractChildren)),
    );

  const extractChildren = (org: IOrg) => org.children || [];

  // eslint-disable-next-line no-param-reassign
  return flatten(extractChildren(item), extractChildren);
};
