import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ko from './translation.ko.json';
import en from './translation.en.json';
import ja from './translation.ja.json';
import cn from './translation.zh-CN.json';
import tw from './translation.zh-TW.json';
import id from './translation.id.json';
import fr from './translation.fr.json';
import it from './translation.it.json';
import es from './translation.es.json';
import de from './translation.de.json';
import ms from './translation.ms.json';
import ru from './translation.ru.json';
import vi from './translation.vi.json';
import th from './translation.th.json';

const resources = {
  ko: { translation: ko },
  en: { translation: en },
  ja: { translation: ja },
  cn: { translation: cn },
  tw: { translation: tw },
  id: { translation: id },
  fr: { translation: fr },
  it: { translation: it },
  es: { translation: es },
  de: { translation: de },
  th: { translation: th },
  ms: { translation: ms },
  vi: { translation: vi },
  ru: { translation: ru },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  fallbackLng: 'en',
  debug: process.env.NODE_ENV === 'development',
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
  react: {
    // wait: true,
    useSuspense: true,
  },
});

export default i18n;
