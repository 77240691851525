import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { memberOrgMultyState } from '../../stores/memberStore';

// components
import Modal from '../modal/modal';
import OrgItem from './orgItem';

// style
import styles from '../modal/selector.module.scss';

function InspectionOrg() {
  const { t } = useTranslation();
  const [memberOrg, setMemberOrg] = useRecoilState(memberOrgMultyState);

  return (
    <AnimatePresence>
      {memberOrg.state && (
        <Modal close={() => setMemberOrg(prev => ({ ...prev, state: false }))}>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            className={styles.modal}
          >
            <div className={styles.header} style={{ marginBottom: '8px' }}>
              <div className={styles.title}>{t('조직을 선택하십시오.')}</div>
              <button
                type="button"
                className="primary"
                onClick={() => setMemberOrg(prev => ({ ...prev, state: false }))}
              >
                {t('확인')}
              </button>
            </div>
            <OrgItem item={memberOrg.data} />
          </motion.div>
        </Modal>
      )}
    </AnimatePresence>
  );
}

export default InspectionOrg;
