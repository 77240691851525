import { motion, AnimatePresence } from 'framer-motion';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { utils, read } from 'xlsx';
import { useMutation } from '@tanstack/react-query';
import { createOrgList } from '../../api/member';
import { createError, throwError } from '../../api/error';
import { useAlert } from '../../stores/alertStore';
import { useConfirm } from '../../stores/confirmStore';
import { loadingState } from '../../stores/loadingStore';
import { orgUploadModal } from '../../stores/memberStore';

// components
import Modal from '../modal/modal';

// img
import { icUploadFile } from '../aside/icon';

// style
import styles from '../modal/dialog.module.scss';

function ExcelOrgUpload({ refresh }: { refresh: () => void }) {
  const { t } = useTranslation();
  const [modal, setModal] = useRecoilState(orgUploadModal);
  const { alertShow } = useAlert();
  const { confirmShow } = useConfirm();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [org, setOrg] = useState<any>(undefined);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function insertIntoTree(tree: any, targetKey: string, newObject: any) {
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const key in tree) {
      if (key === targetKey) {
        // eslint-disable-next-line no-param-reassign
        tree[key] = { ...tree[key], ...newObject };
        return true;
      }
      if (typeof tree[key] === 'object' && insertIntoTree(tree[key], targetKey, newObject)) {
        return true;
      }
    }
    return false;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function arrayToTree(array: any[][]) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let tree: any = {};
    let currentCount = 0;
    let lastIndex = 0;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const historyArray: any[] = [];
    array.forEach((subArray, idx) => {
      let count = 0;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const obj: any = {};
      for (let i = 0; i < subArray.length; i += 1) {
        if (subArray[i] === null) count += 1;
        else obj[subArray[i]] = {};
      }
      if (count === 0) {
        tree = obj;
        lastIndex = idx;
      } else if (count === 1) {
        tree[Object.keys(tree)[0]] = { ...tree[Object.keys(tree)[0]], ...obj };
        currentCount = count;
        lastIndex = idx;
      } else {
        // eslint-disable-next-line no-lonely-if
        if (currentCount === count) {
          insertIntoTree(
            tree,
            array[lastIndex - 1].find(item => item),
            obj,
          );
        } else if (currentCount < count) {
          insertIntoTree(
            tree,
            array[lastIndex !== idx - 1 ? idx - 1 : lastIndex].find(item => item),
            obj,
          );
          lastIndex = idx;
        } else if (currentCount > count) {
          let minDistance = Number.MAX_SAFE_INTEGER;
          let nearestIndex = -1;
          for (let i = 0; i < historyArray.length; i += 1) {
            if (historyArray[i] === count && Math.abs(i - 9) < minDistance) {
              minDistance = Math.abs(i - 9);
              nearestIndex = i;
            }
          }
          insertIntoTree(
            tree,
            array[nearestIndex - 1].find(item => item),
            obj,
          );
          lastIndex = idx;
        }
      }
      currentCount = count;
      historyArray.push(count);
    });
    return tree;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const readUploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = e => {
        const data = e?.target?.result;
        const workbook = read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const array = utils.sheet_to_json(sheet, { header: 1 }) as any[][];
        if (new Set(array.map(item => item.find(el => el))).size !== array.length) {
          alertShow('중복된 값이 포함되어 있습니다.');
          if (uploadRef.current) uploadRef.current.value = '';
          return;
        }
        array.forEach(item => {
          if (item.filter(el => el).length > 1) {
            alertShow('잘못된 형식입니다.');
            if (uploadRef.current) uploadRef.current.value = '';
          }
        });
        const arrayLength = array.map(item => item.length);
        if (arrayLength.filter(el => el === 1).length !== 1) {
          alertShow('잘못된 형식입니다.');
          if (uploadRef.current) uploadRef.current.value = '';
          return;
        }
        for (let i = 0; i < arrayLength.length; i += 1) {
          if (arrayLength[i] - arrayLength[i - 1] > 1) {
            alertShow('잘못된 형식입니다.');
            if (uploadRef.current) uploadRef.current.value = '';
            return;
          }
        }
        const result = array.map(item => {
          const value = [];
          for (let i = 0; i < item.length - 1; i += 1) value.push(null);
          value.push(item[item.length - 1]);
          return value;
        });
        const tree = arrayToTree(result);
        setOrg(tree);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };

  const { mutate: create } = useMutation(createOrgList, {
    onSuccess: () => {
      setModal(false);
      if (uploadRef.current) uploadRef.current.value = '';
      alertShow('등록되었습니다.');
      window.postMessage({ type: 'logout' });
      refresh();
      setLoading(false);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (e: any) => {
      createError({
        type: '[ADMIN] member - createOrgList',
        message: `message: "${e.message}",\nresponse: { status: ${e.response.status}, message: "${e.response.data.message}" }`,
      });
      throwError(e);
      setLoading(false);
    },
  });

  const uploadRef = useRef<HTMLInputElement>(null);

  const setLoading = useSetRecoilState(loadingState);

  return (
    <AnimatePresence>
      {modal && (
        <Modal
          close={() => {
            if (uploadRef.current) uploadRef.current.value = '';
            setModal(false);
          }}
        >
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            className={styles.modal}
          >
            <div className={styles.circle}>{icUploadFile()}</div>
            {/* eslint-disable-next-line react/no-danger */}
            <div className={styles.message}>{t('엑셀 파일을 업로드하여 조직을 등록하십시오.')}</div>
            <div className={styles.input}>
              <input type="file" onChange={readUploadFile} ref={uploadRef} />
            </div>
            <div className={styles.warning}>
              ※{' '}
              {t(
                '최초 등록이 아닌 서비스 이용 중 조직 개편 시 낱개 등록을 권장합니다.엑셀로 조직 등록 시 기존 조직이 모두 삭제되고 새 조직이 생성됩니다. 이 경우 기존 회원은 최상위 조직으로 이동하며, 기존에 등록된 콘텐츠는 조직 이름이 같더라도 이전 조직 아이디를 참조하므로 개별 콘텐츠 수정이 필요합니다. 또한 엑셀 등록 시 조직의 고유 코드가 수정되기 때문에 다시 로그인을 해야합니다.',
              )}
            </div>
            <div className={styles.btns}>
              <div
                className={styles.btn}
                onClick={() => {
                  if (org !== undefined)
                    confirmShow(
                      '엑셀 등록 시 조직의 고유 코드가 수정되기 때문에 다시 로그인을 해야합니다.\n진행하시겠습니까?',
                      () => {
                        setLoading(true);
                        create(org);
                      },
                    );
                  else alertShow('파일을 등록하십시오.');
                }}
              >
                {t('등록')}
              </div>
              <div
                className={styles.btn}
                onClick={() => {
                  if (uploadRef.current) uploadRef.current.value = '';
                  setModal(false);
                }}
              >
                {t('취소')}
              </div>
            </div>
          </motion.div>
        </Modal>
      )}
    </AnimatePresence>
  );
}

export default ExcelOrgUpload;
