import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { useMutation, useQuery } from '@tanstack/react-query';
import { createError, throwError } from '../../api/error';
import { deleteSalestipCategoryGroup, getSalestipCategoryGroupList, getSalestipCategoryList } from '../../api/salestip';
import { useAlert } from '../../stores/alertStore';
import { useConfirm } from '../../stores/confirmStore';
import { categoryState } from '../../stores/salestipStore';

// components
import Page from '../../includes/page';
import SalestipCategoryEdit from '../../components/salestip/categoryEdit';

function SalestipCategory() {
  const { t } = useTranslation();
  const { alertShow } = useAlert();
  const { confirmShow } = useConfirm();
  const [data, setData] = useState<ISCG[]>([]);
  const { isFetching: getCategoryGroupLoading, refetch: getCategoryGroup } = useQuery<{ data: ISCG[] }>(
    ['categoryGroup'],
    getSalestipCategoryGroupList,
    {
      refetchOnWindowFocus: false,
      onSuccess: e => {
        setData(e.data.map(item => ({ ...item, checked: false })));
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (e: any) => alertShow(e.response.data.message),
    },
  );
  const { mutate: remove, isLoading: deleteLoading } = useMutation(deleteSalestipCategoryGroup, {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (e: any) => {
      createError({
        type: '[ADMIN] salestip - deleteSalestipCategoryGroup',
        message: `message: "${e.message}",\nresponse: { status: ${e.response.status}, message: "${e.response.data.message}" }`,
      });
      throwError(e);
    },
  });

  const selectDelete = () =>
    confirmShow('선택한 항목을 삭제하시겠습니까?', () => {
      const checked = data.filter(item => item.checked);
      for (let i = 0; i < checked.length; i += 1) remove(checked[i].key);
    });

  useEffect(() => {
    if (!deleteLoading) getCategoryGroup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteLoading]);

  const allSelect = () =>
    setData(
      data.map(item => ({
        ...item,
        checked: data.filter(item => item.checked).length !== data.length,
      })),
    );

  const [category, setCategory] = useRecoilState(categoryState);

  const { refetch: getCategory } = useQuery<{ data: ISC[] }>(
    ['category'],
    () => getSalestipCategoryList([category.key]),
    {
      refetchOnWindowFocus: false,
      // eslint-disable-next-line no-restricted-globals
      enabled: !isNaN(category.key) && category.key !== 0 && category.state,
      onSuccess: e => {
        setCategory(prev => ({ ...prev, category: e.data.map(el => ({ ...el, selected: false })) }));
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (e: any) => alertShow(e.response.data.message),
    },
  );

  return (
    <Page title="세일즈팁카테고리" isLoading={deleteLoading}>
      <div className="table-tools">
        <div className="btns">
          <button type="button" className="danger" onClick={allSelect}>
            <span>{t('전체선택')}</span>
          </button>
          <button
            type="button"
            className="primary"
            onClick={() => setCategory({ state: true, key: NaN, name: '', category: [] })}
          >
            <span>{t('등록')}</span>
          </button>
          <button
            type="button"
            className={classNames('secondary', data.filter(item => item.checked).length === 0 && 'disabled')}
            onClick={selectDelete}
          >
            <span>{t('선택삭제')}</span>
          </button>
        </div>
        {data.filter(item => item.checked).length > 0 && (
          <div className="selected">
            {t('개의 항목을 선택했습니다.', { amount: data.filter(item => item.checked).length })}
          </div>
        )}
      </div>
      <div className="table-container">
        <table className="table">
          <thead>
            <tr>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <th></th>
              <th>{t('이름')}</th>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {getCategoryGroupLoading && (
              <tr>
                <td colSpan={9}>
                  <div>
                    <div className="lds-spinner">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </td>
              </tr>
            )}
            {!getCategoryGroupLoading && data.length === 0 && (
              <tr>
                <td colSpan={3}>
                  <div>{t('검색된 목록이 없습니다.')}</div>
                </td>
              </tr>
            )}
            {data.map(item => (
              <tr key={item.key}>
                <td>
                  <div>
                    <div
                      className={classNames(
                        'checkbox',
                        data.filter(ele => ele.key === item?.key)[0]?.checked && 'active',
                      )}
                      onClick={() => {
                        setData(
                          data.map(ele => {
                            if (ele.key === item.key) return { ...ele, checked: !ele.checked };
                            return ele;
                          }),
                        );
                      }}
                    ></div>
                  </div>
                </td>
                <td>
                  <div>
                    <span>{item.name}</span>
                  </div>
                </td>
                <td>
                  <div>
                    <button
                      type="button"
                      className="primary"
                      onClick={() => {
                        setCategory(prev => ({ ...prev, state: true, key: item.key, name: item.name }));
                      }}
                    >
                      <span>{t('수정')}</span>
                    </button>
                    <button
                      type="button"
                      className="secondary"
                      onClick={() => confirmShow('삭제하시겠습니까?', () => remove(item.key))}
                    >
                      <span>{t('삭제')}</span>
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <SalestipCategoryEdit getCategoryGroup={getCategoryGroup} getCategory={getCategory} />
    </Page>
  );
}

export default SalestipCategory;
