import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useAlert } from '../../stores/alertStore';
import { useConfirm } from '../../stores/confirmStore';
import { useInspection } from '../../stores/inspectionStore';

// style
import styles from './item.module.scss';

// img
import { icArrowUp, icArrowDown, icDelete, icArrowCircleLeft, icArrowCircleRight } from '../aside/icon';

function InspectionItem({ item, edit }: { item: IInspectionProb; edit: boolean }) {
  const { t } = useTranslation();
  const { alertShow } = useAlert();
  const { confirmShow } = useConfirm();
  const {
    setInspectionTitle,
    setInspectionDescription,
    setInspectionNp,
    setInspectionFile,
    setInspectionFileDelete,
    setInspectionFileMove,
    setInspectionFileInit,
    setInspectionRemove,
    setInspectionDelete,
    setInspectionMove,
  } = useInspection();

  const changeImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event?.target?.files) {
      setInspectionFileInit(item.order);
      return;
    }
    const getExtension = (fileName: string) => {
      const fileLength = fileName.length;
      const lastDot = fileName.lastIndexOf('.');
      const fileExtension = `.${fileName.substring(lastDot + 1, fileLength)}`;
      return fileExtension.toLowerCase();
    };
    if (
      getExtension(event.target.files[0].name) !== '.png' &&
      getExtension(event.target.files[0].name) !== '.jpg' &&
      getExtension(event.target.files[0].name) !== '.jpeg'
    ) {
      alertShow('이미지는 jpg, png 형식의 파일만 등록할 수 있습니다.');
      return;
    }
    const ele = event.target.files[0];
    setInspectionFile(item.order, ele, URL.createObjectURL(ele), ele.name);
    try {
      const reader: FileReader = new FileReader();
      reader.onload = (event: ProgressEvent<FileReader>) => {
        console.log(event);
      };
      reader.onerror = error => {
        console.log(error);
      };
      reader.readAsDataURL(event.target.files[0]);
      // eslint-disable-next-line no-param-reassign
      event.target.value = '';
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.question}>
        <div className={styles.num}>
          <span>{item.order.toString().length < 2 ? `0${item.order}` : item.order}</span>
        </div>
        <div className={styles.text}>
          <input
            type="text"
            placeholder={t('문항 제목') || ''}
            value={item.title}
            onChange={e => setInspectionTitle(item.order, e.target.value)}
          />
        </div>
      </div>
      <div className={styles.description}>
        <textarea
          placeholder={t('문항 설명') || ''}
          value={item.description}
          onChange={e => setInspectionDescription(item.order, e.target.value)}
        ></textarea>
      </div>
      <div className={styles.imgUpload}>
        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
        {item.imgFile?.img?.map((el: any, idx: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className={styles.item} key={idx}>
            <img src={el} alt="logo" style={{ display: el ? '' : 'none' }} />
            <div className={styles.tool}>
              <div className={styles.btns}>
                <object onClick={() => setInspectionFileMove(item.order, idx, 'prev')}>{icArrowCircleLeft()}</object>
                <object onClick={() => setInspectionFileMove(item.order, idx, 'next')}>{icArrowCircleRight()}</object>
                <object
                  onClick={() =>
                    confirmShow('이미지를 제거하시겠습니까?', () => setInspectionFileDelete(item.order, idx))
                  }
                >
                  {icDelete()}
                </object>
              </div>
            </div>
          </div>
        ))}
        {item.imgFile?.img?.length < 5 && (
          <div className={styles.item}>
            <label htmlFor={`imgUpload${item.order}`}>
              <input type="file" id={`imgUpload${item.order}`} onChange={e => changeImage(e)} accept="image/*" />
            </label>
            <div className={styles.add}></div>
          </div>
        )}
      </div>
      <div className={styles.tools}>
        <div className={styles.toggle}>
          <div
            className={classNames(styles.item, !item.needPicture && styles.active)}
            onClick={() => setInspectionNp(item.order, false)}
          >
            {t('기본')}
          </div>
          <div
            className={classNames(styles.item, item.needPicture && styles.active)}
            onClick={() => setInspectionNp(item.order, true)}
          >
            {t('첨부파일')}
          </div>
        </div>
        <object onClick={() => setInspectionMove(item.order, 'up')}>{icArrowUp()}</object>
        <object onClick={() => setInspectionMove(item.order, 'down')}>{icArrowDown()}</object>
        <object
          onClick={() =>
            confirmShow('삭제하시겠습니까?', () =>
              edit ? setInspectionDelete(item.order) : setInspectionRemove(item.order),
            )
          }
        >
          {icDelete()}
        </object>
      </div>
    </div>
  );
}

export default InspectionItem;
