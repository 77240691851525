import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { codeValidation } from '../../api/customer';
import { useAlert } from '../../stores/alertStore';
import { usePrompt } from '../../stores/promptStore';

// style
import styles from './pageNotFound.module.scss';

// img
import img404 from '../../assets/img/images/img_404.svg';

function CodeNotFound() {
  const { t } = useTranslation();
  const [cookies] = useCookies(['xclass-theme']);
  const navigate = useNavigate();
  const { promptShow } = usePrompt();
  const { alertShow } = useAlert();
  const [code, setCode] = useState('');
  useQuery<{ data?: CodeValidation }>(['codeValidation', code], () => codeValidation(code), {
    refetchOnWindowFocus: false,
    enabled: code !== '',
    onSuccess: e => {
      if (e.data) {
        navigate(`${code}`);
      } else {
        alertShow('기업코드가 존재하지 않습니다.');
      }
      setCode('');
    },
  });

  useEffect(() => {
    const htmlTitle = document.querySelector('title') as HTMLElement;
    htmlTitle.innerHTML = 'XClass - Admin';
    document.querySelector('#favicon')?.setAttribute('href', `${process.env.PUBLIC_URL}/favicon.ico`);
  }, []);

  return (
    <div className={styles.container} data-theme={cookies['xclass-theme']}>
      <div className={styles.body}>
        <div className={styles.code}>
          <img src={img404} alt="img" />
        </div>
        <div className={styles.message}>
          <div className={styles.title}>{t('요청하신 페이지를 찾을 수 없습니다.')}</div>
          {t(
            '찾으려는 페이지의 주소가 잘못 입력 되었거나, 주소의 변경 혹은 삭제로 인해 이용하실 수 없습니다. 올바른 URL 주소를 입력하셨는지 확인해 주시기 바랍니다.',
          )}
        </div>
        <div className={styles.btn} onClick={() => promptShow('기업코드를 입력하십시오.', e => setCode(`/${e}`))}>
          <span>{t('기업코드 입력')}</span>
        </div>
      </div>
    </div>
  );
}

export default CodeNotFound;
