import { create } from 'zustand';

export const evalStore = create<{
  type: string;
  setType: (type: string) => void;
  path: string;
  setPath: (path: string) => void;
  uploadList: { type: string; key: number; state: boolean }[];
  setUploadList: (uploadList: { type: string; key: number; state: boolean }[]) => void;
  editUploadList: (key: number, params: { type?: string; key?: number; state?: boolean }) => void;
  answerList: {
    eqa_key: number;
    eq_key?: number;
    eqa_text: string;
    eqa_image_path: string;
    eqa_correct: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    img?: any;
  }[];
  setAnswerList: (
    answerList: {
      eqa_key: number;
      eq_key?: number;
      eqa_text: string;
      eqa_image_path: string;
      eqa_correct: string;
    }[],
  ) => void;
  addAnswerList: (answer: {
    eqa_key: number;
    eq_key?: number;
    eqa_text: string;
    eqa_image_path: string;
    eqa_correct: string;
  }) => void;
  editAnswerList: (
    key: number,
    answer: {
      eqa_key?: number;
      eq_key?: number;
      eqa_text?: string;
      eqa_image_path?: string;
      eqa_correct?: string;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      img?: any;
    },
  ) => void;
  removeAnswerList: (key: number) => void;
  setCorrectAnswerOX: (key: number) => void;
  setCorrectAnswer: (key: number) => void;
  setSaText: (params: { key: number; idx: number; text?: string; add?: boolean; remove?: boolean }) => void;
}>((set, get) => ({
  type: 'MC',
  setType: (type: string) => set(() => ({ type })),
  path: '',
  setPath: (path: string) => set(() => ({ path })),
  answerList: [
    { eqa_key: 0, eqa_text: '', eqa_image_path: '', eqa_correct: 'N' },
    { eqa_key: 1, eqa_text: '', eqa_image_path: '', eqa_correct: 'N' },
  ],
  uploadList: [],
  setUploadList: (uploadList: { type: string; key: number; state: boolean }[]) => set(() => ({ uploadList })),
  editUploadList: (key: number, params: { type?: string; key?: number; state?: boolean }) =>
    set(() => ({ uploadList: get().uploadList.map(item => (item.key === key ? { ...item, ...params } : item)) })),
  setAnswerList: (
    answerList: {
      eqa_key: number;
      eq_key?: number;
      eqa_text: string;
      eqa_image_path: string;
      eqa_correct: string;
    }[],
  ) => set(() => ({ answerList })),
  addAnswerList: (answer: {
    eqa_key: number;
    eq_key?: number;
    eqa_text: string;
    eqa_image_path: string;
    eqa_correct: string;
  }) => set(() => ({ answerList: get().answerList.concat(answer) })),
  editAnswerList: (
    key: number,
    answer: {
      eqa_key?: number;
      eq_key?: number;
      eqa_text?: string;
      eqa_image_path?: string;
      eqa_correct?: string;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      img?: any;
    },
  ) =>
    set(() => ({ answerList: get().answerList.map(item => (item.eqa_key === key ? { ...item, ...answer } : item)) })),
  removeAnswerList: (key: number) => set(() => ({ answerList: get().answerList.filter(item => item.eqa_key !== key) })),
  setCorrectAnswerOX: (key: number) =>
    set(() => ({
      answerList: get().answerList.map(item =>
        item.eqa_key === key ? { ...item, eqa_correct: 'Y' } : { ...item, eqa_correct: 'N' },
      ),
    })),
  setCorrectAnswer: (key: number) =>
    set(() => ({
      answerList: get().answerList.map(item =>
        item.eqa_key === key ? { ...item, eqa_correct: item.eqa_correct === 'Y' ? 'N' : 'Y' } : item,
      ),
    })),
  setSaText: (params: { key: number; idx: number; text?: string; add?: boolean; remove?: boolean }) => {
    const { key, idx, text, add, remove } = params;
    const eqaText = get().answerList[0].eqa_text;
    if (text !== undefined && eqaText !== undefined) {
      const result = eqaText.split('|');
      result[idx] = text;
      set(() => ({
        answerList: get().answerList.map(item =>
          item.eqa_key === key ? { ...item, eqa_text: result?.join('|') || '' } : item,
        ),
      }));
    }
    if (add)
      set(() => ({
        answerList: get().answerList.map(item =>
          item.eqa_key === key ? { ...item, eqa_text: `${item.eqa_text}|` } : item,
        ),
      }));
    if (remove && eqaText !== undefined) {
      const result = eqaText.split('|');
      result?.splice(idx, 1);
      set(() => ({
        answerList: get().answerList.map(item =>
          item.eqa_key === key ? { ...item, eqa_text: result?.join('|') || '' } : item,
        ),
      }));
    }
  },
}));

export const evalPreviewStore = create<{
  previewKey: null | number;
  setPreviewKey: (previewKey: number | null) => void;
}>(set => ({
  previewKey: null,
  setPreviewKey: (previewKey: number | null) => set(() => ({ previewKey })),
}));
