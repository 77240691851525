import axios from 'axios';
import classNames from 'classnames';
import ko from 'date-fns/locale/ko';
import queryString from 'query-string';
import DatePicker from 'react-datepicker';
import { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { useMutation, useQuery } from '@tanstack/react-query';
import { createError, throwError } from '../../api/error';
import { getOrgList } from '../../api/member';
import { createInspection, editInspection, getInspection } from '../../api/inspection';
import { useAlert } from '../../stores/alertStore';
import { useConfirm } from '../../stores/confirmStore';
import { memberOrgMultyState } from '../../stores/memberStore';
import { inspectionState, useInspection } from '../../stores/inspectionStore';
import { flatOrg } from '../../assets/util/flatOrg';
import { editDateFormat } from '../../assets/util/dateFormat';

// components
import Page from '../../includes/page';
import FormItem from '../../components/basic/formItem';
import InspectionOrg from '../../components/inspection/org';
import InspectionItem from '../../components/inspection/item';
import InspectionAddItem from '../../components/inspection/addItem';
import ImgUploadLoading from '../../components/inspection/imgUploadLoading';

const api = process.env.REACT_APP_API;

function InspectionEdit() {
  const { t } = useTranslation();
  const [cookies] = useCookies(['xclass-lang', 'xclass-cname']);
  const { code } = useParams();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { is_key } = queryString.parse(search);

  const { alertShow } = useAlert();
  const { confirmShow } = useConfirm();
  // 제목
  const [title, setTitle] = useState('');
  // 설명
  const [description, setDescription] = useState('');
  // 조직
  const [orgAleady, setOrgAleady] = useState(false);
  const [memberOrg, setMemberOrg] = useRecoilState(memberOrgMultyState);
  const { isFetching: orgLoading } = useQuery<{ data: IOrg }>(['org'], getOrgList, {
    refetchOnWindowFocus: false,
    onSuccess: e => {
      setMemberOrg(prev => ({ ...prev, data: e.data }));
      setOrgAleady(true);
    },
  });
  // 기간
  const [sdate, setSdate] = useState<Date | null>(new Date());
  const [edate, setEdate] = useState<Date | null>(new Date());
  useEffect(() => {
    if (Number(edate) < Number(sdate)) setEdate(sdate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sdate, edate]);
  // 공개여부
  const [isPublic, setIsPublic] = useState(true);
  // 문제
  const [inspection, setInspection] = useRecoilState(inspectionState);
  const { setInspectionImgAdd } = useInspection();

  const createUIO = (
    urls: {
      ispbpc_key: number;
      ispb_key: number;
      ispbpc_url: string;
      ispbpc_order: number;
      created_at: string;
    }[],
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const obj: any = {};
    // eslint-disable-next-line no-return-assign
    urls?.forEach(item => (obj[item.ispbpc_key] = item.ispbpc_order));
    return obj;
  };

  const { isFetching: getInspectionLoading } = useQuery<{ data: IInspectionDetail }>(
    ['inspection'],
    () => getInspection(Number(is_key)),
    {
      refetchOnWindowFocus: false,
      enabled: orgAleady && is_key !== undefined,
      onSuccess: e => {
        setTitle(e.data.is_title);
        setDescription(e.data.is_description);
        setSdate(new Date(e.data.is_start_date));
        setEdate(new Date(e.data.is_end_date));
        setIsPublic(e.data.is_public);
        setInspection(
          e.data.inspectionProbs?.map(el => ({
            key: el.ispb_key,
            title: el.ispb_title,
            description: el.ispb_description,
            needPicture: el.ispb_need_picture,
            order: el.ispb_order,
            imageMaps: [],
            imgFile: {
              file:
                el.urls
                  .sort((a, b) => a.ispbpc_order - b.ispbpc_order)
                  ?.map(e => `uploaded_${e.ispbpc_key}_${e.ispbpc_url}`) || [],
              img:
                el.urls
                  .sort((a, b) => a.ispbpc_order - b.ispbpc_order)
                  ?.map(e => `${process.env.REACT_APP_UPLOADED_URL}/${e.ispbpc_url}`) || [],
              imgName:
                el.urls
                  .sort((a, b) => a.ispbpc_order - b.ispbpc_order)
                  ?.map(e => `uploaded_${e.ispbpc_key}_${e.ispbpc_url}`) || [],
            },
            updateImageOrders: el.urls ? createUIO(el.urls.sort((a, b) => a.ispbpc_order - b.ispbpc_order)) : undefined,
            delete: false,
          })) || [],
        );
        setMemberOrg(prev => ({ ...prev, selected: e.data.organizations.map(el => el.organization) }));
      },
    },
  );

  const { mutate: create, isLoading: createLoading } = useMutation(createInspection, {
    onSuccess: () => navigate(`/${code}/inspection`),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (e: any) => {
      createError({
        type: '[ADMIN] evaluation - createInspection',
        message: `message: "${e.message}",\nresponse: { status: ${e.response.status}, message: "${e.response.data.message}" }`,
      });
      throwError(e);
    },
  });

  const { mutate: update, isLoading: updateLoading } = useMutation(editInspection, {
    onSuccess: () => navigate(`/${code}/inspection`),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (e: any) => {
      createError({
        type: '[ADMIN] evaluation - editInspection',
        message: `message: "${e.message}",\nresponse: { status: ${e.response.status}, message: "${e.response.data.message}" }`,
      });
      throwError(e);
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formData = (_fields: any, _file: any) => {
    const data = new FormData();
    Object.keys(_fields).forEach(key => data.append(key, _fields[key]));
    if (_file) {
      data.append('file', _file);
    }
    return data;
  };

  const [imgCount, setImgCount] = useState<IImgUpload[]>([]);
  const [uploadLoading, setUploadLoading] = useState(false);

  useEffect(() => {
    if (imgCount.length > 0 && imgCount.filter(item => item.complete === item.total).length === imgCount.length) {
      setUploadLoading(false);
      if (is_key) {
        update({
          key: Number(is_key),
          data: {
            title,
            description,
            sdate: editDateFormat(sdate || new Date(), 'start'),
            edate: editDateFormat(edate || new Date(), 'end'),
            groups: memberOrg.selected,
            isPublic,
            probList: inspection
              .filter(el => !el.new)
              .map(item => ({
                title: item.title,
                description: item.description,
                needPicture: item.needPicture,
                order: item.order,
                imageMaps: item.imageMaps,
                key: item.key,
                updateImageOrders: item.updateImageOrders,
                delete: item.delete,
              })),
            newProbList: inspection
              .filter(el => el.new)
              .map(item => ({
                title: item.title,
                description: item.description,
                needPicture: item.needPicture,
                order: item.order,
                imageMaps: item.imageMaps,
                key: item.key,
                updateImageOrders: item.updateImageOrders,
                delete: item.delete,
              })),
          },
        });
      } else {
        create({
          title,
          description,
          sdate: editDateFormat(sdate || new Date(), 'start'),
          edate: editDateFormat(edate || new Date(), 'end'),
          groups: memberOrg.selected,
          isPublic,
          probList: inspection.map(item => ({
            title: item.title,
            description: item.description,
            needPicture: item.needPicture,
            order: item.order,
            imageMaps: item.imageMaps,
          })),
        });
      }
    } else if (imgCount.length > 0) {
      setUploadLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imgCount]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fileUpload = async (url: string, form: any, order: number) => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data = (await axios.post(url, form)) as any;
      if (data.ok || data.status === 204) {
        console.log(data);
        setImgCount(prev => prev.map(item => (item.id === order ? { ...item, complete: item.complete + 1 } : item)));
      } else {
        console.log('error');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const createInspectionPicture = async (order: number, count: number) => {
    try {
      const { data } = await axios.post(
        `${api}/inspection/create/upload_picture`,
        { count },
        { withCredentials: true },
      );

      setImgCount(prev => prev.concat({ id: order, total: data.data.length, complete: 0 }));

      const realIndex =
        inspection
          .find(el => el.order === order)
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ?.imgFile.file.map((el: any, idx: number) => (typeof el === 'string' ? NaN : idx))
          // eslint-disable-next-line no-restricted-globals
          .filter((e: number) => !isNaN(e)) || false;
      await data.data.forEach((item: IUploadItem, idx: number) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const obj: any = {};
        if (!realIndex) {
          obj[idx + 1] = item.name;
        } else {
          obj[realIndex[idx] + 1] = item.name;
        }
        setInspectionImgAdd(order, obj);
      });

      await data.data.forEach((item: IUploadItem, idx: number) =>
        fileUpload(
          item.url.url,
          formData(
            item.url.fields,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            inspection.find(el => el.order === order)?.imgFile?.file.filter((e: any) => typeof e !== 'string')[idx],
          ),
          order,
        ),
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(e);
    }
  };

  const upload = () => {
    if (
      inspection.length === 0 ||
      inspection.length ===
        inspection.filter(
          item =>
            item.imgFile.file.length === 0 ||
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            item.imgFile.file.filter((e: any) => String(e).includes('uploaded_')).length > 0,
        ).length
    ) {
      if (is_key) {
        update({
          key: Number(is_key),
          data: {
            title,
            description,
            sdate: editDateFormat(sdate || new Date(), 'start'),
            edate: editDateFormat(edate || new Date(), 'end'),
            groups: memberOrg.selected,
            isPublic,
            probList: inspection
              .filter(el => !el.new)
              .map(item => ({
                title: item.title,
                description: item.description,
                needPicture: item.needPicture,
                order: item.order,
                imageMaps: item.imageMaps,
                key: item.key,
                updateImageOrders: item.updateImageOrders,
                delete: item.delete,
              })),
            newProbList: inspection
              .filter(el => el.new)
              .map(item => ({
                title: item.title,
                description: item.description,
                needPicture: item.needPicture,
                order: item.order,
                imageMaps: item.imageMaps,
                key: item.key,
                updateImageOrders: item.updateImageOrders,
                delete: item.delete,
              })),
          },
        });
      } else {
        create({
          title,
          description,
          sdate: editDateFormat(sdate || new Date(), 'start'),
          edate: editDateFormat(edate || new Date(), 'end'),
          groups: memberOrg.selected,
          isPublic,
          probList: inspection.map(item => ({
            title: item.title,
            description: item.description,
            needPicture: item.needPicture,
            order: item.order,
            imageMaps: item.imageMaps,
          })),
        });
      }
      return;
    }
    if (
      is_key &&
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      inspection.filter(item => item.imageMaps.length !== item.imgFile.file.length).length === 0
    ) {
      update({
        key: Number(is_key),
        data: {
          title,
          description,
          sdate: editDateFormat(sdate || new Date(), 'start'),
          edate: editDateFormat(edate || new Date(), 'end'),
          groups: memberOrg.selected,
          isPublic,
          probList: inspection
            .filter(el => !el.new)
            .map(item => ({
              title: item.title,
              description: item.description,
              needPicture: item.needPicture,
              order: item.order,
              imageMaps: item.imageMaps,
              key: item.key,
              updateImageOrders: item.updateImageOrders,
              delete: item.delete,
            })),
          newProbList: inspection
            .filter(el => el.new)
            .map(item => ({
              title: item.title,
              description: item.description,
              needPicture: item.needPicture,
              order: item.order,
              imageMaps: item.imageMaps,
              key: item.key,
              updateImageOrders: item.updateImageOrders,
              delete: item.delete,
            })),
        },
      });
      return;
    }
    inspection.forEach(item => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (item.imgFile.file.filter((e: any) => String(e).includes('uploaded_')).length > 0) return;
      if (item.imgFile.file.length === 0) return;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      createInspectionPicture(item.order, item.imgFile?.file.filter((e: any) => typeof e !== 'string').length);
    });
  };

  // 초기화
  const init = () => {
    setTitle('');
    setDescription('');
    setSdate(new Date());
    setEdate(new Date());
    setIsPublic(true);
    setInspection([]);
    setMemberOrg({
      state: false,
      selected: [],
      data: { mo_key: 0 },
    });
  };
  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <Page
      title={is_key ? '체크리스트수정' : '체크리스트등록'}
      isLoading={getInspectionLoading || orgLoading || createLoading || updateLoading}
    >
      <FormItem title="제목" essential>
        <div className="input">
          <input type="text" placeholder={t('제목') || ''} value={title} onChange={e => setTitle(e.target.value)} />
        </div>
      </FormItem>
      <FormItem title="설명" essential>
        <div className="input">
          <textarea
            placeholder={t('설명') || ''}
            value={description}
            onChange={e => setDescription(e.target.value)}
          ></textarea>
        </div>
      </FormItem>
      <FormItem title="조직" essential>
        <div className="input">
          <input
            type="text"
            placeholder={t('조직') || ''}
            value={
              flatOrg(memberOrg.data)
                .concat({ mo_key: memberOrg.data.mo_key })
                .filter(ele => memberOrg.selected.includes(ele.mo_key)).length > 0
                ? flatOrg(memberOrg.data)
                    .concat({ mo_key: memberOrg.data.mo_key })
                    .filter(ele => memberOrg.selected.includes(ele.mo_key))
                    .map(el => el.mo_name)
                    .join(', ').length === 0
                  ? cookies['xclass-cname'] || ''
                  : flatOrg(memberOrg.data)
                      .concat({ mo_key: memberOrg.data.mo_key })
                      .filter(ele => memberOrg.selected.includes(ele.mo_key))
                      .map(el => el.mo_name)
                      .join(', ')
                : ''
            }
            readOnly
            className="combo"
            onClick={() => setMemberOrg(prev => ({ ...prev, state: true }))}
          />
          <div className="arrow"></div>
        </div>
      </FormItem>
      <FormItem title="기간" essential>
        <div className="input" style={{ maxWidth: '608px' }}>
          <DatePicker
            selected={sdate}
            onChange={date => setSdate(date)}
            minDate={null}
            maxDate={edate}
            selectsStart
            startDate={sdate}
            endDate={edate}
            locale={cookies['xclass-lang'] === 'ko' ? ko : undefined}
            dateFormat="yyyy-MM-dd"
            disabledKeyboardNavigation
            onChangeRaw={(e: React.FormEvent<HTMLInputElement>) => e.preventDefault()}
          />
          <div style={{ margin: '0 8px', color: 'var(--greyish1)' }}>-</div>
          <DatePicker
            selected={edate}
            onChange={date => setEdate(date)}
            maxDate={null}
            selectsEnd
            startDate={sdate}
            endDate={edate}
            locale={cookies['xclass-lang'] === 'ko' ? ko : undefined}
            dateFormat="yyyy-MM-dd"
            disabledKeyboardNavigation
            onChangeRaw={(e: React.FormEvent<HTMLInputElement>) => e.preventDefault()}
          />
        </div>
      </FormItem>
      <FormItem title="공개여부" essential>
        <div className="switch">
          <div className={classNames('item', isPublic && 'active')} onClick={() => setIsPublic(prev => !prev)}>
            {t('공개')}
          </div>
          <div className={classNames('item', !isPublic && 'active')} onClick={() => setIsPublic(prev => !prev)}>
            {t('비공개')}
          </div>
        </div>
      </FormItem>
      {inspection
        .filter(item => !item.delete)
        .map(item => (
          <InspectionItem key={item.order} item={item} edit={is_key !== undefined} />
        ))}
      <InspectionAddItem isEdit={is_key !== undefined} />
      {uploadLoading && (
        <ImgUploadLoading>
          {imgCount.map(item => (
            <div className="imgUploadLoadingItem" key={item.id}>
              <div className="gauge">
                <div className="inner" style={{ width: `calc(${(item.complete / item.total) * 100}%)` }}></div>
              </div>
              <div className="text">
                {item.complete} / {item.total}
              </div>
            </div>
          ))}
        </ImgUploadLoading>
      )}
      <div className="formSubmit">
        <button
          type="button"
          className="primary"
          onClick={() => {
            if (!title) {
              alertShow('제목을 입력하십시오.');
              return;
            }
            if (!description) {
              alertShow('설명을 입력하십시오.');
              return;
            }
            if (sdate && edate && sdate >= edate) {
              alertShow('기간을 확인하십시오.');
              return;
            }
            if (memberOrg.selected.length === 0) {
              alertShow('조직을 선택하십시오.');
              return;
            }
            if (inspection.length === 0) {
              alertShow('문항을 추가하십시오.');
              return;
            }
            if (inspection.filter(item => !item.title || !item.description).length > 0) {
              alertShow('문항의 빈칸을 입력하십시오.');
              return;
            }
            confirmShow(is_key ? t('수정하시겠습니까?') : t('등록하시겠습니까?'), upload);
          }}
        >
          {is_key ? t('수정') : t('등록')}
        </button>
      </div>
      <InspectionOrg />
    </Page>
  );
}

export default InspectionEdit;
