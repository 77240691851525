import { motion, AnimatePresence } from 'framer-motion';
import { useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { utils, read } from 'xlsx';
import { useMutation, useQuery } from '@tanstack/react-query';
import { createMemberList, getOrgList } from '../../api/member';
import { createError, throwError } from '../../api/error';
import { useAlert } from '../../stores/alertStore';
import { memberUploadModal } from '../../stores/memberStore';
import { flatOrg } from '../../assets/util/flatOrg';

// components
import Modal from '../modal/modal';
import ModalLoading from '../modal/modalLoading';

// img
import { icUploadFile } from '../aside/icon';

// style
import styles from '../modal/dialog.module.scss';

function ExcelMemberUpload({ refresh }: { refresh: () => void }) {
  const { t } = useTranslation();
  const [modal, setModal] = useRecoilState(memberUploadModal);
  const { code } = useParams();
  const [cookies] = useCookies(['xclass-cname']);
  const { alertShow } = useAlert();
  const [memberOrg, setMemberOrg] = useState<IOrg[]>([]);
  useQuery<{ data: IOrg }>(['getOrgList'], getOrgList, {
    refetchOnWindowFocus: false,
    onSuccess: e => {
      setMemberOrg(flatOrg(e.data).concat({ mo_key: e.data.mo_key, mo_name: cookies['xclass-cname'] || code }));
    },
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [users, setUsers] = useState<any[]>([]);
  const readUploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = e => {
        const data = e?.target?.result;
        const workbook = read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = utils.sheet_to_json(worksheet).map(item => (item ? { ...item, grade: 'MEMBER' } : undefined));
        setUsers(json);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };

  const { mutate: create, isLoading } = useMutation(createMemberList, {
    onSuccess: () => {
      setModal(false);
      if (uploadRef.current) uploadRef.current.value = '';
      alertShow('등록되었습니다.');
      refresh();
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (e: any) => {
      createError({
        type: '[ADMIN] member - createMemberList',
        message: `message: "${e.message}",\nresponse: { status: ${e.response.status}, message: "${e.response.data.message}" }`,
      });
      throwError(e);
    },
  });
  const upload = () => {
    const result = users.map(item => ({
      ...item,
      password: String(item.password),
      organization: memberOrg.find(el => el.mo_name === item.organization)?.mo_key || 0,
    }));
    if (result.filter(item => item.organization === 0).length > 0) alertShow('존재하지 않는 조직이 포함되었습니다.');
    else {
      create(result);
    }
  };

  const uploadRef = useRef<HTMLInputElement>(null);

  return (
    <AnimatePresence>
      {modal && (
        <Modal
          close={() => {
            if (uploadRef.current) uploadRef.current.value = '';
            setModal(false);
          }}
        >
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            className={styles.modal}
          >
            <div className={styles.circle}>{icUploadFile()}</div>
            {/* eslint-disable-next-line react/no-danger */}
            <div className={styles.message}>{t('엑셀 파일을 업로드하여 회원을 등록하십시오.')}</div>
            <div className={styles.input}>
              <input type="file" onChange={readUploadFile} ref={uploadRef} />
            </div>
            <div className={styles.btns}>
              <div
                className={styles.btn}
                onClick={() => {
                  if (users.length === 0) {
                    alertShow('파일을 등록하십시오.');
                    return;
                  }
                  upload();
                }}
              >
                {t('등록')}
              </div>
              <div
                className={styles.btn}
                onClick={() => {
                  if (uploadRef.current) uploadRef.current.value = '';
                  setModal(false);
                }}
              >
                {t('취소')}
              </div>
            </div>
            <ModalLoading isLoading={isLoading} />
          </motion.div>
        </Modal>
      )}
    </AnimatePresence>
  );
}

export default ExcelMemberUpload;
