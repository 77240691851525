import { useSurvey } from '../../stores/surveyStore';

// style
import styles from './item.module.scss';

// img
import { icAdd } from '../aside/icon';

function AddSurveyItem() {
  const { addSurveyProb } = useSurvey();
  return (
    <div className={styles.addItem} onClick={() => addSurveyProb('subjective', true, '', '')}>
      {icAdd()}
    </div>
  );
}

export default AddSurveyItem;
