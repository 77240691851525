import dayjs from 'dayjs';
import queryString from 'query-string';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getEnquiryList } from '../../api/customer';
import { useAlert } from '../../stores/alertStore';

// components
import Page from '../../includes/page';

// img
import Pagination from '../../includes/pagination copy';

function Enquiry() {
  const { t } = useTranslation();
  const { code } = useParams();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { page } = queryString.parse(search);
  const { alertShow } = useAlert();
  const [count, setCount] = useState(0);
  const [data, setData] = useState<IEnquiry[]>([]);
  const { isFetching: getEnquiryListLoading } = useQuery<{ count: number; data: IEnquiry[] }>(
    ['getEnquiryList', page],
    () => getEnquiryList(page === undefined ? 1 : Number(page)),
    {
      refetchOnWindowFocus: false,
      onSuccess: e => {
        setCount(e.count);
        setData(e.data.map(item => ({ ...item, checked: false })));
        if (e.count > 0 && e.data.length === 0 && page !== undefined && Number(page) > 1) navigate('');
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (e: any) => alertShow(e.response.data.message),
    },
  );

  return (
    <Page title="문의사항목록">
      <div className="table-tools"></div>
      <div className="table-container">
        <table className="table">
          <thead>
            <tr>
              <th>{t('고객사')}</th>
              <th>{t('이름')}</th>
              <th>{t('연락처')}</th>
              <th>{t('이메일')}</th>
              <th>{t('직원수')}</th>
              <th>{t('업종')}</th>
              <th>{t('날짜')}</th>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {getEnquiryListLoading && (
              <tr>
                <td colSpan={9}>
                  <div>
                    <div className="lds-spinner">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </td>
              </tr>
            )}
            {!getEnquiryListLoading && data.length === 0 && (
              <tr>
                <td colSpan={8}>
                  <div>{t('검색된 목록이 없습니다.')}</div>
                </td>
              </tr>
            )}
            {data.map(item => (
              <tr key={item.e_key}>
                <td>
                  <div>
                    <span className="link" onClick={() => navigate(`/${code}/enquiry/detail?e_key=${item.e_key}`)}>
                      {item.e_customer}
                    </span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>{item.e_name}</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>{item.e_phone}</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>{item.e_email}</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>{item.e_employee}</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>{item.e_business}</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>{dayjs(item.created_at).format('YYYY-MM-DD HH:mm')}</span>
                  </div>
                </td>
                <td>
                  <div>
                    <button
                      type="button"
                      className="primary"
                      onClick={() => navigate(`/${code}/enquiry/detail?e_key=${item.e_key}`)}
                    >
                      <span>{t('내용')}</span>
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        total={count || 0}
        curr={page === undefined ? 1 : Number(page)}
        movePage={e => navigate(`?page=${e}`)}
      />
    </Page>
  );
}

export default Enquiry;
