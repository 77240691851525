import { atom, useRecoilState } from 'recoil';

export const surveyProbState = atom<ISurveyProps[]>({
  key: 'surveyProps',
  default: [],
});

export function useSurvey() {
  const [surveyProbs, setSurveyProbs] = useRecoilState(surveyProbState);

  const initSurveyProb = () => setSurveyProbs([]);
  const addSurveyProb = (
    surveyProbType: string,
    surveyProbEssential: boolean,
    surveyProbQuestion: string,
    surveyProbSelect: string,
  ) =>
    setSurveyProbs(prev =>
      prev.concat({
        surveyProbType,
        surveyProbEssential,
        surveyProbQuestion,
        surveyProbSelect,
      }),
    );

  // 설문 등록시
  const editType = (e: string, idx: number) => {
    setSurveyProbs(prev =>
      prev.map((item, indx) => {
        if (indx === idx) return { ...item, surveyProbType: e };
        return item;
      }),
    );
  };
  const editQuestion = (e: string, idx: number) => {
    setSurveyProbs(prev =>
      prev.map((item, indx) => {
        if (indx === idx) return { ...item, surveyProbQuestion: e };
        return item;
      }),
    );
  };
  const editEssensial = (e: boolean, idx: number) => {
    setSurveyProbs(prev =>
      prev.map((item, indx) => {
        if (indx === idx) return { ...item, surveyProbEssential: e };
        return item;
      }),
    );
  };
  const editSelect = (e: string, idx: number) => {
    setSurveyProbs(prev =>
      prev.map((item, indx) => {
        if (indx === idx) return { ...item, surveyProbSelect: e };
        return item;
      }),
    );
  };

  // 설문 수정시
  const fixQuestion = (e: string, key: number) => {
    setSurveyProbs(prev =>
      prev.map(item => {
        if (item.surveyProbKey === key) return { ...item, surveyProbQuestion: e };
        return item;
      }),
    );
  };
  const fixEssensial = (e: boolean, key: number) => {
    setSurveyProbs(prev =>
      prev.map(item => {
        if (item.surveyProbKey === key) return { ...item, surveyProbEssential: e };
        return item;
      }),
    );
  };
  const fixSelect = (e: string, key: number) => {
    setSurveyProbs(prev =>
      prev.map(item => {
        if (item.surveyProbKey === key) return { ...item, surveyProbSelect: e };
        return item;
      }),
    );
  };
  const fixDelete = (key: number) => {
    setSurveyProbs(prev =>
      prev.map(item => {
        if (item.surveyProbKey !== key) return item;
        return { ...item, delete: true };
      }),
    );
  };

  const removeSurveyProb = (idx: number) => setSurveyProbs(prev => prev.filter((_item, indx) => indx !== idx));

  const moveSurveyProb = (idx: number, direction: string) => {
    if (idx === 0 && direction === 'up') return;
    if (idx === surveyProbs.length - 1 && direction === 'down') return;
    if (direction === 'up') {
      const temp1 = surveyProbs[idx];
      const temp2 = surveyProbs[idx - 1];
      const edited = surveyProbs.map((item, indx) => {
        if (indx === idx - 1) return temp1;
        if (indx === idx) return temp2;
        return item;
      });
      setSurveyProbs(edited);
    } else if (direction === 'down') {
      const temp1 = surveyProbs[idx + 1];
      const temp2 = surveyProbs[idx];
      const edited = surveyProbs.map((item, indx) => {
        if (indx === idx) return temp1;
        if (indx === idx + 1) return temp2;
        return item;
      });
      setSurveyProbs(edited);
    }
  };

  return {
    initSurveyProb,
    addSurveyProb,
    editType,
    editQuestion,
    editEssensial,
    editSelect,
    fixQuestion,
    fixEssensial,
    fixSelect,
    fixDelete,
    removeSurveyProb,
    moveSurveyProb,
  };
}
