import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { useMutation } from '@tanstack/react-query';
import { createError, throwError } from '../../api/error';
import { deleteSalestipCategory, editSalestipCategory } from '../../api/salestip';
import { useConfirm } from '../../stores/confirmStore';
import { loadingState } from '../../stores/loadingStore';
import { useCategory } from '../../stores/salestipStore';

// style
import styles from './categoryEdit.module.scss';

// img
import { icEdit, icDelete } from '../aside/icon';

function SalestipCategoryEditItem({ item, refetch }: { item: ISC; refetch: () => void }) {
  const { t } = useTranslation();
  const { confirmShow } = useConfirm();
  const { setCategoryItemEnable, setCategoryItem } = useCategory();
  const { mutate: editCategory, isLoading: editCategoryLoading } = useMutation(editSalestipCategory, {
    onSuccess: () => refetch(),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (e: any) => {
      createError({
        type: '[ADMIN] salestip - editSalestipCategory',
        message: `message: "${e.message}",\nresponse: { status: ${e.response.status}, message: "${e.response.data.message}" }`,
      });
      throwError(e);
    },
  });

  const { mutate: remove, isLoading: deleteCategoryLoading } = useMutation(deleteSalestipCategory, {
    onSuccess: () => refetch(),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (e: any) => {
      createError({
        type: '[ADMIN] salestip - deleteSalestipCategory',
        message: `message: "${e.message}",\nresponse: { status: ${e.response.status}, message: "${e.response.data.message}" }`,
      });
      throwError(e);
    },
  });

  const setLoading = useSetRecoilState(loadingState);
  useEffect(
    () => setLoading(editCategoryLoading || deleteCategoryLoading),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editCategoryLoading, deleteCategoryLoading],
  );

  const inputRef = useRef<HTMLInputElement>(null);
  const [isChange, setIsChange] = useState(false);

  return (
    <div className={styles.item} key={item.key}>
      <input
        type="text"
        value={item.name}
        readOnly={!item.selected}
        onChange={e => {
          setCategoryItem(item.key, e.target.value);
          setIsChange(true);
        }}
        className={classNames(styles.edit, item.selected && styles.active)}
        ref={inputRef}
      />
      {!item.selected ? (
        <>
          <object
            onClick={() => {
              setCategoryItemEnable(item.key, true);
              inputRef.current?.focus();
            }}
          >
            {icEdit()}
          </object>
          <object onClick={() => confirmShow('카테고리를 삭제하시겠습니까?', () => remove(item.key))}>
            {icDelete()}
          </object>
        </>
      ) : (
        <div
          className={styles.btn}
          onClick={() => {
            setCategoryItemEnable(item.key, false);
            if (!isChange) return;
            setIsChange(false);
            editCategory({ key: item.key, name: item.name });
          }}
        >
          {t('확인')}
        </div>
      )}
    </div>
  );
}

export default SalestipCategoryEditItem;
