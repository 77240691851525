import { atom, useSetRecoilState } from 'recoil';

export const memberList = atom<{ state: boolean; selected: IMember; data: IMember[] }>({
  key: 'memberList',
  default: {
    state: false,
    selected: {
      m_key: 0,
      mg_code: '',
      mr_code: '',
      mo_key: 0,
      m_id: '',
      m_name: '',
      m_gender: '',
      m_email: '',
      m_last_login_at: '',
      m_delete_at: '',
      created_at: '',
      updated_at: '',
      mo_name: '',
    },
    data: [],
  },
});

export function useMemberList() {
  const setMemberListState = useSetRecoilState(memberList);
  const setMemberListModal = (state: boolean) => setMemberListState(prev => ({ ...prev, state }));
  const setMemberSelect = (selected: IMember) => setMemberListState(prev => ({ ...prev, selected }));
  const setMemberSelectInit = () =>
    setMemberListState(prev => ({
      ...prev,
      selected: {
        m_key: 0,
        mg_code: '',
        mr_code: '',
        mo_key: 0,
        m_id: '',
        m_name: '',
        m_gender: '',
        m_email: '',
        m_last_login_at: '',
        m_delete_at: '',
        created_at: '',
        updated_at: '',
        mo_name: '',
      },
    }));

  return { setMemberListModal, setMemberSelect, setMemberSelectInit };
}

export const memberInfoState = atom<{ state: boolean; data: IMember }>({
  key: 'memberInfo',
  default: {
    state: false,
    data: {
      m_key: 0,
      mg_code: '',
      mr_code: '',
      mo_key: 0,
      m_id: '',
      m_name: '',
      m_gender: '',
      m_email: '',
      m_last_login_at: '',
      m_delete_at: '',
      created_at: '',
      updated_at: '',
      mo_name: '',
    },
  },
});

export function useMemberInfo() {
  const setMemberInfoState = useSetRecoilState(memberInfoState);

  const setMemberInfo = (data: { state: boolean; data: IMember }) => setMemberInfoState(data);

  return { setMemberInfo };
}

export const memberGradeState = atom<{ state: boolean; data: IGrade[] }>({
  key: 'memberGrade',
  default: {
    state: false,
    data: [],
  },
});

export function useMemberGrade() {
  const setMemberGradeState = useSetRecoilState(memberGradeState);

  const setMemberGradeModal = (toggle: boolean) => setMemberGradeState(prev => ({ ...prev, state: toggle }));

  const setMemberGradeData = (mg_code: string) =>
    setMemberGradeState(prev => ({
      ...prev,
      data: prev.data.map(item =>
        item.mg_code === mg_code ? { ...item, checked: true } : { ...item, checked: false },
      ),
    }));

  return { setMemberGradeModal, setMemberGradeData };
}

export const memberRoleState = atom<{ state: boolean; m_key: number; data: string[] }>({
  key: 'memberRole',
  default: {
    state: false,
    m_key: 0,
    data: [],
  },
});

export const memberOrgState = atom<{ state: boolean; selected: number; data: IOrg }>({
  key: 'memberOrg',
  default: {
    state: false,
    selected: 0,
    data: { mo_key: 0 },
  },
});

export function useMemberOrg() {
  const setMemberOrgState = useSetRecoilState(memberOrgState);

  const setMemberOrgModal = (toggle: boolean) => setMemberOrgState(prev => ({ ...prev, state: toggle }));

  const setMemberOrgSelect = (num: number) => setMemberOrgState(prev => ({ ...prev, selected: num }));

  return { setMemberOrgModal, setMemberOrgSelect };
}

export const memberOrgMultyState = atom<{ state: boolean; selected: number[]; data: IOrg }>({
  key: 'memberOrgMulty',
  default: {
    state: false,
    selected: [],
    data: { mo_key: 0 },
  },
});

export const memberUploadModal = atom({
  key: 'memberUploadModal',
  default: false,
});

export const orgUploadModal = atom({
  key: 'orgUploadModal',
  default: false,
});
