import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { Route, Routes, useNavigate, useLocation, Outlet } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { useAlert } from './stores/alertStore';
import { foldState, useLanguage } from './stores/layoutStore';
import { loadingState } from './stores/loadingStore';
import { decrypt } from './assets/util/crypto';

// include
import Header from './includes/header';
import Loading from './includes/loading';
import Main from './includes/main';
import Content from './includes/content';
import Aside from './includes/aside';

// page
// 홈
import Home from './routes';
// 세팅
import Settings from './routes/Settings';
import AccountSettings from './routes/account';
// 회원관리
import MemberList from './routes/member';
import MemberEdit from './routes/member/edit';
import MemberOrganization from './routes/member/organization';
// 고객관리
import CustomerList from './routes/customer';
import CustomerEdit from './routes/customer/edit';
import CustomerRefund from './routes/customer/refund';
import Announcement from './routes/announcement';
import AnnouncementEdit from './routes/announcement/edit';
import AnnouncementDetail from './routes/announcement/detail';
// 문의사항
import Enquiry from './routes/enquiry';
import EnquiryDetail from './routes/enquiry/detail';
// 데모신청
import Demo from './routes/demo';
import DemoHistory from './routes/demo/history';
// 라이브
import LiveList from './routes/live';
import LiveEdit from './routes/live/edit';
import LiveReplayList from './routes/replay';
import LiveReplayDetail from './routes/replay/detail';
import LiveReplayEdit from './routes/replay/edit';
// 회의
import MeetingList from './routes/meeting';
import MeetingEdit from './routes/meeting/edit';
// 체크리스트
import Inspection from './routes/inspection';
import InspectionEdit from './routes/inspection/edit';
// 설문
import Survey from './routes/survey';
import SurveyEdit from './routes/survey/edit';
import SurveyResponse from './routes/survey/response';
import SurveyResponseDetail from './routes/survey/responseDetail';
import SurveyAnalysis from './routes/survey/analysis';
// 평가
import Evaluation from './routes/evaluation';
import EvaluationEdit from './routes/evaluation/edit';
import EvaluationAnalysis from './routes/evaluation/analysis';
import EvaluationHistoryList from './routes/evaluation/history';
import EvaluationHistory from './routes/evaluation/historyDetail';
import EvaluationQuestion from './routes/evaluation/question';
import EvaluationQuestionEdit from './routes/evaluation/questionEdit';
import EvaluationCategory from './routes/evaluation/category';
// 세일즈팁
import Salestip from './routes/salestip';
import SalestipDetail from './routes/salestip/detail';
import SalestipEdit from './routes/salestip/edit';
import SalestipCategory from './routes/salestip/category';
// 커뮤니티
import Category from './routes/board';
import FreeList from './routes/board/freeList';
import FreeDetail from './routes/board/freeDetail';
import FreeEdit from './routes/board/freeEdit';
import NoticeList from './routes/board/noticeList';
import NoticeDetail from './routes/board/noticeDetail';
import NoticeEdit from './routes/board/noticeEdit';
import QnAList from './routes/board/qnaList';
import QnaDetail from './routes/board/qnaDetail';
// Vod
import Vod from './routes/vod';
import VodEdit from './routes/vod/edit';
import VodDetail from './routes/vod/detail';
// 러닝톡톡
import LearningTalkList from './routes/learningtalk';
// 푸시알림
import Pushalarm from './routes/push';
import PushalarmEdit from './routes/push/edit';
// Error
import Error from './routes/error';
// 기타
import Login from './routes/login';
import PageNotFound from './routes/pageNotFound/pageNotFound';
import CodeNotFound from './routes/pageNotFound/codeNotFound';

// modal
import Alert from './components/modal/alert';
import Confirm from './components/modal/confirm';
import Prompt from './components/modal/prompt';
import ManagerList from './routes/member/managers';

function App() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [cookies, setCookie] = useCookies(['xclass-theme', 'xclass-lang', 'XID']);
  const { changeLanguage } = useLanguage();
  const setLang = () => {
    if (cookies['xclass-lang']) {
      changeLanguage(cookies['xclass-lang']);
      return;
    }
    changeLanguage(navigator.language.substr(0, 2));
  };
  const { alertShow } = useAlert();
  const setLoading = useSetRecoilState(loadingState);
  useEffect(() => {
    setLang();
    setCookie(
      'xclass-theme',
      cookies['xclass-theme']
        ? cookies['xclass-theme']
        : window.matchMedia('(prefers-color-scheme: dark)').matches
        ? 'dark'
        : 'light',
      { domain: process.env.NODE_ENV === 'production' ? '.xclass.co.kr' : '.xcast.co.kr', path: '/' },
    );
    window.addEventListener('message', ({ data }) => {
      if (data.type === 'alert') {
        alertShow(data.message);
        setLoading(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const setFold = useSetRecoilState(foldState);
  useEffect(() => {
    if (pathname === '/') navigate('/404');
    if (!pathname.toUpperCase().includes('/SUPER') && decrypt(cookies.XID).mr_code === null)
      window.postMessage({ type: 'logout' });
    if (
      pathname.toUpperCase() !== '/SUPER/login' &&
      pathname.toUpperCase().includes('/SUPER') &&
      decrypt(cookies.XID).mg_code !== 'SUPER'
    )
      window.postMessage({ type: 'logout' });
    if (!cookies.XID && pathname !== '/404') window.postMessage({ type: 'logout' });
    setFold(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <>
      <Routes>
        <Route
          element={
            <>
              <Header />
              <Main>
                <Aside />
                <Content>
                  <Outlet />
                </Content>
              </Main>
            </>
          }
        >
          {/* 메인 */}
          <Route path="/:code" element={<Home />} />
          {/* 세팅 */}
          <Route path="/:code/settings" element={<Settings />} />
          <Route path="/:code/account" element={<AccountSettings />} />
          {/* 최고관리자 - 고객관리 */}
          <Route path="/:code/customer" element={<CustomerList />} />
          <Route path="/:code/customer/edit" element={<CustomerEdit />} />
          <Route path="/:code/customer/refund" element={<CustomerRefund />} />
          {/* 최고관리자 - 공지사항 */}
          <Route path="/:code/announcement" element={<Announcement />} />
          <Route path="/:code/announcement/edit" element={<AnnouncementEdit />} />
          <Route path="/:code/announcement/detail" element={<AnnouncementDetail />} />
          {/* 최고관리자 - 문의사항 */}
          <Route path="/:code/enquiry" element={<Enquiry />} />
          <Route path="/:code/enquiry/detail" element={<EnquiryDetail />} />
          {/* 최고관리자 - 데모신청 */}
          <Route path="/:code/demo" element={<Demo />} />
          <Route path="/:code/demo/history" element={<DemoHistory />} />
          {/* 최고관리자 - 오류 */}
          <Route path="/:code/error" element={<Error />} />
          {/* 회원관리 */}
          <Route path="/:code/member" element={<MemberList />} />
          <Route path="/:code/member/manager" element={<ManagerList />} />
          <Route path="/:code/member/edit" element={<MemberEdit />} />
          <Route path="/:code/member/organization" element={<MemberOrganization />} />
          {/* 라이브 */}
          <Route path="/:code/live" element={<LiveList />} />
          <Route path="/:code/live/edit" element={<LiveEdit />} />
          <Route path="/:code/live/replay" element={<LiveReplayList />} />
          <Route path="/:code/live/replay/detail" element={<LiveReplayDetail />} />
          <Route path="/:code/live/replay/edit" element={<LiveReplayEdit />} />
          {/* 라이브 */}
          <Route path="/:code/meeting" element={<MeetingList />} />
          <Route path="/:code/meeting/edit" element={<MeetingEdit />} />
          {/* 체크리스트 */}
          <Route path="/:code/inspection" element={<Inspection />} />
          <Route path="/:code/inspection/edit" element={<InspectionEdit />} />
          {/* 설문 */}
          <Route path="/:code/survey" element={<Survey />} />
          <Route path="/:code/survey/edit" element={<SurveyEdit />} />
          <Route path="/:code/survey/response" element={<SurveyResponse />} />
          <Route path="/:code/survey/responseDetail" element={<SurveyResponseDetail />} />
          <Route path="/:code/survey/analysis" element={<SurveyAnalysis />} />
          {/* 평가 */}
          <Route path="/:code/evaluation" element={<Evaluation />} />
          <Route path="/:code/evaluation/edit" element={<EvaluationEdit />} />
          <Route path="/:code/evaluation/analysis" element={<EvaluationAnalysis />} />
          <Route path="/:code/evaluation/history" element={<EvaluationHistoryList />} />
          <Route path="/:code/evaluation/historyDetail" element={<EvaluationHistory />} />
          <Route path="/:code/evaluation/question" element={<EvaluationQuestion />} />
          <Route path="/:code/evaluation/question/edit" element={<EvaluationQuestionEdit />} />
          <Route path="/:code/evaluation/category" element={<EvaluationCategory />} />
          {/* 세일즈팁 */}
          <Route path="/:code/salestip" element={<Salestip />} />
          <Route path="/:code/salestip/detail" element={<SalestipDetail />} />
          <Route path="/:code/salestip/edit" element={<SalestipEdit />} />
          <Route path="/:code/salestip/category" element={<SalestipCategory />} />
          {/* VOD */}
          <Route path="/:code/vod" element={<Vod />} />
          <Route path="/:code/vod/edit" element={<VodEdit />} />
          <Route path="/:code/vod/detail" element={<VodDetail />} />
          {/* 커뮤니티 */}
          <Route path="/:code/board" element={<Category />} />
          <Route path="/:code/board/free" element={<FreeList />} />
          <Route path="/:code/board/free/edit" element={<FreeEdit />} />
          <Route path="/:code/board/free/detail" element={<FreeDetail />} />
          <Route path="/:code/board/notice" element={<NoticeList />} />
          <Route path="/:code/board/notice/detail" element={<NoticeDetail />} />
          <Route path="/:code/board/notice/edit" element={<NoticeEdit />} />
          <Route path="/:code/board/qna" element={<QnAList />} />
          <Route path="/:code/board/qna/detail" element={<QnaDetail />} />
          {/* 러닝톡톡 */}
          <Route path="/:code/learningtalk" element={<LearningTalkList />} />
          {/* 푸시알림 */}
          <Route path="/:code/push" element={<Pushalarm />} />
          <Route path="/:code/push/edit" element={<PushalarmEdit />} />
        </Route>
        {/* 로그인 */}
        <Route path="/:code/login" element={<Login />} />
        {/* 404 */}
        <Route path="/404" element={<CodeNotFound />} />
        <Route path="/:code/*" element={<PageNotFound />} />
      </Routes>
      <Alert />
      <Confirm />
      <Prompt />
      <Loading />
      <span style={{ display: 'none' }}>version 1.0.3</span>
    </>
  );
}

export default App;
