import { atom, useRecoilState } from 'recoil';

export const inspectionState = atom<IInspectionProb[]>({
  key: 'inspectionList',
  default: [],
});

export function useInspection() {
  const [inspection, setInspection] = useRecoilState(inspectionState);

  const setInspectionTitle = (order: number, title: string) =>
    setInspection(prev => prev.map(item => (order === item.order ? { ...item, title } : item)));

  const setInspectionDescription = (order: number, description: string) =>
    setInspection(prev => prev.map(item => (order === item.order ? { ...item, description } : item)));

  const setInspectionNp = (order: number, needPicture: boolean) =>
    setInspection(prev => prev.map(item => (order === item.order ? { ...item, needPicture } : item)));

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setInspectionFile = (order: number, file: any, img: any, imgName: any) =>
    setInspection(prev =>
      prev.map(item =>
        order === item.order
          ? {
              ...item,
              imgFile: {
                file: item.imgFile.file.concat(file),
                img: item.imgFile.img.concat(img),
                imgName: item.imgFile.imgName.concat(imgName),
              },
            }
          : item,
      ),
    );

  const setInspectionFileDelete = (order: number, idx: number) => {
    if (inspection.find(item => item.order === order)?.imgFile.imgName[idx].includes('uploaded_')) {
      const imageMaps =
        inspection
          .find(item => item.order === order)
          ?.imageMaps.filter(
            el =>
              Object.values(el)[0] !==
              inspection.find(item => item.order === order)?.imgFile.imgName[idx].split('_')[2],
          ) || [];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const updateImageOrders: any = { ...inspection.find(item => item.order === order)?.updateImageOrders };
      inspection
        .find(item => item.order === order)
        ?.imgFile.imgName.forEach((_: string, index: number) => {
          const uioKey = Number(inspection.find(item => item.order === order)?.imgFile.imgName[index].split('_')[1]);
          if (index === idx) updateImageOrders[uioKey] = -1;
          else if (index > idx) updateImageOrders[uioKey] = index;
          else updateImageOrders[uioKey] = index + 1;
        });
      setInspection(prev =>
        prev.map(item =>
          order === item.order
            ? {
                ...item,
                imageMaps,
                updateImageOrders,
              }
            : item,
        ),
      );
    }
    setInspection(prev =>
      prev.map(item =>
        order === item.order
          ? {
              ...item,
              imgFile: {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                file: item.imgFile.file.filter((_: any, index: number) => idx !== index),
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                img: item.imgFile.img.filter((_: any, index: number) => idx !== index),
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                imgName: item.imgFile.imgName.filter((_: any, index: number) => idx !== index),
              },
            }
          : item,
      ),
    );
  };

  const setInspectionFileMove = (order: number, idx: number, direction: string) => {
    if (idx === 0 && direction === 'prev') return;
    // eslint-disable-next-line no-unsafe-optional-chaining
    if (idx === inspection.find(item => order === item.order)?.imgFile?.file.length - 1 && direction === 'next') return;
    const temp = [
      inspection.find(item => order === item.order)?.imgFile?.file[idx],
      inspection.find(item => order === item.order)?.imgFile?.img[idx],
      inspection.find(item => order === item.order)?.imgFile?.imgName[idx],
    ];
    const pre = [
      inspection.find(item => order === item.order)?.imgFile?.file[idx - 1],
      inspection.find(item => order === item.order)?.imgFile?.img[idx - 1],
      inspection.find(item => order === item.order)?.imgFile?.imgName[idx - 1],
    ];
    const next = [
      inspection.find(item => order === item.order)?.imgFile?.file[idx + 1],
      inspection.find(item => order === item.order)?.imgFile?.img[idx + 1],
      inspection.find(item => order === item.order)?.imgFile?.imgName[idx + 1],
    ];
    if (direction === 'prev') {
      if (
        inspection.find(item => order === item.order)?.imgFile?.imgName[idx].includes('uploaded_') ||
        inspection.find(item => order === item.order)?.imgFile?.imgName[idx - 1].includes('uploaded_')
      ) {
        const tempImg = inspection
          .find(item => order === item.order)
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ?.imgFile?.imgName.filter((item: any) => item.includes('uploaded'))
          .map((item: string) => item.split('_')[2])[idx];
        const preImg = inspection
          .find(item => order === item.order)
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ?.imgFile?.imgName.filter((item: any) => item.includes('uploaded'))
          .map((item: string) => item.split('_')[2])[idx - 1];

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const imageMaps: any[] = [];
        inspection
          .find(item => order === item.order)
          ?.imageMaps.forEach((item: IImageMaps, indx: number) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const obj: any = {};
            obj[indx] = indx === idx ? preImg : indx === idx - 1 ? tempImg : Object.values(item)[0];
            imageMaps.push(obj);
          });

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const updateImageOrders: any = { ...inspection.find(item => item.order === order)?.updateImageOrders };
        inspection
          .find(item => item.order === order)
          ?.imgFile.imgName.forEach((_: string, index: number) => {
            const uioKey = Number(inspection.find(item => item.order === order)?.imgFile.imgName[index].split('_')[1]);
            if (idx === index) updateImageOrders[uioKey] = index;
            else if (idx - 1 === index) updateImageOrders[uioKey] = index + 2;
            else updateImageOrders[uioKey] = index + 1;
          });

        setInspection(prev =>
          prev.map(item =>
            order === item.order
              ? {
                  ...item,
                  imageMaps,
                  updateImageOrders,
                }
              : item,
          ),
        );
      }
      setInspection(prev =>
        prev.map(item =>
          order === item.order
            ? {
                ...item,
                imgFile: {
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  file: item.imgFile.file.map((el: any, index: number) =>
                    index === idx ? pre[0] : index === idx - 1 ? temp[0] : el,
                  ),
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  img: item.imgFile.img.map((el: any, index: number) =>
                    index === idx ? pre[1] : index === idx - 1 ? temp[1] : el,
                  ),
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  imgName: item.imgFile.imgName.map((el: any, index: number) =>
                    index === idx ? pre[2] : index === idx - 1 ? temp[2] : el,
                  ),
                },
              }
            : item,
        ),
      );
    }
    if (direction === 'next') {
      if (
        inspection.find(item => order === item.order)?.imgFile?.imgName[idx].includes('uploaded_') ||
        inspection.find(item => order === item.order)?.imgFile?.imgName[idx + 1].includes('uploaded_')
      ) {
        const tempImg = inspection
          .find(item => order === item.order)
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ?.imgFile?.imgName.filter((item: any) => item.includes('uploaded'))
          .map((item: string) => item.split('_')[2])[idx];
        const nextImg = inspection
          .find(item => order === item.order)
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ?.imgFile?.imgName.filter((item: any) => item.includes('uploaded'))
          .map((item: string) => item.split('_')[2])[idx + 1];

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const imageMaps: any[] = [];
        inspection
          .find(item => order === item.order)
          ?.imageMaps.forEach((item: IImageMaps, indx: number) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const obj: any = {};
            obj[indx] = indx === idx ? nextImg : indx === idx + 1 ? tempImg : Object.values(item)[0];
            imageMaps.push(obj);
          });

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const updateImageOrders: any = { ...inspection.find(item => item.order === order)?.updateImageOrders };
        inspection
          .find(item => item.order === order)
          ?.imgFile.imgName.forEach((_: string, index: number) => {
            const uioKey = Number(inspection.find(item => item.order === order)?.imgFile.imgName[index].split('_')[1]);
            if (idx === index) updateImageOrders[uioKey] = index + 2;
            else if (idx + 1 === index) updateImageOrders[uioKey] = index;
            else updateImageOrders[uioKey] = index + 1;
          });

        setInspection(prev =>
          prev.map(item =>
            order === item.order
              ? {
                  ...item,
                  imageMaps,
                  updateImageOrders,
                }
              : item,
          ),
        );
      }
      setInspection(prev =>
        prev.map(item =>
          order === item.order
            ? {
                ...item,
                imgFile: {
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  file: item.imgFile.file.map((el: any, index: number) =>
                    index === idx ? next[0] : index === idx + 1 ? temp[0] : el,
                  ),
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  img: item.imgFile.img.map((el: any, index: number) =>
                    index === idx ? next[1] : index === idx + 1 ? temp[1] : el,
                  ),
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  imgName: item.imgFile.imgName.map((el: any, index: number) =>
                    index === idx ? next[2] : index === idx + 1 ? temp[2] : el,
                  ),
                },
              }
            : item,
        ),
      );
    }
  };

  const setInspectionFileInit = (order: number) =>
    setInspection(prev =>
      prev.map(item =>
        order === item.order
          ? {
              ...item,
              imgFile: {
                file: [],
                img: [],
                imgName: [],
              },
            }
          : item,
      ),
    );

  const setInspectionImgAdd = (order: number, img: IImageMaps) =>
    setInspection(prev =>
      prev.map(item =>
        order === item.order
          ? {
              ...item,
              imageMaps: item.imageMaps
                .concat(img)
                .sort((a, b) => Number(Object.keys(a)[0]) - Number(Object.keys(b)[0])),
            }
          : item,
      ),
    );

  const setInspectionAdd = (isNew: boolean) =>
    setInspection(prev =>
      prev.concat({
        title: '',
        description: '',
        needPicture: false,
        order: prev.length + 1,
        imageMaps: [],
        new: isNew,
        imgFile: { file: [], img: [], imgName: [] },
      }),
    );

  const setInspectionRemove = (order: number) =>
    setInspection(prev => prev.filter(item => order !== item.order).map((item, idx) => ({ ...item, order: idx + 1 })));

  const setInspectionDelete = (order: number) =>
    setInspection(prev =>
      prev.map((item, idx) =>
        order === item.order ? { ...item, delete: true } : item.order > order ? { ...item, order: idx } : item,
      ),
    );

  const setInspectionMove = (order: number, direction: string) => {
    if (order === 1 && direction === 'up') return;
    if (order === inspection.length && direction === 'down') return;
    if (direction === 'up')
      setInspection(prev =>
        prev
          .map(el =>
            el.order === order
              ? { ...el, order: el.order - 1 }
              : el.order === order - 1
              ? { ...el, order: el.order + 1 }
              : el,
          )
          .sort((a, b) => a.order - b.order),
      );
    if (direction === 'down')
      setInspection(prev =>
        prev
          .map(el =>
            el.order === order
              ? { ...el, order: el.order + 1 }
              : el.order === order + 1
              ? { ...el, order: el.order - 1 }
              : el,
          )
          .sort((a, b) => a.order - b.order),
      );
  };

  return {
    setInspectionTitle,
    setInspectionDescription,
    setInspectionNp,
    setInspectionFile,
    setInspectionFileDelete,
    setInspectionFileMove,
    setInspectionFileInit,
    setInspectionImgAdd,
    setInspectionAdd,
    setInspectionRemove,
    setInspectionDelete,
    setInspectionMove,
  };
}
