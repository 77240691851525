import axios from 'axios';
import { createError, throwError } from './error';

const api = process.env.REACT_APP_API;

// 로그인
export const login = async (element: { code: string; id: string; password: string }) => {
  const { data } = await axios.post(`${api}/admin/member/login`, { ...element }, { withCredentials: true });
  return data;
};

// 로그아웃
export const logout = async () => {
  const { data } = await axios.post(`${api}/admin/member/logout`, {}, { withCredentials: true });
  return data;
};

// 회원 목록 등록
export const createMemberList = async (
  element: {
    grade: string;
    organization: number;
    id: string;
    name: string;
    password: string;
    gender: string;
    email: string;
    phone: string;
  }[],
) => {
  await axios.post(`${api}/admin/member/list`, [...element], { withCredentials: true });
};

// 회원 생성
export const createMember = async (element: {
  grade: string;
  organization: number;
  id: string;
  name: string;
  password: string;
  gender: string;
  email: string;
  phone: string;
  memo: string;
  role?: string;
}) => {
  await axios.post(`${api}/admin/member`, { ...element }, { withCredentials: true });
};

// 회원 수정
export const editMember = async (element: {
  m_key: number;
  data: {
    grade: string;
    organization: number;
    id: string;
    name: string;
    password?: string;
    gender: string;
    email: string;
    phone: string;
    memo: string;
    role?: string;
  };
}) => {
  await axios.patch(`${api}/admin/member/${element.m_key}`, { ...element.data }, { withCredentials: true });
};

// 회원 삭제
export const deleteMember = async (m_key: number) => {
  await axios.delete(`${api}/admin/member/${m_key}`, { withCredentials: true });
};

// 회원 목록 조회
export const getMemberList = async (element: {
  id?: string;
  name?: string;
  organizationKey?: number;
  page: number;
}) => {
  try {
    const { data } = await axios.get(
      `${api}/admin/member/list?${element.id ? `id=${element.id}&` : ''}${element.name ? `name=${element.name}&` : ''}${
        element.organizationKey && element.organizationKey !== 0 ? `organizationKey=${element.organizationKey}&` : ''
      }page=${element.page}&limit=10`,
      { withCredentials: true },
    );
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    createError({
      type: '[ADMIN] member - getMemberList',
      message: `message: "${error.message}",\nresponse: { status: ${error.response.status}, message: "${error.response.data.message}" }`,
    });
    throwError(error);
    return null;
  }
};

// 회원 조회
export const getMember = async (m_key: number) => {
  try {
    const { data } = await axios.get(`${api}/admin/member/${m_key}`, { withCredentials: true });
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    createError({
      type: '[ADMIN] member - getMember',
      message: `message: "${error.message}",\nresponse: { status: ${error.response.status}, message: "${error.response.data.message}" }`,
    });
    throwError(error);
    return null;
  }
};

// 회원 등급 목록 조회
export const getMemberGrade = async () => {
  try {
    const { data } = await axios.get(`${api}/admin/member/grade/list`, { withCredentials: true });
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    createError({
      type: '[ADMIN] member - getMemberGrade',
      message: `message: "${error.message}",\nresponse: { status: ${error.response.status}, message: "${error.response.data.message}" }`,
    });
    throwError(error);
    return null;
  }
};

// 회원 역할 목록 조회
export const getMemberRole = async () => {
  try {
    const { data } = await axios.get(`${api}/admin/member/role/list`, { withCredentials: true });
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    createError({
      type: '[ADMIN] member - getMemberRole',
      message: `message: "${error.message}",\nresponse: { status: ${error.response.status}, message: "${error.response.data.message}" }`,
    });
    throwError(error);
    return null;
  }
};

// 회원 조직 생성
export const createOrg = async (element: { name: string; parent: number }) => {
  await axios.post(`${api}/admin/member/organization`, { ...element }, { withCredentials: true });
};

// 회원 조직 엑셀 등록
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createOrgList = async (element: any) => {
  await axios.post(`${api}/admin/member/organization/excel`, { ...element }, { withCredentials: true });
};

// 회원 조직 수정
export const editOrg = async (element: { name: string; parent: number }) => {
  await axios.patch(`${api}/admin/member/organization/${element.parent}`, { ...element }, { withCredentials: true });
};

// 회원 조직 삭제
export const deleteOrg = async (mo_key: number) => {
  await axios.delete(`${api}/admin/member/organization/${mo_key}`, { withCredentials: true });
};

// 회원 조직 목록 전체 조회
export const getOrgAll = async () => {
  try {
    const { data } = await axios.get(`${api}/admin/member/organization/all`, { withCredentials: true });
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    createError({
      type: '[ADMIN] member - getOrgAll',
      message: `message: "${error.message}",\nresponse: { status: ${error.response.status}, message: "${error.response.data.message}" }`,
    });
    throwError(error);
    return null;
  }
};

// 회원 조직 목록 자신이 속한 조직 조회
export const getOrgList = async () => {
  try {
    const { data } = await axios.get(`${api}/admin/member/organization/list`, { withCredentials: true });
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    createError({
      type: '[ADMIN] member - getOrgList',
      message: `message: "${error.message}",\nresponse: { status: ${error.response.status}, message: "${error.response.data.message}" }`,
    });
    throwError(error);
    return null;
  }
};

// 계정 조회
export const getAccount = async () => {
  try {
    const { data } = await axios.get(`${api}/user/member`, { withCredentials: true });
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    createError({
      type: '[ADMIN] member - getAccount',
      message: `message: "${error.message}",\nresponse: { status: ${error.response.status}, message: "${error.response.data.message}" }`,
    });
    throwError(error);
    return null;
  }
};

// 계정 수정
export const editAccount = async (element: {
  name?: string;
  gender?: string;
  email?: string;
  phone?: string;
}) => {
  await axios.patch(`${api}/user/member`, { ...element }, { withCredentials: true });
};

// 비밀번호 수정
export const editPassword = async (element: {
  password: string;
  newPassword: string;
}) => {
  await axios.patch(`${api}/user/member/password`, { ...element }, { withCredentials: true });
};

// 운영자 목록 조회
export const getManagerList = async (element: {
  id?: string;
  name?: string;
  organizationKey?: number;
  page: number;
}) => {
  try {
    const { data } = await axios.get(
      `${api}/admin/member/list/manager?${element.id ? `id=${element.id}&` : ''}${
        element.name ? `name=${element.name}&` : ''
      }${
        element.organizationKey && element.organizationKey !== 0 ? `organizationKey=${element.organizationKey}&` : ''
      }page=${element.page}&limit=10`,
      { withCredentials: true },
    );
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    createError({
      type: '[ADMIN] member - getManagerList',
      message: `message: "${error.message}",\nresponse: { status: ${error.response.status}, message: "${error.response.data.message}" }`,
    });
    throwError(error);
    return null;
  }
};

// 운영자 역할 수정
export const editManagerRole = async (element: { m_key: number; role: string }) => {
  await axios.patch(`${api}/admin/member/role/${element.m_key}`, { role: element.role }, { withCredentials: true });
};

// 회원로그 엑셀 다운로드
export const getUserLog = async (element: { sdate: string; edate: string }) => {
  try {
    const { data } = await axios.get(`${api}/userlog/excel?sdate=${element.sdate}&edate=${element.edate}`, {
      withCredentials: true,
    });
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    createError({
      type: '[ADMIN] log - getUserLog',
      message: `message: "${error.message}",\nresponse: { status: ${error.response.status}, message: "${error.response.data.message}" }`,
    });
    throwError(error);
    return null;
  }
};
