import classNames from 'classnames';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useMutation, useQuery } from '@tanstack/react-query';
import { createError, throwError } from '../../api/error';
import { deleteMember, getManagerList, getOrgList } from '../../api/member';
import { useAlert } from '../../stores/alertStore';
import { useConfirm } from '../../stores/confirmStore';
import { memberRoleState, useMemberInfo, memberOrgState, useMemberOrg } from '../../stores/memberStore';
import { decrypt } from '../../assets/util/crypto';
import { flatOrg } from '../../assets/util/flatOrg';

// components
import Page from '../../includes/page';
import Pagination from '../../includes/pagination copy';
import MemberInfo from '../../components/member/memberInfo';
import MemberRole from '../../components/member/memberRole';
import MemberOrg from '../../components/member/memberOrg';

// img
import icInfo from '../../assets/img/icons/ic_info.svg';
import { icMenuAccess } from '../../components/aside/icon';

function ManagerList() {
  const { t } = useTranslation();
  const { code } = useParams();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { id, name, org, page } = queryString.parse(search);
  const [cookies] = useCookies(['xclass-lang', 'xclass-cname', 'XID']);
  const { alertShow } = useAlert();
  const { confirmShow } = useConfirm();

  // 검색
  const [searchForm, setSearchForm] = useState(false);
  const [idValue, setIdValue] = useState('');
  const [nameValue, setNameValue] = useState('');
  const { setMemberOrgModal, setMemberOrgSelect } = useMemberOrg();
  const [memberOrg, setMemberOrg] = useRecoilState(memberOrgState);
  const { isFetching: orgLoading } = useQuery<{ data: IOrg }>(['org'], getOrgList, {
    refetchOnWindowFocus: false,
    onSuccess: e => {
      setMemberOrg(prev => ({ ...prev, data: e.data }));
      if (id !== undefined) setIdValue(String(id));
      if (name !== undefined) setNameValue(String(name));
      if (org !== undefined) setMemberOrg(prev => ({ ...prev, selected: Number(org) }));
    },
  });

  const [count, setCount] = useState(0);
  const [data, setData] = useState<IMember[]>([]);
  const { isFetching: getManagerListLoading, refetch } = useQuery<{ count: number; data: IMember[] }>(
    ['memberList', id, name, org, page],
    () =>
      getManagerList({
        id: id === undefined ? '' : String(id),
        name: name === undefined ? '' : String(name),
        organizationKey: org === undefined ? 0 : memberOrg.selected,
        page: page === undefined ? 1 : Number(page),
      }),
    {
      refetchOnWindowFocus: false,
      onSuccess: e => {
        setCount(e.count);
        setData(e.data.map(item => ({ ...item, checked: false })));
        if (e.count > 0 && e.data.length === 0 && page !== undefined && Number(page) > 1)
          navigate(
            `${search !== undefined ? '?' : ''}${id === undefined ? '' : `id=${id}${name === undefined ? '' : '&'}`}${
              name === undefined ? '' : `name=${name}${org === undefined ? '' : '&'}`
            }${org === undefined ? '' : `org=${org}`}`,
          );
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (e: any) => alertShow(e.response.data.message),
    },
  );
  const { mutate: remove, isLoading: deleteLoading } = useMutation(deleteMember, {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (e: any) => {
      createError({
        type: '[ADMIN] member - deleteMember',
        message: `message: "${e.message}",\nresponse: { status: ${e.response.status}, message: "${e.response.data.message}" }`,
      });
      throwError(e);
    },
  });

  const selectDelete = () =>
    confirmShow('선택한 항목을 삭제하시겠습니까?', () => {
      const checked = data.filter(item => item.checked);
      for (let i = 0; i < checked.length; i += 1) remove(checked[i].m_key);
    });

  useEffect(() => {
    if (!deleteLoading) refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteLoading]);

  const allSelect = () =>
    setData(
      data.map(item => ({
        ...item,
        checked: data.filter(item => item.checked).length !== data.length,
      })),
    );

  const { setMemberInfo } = useMemberInfo();

  const setMemberRole = useSetRecoilState(memberRoleState);

  return (
    <Page title="운영자목록" btn={{ name: '회원목록', link: `${code}/member` }} isLoading={orgLoading || deleteLoading}>
      <div className="table-tools">
        <div className="btns">
          <button type="button" className="danger" onClick={allSelect}>
            <span>{t('전체선택')}</span>
          </button>
          <button type="button" className="primary" onClick={() => navigate(`/${code}/member/edit`)}>
            <span>{t('등록')}</span>
          </button>
          <button
            type="button"
            className={classNames('secondary', data.filter(item => item.checked).length === 0 && 'disabled')}
            onClick={selectDelete}
          >
            <span>{t('선택삭제')}</span>
          </button>
        </div>
        <div className="btns">
          <button type="button" className="secondary" onClick={() => setSearchForm(prev => !prev)}>
            <span>{t('상세검색')}</span>
          </button>
        </div>
      </div>
      {searchForm && (
        <div className="search-detail">
          <div className="form">
            <div className="item">
              <div className="subject">
                <span>{t('아이디')}</span>
              </div>
              <div className="input">
                <input type="text" value={idValue} onChange={e => setIdValue(e.target.value)} />
              </div>
            </div>
            <div className="item">
              <div className="subject">
                <span>{t('이름')}</span>
              </div>
              <div className="input">
                <input type="text" value={nameValue} onChange={e => setNameValue(e.target.value)} />
              </div>
            </div>
            <div className="item">
              <div className="subject">
                <span>{t('조직')}</span>
              </div>
              <div className="input">
                <input
                  type="text"
                  placeholder={t('조직') || ''}
                  value={
                    memberOrg.selected === memberOrg.data.mo_key
                      ? cookies['xclass-cname'] || ''
                      : flatOrg(memberOrg.data).find(ele => ele.mo_key === memberOrg.selected)?.mo_name || ''
                  }
                  readOnly
                  className="combo"
                  onClick={() => setMemberOrgModal(true)}
                />
                <div className="arrow"></div>
              </div>
            </div>
          </div>
          <div className="btns">
            <button
              type="button"
              className="secondary"
              onClick={() => {
                setIdValue('');
                setNameValue('');
                setMemberOrgSelect(0);
                navigate('');
              }}
            >
              {t('초기화')}
            </button>
            <button
              type="button"
              className="info"
              onClick={() =>
                navigate(
                  `?${idValue ? `id=${idValue}&` : ''}${nameValue ? `name=${nameValue}&` : ''}${
                    memberOrg.selected === 0 ? '' : `org=${memberOrg.selected}&`
                  }page=1`,
                )
              }
            >
              {t('조회')}
            </button>
          </div>
        </div>
      )}
      <div className="table-tools">
        <div className="btns"></div>
        {data.filter(item => item.checked).length > 0 && (
          <div className="selected">
            {t('개의 항목을 선택했습니다.', { amount: data.filter(item => item.checked).length })}
          </div>
        )}
      </div>
      <div className="table-container">
        <table className="table">
          <thead>
            <tr>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <th></th>
              <th>{t('조직')}</th>
              <th>{t('아이디')}</th>
              <th>{t('이름')}</th>
              <th>{t('회원등급')}</th>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {getManagerListLoading && (
              <tr>
                <td colSpan={9}>
                  <div>
                    <div className="lds-spinner">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </td>
              </tr>
            )}
            {!getManagerListLoading && data.length === 0 && (
              <tr>
                <td colSpan={6}>
                  <div>{t('검색된 목록이 없습니다.')}</div>
                </td>
              </tr>
            )}
            {data.map(item => (
              <tr key={item.m_key}>
                <td>
                  <div>
                    <div
                      className={classNames(
                        'checkbox',
                        data.filter(ele => ele.m_key === item?.m_key)[0]?.checked && 'active',
                      )}
                      onClick={() => {
                        setData(
                          data.map(ele => {
                            if (ele.m_key === item.m_key) return { ...ele, checked: !ele.checked };
                            return ele;
                          }),
                        );
                      }}
                    ></div>
                  </div>
                </td>
                <td>
                  <div>
                    <span>{item.mo_name}</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>{item.m_id}</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>{item.m_name}</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>
                      {item.mg_code === 'SUPER' && (cookies['xclass-lang'] === 'ko' ? '최고관리자' : 'Super Admin')}
                      {item.mg_code === 'ADMIN' && (cookies['xclass-lang'] === 'ko' ? '관리자' : 'Admin')}
                      {item.mg_code === 'MANAGER' && (cookies['xclass-lang'] === 'ko' ? '운영자' : 'Manager')}
                      {item.mg_code === 'MEMBER' && (cookies['xclass-lang'] === 'ko' ? '회원' : 'Member')}
                    </span>
                  </div>
                </td>
                <td>
                  <div>
                    {decrypt(cookies.XID).mg_code === 'ADMIN' && item.mg_code === 'MANAGER' && (
                      <object
                        className="icon"
                        onClick={() =>
                          setMemberRole({
                            state: true,
                            m_key: item.m_key,
                            data: item.mr_code?.split(','),
                          })
                        }
                      >
                        {icMenuAccess()}
                      </object>
                    )}
                    <img
                      src={icInfo}
                      alt="icon"
                      className="icon"
                      onClick={() => setMemberInfo({ state: true, data: item })}
                    />
                    {decrypt(cookies.XID).mg_code === 'ADMIN' &&
                    (item.mg_code === 'SUPER' || item.mg_code === 'ADMIN') ? null : decrypt(cookies.XID).mg_code ===
                        'MANAGER' &&
                      (item.mg_code === 'SUPER' || item.mg_code === 'ADMIN' || item.mg_code === 'MANAGER') ? null : (
                      <>
                        <button
                          type="button"
                          className="primary"
                          onClick={() => navigate(`/${code}/member/edit?m_key=${item.m_key}`)}
                        >
                          <span>{t('수정')}</span>
                        </button>
                        <button
                          type="button"
                          className="secondary"
                          onClick={() => confirmShow('삭제하시겠습니까?', () => remove(item.m_key))}
                        >
                          <span>{t('삭제')}</span>
                        </button>
                      </>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        total={count || 0}
        curr={page === undefined ? 1 : Number(page)}
        movePage={e =>
          navigate(
            `?${id === undefined ? '' : `id=${id}&`}${name === undefined ? '' : `name=${name}&`}${
              org === undefined ? '' : `org=${org}&`
            }page=${e}`,
          )
        }
      />
      <MemberInfo />
      <MemberRole refresh={refetch} />
      <MemberOrg />
    </Page>
  );
}

export default ManagerList;
