import classNames from 'classnames';
import queryString from 'query-string';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useMutation, useQuery } from '@tanstack/react-query';
import { createError, throwError } from '../../api/error';
import {
  getLearningTalkChannel,
  getLearningTalkComment,
  getLearningTalkContents,
  getLearningTalkReportList,
  updateReport,
} from '../../api/learning';
import { useAlert } from '../../stores/alertStore';
import { reportEditState, reportInfoState } from '../../stores/learningtalkStore';

// components
import Page from '../../includes/page';
import Pagination from '../../includes/pagination copy';
import ReportEdit from '../../components/learningtalk/reportEdit';
import ReportInfo from '../../components/learningtalk/reportInfo';

function LearningTalkList() {
  const { t } = useTranslation();
  const { alertShow } = useAlert();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { page } = queryString.parse(search);
  const [count, setCount] = useState(0);
  const [data, setData] = useState<ILearningtalkReport[]>([]);
  const { isFetching: getLearningTalkReportListLoading, refetch } = useQuery<{
    count: number;
    data: ILearningtalkReport[];
  }>(['getLearningTalkReportList', page], () => getLearningTalkReportList(page === undefined ? 1 : Number(page)), {
    refetchOnWindowFocus: false,
    onSuccess: e => {
      setCount(e.count);
      setData(e.data.map(item => ({ ...item, checked: false })));
      if (e.count > 0 && e.data.length === 0 && page !== undefined && Number(page) > 1) navigate('');
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (e: any) => alertShow(e.response.data.message),
  });

  const [reportInfo, setReoprtInfo] = useRecoilState(reportInfoState);
  const [ltKey, setLtKey] = useState(0);
  const { isFetching: getLearningTalkChannelLoading } = useQuery<{
    data: ILearningtalkChannel;
  }>(['getLearningTalkChannel', ltKey], () => getLearningTalkChannel(ltKey), {
    refetchOnWindowFocus: false,
    enabled: ltKey !== 0,
    onSuccess: e => {
      setReoprtInfo({ ...reportInfo, channelData: e.data });
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (e: any) => alertShow(e.response.data.message),
  });
  const [ltcKey, setLtcKey] = useState(0);
  const { isFetching: getLearningTalkContentsLoading } = useQuery<{
    data: ILearningtalkContents;
  }>(['getLearningTalkContents', ltcKey], () => getLearningTalkContents(ltcKey), {
    refetchOnWindowFocus: false,
    enabled: ltcKey !== 0,
    onSuccess: e => {
      setReoprtInfo({ ...reportInfo, contentsData: e.data });
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (e: any) => alertShow(e.response.data.message),
  });
  const [ltccKey, setLtccKey] = useState(0);
  const { isFetching: getLearningTalkCommentLoading } = useQuery<{
    data: ILearningtalkComment;
  }>(['ILearningtalkComment', ltccKey], () => getLearningTalkComment(ltccKey), {
    refetchOnWindowFocus: false,
    enabled: ltccKey !== 0,
    onSuccess: e => {
      setReoprtInfo({ ...reportInfo, commentData: e.data });
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (e: any) => alertShow(e.response.data.message),
  });

  const setReportEdit = useSetRecoilState(reportEditState);
  const { mutate: update, isLoading: updateLoading } = useMutation(updateReport, {
    onSuccess: () => refetch(),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (e: any) => {
      createError({
        type: '[ADMIN] learningtalk - updateReport',
        message: `message: "${e.message}",\nresponse: { status: ${e.response.status}, message: "${e.response.data.message}" }`,
      });
      throwError(e);
    },
  });

  return (
    <Page
      title="러닝톡톡신고목록"
      isLoading={
        getLearningTalkChannelLoading ||
        getLearningTalkContentsLoading ||
        getLearningTalkCommentLoading ||
        updateLoading
      }
    >
      <div className="table-tools"></div>
      <div className="table-container">
        <table className="table">
          <thead>
            <tr>
              <th>{t('카테고리')}</th>
              <th>{t('작성자')}</th>
              <th>{t('유형')}</th>
              <th>{t('내용')}</th>
              <th>{t('상태')}</th>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {getLearningTalkReportListLoading && (
              <tr>
                <td colSpan={9}>
                  <div>
                    <div className="lds-spinner">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </td>
              </tr>
            )}
            {!getLearningTalkReportListLoading && data.length === 0 && (
              <tr>
                <td colSpan={9}>
                  <div>{t('검색된 목록이 없습니다.')}</div>
                </td>
              </tr>
            )}
            {data.map(item => (
              <tr key={item.ltr_key}>
                <td>
                  <div>
                    <span>{item.ltr_category}</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>{item.m_name}</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>
                      {item.lt_key && t('채널')}
                      {item.ltc_key && t('콘텐츠')}
                      {item.ltcc_key && t('댓글')}
                    </span>
                  </div>
                </td>
                <td>
                  <div className="title">
                    <span
                      className="link"
                      style={{
                        opacity: item.ltr_status === 'COMPLETED' ? '0.25' : '',
                        pointerEvents: item.ltr_status === 'COMPLETED' ? 'none' : 'auto',
                      }}
                      onClick={() => {
                        if (item.ltr_status === 'COMPLETED') {
                          alertShow('삭제된 개체입니다.');
                          return;
                        }
                        setReoprtInfo({
                          state: true,
                          type: item.lt_key ? 'channel' : item.ltc_key ? 'contents' : 'comment',
                        });
                        if (item.lt_key) setLtKey(item.lt_key);
                        if (item.ltc_key) setLtcKey(item.ltc_key);
                        if (item.ltcc_key) setLtccKey(item.ltcc_key);
                      }}
                    >
                      {item.ltr_description}
                    </span>
                  </div>
                </td>
                <td>
                  <div>
                    {item.ltr_status === 'WAITING' && (
                      <span
                        style={{
                          backgroundColor: 'var(--success)',
                          padding: '2px 6px',
                          borderRadius: '4px',
                          fontSize: '11px',
                          color: 'white',
                        }}
                      >
                        {t('대기')}
                      </span>
                    )}
                    {item.ltr_status === 'PENDDING' && (
                      <span
                        style={{
                          backgroundColor: 'var(--warning)',
                          padding: '2px 6px',
                          borderRadius: '4px',
                          fontSize: '11px',
                          color: 'white',
                        }}
                      >
                        {t('보류')}
                      </span>
                    )}
                    {item.ltr_status === 'REJECT' && (
                      <span
                        style={{
                          backgroundColor: 'var(--danger)',
                          padding: '2px 6px',
                          borderRadius: '4px',
                          fontSize: '11px',
                          color: 'white',
                        }}
                      >
                        {t('거절')}
                      </span>
                    )}
                    {item.ltr_status === 'COMPLETED' && (
                      <span
                        style={{
                          backgroundColor: 'var(--greyish6)',
                          padding: '2px 6px',
                          borderRadius: '4px',
                          fontSize: '11px',
                          color: 'white',
                        }}
                      >
                        {t('삭제')}
                      </span>
                    )}
                  </div>
                </td>
                <td>
                  <div>
                    <button
                      type="button"
                      className={classNames('primary', item.ltr_status === 'COMPLETED' && 'disabled')}
                      onClick={() => {
                        if (item.ltr_status === 'COMPLETED') return;
                        setReportEdit({ state: true, lrKey: item.ltr_key, status: 'PENDDING' });
                      }}
                    >
                      <span>{t('처리')}</span>
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        total={count || 0}
        curr={page === undefined ? 1 : Number(page)}
        movePage={e => navigate(`?page=${e}`)}
      />
      <ReportEdit update={update} />
      <ReportInfo
        init={() => {
          setLtKey(0);
          setLtcKey(0);
          setLtccKey(0);
        }}
      />
    </Page>
  );
}

export default LearningTalkList;
