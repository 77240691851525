import dayjs from 'dayjs';
import queryString from 'query-string';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getDemoHistoryList } from '../../api/customer';
import { useAlert } from '../../stores/alertStore';

// components
import Page from '../../includes/page';

// img
import Pagination from '../../includes/pagination copy';

function DemoHistory() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { page } = queryString.parse(search);
  const { alertShow } = useAlert();
  const [count, setCount] = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [data, setData] = useState<any[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { isFetching: getDemoHistoryListLoading } = useQuery<{ count: number; data: any[] }>(
    ['getDemoHistoryList', page],
    () => getDemoHistoryList(page === undefined ? 1 : Number(page)),
    {
      refetchOnWindowFocus: false,
      onSuccess: e => {
        setCount(e.count);
        setData(e.data.map(item => ({ ...item, checked: false })));
        if (e.count > 0 && e.data.length === 0 && page !== undefined && Number(page) > 1) navigate('');
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (e: any) => alertShow(e.response.data.message),
    },
  );

  return (
    <Page title="이전데모신청목록">
      <div className="table-tools"></div>
      <div className="table-container">
        <table className="table">
          <thead>
            <tr>
              <th>{t('아이디')}</th>
              <th>{t('고객사')}</th>
              <th>{t('이름')}</th>
              <th>{t('연락처')}</th>
              <th>{t('이메일')}</th>
              <th>{t('직원수')}</th>
              <th>{t('업종')}</th>
              <th>{t('문의사항')}</th>
              <th>{t('날짜')}</th>
              <th>{t('상태')}</th>
            </tr>
          </thead>
          <tbody>
            {getDemoHistoryListLoading && (
              <tr>
                <td colSpan={9}>
                  <div>
                    <div className="lds-spinner">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </td>
              </tr>
            )}
            {!getDemoHistoryListLoading && data.length === 0 && (
              <tr>
                <td colSpan={8}>
                  <div>{t('검색된 목록이 없습니다.')}</div>
                </td>
              </tr>
            )}
            {data.map(item => (
              <tr key={item.d_key}>
                <td>
                  <div>
                    <span>{item.d_user_id}</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span
                    // className="link"
                    // onClick={() => navigate(`/${code}/enquiry/detail?e_key=${item.e_key}`)}
                    >
                      {item.d_company}
                    </span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>{item.d_name}</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>{item.d_tel}</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>{item.d_email}</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>{item.d_emp_count}</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>{item.d_sector}</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>{item.d_question}</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>{dayjs(item.created_at).format('YYYY-MM-DD HH:mm')}</span>
                  </div>
                </td>
                <td>
                  <div>{item.d_status === 'reject' ? t('거절') : t('승인')}</div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        total={count || 0}
        curr={page === undefined ? 1 : Number(page)}
        movePage={e => navigate(`?page=${e}`)}
      />
    </Page>
  );
}

export default DemoHistory;
