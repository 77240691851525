import dayjs from 'dayjs';
import queryString from 'query-string';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getPushList } from '../../api/push';
import { useAlert } from '../../stores/alertStore';

// components
import Page from '../../includes/page';
import Pagination from '../../includes/pagination copy';

function Pushalarm() {
  const { t } = useTranslation();
  const { alertShow } = useAlert();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { page } = queryString.parse(search);
  const { code } = useParams();
  const [count, setCount] = useState(0);
  const [data, setData] = useState<IPush[]>([]);
  const { isFetching: getPushListLoading } = useQuery<{
    count: number;
    data: IPush[];
  }>(['getPushList', page], () => getPushList(page === undefined ? 1 : Number(page)), {
    refetchOnWindowFocus: false,
    onSuccess: e => {
      setCount(e.count);
      setData(e.data.map(item => ({ ...item, checked: false })));
      if (e.count > 0 && e.data.length === 0 && page !== undefined && Number(page) > 1) navigate('');
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (e: any) => alertShow(e.response.data.message),
  });

  return (
    <Page title="푸시알림기록" isLoading={getPushListLoading}>
      <div className="table-tools">
        <div className="btns">
          <button type="button" className="primary" onClick={() => navigate(`/${code}/push/edit`)}>
            <span>{t('등록')}</span>
          </button>
        </div>
      </div>
      <div className="table-container">
        <table className="table">
          <thead>
            <tr>
              <th>{t('고객정보')}</th>
              <th>{t('제목')}</th>
              <th>{t('내용')}</th>
              <th>{t('등록시간')}</th>
            </tr>
          </thead>
          <tbody>
            {data.length === 0 && (
              <tr>
                <td colSpan={6}>
                  <div>{t('검색된 목록이 없습니다.')}</div>
                </td>
              </tr>
            )}
            {data.map(item => (
              <tr key={item.ph_key}>
                <td>
                  <div>
                    <span>{item.ph_type === 'TOPIC' ? t('전체') : `[${item.m_id}] ${item.m_name}`}</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>{item.ph_title}</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>{item.ph_body}</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>{dayjs(item.created_at).format('YYYY-MM-DD HH:mm')}</span>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        total={count || 0}
        curr={page === undefined ? 1 : Number(page)}
        movePage={e => navigate(`?page=${e}`)}
      />
    </Page>
  );
}

export default Pushalarm;
