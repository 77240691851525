import classNames from 'classnames';
import dayjs from 'dayjs';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { useMutation, useQuery } from '@tanstack/react-query';
import { createError, throwError } from '../../api/error';
import { deleteVod, getVodList } from '../../api/vod';
import { useAlert } from '../../stores/alertStore';
import { useConfirm } from '../../stores/confirmStore';
import { logModalState } from '../../stores/logStore';
import { millisecondsToTime } from '../../assets/util/millisecondsToTime';

// components
import Page from '../../includes/page';
import Pagination from '../../includes/pagination copy';
import LogModal from '../../components/log/logModal';

// img
import { icSearch } from '../../components/aside/icon';
import Image from '../../components/vod/thumbnail';

function VodList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { page, str } = queryString.parse(search);
  const { code } = useParams();
  const { alertShow } = useAlert();
  const { confirmShow } = useConfirm();
  const [searchStr, setSearchStr] = useState('');
  const [count, setCount] = useState(0);
  const [data, setData] = useState<IVod[]>([]);
  const { isFetching: getVodListLoading, refetch } = useQuery<{ count: number; data: IVod[] }>(
    ['getVodList', page, str],
    () => getVodList({ str: str === undefined ? '' : String(str), page: page === undefined ? 1 : Number(page) }),
    {
      refetchOnWindowFocus: false,
      onSuccess: e => {
        setCount(e.count);
        setData(e.data.map(item => ({ ...item, checked: false })));
        if (e.count > 0 && e.data.length === 0 && page !== undefined && Number(page) > 1)
          navigate(`${str === undefined ? '' : `?str=${str}`}`);
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (e: any) => alertShow(e.response.data.message),
    },
  );
  const { mutate: remove, isLoading: deleteLoading } = useMutation(deleteVod, {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (e: any) => {
      createError({
        type: '[ADMIN] vod - deleteVod',
        message: `message: "${e.message}",\nresponse: { status: ${e.response.status}, message: "${e.response.data.message}" }`,
      });
      throwError(e);
    },
  });

  const selectDelete = () =>
    confirmShow('선택한 항목을 삭제하시겠습니까?', () => {
      const checked = data.filter(item => item.checked);
      for (let i = 0; i < checked.length; i += 1) remove(checked[i].vod);
    });

  useEffect(() => {
    if (!deleteLoading) refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteLoading]);

  const allSelect = () =>
    setData(
      data.map(item => ({
        ...item,
        checked: data.filter(item => item.checked).length !== data.length,
      })),
    );

  const setLogModal = useSetRecoilState(logModalState);

  return (
    <Page title="VOD목록" isLoading={deleteLoading}>
      <div className="table-tools">
        <div className="btns">
          <button type="button" className="danger" onClick={allSelect}>
            <span>{t('전체선택')}</span>
          </button>
          <button type="button" className="primary" onClick={() => navigate(`/${code}/vod/edit`)}>
            <span>{t('등록')}</span>
          </button>
          <button
            type="button"
            className={classNames('secondary', data.filter(item => item.checked).length === 0 && 'disabled')}
            onClick={selectDelete}
          >
            <span>{t('선택삭제')}</span>
          </button>
        </div>
        {data.filter(item => item.checked).length > 0 && (
          <div className="selected">
            {t('개의 항목을 선택했습니다.', { amount: data.filter(item => item.checked).length })}
          </div>
        )}
      </div>
      <div className="search">
        <div className="inner">
          <input
            type="text"
            placeholder={t('검색') || ''}
            value={searchStr}
            onChange={e => setSearchStr(e.target.value)}
            onKeyPress={e => {
              if (e.key === 'Enter' || e.code === 'Enter')
                navigate(`?page=${page === undefined ? 1 : Number(page)}${!searchStr ? '' : `&str=${searchStr}`}`);
            }}
          />
          <object
            onClick={() =>
              navigate(`?page=${page === undefined ? 1 : Number(page)}${!searchStr ? '' : `&str=${searchStr}`}`)
            }
          >
            {icSearch()}
          </object>
        </div>
      </div>
      <div className="table-container">
        <table className="table">
          <thead>
            <tr>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <th></th>
              <th>{t('섬네일')}</th>
              <th>{t('상태')}</th>
              <th>{t('제목')}</th>
              <th>{t('작성자')}</th>
              <th>{t('상태')}</th>
              <th>{t('기간')}</th>
              <th>{t('좋아요')}</th>
              <th>{t('조회수')}</th>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {getVodListLoading && (
              <tr>
                <td colSpan={9}>
                  <div>
                    <div className="lds-spinner">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </td>
              </tr>
            )}
            {!getVodListLoading && data.length === 0 && (
              <tr>
                <td colSpan={9}>
                  <div>{t('검색된 목록이 없습니다.')}</div>
                </td>
              </tr>
            )}
            {data.map(item => (
              <tr key={item.vod}>
                <td>
                  <div>
                    <div
                      className={classNames(
                        'checkbox',
                        data.filter(ele => ele.vod === item?.vod)[0]?.checked && 'active',
                      )}
                      onClick={() => {
                        setData(
                          data.map(ele => {
                            if (ele.vod === item.vod) return { ...ele, checked: !ele.checked };
                            return ele;
                          }),
                        );
                      }}
                    ></div>
                  </div>
                </td>
                <td>
                  <div>
                    <div className="thumbnail">
                      <div
                        className="thumbnail__inner"
                        style={{ backgroundColor: !item.thumbnail ? 'var(--primary)' : '' }}
                      >
                        {item.thumbnail &&
                          (item.thumbnail.includes('thumb-') ? (
                            <Image src={`${process.env.REACT_APP_S3_API}/asset/${item.thumbnail}`} />
                          ) : (
                            <Image src={`${process.env.REACT_APP_UPLOADED_URL}/${item.thumbnail}`} />
                          ))}
                        {!item.thumbnail &&
                          (item.status === 'complete' ? (
                            <Image
                              src={`${process.env.REACT_APP_S3_API}/asset/xclass-vod/${code}/${item.url}/thumbnail/thumb-0.png`}
                            />
                          ) : (
                            <Image
                              src={`${process.env.REACT_APP_UPLOADED_URL}/xclass/image/customer/${code}_DARK_LOGO`}
                              cn="logo"
                            />
                          ))}
                        <div className="time">{millisecondsToTime(item.duration)}</div>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    {item.status === 'wait' && (
                      <span
                        style={{
                          backgroundColor: 'var(--success)',
                          padding: '2px 6px',
                          borderRadius: '4px',
                          fontSize: '11px',
                          color: 'white',
                        }}
                      >
                        {t('대기')}
                      </span>
                    )}
                    {item.status === 'transcoding' && (
                      <span
                        style={{
                          backgroundColor: 'var(--warning)',
                          padding: '2px 6px',
                          borderRadius: '4px',
                          fontSize: '11px',
                          color: 'white',
                        }}
                      >
                        {t('변환중')}
                      </span>
                    )}
                    {item.status === 'complete' && (
                      <span
                        style={{
                          backgroundColor: 'var(--greyish6)',
                          padding: '2px 6px',
                          borderRadius: '4px',
                          fontSize: '11px',
                          color: 'white',
                        }}
                      >
                        {t('완료')}
                      </span>
                    )}
                    {item.status === 'error' && (
                      <span
                        style={{
                          backgroundColor: 'var(--danger)',
                          padding: '2px 6px',
                          borderRadius: '4px',
                          fontSize: '11px',
                          color: 'white',
                        }}
                      >
                        {t('에러')}
                      </span>
                    )}
                  </div>
                </td>
                <td>
                  <div className="title">
                    <span
                      className="link"
                      onClick={() => {
                        if (item.status === 'complete') {
                          navigate(`/${code}/vod/detail?key=${item.vod}`);
                        } else {
                          alertShow(
                            item.status === 'ready'
                              ? '변환 대기 중입니다.'
                              : item.status === 'error'
                              ? '변환 오류'
                              : '변환 중입니다.',
                          );
                        }
                      }}
                    >
                      {item.title}
                    </span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>{item.creatorName}</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>{item.public === 1 ? t('공개') : t('비공개')}</span>
                  </div>
                </td>
                <td>
                  <div className="left">
                    <span>
                      {dayjs(item.startDate).format('YYYY-MM-DD HH:mm')}
                      {' ~\n'}
                      {dayjs(item.endDate).format('YYYY-MM-DD HH:mm')}
                    </span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>{item.likeCount}</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>{item.viewCount}</span>
                  </div>
                </td>
                <td>
                  <div>
                    <button
                      type="button"
                      className="info"
                      onClick={() =>
                        setLogModal(prev => ({
                          ...prev,
                          state: true,
                          sdate: dayjs(item.startDate).format('YYYY-MM-DD'),
                          edate: dayjs(item.endDate).format('YYYY-MM-DD'),
                          element: {
                            vodKey: item.vod,
                            fileName: `vod_${item.title}`,
                          },
                        }))
                      }
                    >
                      <span>{t('로그')}</span>
                    </button>
                    <button
                      type="button"
                      className="primary"
                      onClick={() => navigate(`/${code}/vod/edit?key=${item.vod}`)}
                    >
                      <span>{t('수정')}</span>
                    </button>
                    <button
                      type="button"
                      className="secondary"
                      onClick={() => confirmShow('삭제하시겠습니까?', () => remove(item.vod))}
                    >
                      <span>{t('삭제')}</span>
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        total={count || 0}
        curr={page === undefined ? 1 : Number(page)}
        movePage={e => navigate(`?page=${e}${str === undefined ? '' : `&str=${String(str)}`}`)}
      />
      <LogModal />
    </Page>
  );
}

export default VodList;
