import dayjs from 'dayjs';
import queryString from 'query-string';
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Viewer } from '@toast-ui/react-editor';
import { getEnquiry } from '../../api/customer';

// components
import Page from '../../includes/page';

function EnquiryDetail() {
  const { t } = useTranslation();
  const [cookies] = useCookies(['xclass-theme']);
  const { search } = useLocation();
  const { e_key } = queryString.parse(search);

  const [data, setData] = useState<IEnquiry | null>(null);
  const [description, setDescription] = useState('');

  const { isFetching: getEnquiryLoading } = useQuery<{ data: IEnquiry }>(
    ['getEnquiry'],
    () => getEnquiry(Number(e_key)),
    {
      refetchOnWindowFocus: false,
      enabled: e_key !== undefined,
      onSuccess: e => {
        setData(e.data);
        setDescription(e.data.e_text);
      },
    },
  );

  return (
    <Page title="문의사항" goBack="/enquiry" isLoading={getEnquiryLoading}>
      <div className="etc-info">
          <div className="item">{dayjs(data?.created_at).format('YYYY-MM-DD HH:mm')}</div>
      </div>
      <div className="viewer">
        <div className="infomation">
          {`- ${t('고객사')}: ${data?.e_customer}\n- ${t('이름')}: ${data?.e_name}\n- ${t('연락처')}: ${
            data?.e_phone
          }\n- ${t('이메일')}: ${data?.e_email}\n- ${t('직원수')}: ${data?.e_employee}\n- ${t('업종')}: ${
            data?.e_business
          }`}
        </div>
        {description && <Viewer initialValue={description} theme={cookies['xclass-theme']} />}
      </div>
    </Page>
  );
}

export default EnquiryDetail;
