import { useRef, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useRecoilValue } from 'recoil';
import { foldState } from '../stores/layoutStore';

// style
import styles from './main.module.scss';

function Main({ children }: { children: React.ReactNode }) {
  const [cookies] = useCookies(['xclass-theme']);
  const fold = useRecoilValue(foldState);
  const mainRef = useRef<HTMLDivElement>(null);
  useEffect(() => mainRef.current?.scrollTo(0, 0), [fold]);
  return (
    <div className={styles.container} ref={mainRef} data-theme={cookies['xclass-theme']}>
      {children}
    </div>
  );
}

export default Main;
