import axios from 'axios';
import classNames from 'classnames';
import queryString from 'query-string';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { createError, throwError } from '../../api/error';
import { createEvaluationQuestion, editEvaluationQuestion, getEvaluationQuestion } from '../../api/evaluation';
import { useAlert } from '../../stores/alertStore';
import { useConfirm } from '../../stores/confirmStore';
import { evalStore } from '../../stores/evaluationZustandStore';

// components
import Page from '../../includes/page';
import FormItem from '../../components/basic/formItem';
import CategoryModal from '../../components/board/category';
import QuestionAnswerItem from '../../components/evaluation/questionAnswerItem';

// img
import imgO from '../../assets/img/images/img_o.svg';
import imgOActive from '../../assets/img/images/img_o_active.svg';
import imgX from '../../assets/img/images/img_x.svg';
import imgXActive from '../../assets/img/images/img_x_active.svg';
import { icDelete, icAdd, icUploadFile, icClose } from '../../components/aside/icon';
import { loadingState } from '../../stores/loadingStore';

const api = process.env.REACT_APP_API;

function EvaluationQuestionEdit() {
  const { t } = useTranslation();
  const { code } = useParams();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { eq_key } = queryString.parse(search);
  const { alertShow } = useAlert();
  const { confirmShow } = useConfirm();
  const {
    type,
    setType,
    path,
    setPath,
    uploadList,
    setUploadList,
    answerList,
    setAnswerList,
    addAnswerList,
    editAnswerList,
    setCorrectAnswerOX,
    setSaText,
  } = evalStore();

  const [fileType, setFileType] = useState('');
  const [filePreview, setFilePreview] = useState('');
  const [file, setFile] = useState<File | null>(null);
  const [title, setTitle] = useState('');
  const [contents, setContents] = useState('');
  const [difficulty, setDifficulty] = useState(1);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { isFetching: getEvaluationQuestionLoading } = useQuery<{ data: any }>(
    ['eqDetail'],
    () => getEvaluationQuestion(Number(eq_key)),
    {
      refetchOnWindowFocus: false,
      enabled: eq_key !== undefined,
      onSuccess: e => {
        setTitle(e.data?.eq_title || '');
        setContents(e.data?.eq_contents || '');
        if (e.data?.eq_image_path) {
          setPath(e.data.eq_image_path);
          setFileType('image');
          setFilePreview(`${process.env.REACT_APP_UPLOADED_URL}/${e.data.eq_image_path}`);
        }
        if (e.data?.eq_video_path) {
          setPath(e.data.eq_video_path);
          setFileType('video');
          setFilePreview(`${process.env.REACT_APP_UPLOADED_URL}/${e.data.eq_video_path}`);
        }
        setDifficulty(e.data?.eq_difficulty || 0);
        setType(e.data?.eq_type || 'MC');
        setAnswerList(e.data?.evaluationQuestionAnswerList || []);
      },
    },
  );

  const { mutate: create, isLoading: createLoading } = useMutation(createEvaluationQuestion, {
    onSuccess: () => navigate(`/${code}/evaluation/question`),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (e: any) => {
      setType('MC');
      setPath('');
      setUploadList([]);
      setAnswerList([
        { eqa_key: -1, eqa_text: '', eqa_image_path: '', eqa_correct: 'N' },
        { eqa_key: -2, eqa_text: '', eqa_image_path: '', eqa_correct: 'N' },
      ]);
      setFileType('');
      setFilePreview('');
      setFile(null);
      setTitle('');
      setContents('');
      setDifficulty(1);
      createError({
        type: '[ADMIN] evaluation - createEvaluationQuestion',
        message: `message: "${e.message}",\nresponse: { status: ${e.response.status}, message: "${e.response.data.message}" }`,
      });
      throwError(e);
    },
  });

  const { mutate: update, isLoading: updateLoading } = useMutation(editEvaluationQuestion, {
    onSuccess: () => navigate(`/${code}/evaluation/question`),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (e: any) => {
      setType('MC');
      setPath('');
      setUploadList([]);
      setAnswerList([
        { eqa_key: -1, eqa_text: '', eqa_image_path: '', eqa_correct: 'N' },
        { eqa_key: -2, eqa_text: '', eqa_image_path: '', eqa_correct: 'N' },
      ]);
      setFileType('');
      setFilePreview('');
      setFile(null);
      setTitle('');
      setContents('');
      setDifficulty(1);
      createError({
        type: '[ADMIN] evaluation - editEvaluationQuestion',
        message: `message: "${e.message}",\nresponse: { status: ${e.response.status}, message: "${e.response.data.message}" }`,
      });
      throwError(e);
    },
  });

  const complete = () => {
    if (eq_key) {
      let answer = '';
      if (type === 'MC')
        answer = evalStore
          .getState()
          .answerList.map((item, idx) => ({ idx: idx + 1, ...item }))
          .filter(item => item.eqa_correct === 'Y')
          .map(item => item.idx)
          .join('|');
      else answer = evalStore.getState().answerList.find(item => item.eqa_correct === 'Y')?.eqa_text || '';
      update({
        eq_key: Number(eq_key),
        data: {
          title,
          imagePath: fileType === 'image' ? evalStore.getState().path : null,
          videoPath: fileType === 'video' ? evalStore.getState().path : null,
          contents,
          type,
          answer,
          difficulty,
          answerList: evalStore.getState().answerList.map(item => ({
            answerKey: item.eqa_key >= 0 ? item.eqa_key : undefined,
            text: item.eqa_text,
            correct: item.eqa_correct,
            imagePath: item.eqa_image_path,
          })),
        },
      });
    } else {
      let answer = '';
      if (type === 'MC')
        answer = evalStore
          .getState()
          .answerList.map((item, idx) => ({ idx: idx + 1, ...item }))
          .filter(item => item.eqa_correct === 'Y')
          .map(item => item.idx)
          .join('|');
      else answer = evalStore.getState().answerList.find(item => item.eqa_correct === 'Y')?.eqa_text || '';
      create({
        title,
        imagePath: fileType === 'image' ? evalStore.getState().path : '',
        videoPath: fileType === 'video' ? evalStore.getState().path : '',
        contents,
        type,
        answer,
        difficulty,
        answerList: evalStore.getState().answerList.map(item => ({
          text: item.eqa_text,
          correct: item.eqa_correct,
          imagePath: item.eqa_image_path,
        })),
      });
    }
    setLoading(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formData = (_fields: any, _file: any) => {
    const data = new FormData();
    Object.keys(_fields).forEach(key => data.append(key, _fields[key]));
    if (_file) {
      data.append('file', _file);
    }
    return data;
  };

  const setLoading = useSetRecoilState(loadingState);

  const uploadUrl = async () => {
    try {
      const { data } = await axios.post(`${api}/admin/evaluation/upload`, {}, { withCredentials: true });
      return data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(e);
      setLoading(false);
      return null;
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fileUpload = async (url: string, form: any, type?: string, key?: number) => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data = (await axios.post(url, form)) as any;
      switch (type) {
        case 'problem':
          if (data.status === 204) {
            if (
              evalStore.getState().uploadList.length ===
              evalStore.getState().uploadList.filter(item => item.state).length + 1
            )
              complete();
            evalStore.getState().editUploadList(999999999, { state: true });
          } else {
            evalStore.getState().setUploadList(uploadList.filter(item => item.key !== 999999999));
            setPath('');
            setFileType('');
            setFilePreview('');
            setFile(null);
            alertShow('업로드에 실패했습니다.');
            setLoading(false);
          }
          break;
        case 'choice':
          if (data.status === 204) {
            if (
              evalStore.getState().uploadList.length ===
              evalStore.getState().uploadList.filter(item => item.state).length + 1
            )
              complete();
            evalStore.getState().editUploadList(Number(key), { state: true });
          } else {
            evalStore.getState().setUploadList(uploadList.filter(item => item.key !== key));
            setAnswerList(
              answerList.map(item => ({
                eqa_key: item.eqa_key,
                eqa_text: item.eqa_text,
                eqa_image_path: '',
                eqa_correct: item.eqa_correct,
              })),
            );
            alertShow('업로드에 실패했습니다.');
            setLoading(false);
          }
          break;
        default:
          break;
      }
    } catch (error) {
      evalStore.getState().setUploadList(uploadList.filter(item => item.key !== 999999999));
      setPath('');
      setFileType('');
      setFilePreview('');
      setFile(null);
      evalStore.getState().setUploadList(uploadList.filter(item => item.key !== key));
      setAnswerList(
        answerList.map(item => ({
          eqa_key: item.eqa_key,
          eqa_text: item.eqa_text,
          eqa_image_path: '',
          eqa_correct: item.eqa_correct,
        })),
      );
      alertShow('업로드에 실패했습니다.');
      setLoading(false);
    }
  };

  const upload = async () => {
    setLoading(true);
    if (evalStore.getState().uploadList.length === 0) {
      complete();
      return;
    }
    if (file) {
      const { data } = await uploadUrl();
      evalStore.getState().setPath(data.name);
      setTimeout(() => {
        fileUpload(data.url.url, formData(data.url.fields, file), 'problem');
      }, 0);
    }
    if (answerList.filter(item => item.img).length > 0) {
      answerList
        .filter(item => item.img)
        .forEach(async item => {
          const { data } = await uploadUrl();
          editAnswerList(item.eqa_key, { eqa_image_path: data.name });
          setTimeout(() => {
            fileUpload(data.url.url, formData(data.url.fields, item.img), 'choice', item.eqa_key);
          }, 0);
        });
    }
  };

  useEffect(() => {
    // init
    return () => {
      setType('MC');
      setPath('');
      setUploadList([]);
      setAnswerList([
        { eqa_key: 0, eqa_text: '', eqa_image_path: '', eqa_correct: 'N' },
        { eqa_key: 1, eqa_text: '', eqa_image_path: '', eqa_correct: 'N' },
      ]);
      setFileType('');
      setFilePreview('');
      setFile(null);
      setTitle('');
      setContents('');
      setDifficulty(1);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page
      title={eq_key ? '평가문제수정' : '평가문제등록'}
      isLoading={getEvaluationQuestionLoading || createLoading || updateLoading}
    >
      <FormItem title="제목" essential>
        <div className="input">
          <input type="text" placeholder={t('제목') || ''} value={title} onChange={e => setTitle(e.target.value)} />
        </div>
      </FormItem>
      <FormItem title="내용" essential>
        {filePreview && (
          <div className="preview">
            {fileType === 'image' && <img src={filePreview} alt="preview" className="preview-img" />}
            {fileType === 'video' && (
              <video src={filePreview} playsInline controls muted className="preview-img"></video>
            )}
            <object
              className="preview-delete"
              onClick={() => {
                setFileType('');
                setFilePreview('');
                setFile(null);
                setPath('');
              }}
            >
              {icClose('#ffffff')}
            </object>
          </div>
        )}
        <div className="input">
          <div className="upload">
            <label htmlFor="upload" className="label">
              <input
                type="file"
                id="upload"
                accept="image/png, image/jpeg, image/jpg, video/*"
                onChange={e => {
                  if (e.target.files && e.target.files.length > 0) {
                    const result = e.target.files[0];
                    if (result.type.split('/')[0] === 'image' && result?.size > 1000000) {
                      alertShow('이미지 파일은 최대 1MB까지만 등록가능합니다.');
                      return;
                    }
                    if (result.type.split('/')[0] === 'video' && result?.size > 30000000) {
                      alertShow('동영상 파일은 최대 30MB까지만 등록가능합니다.');
                      return;
                    }
                    setFileType(result.type.split('/')[0]);
                    setUploadList(uploadList.concat({ type: 'problem', key: 999999999, state: false }));
                    const reader = new FileReader();
                    reader.readAsDataURL(result);
                    reader.onloadend = () => {
                      setFilePreview(reader.result ? String(reader.result) : '');
                      setFile(result);
                    };
                  }
                }}
              />
              {icUploadFile('var(--greyish5)')}
              <span>{(file && file.name) || path || t('업로드')}</span>
            </label>
            {file && (
              <object
                className="delete"
                onClick={() => {
                  setFileType('');
                  setFilePreview('');
                  setFile(null);
                  setPath('');
                }}
              >
                {icClose('var(--greyish5)')}
              </object>
            )}
          </div>
        </div>
        <div className="input">
          <textarea
            placeholder={t('내용') || ''}
            value={contents}
            onChange={e => setContents(e.target.value)}
          ></textarea>
        </div>
      </FormItem>
      <FormItem title="난이도" essential>
        <div className="input">
          <select value={difficulty} onChange={e => setDifficulty(Number(e.target.value))}>
            <option value={1}>{t('하')}</option>
            <option value={2}>{t('중')}</option>
            <option value={3}>{t('상')}</option>
          </select>
          <div className="arrow"></div>
        </div>
      </FormItem>
      <FormItem title="유형" essential>
        <div className="input">
          <select
            value={type}
            onChange={e => {
              setType(e.target.value);
              if (e.target.value === 'MC')
                setAnswerList([
                  {
                    eqa_key: 0,
                    eqa_text: '',
                    eqa_image_path: '',
                    eqa_correct: 'N',
                  },
                  {
                    eqa_key: 1,
                    eqa_text: '',
                    eqa_image_path: '',
                    eqa_correct: 'N',
                  },
                ]);
              if (e.target.value === 'OX')
                setAnswerList([
                  {
                    eqa_key: 0,
                    eqa_text: 'o',
                    eqa_image_path: '',
                    eqa_correct: 'N',
                  },
                  {
                    eqa_key: 1,
                    eqa_text: 'x',
                    eqa_image_path: '',
                    eqa_correct: 'N',
                  },
                ]);
              if (e.target.value === 'SA')
                setAnswerList([
                  {
                    eqa_key: 0,
                    eqa_text: '',
                    eqa_image_path: '',
                    eqa_correct: 'Y',
                  },
                ]);
            }}
          >
            <option value="MC">{t('객관식')}</option>
            <option value="OX">OX</option>
            <option value="SA">{t('주관식')}</option>
          </select>
          <div className="arrow"></div>
        </div>
      </FormItem>
      <FormItem title="보기" essential>
        {type === 'MC' && (
          <div className="mcList">
            {answerList.map((item, idx) => (
              <QuestionAnswerItem key={item.eqa_key} idx={idx} item={item} />
            ))}
            {answerList.length < 5 && (
              <div className="item">
                <div
                  className="add"
                  onClick={() =>
                    addAnswerList({ eqa_key: -answerList.length, eqa_text: '', eqa_image_path: '', eqa_correct: 'N' })
                  }
                >
                  {icAdd()}
                </div>
              </div>
            )}
          </div>
        )}
        {type === 'OX' && (
          <div className="oxList">
            {answerList.map((item, idx) => (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={item.eqa_key}
                className={classNames('item', item.eqa_correct === 'Y' && 'active')}
                onClick={() => setCorrectAnswerOX(item.eqa_key)}
              >
                {idx === 0 ? (
                  <img src={item?.eqa_correct === 'Y' ? imgOActive : imgO} alt="icon" />
                ) : (
                  <img src={item?.eqa_correct === 'Y' ? imgXActive : imgX} alt="icon" />
                )}
              </div>
            ))}
          </div>
        )}
        {type === 'SA' && (
          <div className="sa">
            {answerList[0].eqa_text.split('|').map((item, idx) => (
              // eslint-disable-next-line react/no-array-index-key
              <div className="item" key={idx}>
                <input
                  type="text"
                  placeholder={t('정답을 입력하십시오.') || ''}
                  value={item}
                  onChange={e => {
                    if (/[|]/.test(e.target.value)) return;
                    setSaText({ key: answerList[0].eqa_key, idx, text: e.target.value });
                  }}
                />
                {idx > 0 && (
                  <object
                    className="remove"
                    onClick={() => setSaText({ key: answerList[0].eqa_key, idx, remove: true })}
                  >
                    {icDelete()}
                  </object>
                )}
              </div>
            ))}
            <div
              className="item"
              onClick={() =>
                setSaText({ key: answerList[0].eqa_key, idx: answerList[0].eqa_text.split('|').length, add: true })
              }
            >
              <button type="button" className="info">
                {t('중복답안 추가')}
              </button>
            </div>
          </div>
        )}
      </FormItem>
      <div className="formSubmit">
        <button
          type="button"
          className="primary"
          onClick={() => {
            if (!title) {
              alertShow('제목을 입력하십시오.');
              return;
            }
            if (!contents) {
              alertShow('내용을 입력하십시오.');
              return;
            }
            if (answerList.find(item => !item.eqa_text && !item.eqa_image_path && !item.img)) {
              alertShow('빈 문항을 입력하십시오.');
              return;
            }
            if (type === 'SA' && answerList[0].eqa_text?.split('|').find(item => item === '') === '') {
              alertShow('빈 문항을 입력하십시오.');
              return;
            }
            if (!answerList.find(item => item.eqa_correct === 'Y')) {
              alertShow('정답을 체크하십시오.');
              return;
            }
            confirmShow(eq_key ? '수정하시겠습니까?' : '등록하시겠습니까?', upload);
          }}
        >
          {eq_key ? t('수정') : t('등록')}
        </button>
      </div>
      <CategoryModal />
    </Page>
  );
}

export default EvaluationQuestionEdit;
