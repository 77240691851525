import axios from 'axios';
import { t } from 'i18next';

const api = process.env.REACT_APP_API;

// 오류 등록
export const createError = async (element: { type: string; message: string }) => {
  await axios.post(`${api}/admin/error`, { ...element }, { withCredentials: true });
};

// 오류 처리
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const throwError = (error: any) => {
  if (error.response.status === 401) window.postMessage({ type: 'logout' });
  else if (error.response.status === 403) {
    window.postMessage({ type: 'alert', message: t('권한이 없기 때문에 액세스할 수 없습니다.') });
    window.postMessage({ type: 'logout' });
  } else if (error.response.status === 500) {
    if (error.response.data.message === 'ER_DUP_ENTRY') {
      window.postMessage({ type: 'alert', message: t('이미 존재하는 값이 있습니다.') });
      return;
    }
    window.postMessage({ type: 'alert', message: t('서버에서 오류가 발생하여 작업을 수행할 수 없습니다.') });
  } else {
    // 로그인
    if (error.response.data.message === 'NOT_MATCH_PASSWORD') {
      window.postMessage({ type: 'alert', message: t('비밀번호가 일치하지 않습니다.') });
      return;
    }
    if (error.response.data.message === 'NOT_ACCESSABLE_PERMISSION') {
      window.postMessage({ type: 'alert', message: t('권한이 없기 때문에 액세스할 수 없습니다.') });
      return;
    }
    // 푸시알림
    if (error.response.data.message === 'USER_TOKEN_NOT_FOUND') {
      window.postMessage({ type: 'alert', message: t('사용자 토큰을 찾을 수 없습니다.') });
      return;
    }
    if (error.response.data.message === 'NOT_PUSH_TOKEN') {
      window.postMessage({ type: 'alert', message: t('푸시알림을 전송할 수 없습니다.') });
      return;
    }
    // 설문
    if (error.response.data.message === 'Date can not over one month') {
      window.postMessage({ type: 'alert', message: t('조회 기간은 한 달을 넘을 수 없습니다.') });
      return;
    }
    // 세일즈팁
    if (error.response.data.message === 'Date can not over one month') {
      window.postMessage({ type: 'alert', message: t('첨부 파일은 최대 30개까지 등록할 수 있습니다.') });
      return;
    }
    // VOD
    if (error.response.data.message === 'TRAFFIC_LIMIT_OVER') {
      window.postMessage({ type: 'alert', message: t('트래픽 초과') });
      return;
    }
    if (error.response.data.message === 'NO DATA') {
      window.postMessage({ type: 'alert', message: t('검색된 목록이 없습니다.') });
      return;
    }
    window.postMessage({ type: 'alert', message: t(error.response.data.message) });
  }
};

// 오류 목록 조회
export const getErrorList = async (page: number) => {
  try {
    const { data } = await axios.get(`${api}/admin/error/list?page=${page}&limit=10`, { withCredentials: true });
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    createError({
      type: '[ADMIN] error - getErrorList',
      message: `message: "${error.message}",\nresponse: { status: ${error.response.status}, message: "${error.response.data.message}" }`,
    });
    throwError(error);
    return null;
  }
};

// 오류 전체 삭제
export const deleteErrorAll = async () => {
  await axios.delete(`${api}/admin/error/all`, { withCredentials: true });
};

// 오류 삭제
export const deleteError = async (er_key: number) => {
  await axios.delete(`${api}/admin/error/${er_key}`, { withCredentials: true });
};
