import i18next from 'i18next';
import { atom, useSetRecoilState } from 'recoil';
import { useCookies } from 'react-cookie';

export const foldState = atom({
  key: 'fold',
  default: false,
});

export const languageState = atom({
  key: 'language',
  default: {
    state: false,
    list: [
      { lang: 'ko', mark: '🇰🇷 한국어', selected: false },
      { lang: 'en', mark: '🇺🇸 English', selected: false },
      { lang: 'ja', mark: '🇯🇵 日本語', selected: false },
      { lang: 'cn', mark: '🇨🇳 简体', selected: false },
      { lang: 'tw', mark: '🇹🇼 繁體', selected: false },
      { lang: 'id', mark: '🇮🇩 bahasa Indonesia', selected: false },
      { lang: 'fr', mark: '🇫🇷 Français', selected: false },
      { lang: 'it', mark: '🇮🇹 Italiano', selected: false },
      { lang: 'es', mark: '🇪🇸 español', selected: false },
      { lang: 'de', mark: '🇩🇪 Deutsch', selected: false },
      { lang: 'ms', mark: '🇲🇾 Melayu', selected: false },
      { lang: 'ru', mark: '🇷🇺 Русский', selected: false },
      { lang: 'vi', mark: '🇻🇳 Tiếng Việt', selected: false },
      { lang: 'th', mark: '🇹🇭 ภาษาไทย', selected: false },
    ],
  },
});

export function useLanguage() {
  const setLanguage = useSetRecoilState(languageState);

  const [, setCookie] = useCookies(['xclass-lang']);

  const changeLanguage = (e: string) => {
    setCookie('xclass-lang', e, { domain: process.env.NODE_ENV === 'production' ? '.xclass.co.kr' : '.xcast.co.kr', path: '/' });
    i18next.changeLanguage(e || 'en');
    setLanguage(prev => ({
      ...prev,
      list: prev.list.map(item => (item.lang === e ? { ...item, selected: true } : { ...item, selected: false })),
    }));
  };

  return { changeLanguage };
}
