import classNames from 'classnames';
import dayjs from 'dayjs';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { useMutation, useQuery } from '@tanstack/react-query';
import { createError, throwError } from '../../api/error';
import { deleteInspection, getInspectionList, getInspectionMylist } from '../../api/inspection';
import { useAlert } from '../../stores/alertStore';
import { useConfirm } from '../../stores/confirmStore';
import { logModalState } from '../../stores/logStore';

// components
import Page from '../../includes/page';
import LogModal from '../../components/log/logModal';

// img
import Pagination from '../../includes/pagination copy';

function Inspection() {
  const { t } = useTranslation();
  const { code } = useParams();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { page, mine } = queryString.parse(search);
  const { alertShow } = useAlert();
  const { confirmShow } = useConfirm();
  const [count, setCount] = useState(0);
  const [data, setData] = useState<IInspection[]>([]);
  const { isFetching: getListLoading, refetch: getList } = useQuery<{ count: number; data: IInspection[] }>(
    ['inq', page],
    () => getInspectionList(page === undefined ? 1 : Number(page)),
    {
      refetchOnWindowFocus: false,
      enabled: mine === undefined,
      onSuccess: e => {
        setCount(e.count || 1);
        setData(e.data.map(item => ({ ...item, checked: false })));
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (e: any) => alertShow(e.response.data.message),
    },
  );
  const { isFetching: getMyListLoading, refetch: getMyList } = useQuery<{ count: number; data: IInspection[] }>(
    ['inq', page],
    () => getInspectionMylist(page === undefined ? 1 : Number(page)),
    {
      refetchOnWindowFocus: false,
      enabled: mine !== undefined,
      onSuccess: e => {
        setCount(e.count || 1);
        setData(e.data.map(item => ({ ...item, checked: false })));
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (e: any) => alertShow(e.response.data.message),
    },
  );
  const { mutate: remove, isLoading: deleteLoading } = useMutation(deleteInspection, {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (e: any) => {
      createError({
        type: '[ADMIN] inspection - deleteInspection',
        message: `message: "${e.message}",\nresponse: { status: ${e.response.status}, message: "${e.response.data.message}" }`,
      });
      throwError(e);
    },
  });

  const selectDelete = () =>
    confirmShow('선택한 항목을 삭제하시겠습니까?', () => {
      const checked = data.filter(item => item.checked);
      for (let i = 0; i < checked.length; i += 1) remove(checked[i].is_key);
    });

  useEffect(() => {
    if (!deleteLoading) {
      if (mine !== undefined) getMyList();
      else getList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteLoading]);

  const allSelect = () =>
    setData(
      data.map(item => ({
        ...item,
        checked: data.filter(item => item.checked).length !== data.length,
      })),
    );

  const setLogModal = useSetRecoilState(logModalState);

  return (
    <Page title="체크리스트목록" isLoading={deleteLoading}>
      <div className="table-tools">
        <div className="btns">
          <button type="button" className="danger" onClick={allSelect}>
            <span>{t('전체선택')}</span>
          </button>
          <button type="button" className="primary" onClick={() => navigate(`/${code}/inspection/edit`)}>
            <span>{t('등록')}</span>
          </button>
          <button
            type="button"
            className={classNames('secondary', data.filter(item => item.checked).length === 0 && 'disabled')}
            onClick={selectDelete}
          >
            <span>{t('선택삭제')}</span>
          </button>
        </div>
        <div className="btns">
          <button
            type="button"
            className="info"
            onClick={() => {
              if (mine === undefined) navigate('?page=1&mine=true');
              else navigate('?page=1');
            }}
          >
            <span>{mine === undefined ? t('전체목록') : t('내 체크리스트목록')}</span>
          </button>
        </div>
      </div>
      <div className="table-tools">
        <div className="btns"></div>
        {data.filter(item => item.checked).length > 0 && (
          <div className="selected">
            {t('개의 항목을 선택했습니다.', { amount: data.filter(item => item.checked).length })}
          </div>
        )}
      </div>
      <div className="table-container">
        <table className="table">
          <thead>
            <tr>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <th></th>
              <th>{t('제목')}</th>
              <th>{t('작성자')}</th>
              <th>{t('공개여부')}</th>
              <th>{t('기간')}</th>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {(getListLoading || getMyListLoading) && (
              <tr>
                <td colSpan={9}>
                  <div>
                    <div className="lds-spinner">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </td>
              </tr>
            )}
            {!getListLoading && !getMyListLoading && data.length === 0 && (
              <tr>
                <td colSpan={6}>
                  <div>{t('검색된 목록이 없습니다.')}</div>
                </td>
              </tr>
            )}
            {data.map(item => (
              <tr key={item.is_key}>
                <td>
                  <div>
                    <div
                      className={classNames(
                        'checkbox',
                        data.filter(ele => ele.is_key === item?.is_key)[0]?.checked && 'active',
                      )}
                      onClick={() => {
                        setData(
                          data.map(ele => {
                            if (ele.is_key === item.is_key) return { ...ele, checked: !ele.checked };
                            return ele;
                          }),
                        );
                      }}
                    ></div>
                  </div>
                </td>
                <td>
                  <div className="title">
                    <span>{item.is_title}</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>{item.creatorName}</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>{item.is_public ? t('공개') : t('비공개')}</span>
                  </div>
                </td>
                <td>
                  <div className="left">
                    <span>
                      {dayjs(item.is_start_date).format('YYYY-MM-DD HH:mm')}
                      {' ~\n'}
                      {dayjs(item.is_end_date).format('YYYY-MM-DD HH:mm')}
                    </span>
                  </div>
                </td>
                <td>
                  <div>
                    <button
                      type="button"
                      className="info"
                      onClick={() =>
                        setLogModal(prev => ({
                          ...prev,
                          state: true,
                          sdate: dayjs(item.is_start_date).format('YYYY-MM-DD'),
                          edate: dayjs(item.is_end_date).format('YYYY-MM-DD'),
                          element: {
                            ckKey: item.is_key,
                            fileName: `checklist_${item.is_title}`,
                          },
                        }))
                      }
                    >
                      <span>{t('로그')}</span>
                    </button>
                    <button
                      type="button"
                      className="primary"
                      onClick={() => navigate(`/${code}/inspection/edit?is_key=${item.is_key}`)}
                    >
                      <span>{t('수정')}</span>
                    </button>
                    <button
                      type="button"
                      className="secondary"
                      onClick={() => confirmShow('삭제하시겠습니까?', () => remove(item.is_key))}
                    >
                      <span>{t('삭제')}</span>
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        total={count || 0}
        curr={page === undefined ? 1 : Number(page)}
        movePage={e => navigate(`?page=${e}${mine === undefined ? '' : '&mine=true'}`)}
      />
      <LogModal />
    </Page>
  );
}

export default Inspection;
