import axios from 'axios';
import { createError, throwError } from './error';

const api = process.env.REACT_APP_API;

// 라이브 등록
export const createLive = async (element: ILiveEdit) => {
  await axios.post(`${api}/admin/live`, { ...element }, { withCredentials: true });
};

// 라이브 수정
export const editLive = async (element: { l_key: number; data: ILiveEdit }) => {
  await axios.patch(`${api}/admin/live/${element.l_key}`, { ...element.data }, { withCredentials: true });
};

// 라이브 삭제
export const deleteLive = async (l_key: number) => {
  await axios.delete(`${api}/admin/live/${l_key}`, { withCredentials: true });
};

// 라이브 목록 조회
export const getLiveList = async (page: number) => {
  try {
    const { data } = await axios.get(`${api}/admin/live/list?page=${page}&limit=10`, { withCredentials: true });
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    createError({
      type: '[ADMIN] live - getLiveList',
      message: `message: "${error.message}",\nresponse: { status: ${error.response.status}, message: "${error.response.data.message}" }`,
    });
    throwError(error);
    return null;
  }
};

// 라이브 조회
export const getLive = async (l_key: number) => {
  try {
    const { data } = await axios.get(`${api}/admin/live/${l_key}`, { withCredentials: true });
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    createError({
      type: '[ADMIN] live - getLive',
      message: `message: "${error.message}",\nresponse: { status: ${error.response.status}, message: "${error.response.data.message}" }`,
    });
    throwError(error);
    return null;
  }
};
