import classNames from 'classnames';
import { useQuery } from '@tanstack/react-query';
import { motion, AnimatePresence } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getEvaluationQuestion } from '../../api/evaluation';
import { evalPreviewStore } from '../../stores/evaluationZustandStore';

// components
import Modal from '../modal/modal';

// style
import styles from './questionPrewview.module.scss';

// img
import icClose from '../../assets/img/icons/ic_close.svg';
import imgO from '../../assets/img/images/img_o.svg';
import imgOActive from '../../assets/img/images/img_o_active.svg';
import imgX from '../../assets/img/images/img_x.svg';
import imgXActive from '../../assets/img/images/img_x_active.svg';
import { icFullscreen, icFullscreenExit } from '../aside/icon';
import ModalLoading from '../modal/modalLoading';

function QuestionPreview() {
  const { t } = useTranslation();
  const { previewKey, setPreviewKey } = evalPreviewStore();
  const [title, setTitle] = useState('');
  const [score, setScore] = useState(0);
  const [image, setImage] = useState('');
  const [video, setVideo] = useState('');
  const [contents, setContents] = useState('');
  const [type, setType] = useState('');
  const [list, setList] = useState<
    {
      eqa_key: number;
      eq_key: number;
      eqa_text: string;
      eqa_image_path: string;
      eqa_correct: string;
      created_at: string;
      updated_at: string;
    }[]
  >([]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { isFetching: getEvaluationQuestionLoading } = useQuery<{ data: any }>(
    ['eqDetail'],
    () => getEvaluationQuestion(Number(previewKey)),
    {
      refetchOnWindowFocus: false,
      enabled: previewKey !== null,
      onSuccess: e => {
        setTitle(e.data?.eq_title || '');
        setScore(e.data?.eh_score || 0);
        setContents(e.data?.eq_contents || '');
        console.log(
          process.env.REACT_APP_UPLOADED_URL,
          `${process.env.REACT_APP_UPLOADED_URL}/${e.data.eq_image_path}`,
        );
        console.log(
          process.env.REACT_APP_UPLOADED_URL,
          `${process.env.REACT_APP_UPLOADED_URL}/${e.data.eq_video_path}`,
        );
        if (e.data?.eq_image_path) setImage(`${process.env.REACT_APP_UPLOADED_URL}/${e.data.eq_image_path}`);
        if (e.data?.eq_video_path) setVideo(`${process.env.REACT_APP_UPLOADED_URL}/${e.data.eq_video_path}`);
        setType(e.data?.eq_type);
        setList(e.data?.evaluationQuestionAnswerList || []);
      },
    },
  );

  const [titleImgFull, setTitleImgFull] = useState(false);
  const [imgFull, setImgFull] = useState<number | null>(null);

  useEffect(() => {
    if (!previewKey) {
      setTitle('');
      setScore(0);
      setImage('');
      setVideo('');
      setContents('');
      setType('');
      setList([]);
      setTitleImgFull(false);
      setImgFull(null);
    }
  }, [previewKey]);

  return (
    <AnimatePresence>
      {previewKey && (
        <Modal close={() => setPreviewKey(null)}>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            className={styles.container}
          >
            <div className={styles.header}>
              <div className={styles.title}>{t('미리보기')}</div>
              <img src={icClose} alt="close" className={styles.close} onClick={() => setPreviewKey(null)} />
            </div>
            <div className={styles.body}>
              <div className={styles.title}>
                {image && (
                  <div className={classNames(styles.img, titleImgFull && styles.full)}>
                    <div className={styles.imgInner}>
                      <img src={image} alt="img" />
                      <object className={styles.fullscreen} onClick={() => setTitleImgFull(prev => !prev)}>
                        {titleImgFull ? icFullscreenExit('#ffffff') : icFullscreen('#ffffff')}
                      </object>
                    </div>
                  </div>
                )}
                {video && (
                  <div className={classNames(styles.video)}>
                    <div className={styles.videoInner}>
                      <video controls src={video} autoPlay playsInline muted></video>
                    </div>
                  </div>
                )}
                <span>
                  {title} [{t('n점', { n: score })}]
                </span>
                <span className={styles.contents}>* {contents}</span>
              </div>
              {type === 'OX' && (
                <div className={styles.ox}>
                  {list.map(item => (
                    <div
                      className={classNames(styles.item, item.eqa_correct === 'Y' && styles.active)}
                      key={item.eqa_key}
                    >
                      <div className={styles.inner}>
                        <img
                          src={
                            item.eqa_text === 'o'
                              ? item.eqa_correct === 'Y'
                                ? imgOActive
                                : imgO
                              : item.eqa_correct === 'Y'
                              ? imgXActive
                              : imgX
                          }
                          alt="img"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {type === 'MC' && (
                <div className={styles.choice}>
                  {list.map(item => (
                    <div
                      key={item.eqa_key}
                      className={classNames(styles.item, item.eqa_correct === 'Y' && styles.active)}
                    >
                      {item.eqa_image_path && (
                        <div className={classNames(styles.img, imgFull === item.eqa_key && styles.full)}>
                          <div className={styles.imgInner}>
                            <img src={`${process.env.REACT_APP_UPLOADED_URL}/${item.eqa_image_path}`} alt="img" />
                            <object
                              className={styles.fullscreen}
                              onClick={() => setImgFull(prev => (prev === item.eqa_key ? null : item.eqa_key || null))}
                            >
                              {imgFull === item.eqa_key ? icFullscreenExit('#ffffff') : icFullscreen('#ffffff')}
                            </object>
                          </div>
                        </div>
                      )}
                      {item.eqa_text && <div className={styles.text}>{item.eqa_text}</div>}
                    </div>
                  ))}
                </div>
              )}

              {type === 'SA' && (
                <div className={styles.subjective}>
                  <input type="text" value={list[0].eqa_text ? list[0].eqa_text.replaceAll('|', ', ') : ''} readOnly />
                </div>
              )}
            </div>
            {getEvaluationQuestionLoading && <ModalLoading isLoading />}
          </motion.div>
        </Modal>
      )}
    </AnimatePresence>
  );
}

export default QuestionPreview;
