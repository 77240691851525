import axios from 'axios';
import queryString from 'query-string';
import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Editor } from '@toast-ui/react-editor';
import { createError, throwError } from '../../api/error';
import { createFree, editFree, getCategoryList, getFree } from '../../api/board';
import { useAlert } from '../../stores/alertStore';
import { categoryState, imgState, useCategory, useImg } from '../../stores/boardStore';
import { useConfirm } from '../../stores/confirmStore';

// components
import Page from '../../includes/page';
import FormItem from '../../components/basic/formItem';
import CategoryModal from '../../components/board/category';
import ImgUploadLoading from '../../components/inspection/imgUploadLoading';

// style
import '@toast-ui/editor/dist/toastui-editor.css';
import '@toast-ui/editor/dist/theme/toastui-editor-dark.css';

// img
import icDelete from '../../assets/img/icons/ic_delete.svg';

const api = process.env.REACT_APP_API;

function FreeEdit() {
  const { t } = useTranslation();
  const [cookies] = useCookies(['xclass-theme']);
  const { code } = useParams();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { bf_key } = queryString.parse(search);
  const { alertShow } = useAlert();
  const { confirmShow } = useConfirm();

  // 카테고리
  const [categoryAleady, setCategoryAleady] = useState(false);
  const { setCategoryModal, setCategoryData } = useCategory();
  const [category, setCategory] = useRecoilState(categoryState);
  const { isFetching: categoryLoading } = useQuery<{ data: ICategory[] }>(['category'], () => getCategoryList('FREE'), {
    refetchOnWindowFocus: false,
    onSuccess: e => {
      setCategory(prev => ({ ...prev, data: e.data.map(item => ({ ...item, checked: false })) }));
      setCategoryAleady(true);
    },
  });
  // 제목
  const [title, setTitle] = useState('');
  // 내용
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const editorRef = useRef<any>(null);
  // 이미지
  const [uploaded, setUploaded] = useState<{ path1: string; path2: string; path3: string }>({
    path1: '',
    path2: '',
    path3: '',
  });
  const img = useRecoilValue(imgState);
  const { initFile, setFile, setFileDelete } = useImg();
  const changeImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event?.target?.files) {
      initFile();
      return;
    }
    const getExtension = (fileName: string) => {
      const fileLength = fileName.length;
      const lastDot = fileName.lastIndexOf('.');
      const fileExtension = `.${fileName.substring(lastDot + 1, fileLength)}`;
      return fileExtension.toLowerCase();
    };
    if (
      getExtension(event.target.files[0].name) !== '.png' &&
      getExtension(event.target.files[0].name) !== '.jpg' &&
      getExtension(event.target.files[0].name) !== '.jpeg'
    ) {
      alertShow('이미지는 jpg, png 형식의 파일만 등록할 수 있습니다.');
      return;
    }
    const ele = event.target.files[0];
    setFile(ele, URL.createObjectURL(ele), ele.name);
    try {
      const reader: FileReader = new FileReader();
      reader.onload = (event: ProgressEvent<FileReader>) => {
        console.log(event);
      };
      reader.onerror = error => {
        console.log(error);
      };
      reader.readAsDataURL(event.target.files[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const { mutate: create, isLoading: createLoading } = useMutation(createFree, {
    onSuccess: () => navigate(`/${code}/board/free`),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (e: any) => {
      createError({
        type: '[ADMIN] board - createFree',
        message: `message: "${e.message}",\nresponse: { status: ${e.response.status}, message: "${e.response.data.message}" }`,
      });
      throwError(e);
    },
  });

  const { mutate: update, isLoading: updateLoading } = useMutation(editFree, {
    onSuccess: () => navigate(`/${code}/board/free`),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (e: any) => {
      createError({
        type: '[ADMIN] board - editFree',
        message: `message: "${e.message}",\nresponse: { status: ${e.response.status}, message: "${e.response.data.message}" }`,
      });
      throwError(e);
    },
  });

  const { isFetching: getFreeLoading } = useQuery<{ data: IFree }>(['free'], () => getFree(Number(bf_key)), {
    refetchOnWindowFocus: false,
    enabled: categoryAleady && bf_key !== undefined,
    onSuccess: e => {
      setCategoryData(e.data.bc_key);
      setTitle(e.data.bf_title);
      editorRef.current.getInstance().setHTML(e.data.bf_contents);
      if (e.data.bf_path_1) {
        setFile(
          `uploaded_${e.data.bf_path_1}`,
          `${process.env.REACT_APP_UPLOADED_URL}/${e.data.bf_path_1}`,
          `uploaded_${e.data.bf_path_1}`,
        );
        setUploaded(prev => ({ ...prev, path1: e.data.bf_path_1 }));
      }
      if (e.data.bf_path_2) {
        setFile(
          `uploaded_${e.data.bf_path_2}`,
          `${process.env.REACT_APP_UPLOADED_URL}/${e.data.bf_path_2}`,
          `uploaded_${e.data.bf_path_2}`,
        );
        setUploaded(prev => ({ ...prev, path2: e.data.bf_path_2 }));
      }
      if (e.data.bf_path_3) {
        setFile(
          `uploaded_${e.data.bf_path_3}`,
          `${process.env.REACT_APP_UPLOADED_URL}/${e.data.bf_path_3}`,
          `uploaded_${e.data.bf_path_3}`,
        );
        setUploaded(prev => ({ ...prev, path3: e.data.bf_path_3 }));
      }
    },
  });

  const [imgCount, setImgCount] = useState<IImgUpload[]>([]);
  const [uploadLoading, setUploadLoading] = useState(false);

  useEffect(() => {
    if (imgCount.length > 0 && imgCount.filter(item => item.complete === item.total).length === imgCount.length) {
      setUploadLoading(false);
      if (bf_key) {
        update({
          bf_key: Number(bf_key),
          data: {
            category: Number(category.data.find(item => item.checked)?.bc_key),
            title,
            contents: editorRef.current?.getInstance().getHTML(),
            path1: uploaded.path1 || '',
            path2: uploaded.path2 || '',
            path3: uploaded.path3 || '',
          },
        });
      } else {
        create({
          category: Number(category.data.find(item => item.checked)?.bc_key),
          title,
          contents: editorRef.current?.getInstance().getHTML(),
          path1: uploaded.path1 || '',
          path2: uploaded.path2 || '',
          path3: uploaded.path3 || '',
        });
      }
    } else if (imgCount.length > 0) {
      setUploadLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imgCount]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formData = (_fields: any, _file: any) => {
    const data = new FormData();
    Object.keys(_fields).forEach(key => data.append(key, _fields[key]));
    if (_file) {
      data.append('file', _file);
    }
    return data;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fileUpload = async (url: string, form: any) => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data = (await axios.post(url, form)) as any;
      if (data.ok || data.status === 204) {
        console.log(data);
        setImgCount(prev => prev.map(item => ({ ...item, complete: item.complete + 1 })));
      } else {
        console.log('error');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const uploadImg = async (count: number) => {
    try {
      const { data } = await axios.post(`${api}/admin/board/free/upload`, { count }, { withCredentials: true });

      setImgCount(prev => prev.concat({ id: 1, total: data.data.length, complete: 0 }));

      const array = img.imgName.filter(el => el.includes('uploaded_')).map(el => el.split('_')[1]);
      await data.data.forEach((item: IUploadItem) => array.push(item.name));
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const obj: any = {};
      array.forEach((el, index) => {
        obj[`path${index + 1}`] = el;
      });
      setUploaded(obj);

      await data.data.forEach((item: IUploadItem, idx: number) =>
        fileUpload(
          item.url.url,
          formData(
            item.url.fields,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            img.file.filter((e: any) => typeof e !== 'string')[idx],
          ),
        ),
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(e);
    }
  };
  const upload = () => {
    if (img.imgName.length === 0 || (bf_key && img.imgName.filter(el => !el.includes('uploaded_')).length === 0)) {
      if (bf_key) {
        update({
          bf_key: Number(bf_key),
          data: {
            category: Number(category.data.find(item => item.checked)?.bc_key),
            title,
            contents: editorRef.current?.getInstance().getHTML(),
            path1: uploaded.path1 || '',
            path2: uploaded.path2 || '',
            path3: uploaded.path3 || '',
          },
        });
      } else {
        create({
          category: Number(category.data.find(item => item.checked)?.bc_key),
          title,
          contents: editorRef.current?.getInstance().getHTML(),
          path1: uploaded.path1 || '',
          path2: uploaded.path2 || '',
          path3: uploaded.path3 || '',
        });
      }
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    uploadImg(img.file.filter((e: any) => typeof e !== 'string').length);
  };

  const init = () => {
    setCategory({
      state: false,
      data: [],
    });
    setCategoryAleady(false);
    setTitle('');
    editorRef.current.getInstance().setHTML('');
    initFile();
  };
  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const toBase64 = (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  return (
    <Page
      title={bf_key ? '자유게시판수정' : '자유게시판등록'}
      isLoading={getFreeLoading || categoryLoading || createLoading || updateLoading}
    >
      <FormItem title="카테고리" essential>
        <div className="input">
          <input
            type="text"
            placeholder={t('카테고리') || ''}
            value={category.data.find(item => item.checked)?.bc_name || ''}
            readOnly
            className="combo"
            onClick={() => setCategoryModal(true)}
          />
          <div className="arrow"></div>
        </div>
      </FormItem>
      <FormItem title="제목" essential>
        <div className="input">
          <input type="text" placeholder={t('제목') || ''} value={title} onChange={e => setTitle(e.target.value)} />
        </div>
      </FormItem>
      <FormItem title="내용" essential>
        <div className="input"></div>
      </FormItem>
      <Editor
        previewStyle="vertical"
        height="400px"
        useCommandShortcut
        ref={editorRef}
        initialEditType="wysiwyg"
        hooks={{
          // eslint-disable-next-line consistent-return
          addImageBlobHook: async blob => {
            if (document.querySelectorAll('.toastui-editor-contents img').length >= 5)
              return alertShow('최대 5개의 이미지를 업로드할 수 있습니다.');
            const maxSize = 1024 * 1024 * 1;
            const { size } = blob;
            if (maxSize < size) return alertShow('첨부 이미지의 사이즈는 1024KB 이내로 등록 가능합니다.');
            toBase64(blob)
              .then(res => {
                const cancelEle = document.querySelector('.toastui-editor-close-button') as HTMLElement;
                cancelEle?.click();
                document
                  .querySelector('.toastui-editor-contents p:last-child')
                  ?.insertAdjacentHTML(
                    'beforebegin',
                    `<img src="${res}" contenteditable="false"><img class="ProseMirror-separator" alt=""><br class="ProseMirror-trailingBreak">`,
                  );
              })
              .catch(() => {
                alertShow('이미지 업로드에 실패했습니다. 다시 시도해 주세요.');
              });
          },
        }}
        theme={cookies['xclass-theme']}
      />
      <div className="imgUpload" style={{ display: 'none' }}>
        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
        {img.img.map((el: any, idx: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className="item" key={idx}>
            <img src={el} alt="logo" style={{ display: el ? '' : 'none' }} />
            <div className="tool">
              <div className="btns">
                <img
                  src={icDelete}
                  alt="icon"
                  onClick={() =>
                    confirmShow('이미지를 제거하시겠습니까?', () => {
                      if (img.imgName[idx].includes('uploaded_')) {
                        const array = img.imgName
                          .filter(el => el !== img.imgName[idx])
                          .map(el => (el.includes('uploaded_') ? el.split('_')[1] : el));

                        if (array[0]) setUploaded(prev => ({ ...prev, path1: array[0] }));
                        else setUploaded(prev => ({ ...prev, path1: '' }));

                        if (array[1]) setUploaded(prev => ({ ...prev, path2: array[1] }));
                        else setUploaded(prev => ({ ...prev, path2: '' }));

                        if (array[2]) setUploaded(prev => ({ ...prev, path3: array[2] }));
                        else setUploaded(prev => ({ ...prev, path3: '' }));
                      }
                      setFileDelete(idx);
                    })
                  }
                />
              </div>
            </div>
          </div>
        ))}
        {img.img.length < 3 && (
          <div className="item">
            <label htmlFor="imgUpload">
              <input type="file" id="imgUpload" onChange={e => changeImage(e)} accept="image/*" />
            </label>
            <div className="add"></div>
          </div>
        )}
      </div>
      {uploadLoading && (
        <ImgUploadLoading>
          {imgCount.map(item => (
            <div className="imgUploadLoadingItem" key={item.id}>
              <div className="gauge">
                <div className="inner" style={{ width: `calc(${(item.complete / item.total) * 100}%)` }}></div>
              </div>
              <div className="text">
                {item.complete} / {item.total}
              </div>
            </div>
          ))}
        </ImgUploadLoading>
      )}
      <div className="formSubmit">
        <button
          type="button"
          className="primary"
          onClick={() => {
            if (!category.data.find(item => item.checked)?.bc_name) {
              alertShow('카테고리를 선택하십시오.');
              return;
            }
            if (!title) {
              alertShow('제목을 입력하십시오.');
              return;
            }
            if (
              !editorRef.current
                ?.getInstance()
                .getHTML()
                .replace(/<[^>]*>?/g, '')
                .replaceAll(' ', '')
            ) {
              alertShow('내용을 입력하십시오.');
              return;
            }
            confirmShow(bf_key ? '수정하시겠습니까?' : '등록하시겠습니까?', upload);
          }}
        >
          {bf_key ? t('수정') : t('등록')}
        </button>
      </div>
      <CategoryModal />
    </Page>
  );
}

export default FreeEdit;
