import dayjs from 'dayjs';
import { atom } from 'recoil';

export const logModalState = atom<{
  state: boolean;
  sdate: string;
  edate: string;
  element?: {
    fileName: string;
    roomId?: string;
    surveyKey?: number;
    vodKey?: number;
    ckKey?: number;
    evaluationKey?: number;
  };
}>({
  key: 'logModal',
  default: {
    state: false,
    sdate: dayjs(new Date()).format('YYYY-MM-DD'),
    edate: dayjs(new Date()).format('YYYY-MM-DD'),
  },
});
