import { useParams } from 'react-router-dom';
import { existImg } from '../../assets/util/existImg';

// img
import logoEmpty from '../../assets/img/logo_empty.svg';

// eslint-disable-next-line react/require-default-props
function Image({ src, cn }: { src: string; cn?: string }) {
  const { code } = useParams();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onError = (e: any) => {
    const lightLogo = `${process.env.REACT_APP_UPLOADED_URL}/xclass/image/customer/${code}_LIGHT_LOGO`;
    const DarkLogo = `${process.env.REACT_APP_UPLOADED_URL}/xclass/image/customer/${code}_DARK_LOGO`;
    existImg(DarkLogo, (result: boolean) => {
      e.target.classList.add('logo');
      if (result) {
        e.target.src = DarkLogo;
        return;
      }
      existImg(lightLogo, (result: boolean) => {
        e.target.classList.add('logo');
        if (result) {
          e.target.src = lightLogo;
          return;
        }
        e.target.src = logoEmpty;
      });
    });
  };

  return <img src={src} className={cn || ''} alt="logo" onError={onError} />;
}

export default Image;
