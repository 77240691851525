import queryString from 'query-string';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { getHistory } from '../../api/evaluation';
import { useAlert } from '../../stores/alertStore';

// components
import Page from '../../includes/page';
import EvaluationHistoryItem from '../../components/evaluation/evaluationHistoryItem';

// style
import styles from './Evaluation.module.scss';

function EvaluationHistory() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { eh_key, title } = queryString.parse(search);
  const { alertShow } = useAlert();
  const { isFetching: getHistoryLoading, data } = useQuery<{ data: IEvaluationHistoryDetail }>(
    ['getHistory'],
    () => getHistory(Number(eh_key)),
    {
      refetchOnWindowFocus: false,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (e: any) => alertShow(e.response.data.message),
    },
  );

  return (
    <Page title="평가기록" isLoading={getHistoryLoading}>
      <div className={styles.title}>{title}</div>
      {data?.data.evaluationHistoryQuestionList.length === 0 && (
        <>
          <div className={styles.msg}>{t('풀이한 문제가 없습니다.')}</div>
          <div className={styles.btns}>
            <div className={styles.btn} onClick={() => navigate(-1)}>
              {t('목록')}
            </div>
          </div>
        </>
      )}
      {data?.data.evaluationHistoryQuestionList.map((item, idx) => (
        <EvaluationHistoryItem
          key={item.ehq_key}
          item={item}
          idx={(idx + 1).toString().length < 2 ? `0${idx + 1}` : `${idx + 1}`}
        />
      ))}
    </Page>
  );
}

export default EvaluationHistory;
