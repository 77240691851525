import { motion, AnimatePresence } from 'framer-motion';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { decrypt } from '../../assets/util/crypto';
import { memberGradeState, useMemberGrade } from '../../stores/memberStore';

// components
import Modal from '../modal/modal';

// style
import styles from '../modal/selector.module.scss';

function MemberGrade() {
  const { t } = useTranslation();
  const [cookies] = useCookies(['xclass-lang', 'XID']);
  const memberGrade = useRecoilValue(memberGradeState);
  const { setMemberGradeModal, setMemberGradeData } = useMemberGrade();

  return (
    <AnimatePresence>
      {memberGrade.state && (
        <Modal close={() => setMemberGradeModal(false)}>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            className={styles.modal}
          >
            <div className={styles.title}>{t('회원등급을 선택하십시오.')}</div>
            <table className="table">
              <thead>
                <tr>
                  <th>{t('회원등급')}</th>
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {!memberGrade.data && (
                  <tr>
                    <td colSpan={2}>
                      <div>{t('검색된 목록이 없습니다.')}</div>
                    </td>
                  </tr>
                )}
                {memberGrade.data?.map(item => {
                  if (
                    decrypt(cookies.XID).mg_code === 'ADMIN' &&
                    (item.mg_code === 'SUPER' || item.mg_code === 'ADMIN')
                  )
                    return null;
                  if (
                    decrypt(cookies.XID).mg_code === 'MANAGER' &&
                    (item.mg_code === 'SUPER' || item.mg_code === 'ADMIN' || item.mg_code === 'MANAGER')
                  )
                    return null;
                  return (
                    <tr key={item.mg_code}>
                      <td>
                        <div>
                          <span>
                            {item.mg_code === 'SUPER' &&
                              (cookies['xclass-lang'] === 'ko' ? '최고관리자' : 'Super Admin')}
                            {item.mg_code === 'ADMIN' && (cookies['xclass-lang'] === 'ko' ? '관리자' : 'Admin')}
                            {item.mg_code === 'MANAGER' && (cookies['xclass-lang'] === 'ko' ? '운영자' : 'Manager')}
                            {item.mg_code === 'MEMBER' && (cookies['xclass-lang'] === 'ko' ? '회원' : 'Member')}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div>
                          <button
                            type="button"
                            className="success"
                            onClick={() => {
                              setMemberGradeData(item.mg_code);
                              setMemberGradeModal(false);
                            }}
                          >
                            <span>{t('선택')}</span>
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </motion.div>
        </Modal>
      )}
    </AnimatePresence>
  );
}

export default MemberGrade;
