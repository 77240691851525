import classNames from 'classnames';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// style
import styles from './asideItem.module.scss';

function AsideItem({
  title,
  icon,
  iconActive,
  active,
  children,
  link,
}: {
  title: string;
  icon: () => JSX.Element;
  iconActive: () => JSX.Element;
  active: boolean;
  // eslint-disable-next-line react/require-default-props
  children?: ReactNode;
  // eslint-disable-next-line react/require-default-props
  link?: string;
}) {
  const navigate = useNavigate();
  const { code } = useParams();
  const [open, setOpen] = useState(false);
  const [height, setHeight] = useState(0);

  const menuRef = useRef<HTMLDivElement>(null);
  const openMenu = (event: React.MouseEvent) => {
    if (link) {
      navigate(`/${code}${link}`);
      return;
    }
    setOpen(prev => !prev);
    if (open) {
      setHeight(0);
    } else {
      const itemCount = event.currentTarget.parentElement?.children[1].childElementCount;
      setHeight(itemCount || 0);
      window.postMessage({ type: 'asideScroll', message: menuRef?.current?.offsetTop || 0 });
    }
  };

  const openRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (active) openRef?.current?.click();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.menu} ref={menuRef}>
      <div
        className={classNames(styles.subject, open && styles.active, link && styles.link)}
        onClick={openMenu}
        ref={openRef}
      >
        {active ? iconActive() : icon()}
        <span style={{ color: active ? 'var(--primary)' : '' }}>{title}</span>
      </div>
      <div
        className={styles.submenu}
        style={{
          height: open ? `calc(40px * ${height})` : '',
        }}
      >
        {children}
      </div>
    </div>
  );
}

export default AsideItem;
