import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { reportEditState } from '../../stores/learningtalkStore';
import { useConfirm } from '../../stores/confirmStore';
import { icDone } from '../aside/icon';

// components
import Modal from '../modal/modal';

// style
import styles from '../modal/dialog.module.scss';

function ReportEdit({ update }: { update: (element: { lrKey: number; status: string }) => void }) {
  const { t } = useTranslation();
  const { confirmShow } = useConfirm();
  const [reportEdit, setReportEdit] = useRecoilState(reportEditState);

  return (
    <AnimatePresence>
      {reportEdit.state && (
        <Modal close={() => setReportEdit({ state: false, lrKey: 0, status: '' })}>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            className={styles.modal}
          >
            <div className={styles.circle}>{icDone()}</div>
            <div className={styles.message}>{t('신고를 처리하시겠습니까?')}</div>
            <div className={styles.input}>
              <select
                value={reportEdit.status}
                onChange={e => setReportEdit(prev => ({ ...prev, status: e.target.value }))}
              >
                <option value="PENDDING">{t('보류')}</option>
                <option value="REJECT">{t('거절')}</option>
                <option value="COMPLETED">{t('승인')}</option>
              </select>
              <div className={styles.arrow}></div>
            </div>
            <div className={styles.btns}>
              <div
                className={styles.btn}
                onClick={() => {
                  setReportEdit({ state: false, lrKey: 0, status: '' });
                  if (reportEdit.status === 'COMPLETED') {
                    confirmShow('신고된 개체가 삭제됩니다. 계속하시겠습니까?', () =>
                      update({ lrKey: reportEdit.lrKey, status: reportEdit.status }),
                    );
                    return;
                  }
                  update({ lrKey: reportEdit.lrKey, status: reportEdit.status });
                }}
              >
                {t('확인')}
              </div>
              <div className={styles.btn} onClick={() => setReportEdit({ state: false, lrKey: 0, status: '' })}>
                {t('취소')}
              </div>
            </div>
          </motion.div>
        </Modal>
      )}
    </AnimatePresence>
  );
}

export default ReportEdit;
