import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { categoryState, useCategory } from '../../stores/boardStore';

// components
import Modal from '../modal/modal';

// style
import styles from '../modal/selector.module.scss';

function MemberGrade() {
  const { t } = useTranslation();
  const category = useRecoilValue(categoryState);
  const { setCategoryModal, setCategoryData } = useCategory();

  return (
    <AnimatePresence>
      {category.state && (
        <Modal close={() => setCategoryModal(false)}>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            className={styles.modal}
          >
            <div className={styles.title}>{t('카테고리를 선택하십시오.')}</div>
            <table className="table">
              <thead>
                <tr>
                  <th>{t('카테고리')}</th>
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {!category.data && (
                  <tr>
                    <td colSpan={2}>
                      <div>{t('검색된 목록이 없습니다.')}</div>
                    </td>
                  </tr>
                )}
                {category.data?.map(item => (
                  <tr key={item.bc_key}>
                    <td>
                      <div>
                        <span>{item.bc_name}</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <button
                          type="button"
                          className="success"
                          onClick={() => {
                            setCategoryData(item.bc_key);
                            setCategoryModal(false);
                          }}
                        >
                          <span>{t('선택')}</span>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </motion.div>
        </Modal>
      )}
    </AnimatePresence>
  );
}

export default MemberGrade;
