import classNames from 'classnames';
import { useEffect, useState } from 'react';

// style
import styles from './pagination.module.scss';

// img
import { icArrowFirst, icArrowPrev, icArrowNext, icArrowLast } from '../components/aside/icon';

function Pagination({ total, curr, movePage }: { total: number; curr: number; movePage: (e: number) => void }) {
  const [crrPage, setCrrPage] = useState(1);
  const [maxPage, setMaxPage] = useState(5);
  const onPage = (e: number) => {
    setCrrPage(e);
    setMaxPage(e === 0 ? 5 : e % 5 === 0 ? e : Math.floor(e / 5) * 5 + 5);
    if (curr !== e) movePage(e);
    window.postMessage({ type: 'contentScollTop' });
  };

  const pagination = (e: number) => {
    const pages = [];
    for (let i = 1; i <= e; i += 1) {
      pages.push(i);
    }
    return pages
      .filter(item => !(maxPage - item < 0 || maxPage - item >= 5))
      .map(element => (
        <div
          className={classNames(styles.page, crrPage === element && styles.active)}
          key={element}
          onClick={() => onPage(element)}
        >
          {element}
        </div>
      ));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => onPage(curr), [curr]);

  const onPrev = () => {
    if (maxPage === 5) return;
    setCrrPage(maxPage - 9);
    setMaxPage(maxPage - 5);
    onPage(maxPage - 9);
  };

  const onNext = () => {
    if (maxPage >= Math.floor(total / 10) + (total % 10 !== 0 ? 1 : 0)) return;
    onPage(maxPage + 1);
    setMaxPage(maxPage + 5);
  };

  const onFirst = () => {
    if (maxPage === 5) return;
    setCrrPage(1);
    setMaxPage(5);
    onPage(1);
  };

  const onLast = () => {
    if (maxPage >= Math.floor(total / 10) + (total % 10 !== 0 ? 1 : 0)) return;
    const remain = total % 10 !== 0 ? 1 : 0;
    setMaxPage(Math.floor(total / 10) + remain);
    onPage(Math.floor(total / 10) + remain);
  };
  return (
    <div className={styles.container}>
      <div className={classNames(styles.page, styles.prev, maxPage === 5 && styles.disabled)} onClick={onFirst}>
        <object>{icArrowFirst()}</object>
      </div>
      <div className={classNames(styles.page, styles.prev, maxPage === 5 && styles.disabled)} onClick={onPrev}>
        <object>{icArrowPrev()}</object>
      </div>
      {pagination(Math.floor(total / 10) + (total % 10 !== 0 ? 1 : 0))}
      <div
        className={classNames(
          styles.page,
          styles.next,
          maxPage >= Math.floor(total / 10) + (total % 10 !== 0 ? 1 : 0) && styles.disabled,
        )}
        onClick={onNext}
      >
        <object>{icArrowNext()}</object>
      </div>
      <div
        className={classNames(
          styles.page,
          styles.next,
          maxPage >= Math.floor(total / 10) + (total % 10 !== 0 ? 1 : 0) && styles.disabled,
        )}
        onClick={onLast}
      >
        <object>{icArrowLast()}</object>
      </div>
    </div>
  );
}

export default Pagination;
