import { atom } from 'recoil';

export const reportEditState = atom<{ state: boolean; lrKey: number; status: string }>({
  key: 'reportEdit',
  default: {
    state: false,
    lrKey: 0,
    status: '',
  },
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const reportInfoState = atom<{
  state: boolean;
  type: string;
  channelData?: ILearningtalkChannel;
  contentsData?: ILearningtalkContents;
  commentData?: ILearningtalkComment;
}>({
  key: 'reportInfo',
  default: {
    state: false,
    type: '',
  },
});
