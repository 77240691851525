import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

export const categoryEditState = atom<{ state: boolean; type: string; ecKey: number; name: string }>({
  key: 'evaluationCategoryEdit',
  default: {
    state: false,
    type: 'create',
    ecKey: 0,
    name: '',
  },
});

export const categoryState = atom<{ state: boolean; data: IEvaluationCategory[] }>({
  key: 'evaluationCategory',
  default: {
    state: false,
    data: [],
  },
});

export function useCategory() {
  const setCategoryState = useSetRecoilState(categoryState);

  const setCategoryModal = (toggle: boolean) => setCategoryState(prev => ({ ...prev, state: toggle }));

  const setCategoryData = (ec_key: number) =>
    setCategoryState(prev => ({
      ...prev,
      data: prev.data.map(item => (item.ec_key === ec_key ? { ...item, checked: true } : { ...item, checked: false })),
    }));

  return { setCategoryModal, setCategoryData };
}

export const answerState = atom<IEvaluationAnswer[]>({
  key: 'evaluationAnswer',
  default: [],
});

export function useAnswer() {
  const setAnswer = useSetRecoilState(answerState);
  const answer = useRecoilValue(answerState);

  const setMcText = (idx: number, text: string) =>
    setAnswer(prev =>
      prev.map((item, index) => {
        if (idx === index) return item.eqa_text ? { ...item, eqa_text: text } : { ...item, text };
        return { ...item };
      }),
    );

  const setSaText = (idx: number, text: string) =>
    setAnswer(prev => {
      if (prev[0].eqa_text) {
        const result = prev[0].eqa_text.split('|');
        result[idx] = text;
        return prev.map(item => ({ ...item, eqa_text: result.join('|') }));
      }

      const result = prev[0].text?.split('|');
      if (result) result[idx] = text;
      return prev.map(item => ({ ...item, text: result?.join('|') }));
    });

  const setSaTextAdd = () =>
    setAnswer(prev =>
      prev.map(item => {
        if (item.eqa_text) return { ...item, eqa_text: `${item.eqa_text}|` };
        return { ...item, text: `${item.text}|` };
      }),
    );

  const setSaTextRemove = (idx: number) => {
    const answerTextMap = answer[0].text?.split('|') || answer[0].eqa_text?.split('|');
    answerTextMap?.splice(idx, 1);
    setAnswer(prev =>
      prev.map(item => {
        if (item.eqa_text)
          return { ...item, eqa_text: answerTextMap?.join('|') };
        return { ...item, text: answerTextMap?.join('|') };
      }),
    );
  };

  const setCorrectAnswer = (idx: number) =>
    setAnswer(prev =>
      prev.map((item, index) =>
        index === idx
          ? item.eqa_correct
            ? { ...item, eqa_correct: 'Y' }
            : { ...item, correct: 'Y' }
          : item.eqa_correct
          ? { ...item, eqa_correct: 'N' }
          : { ...item, correct: 'N' },
      ),
    );

  const addItem = () => setAnswer(prev => prev.concat({ text: '', correct: 'N' }));

  const removeItem = (idx: number) => setAnswer(prev => prev.filter((_, index) => index !== idx));

  return { setMcText, setSaText, setSaTextAdd, setSaTextRemove, setCorrectAnswer, addItem, removeItem };
}

export const questionListState = atom<{ state: boolean; data: IEvaluationQuestionDetail[] }>({
  key: 'evaluationQuetionList',
  default: { state: false, data: [] },
});
