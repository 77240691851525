import dayjs from 'dayjs';
import queryString from 'query-string';
import { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Viewer } from '@toast-ui/react-editor';
import { createError, throwError } from '../../api/error';
import { getReplay, createReplayComment, deleteReplayComment, getReplayComment } from '../../api/replay';
import { useAlert } from '../../stores/alertStore';

// components
import Page from '../../includes/page';
import Pagination from '../../includes/pagination';

// img
import VodComment from '../../components/vod/comment';

function LiveReplayDetail() {
  const { t } = useTranslation();
  const [cookies] = useCookies(['xclass-theme']);
  const { search } = useLocation();
  const { key } = queryString.parse(search);
  const { alertShow } = useAlert();

  const [title, setTitle] = useState('');
  const [date, setDate] = useState('');
  const [description, setDescription] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [video, setVideo] = useState('');

  const { isFetching: getReplayLoading } = useQuery<{ data: IReplayDetail }>(
    ['getReplay'],
    () => getReplay(Number(key)),
    {
      refetchOnWindowFocus: false,
      enabled: key !== undefined,
      onSuccess: e => {
        setTitle(e.data.title);
        setDate(e.data.createdAt);
        setDescription(e.data.description);
        setVideo(`${process.env.REACT_APP_VOD}/tp?vod_key=${e.data.originVodKey}`);
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (e: any) => alertShow(e.response.data.message),
    },
  );

  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [comment, setComment] = useState('');
  const {
    isFetching: getReplayCommentLoading,
    data: commentList,
    refetch,
  } = useQuery<{ count: number; data: IReplayComment[] }>(
    ['getReplayComment'],
    () => getReplayComment({ key: Number(key), page }),
    {
      refetchOnWindowFocus: false,
      enabled: key !== undefined,
      onSuccess: e => {
        setCount(e.count);
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (e: any) => alertShow(e.response.data.message),
    },
  );

  const { mutate: create, isLoading: createLoading } = useMutation(createReplayComment, {
    onSuccess: () => {
      setComment('');
      refetch();
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (e: any) => {
      createError({
        type: '[ADMIN] live - createReplayComment',
        message: `message: "${e.message}",\nresponse: { status: ${e.response.status}, message: "${e.response.data.message}" }`,
      });
      throwError(e);
    },
  });

  const { mutate: remove, isLoading: deleteLoading } = useMutation(deleteReplayComment, {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (e: any) => {
      createError({
        type: '[ADMIN] live - deleteReplayComment',
        message: `message: "${e.message}",\nresponse: { status: ${e.response.status}, message: "${e.response.data.message}" }`,
      });
      throwError(e);
    },
  });

  useEffect(() => {
    if (!deleteLoading) refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteLoading]);

  return (
    <Page
      title={title}
      goBack="/live/replay"
      isLoading={getReplayLoading || createLoading || deleteLoading || getReplayCommentLoading}
    >
      <div className="etc-info">
        <div className="item">{dayjs(date).format('YYYY-MM-DD HH:mm')}</div>
      </div>
      <div className="viewer">
        {description && <Viewer initialValue={description} theme={cookies['xclass-theme']} />}
        <div className="video">
          <iframe src={video} title="vod" allowFullScreen></iframe>
        </div>
      </div>
      <div className="comment-write">
        <textarea
          maxLength={200}
          placeholder={t('댓글을 입력하십시오.') || ''}
          value={comment}
          onChange={e => setComment(e.target.value)}
        ></textarea>
        <div className="tools">
          <div className="count">{comment.length} / 200</div>
          <button type="button" className="primary" onClick={() => create({ key: Number(key), comment })}>
            {t('등록')}
          </button>
        </div>
      </div>
      <div className="comment-list">
        {commentList?.data.map(item => (
          <VodComment key={item.key} item={item} remove={remove} />
        ))}
      </div>
      <Pagination total={count || 0} movePage={e => setPage(e)} />
    </Page>
  );
}

export default LiveReplayDetail;
