import queryString from 'query-string';
import { useState, useEffect, useRef } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Editor } from '@toast-ui/react-editor';
import { createError, throwError } from '../../api/error';
import { createAnnouncement, editAnnouncement, getAnnouncement } from '../../api/customer';
import { useAlert } from '../../stores/alertStore';
import { useConfirm } from '../../stores/confirmStore';

// components
import Page from '../../includes/page';
import FormItem from '../../components/basic/formItem';
import CategoryModal from '../../components/board/category';

function AnnouncementEdit() {
  const { t } = useTranslation();
  const [cookies] = useCookies(['xclass-theme']);
  const { code } = useParams();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { cn_key } = queryString.parse(search);
  const { alertShow } = useAlert();
  const { confirmShow } = useConfirm();
  // 제목
  const [title, setTitle] = useState('');
  // 내용
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const editorRef = useRef<any>(null);

  const { mutate: create, isLoading: createLoading } = useMutation(createAnnouncement, {
    onSuccess: () => navigate(`/${code}/announcement`),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (e: any) => {
      createError({
        type: '[ADMIN] customer - createAnnouncement',
        message: `message: "${e.message}",\nresponse: { status: ${e.response.status}, message: "${e.response.data.message}" }`,
      });
      throwError(e);
    },
  });

  const { mutate: update, isLoading: updateLoading } = useMutation(editAnnouncement, {
    onSuccess: () => navigate(`/${code}/announcement`),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (e: any) => {
      createError({
        type: '[ADMIN] customer - editAnnouncement',
        message: `message: "${e.message}",\nresponse: { status: ${e.response.status}, message: "${e.response.data.message}" }`,
      });
      throwError(e);
    },
  });

  const { isFetching } = useQuery<{ data: IAnnouncement }>(['getAnnouncement'], () => getAnnouncement(Number(cn_key)), {
    refetchOnWindowFocus: false,
    enabled: cn_key !== undefined,
    onSuccess: e => {
      setTitle(e.data.cn_title);
      editorRef.current.getInstance().setHTML(e.data.cn_contents);
    },
  });

  const init = () => {
    setTitle('');
    editorRef.current.getInstance().setHTML('');
  };
  useEffect(() => init(), [search]);
  return (
    <Page title={cn_key ? '공지사항수정' : '공지사항등록'} isLoading={createLoading || updateLoading || isFetching}>
      <FormItem title="제목" essential>
        <div className="input">
          <input type="text" placeholder={t('제목') || ''} value={title} onChange={e => setTitle(e.target.value)} />
        </div>
      </FormItem>
      <FormItem title="내용" essential>
        <div className="input"></div>
      </FormItem>
      <Editor
        previewStyle="vertical"
        height="400px"
        useCommandShortcut
        ref={editorRef}
        initialEditType="wysiwyg"
        toolbarItems={[
          ['heading', 'bold', 'italic', 'strike'],
          ['hr', 'quote'],
          ['ul', 'ol', 'task', 'indent', 'outdent'],
          [
            'table',
            // 'image',
            'link',
          ],
          ['code', 'codeblock'],
        ]}
        theme={cookies['xclass-theme']}
      />
      <div className="formSubmit">
        <button
          type="button"
          className="primary"
          onClick={() => {
            if (!title) {
              alertShow('제목을 입력하십시오.');
              return;
            }
            if (
              !editorRef.current
                ?.getInstance()
                .getHTML()
                .replace(/<[^>]*>?/g, '')
                .replaceAll(' ', '')
            ) {
              alertShow('내용을 입력하십시오.');
              return;
            }
            confirmShow(cn_key ? '수정하시겠습니까?' : '등록하시겠습니까?', () => {
              if (cn_key) {
                update({
                  cn_key: Number(cn_key),
                  data: {
                    title,
                    contents: editorRef.current?.getInstance().getHTML(),
                  },
                });
              } else {
                create({
                  title,
                  contents: editorRef.current?.getInstance().getHTML(),
                });
              }
            });
          }}
        >
          {cn_key ? t('수정') : t('등록')}
        </button>
      </div>
      <CategoryModal />
    </Page>
  );
}

export default AnnouncementEdit;
