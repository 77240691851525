import CryptoJS from 'crypto-js';

const secretKey = process.env.REACT_APP_AES_SECRETKEY;
const iv = process.env.REACT_APP_AES_IV;
let decrpytData: string | null = null;

// 암호화
export const encrypt = (text: string) => {
  decrpytData = null;
  const cipher = CryptoJS.AES.encrypt(JSON.stringify(text), CryptoJS.enc.Utf8.parse(secretKey || ''), {
    iv: CryptoJS.enc.Utf8.parse(iv || ''),
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC,
  });
  return cipher.toString();
};

// 복호화
export const decrypt = (encryptedText?: string) => {
  if (!encryptedText) return {};
  if (decrpytData) return decrpytData;
  try {
    const decipher = CryptoJS.AES.decrypt(encryptedText.toString(), CryptoJS.enc.Utf8.parse(secretKey || ''), {
      iv: CryptoJS.enc.Utf8.parse(iv || ''),
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC,
    });
    decrpytData = JSON.parse(decipher.toString(CryptoJS.enc.Utf8));

    return JSON.parse(decipher.toString(CryptoJS.enc.Utf8));
  } catch (e) {
    decrpytData = null;
    return {};
  }
};
