import axios from 'axios';
import { createError, throwError } from './error';

const api = process.env.REACT_APP_API;

// 코드 유효성 검사
export const codeValidation = async (e: string) => {
  const code = e.replace('/', '').toUpperCase();
  try {
    const { data } = await axios.get(`${api}/user/customer/${code}`, { withCredentials: true });
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    createError({
      type: '[ADMIN] customer - codeValidation',
      message: `message: "${error.message}",\nresponse: { status: ${error.response.status}, message: "${error.response.data.message}" }`,
    });
    throwError(error);
    return null;
  }
};

// 고객 중복 검사
export const getCustomerTest = async (code: string) => {
  try {
    const { data } = await axios.get(`${api}/admin/customer/check/${code}`, { withCredentials: true });
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    createError({
      type: '[ADMIN] customer - getCustomerTest',
      message: `message: "${error.message}",\nresponse: { status: ${error.response.status}, message: "${error.response.data.message}" }`,
    });
    throwError(error);
    return null;
  }
};

// 고객 등록
export const createCustomer = async (element: ICustomerEdit) => {
  await axios.post(`${api}/admin/customer`, { ...element }, { withCredentials: true });
};

// 고객 수정
export const editCustomer = async (element: { c_key: number; data: ICustomerEdit }) => {
  await axios.patch(`${api}/admin/customer/${element.c_key}`, { ...element.data }, { withCredentials: true });
};

// 고객 삭제
export const deleteCustomer = async (c_key: number) => {
  await axios.delete(`${api}/admin/customer/${c_key}`, { withCredentials: true });
};

// 고객 목록 조회
export const getCustomerList = async (page: number) => {
  try {
    const { data } = await axios.get(`${api}/admin/customer/list?page=${page}&limit=10`, { withCredentials: true });
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    createError({
      type: '[ADMIN] customer - getCustomerList',
      message: `message: "${error.message}",\nresponse: { status: ${error.response.status}, message: "${error.response.data.message}" }`,
    });
    throwError(error);
    return null;
  }
};

// 고객 전체 수 조회
export const getCustomerCount = async () => {
  try {
    const { data } = await axios.get(`${api}/admin/customer/count`, { withCredentials: true });
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    createError({
      type: '[ADMIN] customer - getCustomerCount',
      message: `message: "${error.message}",\nresponse: { status: ${error.response.status}, message: "${error.response.data.message}" }`,
    });
    throwError(error);
    return null;
  }
};

// 고객 조회
export const getCustomer = async (c_key: number) => {
  try {
    const { data } = await axios.get(`${api}/admin/customer/${c_key}`, { withCredentials: true });
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    createError({
      type: '[ADMIN] customer - getCustomer',
      message: `message: "${error.message}",\nresponse: { status: ${error.response.status}, message: "${error.response.data.message}" }`,
    });
    throwError(error);
    return null;
  }
};

// 공지사항 생성
export const createAnnouncement = async (element: { title: string; contents: string }) => {
  await axios.post(`${api}/admin/customer/notice`, { ...element }, { withCredentials: true });
};

// 공지사항 수정
export const editAnnouncement = async (element: {
  cn_key: number;
  data: {
    title: string;
    contents: string;
  };
}) => {
  await axios.patch(`${api}/admin/customer/notice/${element.cn_key}`, { ...element.data }, { withCredentials: true });
};

// 공지사항 삭제
export const deleteAnnouncement = async (cn_key: number) => {
  await axios.delete(`${api}/admin/customer/notice/${cn_key}`, { withCredentials: true });
};

// 공지사항 목록 조회
export const getAnnouncementList = async (page: number) => {
  try {
    const { data } = await axios.get(`${api}/admin/customer/notice/list?page=${page}&limit=10`, {
      withCredentials: true,
    });
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    createError({
      type: '[ADMIN] customer - getAnnouncementList',
      message: `message: "${error.message}",\nresponse: { status: ${error.response.status}, message: "${error.response.data.message}" }`,
    });
    throwError(error);
    return null;
  }
};

// 공지사항 조회
export const getAnnouncement = async (cn_key: number) => {
  try {
    const { data } = await axios.get(`${api}/admin/customer/notice/${cn_key}`, { withCredentials: true });
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    createError({
      type: '[ADMIN] customer - getAnnouncement',
      message: `message: "${error.message}",\nresponse: { status: ${error.response.status}, message: "${error.response.data.message}" }`,
    });
    throwError(error);
    return null;
  }
};

// 문의사항 목록 조회
export const getEnquiryList = async (page: number) => {
  try {
    const { data } = await axios.get(`${api}/admin/enquiry/list?page=${page}&limit=10`, {
      withCredentials: true,
    });
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    createError({
      type: '[ADMIN] customer - getEnquiryList',
      message: `message: "${error.message}",\nresponse: { status: ${error.response.status}, message: "${error.response.data.message}" }`,
    });
    throwError(error);
    return null;
  }
};

// 문의사항 조회
export const getEnquiry = async (e_key: number) => {
  try {
    const { data } = await axios.get(`${api}/admin/enquiry/${e_key}`, { withCredentials: true });
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    createError({
      type: '[ADMIN] customer - getEnquiry',
      message: `message: "${error.message}",\nresponse: { status: ${error.response.status}, message: "${error.response.data.message}" }`,
    });
    throwError(error);
    return null;
  }
};

// 데모신청 목록 조회
export const getDemoList = async (page: number) => {
  try {
    // todo
    const { data } = await axios.get(`${api}/demo?page=${page}&limit=10`, {
      withCredentials: true,
    });
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    createError({
      type: '[ADMIN] customer - getDemoList',
      message: `message: "${error.message}",\nresponse: { status: ${error.response.status}, message: "${error.response.data.message}" }`,
    });
    throwError(error);
    return null;
  }
};
// 이전데모신청 목록 조회
export const getDemoHistoryList = async (page: number) => {
  try {
    // todo
    const { data } = await axios.get(`${api}/demo/history?page=${page}&limit=10`, {
      withCredentials: true,
    });
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    createError({
      type: '[ADMIN] customer - getDemoHistoryList',
      message: `message: "${error.message}",\nresponse: { status: ${error.response.status}, message: "${error.response.data.message}" }`,
    });
    throwError(error);
    return null;
  }
};

// 데모신청 처리
export const updateDemo = async (element: { userId: string; accept: boolean }) => {
  await axios.patch(`${api}/demo/change`, { ...element }, { withCredentials: true });
};
