import { useTranslation } from 'react-i18next';

function FormItem({
  title,
  essential,
  children,
  warning,
}: {
  title: string;
  children: React.ReactNode | null;
  // eslint-disable-next-line react/require-default-props
  essential?: boolean;
  // eslint-disable-next-line react/require-default-props
  warning?: string;
}) {
  const { t } = useTranslation();
  return (
    <div className="formItem">
      <div className="subject">
        <span>
          {t(title)}
          {essential && <u>*</u>}
        </span>
        {warning && <div className="warning">{t(warning)}</div>}
      </div>
      <div className="option">{children}</div>
    </div>
  );
}

export default FormItem;
