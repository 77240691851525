import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { useMutation } from '@tanstack/react-query';
import { createError, throwError } from '../../api/error';
import { setPush } from '../../api/push';
import { useAlert } from '../../stores/alertStore';
import { useConfirm } from '../../stores/confirmStore';
import { memberList } from '../../stores/memberStore';

// components
import Page from '../../includes/page';
import FormItem from '../../components/basic/formItem';
import MemberList from '../../components/member/memberList';

function PushalarmEdit() {
  const { t } = useTranslation();
  const { code } = useParams();
  const navigate = useNavigate();
  const { alertShow } = useAlert();
  const { confirmShow } = useConfirm();

  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [type, setType] = useState('TOPIC');
  const [list, setList] = useRecoilState(memberList);
  // const [image, setImage] = useState('');

  const init = () => {
    setTitle('');
    setContent('');
    setType('TOPIC');
    setList({
      state: false,
      selected: {
        m_key: 0,
        mg_code: '',
        mr_code: '',
        mo_key: 0,
        m_id: '',
        m_name: '',
        m_gender: '',
        m_email: '',
        m_last_login_at: '',
        m_delete_at: '',
        created_at: '',
        updated_at: '',
        mo_name: '',
      },
      data: [],
    });
    // setImage('');
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => init(), []);

  const { mutate: create, isLoading: createLoading } = useMutation(setPush, {
    onSuccess: () => navigate(`/${code}/push`),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (e: any) => {
      createError({
        type: '[ADMIN] pushalarm - setPush',
        message: `message: "${e.message}",\nresponse: { status: ${e.response.status}, message: "${e.response.data.message}" }`,
      });
      throwError(e);
    },
  });

  const upload = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const obj: any = {};
    obj.title = title;
    obj.body = content;
    obj.type = type;
    if (type === 'TOKEN') obj.target = list.selected.m_key;
    // obj.image = image;
    create(obj);
  };

  return (
    <Page title="푸시알림등록" isLoading={createLoading}>
      <FormItem title="제목" essential>
        <div className="input">
          <input type="text" placeholder={t('제목') || ''} value={title} onChange={e => setTitle(e.target.value)} />
        </div>
      </FormItem>
      <FormItem title="내용">
        <div className="input">
          <textarea
            placeholder={t('내용') || ''}
            maxLength={200}
            value={content}
            onChange={e => setContent(e.target.value)}
          ></textarea>
          <div className="count">{content?.length || 0} / 200</div>
        </div>
      </FormItem>
      <FormItem title="유형">
        <div className="input">
          <select value={type} onChange={e => setType(e.target.value)}>
            <option value="TOPIC">{t('전체')}</option>
            <option value="TOKEN">{t('선택')}</option>
          </select>
          <div className="arrow"></div>
        </div>
      </FormItem>
      {type === 'TOKEN' && (
        <FormItem title="회원" essential>
          <div className="input">
            <input
              type="text"
              placeholder={t('회원') || ''}
              value={list.selected.m_name}
              readOnly
              className="combo"
              onClick={() => setList(prev => ({ ...prev, state: true }))}
            />
            <div className="arrow"></div>
          </div>
        </FormItem>
      )}
      {/* <FormItem title="섬네일" essential>
        <div className="file">
          <div className="uploaded" style={{ display: uploadImg ? '' : 'none' }}>
            <img src={uploadImg || ''} alt="img" ref={imgRef} />
          </div>
          <label htmlFor="img-upload">
            {uploadImgName === '' ? (
              <div className="placeholder">{t('섬네일 업로드 (용량 1024KB 이내의 jpg, png 파일)')}</div>
            ) : (
              <div className="text">{uploadImgName}</div>
            )}
            <input type="file" id="img-upload" onChange={e => changeThumb(e)} accept="image/*" />
          </label>
        </div>
      </FormItem> */}
      <div className="formSubmit">
        <button
          type="button"
          className="primary"
          onClick={() => {
            if (!title) {
              alertShow('제목을 입력하십시오.');
              return;
            }
            if (!content) {
              alertShow('내용을 입력하십시오.');
              return;
            }
            if (type === 'TOKEN' && !list.selected.m_name) {
              alertShow('회원을 선택하십시오.');
              return;
            }
            confirmShow('등록하시겠습니까?', upload);
          }}
        >
          {t('등록')}
        </button>
      </div>
      <MemberList />
    </Page>
  );
}

export default PushalarmEdit;
