import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { useConfirm } from '../../stores/confirmStore';
import { useAlert } from '../../stores/alertStore';
import { surveyProbState, useSurvey } from '../../stores/surveyStore';

// style
import styles from './item.module.scss';

// img
import imgO from '../../assets/img/images/img_o.svg';
import imgOActive from '../../assets/img/images/img_o_active.svg';
import imgX from '../../assets/img/images/img_x.svg';
import imgXActive from '../../assets/img/images/img_x_active.svg';
import { icDelete, icCreate } from '../aside/icon';

function DetailSurveyItem({ item, idx }: { item: ISurveyProps; idx: number }) {
  const { t } = useTranslation();
  const { alertShow } = useAlert();
  const { confirmShow } = useConfirm();
  const {
    editType,
    editQuestion,
    editEssensial,
    editSelect,
    removeSurveyProb,
    fixQuestion,
    fixEssensial,
    fixSelect,
    fixDelete,
  } = useSurvey();

  const surveyProbs = useRecoilValue(surveyProbState);

  const questionForm = (e: string) => {
    if (e === 'subjective') return styles.subjective;
    if (e === 'long') return styles.long;
    if (e === 'multiple') return styles.multiple;
    if (e === 'single') return styles.single;
    if (e === 'ox') return styles.ox;
    if (e === 'scope') return styles.scope;
    if (e === 'select') return styles.select;
    if (e === 'score') return styles.score;
    return '';
  };

  interface prob {
    key: number;
    text: string;
  }
  const [probs, setProbs] = useState<prob[]>([]);

  const setAnswer = (isScore?: boolean) => {
    const setObject = (key: number, text: string) => ({ key, text });
    const split = item.surveyProbSelect?.split('$');
    const array = split?.map((item: string) =>
      setObject(
        // eslint-disable-next-line no-restricted-globals
        isNaN(parseInt(item.split('#')[0], 10)) ? 1 : parseInt(item.split('#')[0], 10),
        isScore ? '3' : item.split('#')[1] || '',
      ),
    );
    setProbs(array);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setAnswer(), []);

  useEffect(
    () => {
      if (item.surveyProbKey === undefined) editSelect(probs.map(item => `${item.key}#${item.text}`).join('$'), idx);
      else fixSelect(probs.map(item => `${item.key}#${item.text}`).join('$'), Number(item.surveyProbKey));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [probs],
  );

  const scoreList = (e: number): JSX.Element[] => {
    const array = [];
    for (let i = 0; i < e; i += 1) {
      array.push(
        <div key={i} className={classNames(styles.score)}>
          {i}
        </div>,
      );
    }
    return array;
  };

  if (item.delete) return null;

  return (
    <div className={styles.container}>
      <div className={styles.question}>
        <div className={styles.num}>
          <span>
            {surveyProbs
              .map((item, index) => (item.delete ? index : -1))
              .filter(index => index !== -1)
              .filter(item => item < idx).length > 0
              ? (
                  idx -
                  surveyProbs
                    .map((item, index) => (item.delete ? index : -1))
                    .filter(index => index !== -1)
                    .filter(item => item < idx).length +
                  1
                ).toString().length < 2
                ? `0${
                    idx -
                    surveyProbs
                      .map((item, index) => (item.delete ? index : -1))
                      .filter(index => index !== -1)
                      .filter(item => item < idx).length +
                    1
                  }`
                : idx -
                  surveyProbs
                    .map((item, index) => (item.delete ? index : -1))
                    .filter(index => index !== -1)
                    .filter(item => item < idx).length +
                  1
              : (idx + 1).toString().length < 2
              ? `0${idx + 1}`
              : idx + 1}
          </span>
        </div>
        <div className={styles.text}>
          {item.surveyProbKey === undefined && (
            <select
              value={item.surveyProbType}
              onChange={async e => {
                await editType(e.target.value, idx);
                await editSelect(
                  e.target.value === 'subjective' || e.target.value === 'long' || e.target.value === 'ox' ? '' : '1#',
                  idx,
                );
                setAnswer(e.target.value === 'score');
              }}
            >
              <option value="subjective">{t('단답형')}</option>
              <option value="long">{t('장문형')}</option>
              <option value="single">{t('객관식')}</option>
              <option value="multiple">{t('체크박스')}</option>
              <option value="select">{t('드롭다운')}</option>
              <option value="ox">OX</option>
              <option value="scope">{t('선형배율')}</option>
              <option value="score">{t('점수')}</option>
            </select>
          )}
          <input
            type="text"
            value={item.surveyProbQuestion}
            onChange={e => {
              if (item.surveyProbKey) fixQuestion(e.target.value, item.surveyProbKey);
              else editQuestion(e.target.value, idx);
            }}
          />
        </div>
      </div>
      <div className={questionForm(item.surveyProbType)}>
        {item.surveyProbType === 'subjective' && (
          <div className={styles.subjective}>
            <input type="text" placeholder={t('단답형 텍스트') || ''} readOnly />
          </div>
        )}
        {item.surveyProbType === 'long' && (
          <div className={styles.long}>
            <textarea placeholder={t('장문형 텍스트') || ''} readOnly></textarea>
          </div>
        )}
        {item.surveyProbType === 'single' && (
          <div className={styles.single}>
            {probs.map(item => (
              <div className={styles.item} key={item.key}>
                <div className={styles.radio}></div>
                <input
                  type="text"
                  value={item.text}
                  onChange={e => {
                    if (/[#$%^,]/i.test(e.target.value)) return;
                    setProbs(
                      probs.map(ele => {
                        if (ele.key === item.key) return { key: ele.key, text: e.target.value };
                        return { key: ele.key, text: ele.text };
                      }),
                    );
                  }}
                />
                <object
                  onClick={() => {
                    if (probs.length === 1) return;
                    setProbs(
                      probs.filter(ele => ele.key !== item.key).map((e, idx) => ({ key: idx + 1, text: e.text })),
                    );
                  }}
                >
                  {icDelete()}
                </object>
              </div>
            ))}
            <div
              className={styles.item}
              onClick={() => {
                if (probs.length === 50) {
                  alertShow('최대 50개의 옵션을 생성할 수 있습니다.');
                  return;
                }
                setProbs([...probs, { key: probs.length + 1, text: '' }]);
              }}
            >
              <div className={styles.radio}></div>
              <div className={styles.add}>{t('옵션 추가')}</div>
              <object>{icCreate()}</object>
            </div>
          </div>
        )}
        {item.surveyProbType === 'multiple' && (
          <div className={styles.multiple}>
            {probs.map(item => (
              <div className={styles.item} key={item.key}>
                <div className={styles.checkbox}></div>
                <input
                  type="text"
                  value={item.text}
                  onChange={e => {
                    if (/[#$%^,]/i.test(e.target.value)) return;
                    setProbs(
                      probs.map(ele => {
                        if (ele.key === item.key) return { key: ele.key, text: e.target.value };
                        return { key: ele.key, text: ele.text };
                      }),
                    );
                  }}
                />
                <object
                  onClick={() => {
                    if (probs.length === 1) return;
                    setProbs(
                      probs.filter(ele => ele.key !== item.key).map((e, idx) => ({ key: idx + 1, text: e.text })),
                    );
                  }}
                >
                  {icDelete()}
                </object>
              </div>
            ))}
            <div
              className={styles.item}
              onClick={() => {
                setProbs([...probs, { key: probs.length + 1, text: '' }]);
              }}
            >
              <div className={styles.checkbox}></div>
              <div className={styles.add}>{t('옵션 추가')}</div>
              <object>{icCreate()}</object>
            </div>
          </div>
        )}
        {item.surveyProbType === 'select' && (
          <div className={styles.select}>
            {probs.map(item => (
              <div className={styles.item} key={item.key}>
                <input
                  type="text"
                  value={item.text}
                  onChange={e => {
                    if (/[#$%^,]/i.test(e.target.value)) return;
                    setProbs(
                      probs.map(ele => {
                        if (ele.key === item.key) return { key: ele.key, text: e.target.value };
                        return { key: ele.key, text: ele.text };
                      }),
                    );
                  }}
                />
                <object
                  onClick={() => {
                    if (probs.length === 1) return;
                    setProbs(
                      probs.filter(ele => ele.key !== item.key).map((e, idx) => ({ key: idx + 1, text: e.text })),
                    );
                  }}
                >
                  {icDelete()}
                </object>
              </div>
            ))}
            <div
              className={styles.item}
              onClick={() => {
                setProbs([...probs, { key: probs.length + 1, text: '' }]);
              }}
            >
              <div className={styles.add}>{t('옵션 추가')}</div>
              <object>{icCreate()}</object>
            </div>
          </div>
        )}
        {item.surveyProbType === 'ox' && (
          <div className={styles.ox}>
            <div className={styles.item}>
              <img src={imgO} alt="icon" />
              <img src={imgOActive} alt="icon" />
            </div>
            <div className={styles.item}>
              <img src={imgX} alt="icon" />
              <img src={imgXActive} alt="icon" />
            </div>
          </div>
        )}
        {item.surveyProbType === 'scope' && (
          <div className={styles.scope}>
            <div className={styles.item}>
              <div className={styles.subject}></div>
              <div className={styles.scores}>
                <div className={styles.score}>
                  <span>1</span>
                </div>
                <div className={styles.score}>
                  <span>2</span>
                </div>
                <div className={styles.score}>
                  <span>3</span>
                </div>
                <div className={styles.score}>
                  <span>4</span>
                </div>
                <div className={styles.score}>
                  <span>5</span>
                </div>
                <object style={{ opacity: 0, pointerEvents: 'none' }}>{icDelete()}</object>
              </div>
            </div>
            {probs.map(item => (
              <div className={styles.item} key={item.key}>
                <input
                  type="text"
                  placeholder={t('옵션') || ''}
                  value={item.text}
                  onChange={e => {
                    if (/[#$%^,]/i.test(e.target.value)) return;
                    setProbs(
                      probs.map(ele => {
                        if (ele.key === item.key) return { key: ele.key, text: e.target.value };
                        return { key: ele.key, text: ele.text };
                      }),
                    );
                  }}
                  className={styles.subject}
                />
                <div className={styles.scores}>
                  <div className={styles.score}>
                    <div className={styles.radio}></div>
                    <div className={styles.num}>{t('매우 나쁨')}</div>
                  </div>
                  <div className={styles.score}>
                    <div className={styles.radio}></div>
                    <div className={styles.num}>{t('나쁨')}</div>
                  </div>
                  <div className={styles.score}>
                    <div className={styles.radio}></div>
                    <div className={styles.num}>{t('보통')}</div>
                  </div>
                  <div className={styles.score}>
                    <div className={styles.radio}></div>
                    <div className={styles.num}>{t('좋음')}</div>
                  </div>
                  <div className={styles.score}>
                    <div className={styles.radio}></div>
                    <div className={styles.num}>{t('매우 좋음')}</div>
                  </div>
                </div>
                <object
                  onClick={() => {
                    if (probs.length === 1) return;
                    setProbs(
                      probs.filter(ele => ele.key !== item.key).map((e, idx) => ({ key: idx + 1, text: e.text })),
                    );
                  }}
                >
                  {icDelete()}
                </object>
              </div>
            ))}
            <div
              className={styles.item}
              onClick={() => {
                setProbs([...probs, { key: probs.length + 1, text: '' }]);
              }}
            >
              <div className={styles.add}>{t('옵션 추가')}</div>
              <object>{icCreate()}</object>
            </div>
          </div>
        )}
        {item.surveyProbType === 'score' && (
          <>
            <select value={probs[0]?.text || 3} onChange={e => setProbs([{ key: 1, text: e.target.value }])}>
              <option value="3">0 ~ 2</option>
              <option value="4">0 ~ 3</option>
              <option value="5">0 ~ 4</option>
              <option value="6">0 ~ 5</option>
              <option value="7">0 ~ 6</option>
              <option value="8">0 ~ 7</option>
              <option value="9">0 ~ 8</option>
              <option value="10">0 ~ 9</option>
              <option value="11">0 ~ 10</option>
            </select>
            <div className={styles.item}>{scoreList(parseInt(probs[0]?.text, 10))}</div>
          </>
        )}
      </div>
      <div className={styles.tools}>
        <div className={styles.toggle}>
          <div
            className={classNames(styles.item, item.surveyProbEssential && styles.active)}
            onClick={() => {
              if (item.surveyProbKey) fixEssensial(true, item.surveyProbKey ?? NaN);
              else editEssensial(true, idx);
            }}
          >
            {t('필수')}
          </div>
          <div
            className={classNames(styles.item, !item.surveyProbEssential && styles.active)}
            onClick={() => {
              if (item.surveyProbKey) fixEssensial(false, item.surveyProbKey ?? NaN);
              else editEssensial(false, idx);
            }}
          >
            {t('선택')}
          </div>
        </div>
        <object
          onClick={() => {
            confirmShow(
              '삭제하시겠습니까?',
              item.surveyProbKey === undefined
                ? () => removeSurveyProb(idx)
                : () => fixDelete(item.surveyProbKey ?? NaN),
            );
          }}
        >
          {icDelete()}
        </object>
      </div>
    </div>
  );
}

export default DetailSurveyItem;
