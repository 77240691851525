import classNames from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { useMutation } from '@tanstack/react-query';
import { createError, throwError } from '../../api/error';
import { createSalestipCategory, createSalestipCategoryGroup, editSalestipCategoryGroup } from '../../api/salestip';
import { categoryState, useCategory } from '../../stores/salestipStore';

// components
import Modal from '../modal/modal';
import SalestipCategoryEditItem from './categoryEditItem';

// style
import styles from './categoryEdit.module.scss';

// img
import { icAdd, icEditActive } from '../aside/icon';
import ModalLoading from '../modal/modalLoading';

function SalestipCategoryEdit({
  getCategoryGroup,
  getCategory,
}: {
  getCategoryGroup: () => void;
  getCategory: () => void;
}) {
  const { t } = useTranslation();

  const [titleEnable, setTitleEnable] = useState(false);
  const titleRef = useRef<HTMLInputElement>(null);

  const { mutate: createCategoryGroup, isLoading: createCategoryGroupLoading } = useMutation(
    createSalestipCategoryGroup,
    {
      onSuccess: () => {
        getCategoryGroup();
        setCategoryModal(false);
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (e: any) => {
        createError({
          type: '[ADMIN] salestip - createSalestipCategoryGroup',
          message: `message: "${e.message}",\nresponse: { status: ${e.response.status}, message: "${e.response.data.message}" }`,
        });
        throwError(e);
      },
    },
  );

  const categoryEdit = useRecoilValue(categoryState);
  const { setCategoryModal, setCategoryName } = useCategory();
  const [isChange, setIsChange] = useState(false);

  const { mutate: editCategoryGroup, isLoading: editCategoryGroupLoading } = useMutation(editSalestipCategoryGroup, {
    onSuccess: () => {
      getCategoryGroup();
      getCategory();
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (e: any) => {
      createError({
        type: '[ADMIN] salestip - editSalestipCategoryGroup',
        message: `message: "${e.message}",\nresponse: { status: ${e.response.status}, message: "${e.response.data.message}" }`,
      });
      throwError(e);
    },
  });

  const [addState, setAddState] = useState(false);
  const [name, setName] = useState('');
  const { mutate: createCategory, isLoading: createCategoryLoading } = useMutation(createSalestipCategory, {
    onSuccess: () => getCategory(),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (e: any) => {
      createError({
        type: '[ADMIN] salestip - createSalestipCategory',
        message: `message: "${e.message}",\nresponse: { status: ${e.response.status}, message: "${e.response.data.message}" }`,
      });
      throwError(e);
    },
  });

  useEffect(() => {
    setTitleEnable(false);
    setAddState(false);
  }, [categoryEdit.state]);

  return (
    <AnimatePresence>
      {categoryEdit.state && (
        <Modal close={() => setCategoryModal(false)}>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            className={styles.modal}
          >
            <div className={styles.title}>
              {/* eslint-disable-next-line no-restricted-globals */}
              {isNaN(categoryEdit.key) ? t('카테고리를 등록합니다.') : t('카테고리를 수정합니다.')}
            </div>
            <div className={styles.body}>
              {/* eslint-disable-next-line no-restricted-globals */}
              {isNaN(categoryEdit.key) && (
                <div className={classNames(styles.title, styles.active)}>
                  <input
                    type="text"
                    placeholder={t('그룹명') || ''}
                    value={categoryEdit.name}
                    onChange={e => {
                      setCategoryName(e.target.value);
                    }}
                    className={classNames(styles.create)}
                  />
                </div>
              )}
              <div
                className={classNames(styles.title, titleEnable && styles.active)}
                // eslint-disable-next-line no-restricted-globals
                style={{ display: !isNaN(categoryEdit.key) ? '' : 'none' }}
              >
                <input
                  type="text"
                  placeholder={t('그룹명') || ''}
                  value={categoryEdit.name}
                  readOnly={!titleEnable}
                  onChange={e => {
                    setCategoryName(e.target.value);
                    setIsChange(true);
                  }}
                  className={classNames(titleEnable && styles.active)}
                  ref={titleRef}
                />
                {!titleEnable ? (
                  <object
                    onClick={() => {
                      setTitleEnable(true);
                      titleRef.current?.focus();
                    }}
                    className={styles.edit}
                  >
                    {icEditActive()}
                  </object>
                ) : (
                  <div
                    className={styles.btn}
                    onClick={() => {
                      setTitleEnable(false);
                      if (!isChange) return;
                      setIsChange(false);
                      editCategoryGroup({ key: categoryEdit.key, name: categoryEdit.name });
                    }}
                  >
                    {t('확인')}
                  </div>
                )}
              </div>
              {/* eslint-disable-next-line no-restricted-globals */}
              <div className={styles.sublist} style={{ display: !isNaN(categoryEdit.key) ? '' : 'none' }}>
                <div className={styles.title}>
                  <span>{t('하위 카테고리')}</span>
                </div>
                <div className={styles.list}>
                  {categoryEdit.category.length === 0 && (
                    <div className={styles.empty}>
                      <span>{t('하위 카테고리를 생성하십시오.')}</span>
                    </div>
                  )}
                  {categoryEdit.category.map(item => (
                    <SalestipCategoryEditItem
                      key={item.key}
                      item={item}
                      refetch={getCategory}
                    ></SalestipCategoryEditItem>
                  ))}
                </div>
                <div className={styles.add}>
                  {addState ? (
                    <>
                      <input type="text" value={name} onChange={e => setName(e.target.value)} />
                      <div
                        className={classNames(styles.btn, styles.confirm)}
                        onClick={() => {
                          createCategory({ key: categoryEdit.key, name });
                          setName('');
                          setAddState(false);
                        }}
                      >
                        {t('생성')}
                      </div>
                      <div
                        className={styles.btn}
                        onClick={() => {
                          setName('');
                          setAddState(false);
                        }}
                      >
                        {t('취소')}
                      </div>
                    </>
                  ) : (
                    <object className={styles.icon} onClick={() => setAddState(true)}>
                      {icAdd()}
                    </object>
                  )}
                </div>
              </div>
              <div className={styles.btns}>
                <div
                  className={styles.btn}
                  onClick={() => {
                    // eslint-disable-next-line no-restricted-globals
                    if (isNaN(categoryEdit.key)) {
                      createCategoryGroup(categoryEdit.name);
                      return;
                    }
                    setCategoryModal(false);
                  }}
                >
                  {/* eslint-disable-next-line no-restricted-globals */}
                  {isNaN(categoryEdit.key) ? t('저장') : t('확인')}
                </div>
              </div>
            </div>
            <ModalLoading isLoading={createCategoryGroupLoading || editCategoryGroupLoading || createCategoryLoading} />
          </motion.div>
        </Modal>
      )}
    </AnimatePresence>
  );
}

export default SalestipCategoryEdit;
