import { motion, AnimatePresence } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { useQuery } from '@tanstack/react-query';
import { getSalestipCategoryGroupList, getSalestipCategoryList } from '../../api/salestip';
import { useAlert } from '../../stores/alertStore';
import { categorySelectorState } from '../../stores/salestipStore';

// components
import Modal from '../modal/modal';
import ModalLoading from '../modal/modalLoading';

// style
import styles from '../modal/selector.module.scss';
import SalestipCategorySelectorItem from './categorySelectorItem';

// eslint-disable-next-line react/require-default-props
function SalestipCategorySelector({ onSelect }: { onSelect?: (element: { key: number; name: string }) => void }) {
  const { t } = useTranslation();
  const { alertShow } = useAlert();
  const navigate = useNavigate();
  const { code } = useParams();

  const [categorySelector, setCategorySelector] = useRecoilState(categorySelectorState);

  const [cg, setCg] = useState<number[]>([]);
  const [data, setData] = useState<ISCG[]>([]);
  const { isFetching: getCategoryGroupLoading } = useQuery<{ data: ISCG[] }>(
    ['categoryGroup'],
    getSalestipCategoryGroupList,
    {
      refetchOnWindowFocus: false,
      enabled: categorySelector.state,
      onSuccess: e => {
        setData(e.data.map(item => ({ ...item, categoryKey: [] })));
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (e: any) => alertShow(e.response.data.message),
    },
  );

  useQuery<{ data: ISC[] }>(['category', cg], () => getSalestipCategoryList(cg), {
    refetchOnWindowFocus: false,
    enabled: cg.length > 0,
    onSuccess: e => {
      setData(prev => prev.map(item => (cg.includes(item.key) ? { ...item, children: e.data } : item)));
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (e: any) => alertShow(e.response.data.message),
  });

  useEffect(() => {
    setCategorySelector(prev => ({ ...prev, data: [], page: 1, categoryKey: [] }));
    setCg([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categorySelector.state]);

  return (
    <AnimatePresence>
      {categorySelector.state && (
        <Modal close={() => setCategorySelector(prev => ({ ...prev, state: false }))}>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            className={styles.modal}
          >
            <div className={styles.header} style={{ marginBottom: '8px' }}>
              <div className={styles.title}>{t('카테고리를 선택하십시오.')}</div>
              <button
                type="button"
                className="primary"
                onClick={() => {
                  setCategorySelector(prev => ({ ...prev, state: false }));
                  if (!onSelect)
                    navigate(
                      `/${code}/salestip?${
                        categorySelector.groupKey
                          ? `cg_key=${categorySelector.groupKey.key}`
                          : `c_key=${categorySelector.categoryKey.map(el => el.key).join(',')}`
                      }`,
                    );
                }}
              >
                {t('확인')}
              </button>
            </div>
            {data.map(item => (
              <SalestipCategorySelectorItem
                key={item.key}
                item={item}
                getChild={e =>
                  setCg(prev => {
                    if (prev.includes(e)) return prev.filter(el => el !== e);
                    return [e];
                  })
                }
                onSelect={onSelect}
              />
            ))}
            <ModalLoading isLoading={getCategoryGroupLoading} />
          </motion.div>
        </Modal>
      )}
    </AnimatePresence>
  );
}

export default SalestipCategorySelector;
