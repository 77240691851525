import { atom, useSetRecoilState } from 'recoil';

export const categoryEditState = atom<{ state: boolean; bcKey: number; type: string; name: string }>({
  key: 'categoryEdit',
  default: {
    state: false,
    bcKey: 0,
    type: '',
    name: '',
  },
});

export const categoryState = atom<{ state: boolean; data: ICategory[] }>({
  key: 'category',
  default: {
    state: false,
    data: [],
  },
});

export function useCategory() {
  const setCategoryState = useSetRecoilState(categoryState);

  const setCategoryModal = (toggle: boolean) => setCategoryState(prev => ({ ...prev, state: toggle }));

  const setCategoryData = (bc_key: number) =>
    setCategoryState(prev => ({
      ...prev,
      data: prev.data.map(item => (item.bc_key === bc_key ? { ...item, checked: true } : { ...item, checked: false })),
    }));

  return { setCategoryModal, setCategoryData };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const imgState = atom<{ file: any[]; img: any[]; imgName: string[] }>({
  key: 'boardImg',
  default: { file: [], img: [], imgName: [] },
});

export function useImg() {
  const setImg = useSetRecoilState(imgState);

  const initFile = () => setImg({ file: [], img: [], imgName: [] });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setFile = (file: any, img: any, imgName: string) =>
    setImg(prev => ({
      file: prev.file.concat(file),
      img: prev.img.concat(img),
      imgName: prev.imgName.concat(imgName),
    }));

  const setFileDelete = (idx: number) => {
    setImg(prev => ({
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      file: prev.file.filter((_: any, index: number) => idx !== index),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      img: prev.img.filter((_: any, index: number) => idx !== index),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      imgName: prev.imgName.filter((_: any, index: number) => idx !== index),
    }));
  };

  return { initFile, setFile, setFileDelete };
}
