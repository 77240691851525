import dayjs from 'dayjs';
import { motion, AnimatePresence } from 'framer-motion';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { customerInfoState, useCustomerInfo } from '../../stores/customerStore';

// components
import Modal from '../modal/modal';

// style
import styles from '../modal/selector.module.scss';

function CustomerInfo() {
  const { t } = useTranslation();
  const [cookies] = useCookies(['xclass-theme']);
  const info = useRecoilValue(customerInfoState);
  const { setCustomerInfo } = useCustomerInfo();

  return (
    <AnimatePresence>
      {info.state && (
        <Modal
          close={() =>
            setCustomerInfo({
              state: false,
              data: {
                c_key: 0,
                c_code: '',
                c_name: '',
                c_favicon_path: '',
                c_light_color: '',
                c_dark_color: '',
                c_watermark: '',
                c_modules: '',
                c_post_code: '',
                c_address: '',
                c_address_detail: '',
                c_admin_id: '',
                c_admin_name: '',
                c_admin_phone: '',
                c_admin_email: '',
                c_start_at: '',
                c_end_at: '',
                c_active: '',
                c_api_key: null,
                c_user_limit: 0,
                c_traffic_limit: 0,
                c_storage_limit: 0,
                c_delete_at: null,
                created_at: '',
                updated_at: '',
              },
            })
          }
        >
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            className={styles.modal}
          >
            <div className={styles.title}>{t('고객정보')}</div>
            <table className="table">
              <tbody>
                <tr>
                  <td>
                    <div>
                      <span style={{ fontWeight: '500', minWidth: '100px' }}>{t('기업코드')}</span>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span>{info.data.c_code}</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <span style={{ fontWeight: '500', minWidth: '100px' }}>{t('기업명')}</span>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span>{info.data.c_name}</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <span style={{ fontWeight: '500', minWidth: '100px' }}>{t('기본 로고')}</span>
                    </div>
                  </td>
                  <td>
                    <div>
                      <img
                        src={`${process.env.REACT_APP_UPLOADED_URL}/xclass/image/customer/${info.data.c_code}_LIGHT_LOGO`}
                        alt="img"
                        className="logo"
                        style={{ backgroundColor: 'white' }}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <span style={{ fontWeight: '500', minWidth: '100px' }}>{t('다크모드 로고')}</span>
                    </div>
                  </td>
                  <td>
                    <div>
                      <img
                        src={`${process.env.REACT_APP_UPLOADED_URL}/xclass/image/customer/${info.data.c_code}_DARK_LOGO`}
                        alt="img"
                        className="logo"
                        style={{
                          backgroundColor: cookies['xclass-theme'] === 'dark' ? 'var(--background)' : 'var(--greyish1)',
                        }}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <span style={{ fontWeight: '500', minWidth: '100px' }}>{t('파비콘')}</span>
                    </div>
                  </td>
                  <td>
                    <div>
                      {info.data.c_favicon_path && (
                        <img
                          src={`${process.env.REACT_APP_UPLOADED_URL}/xclass/image/customer/${info.data.c_favicon_path}`}
                          alt="favicon"
                          className="logo"
                          style={{ width: '20px' }}
                        />
                      )}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <span style={{ fontWeight: '500', minWidth: '100px' }}>{t('컬러')}</span>
                    </div>
                  </td>
                  <td>
                    <div style={{ color: info.data.c_light_color }}>{info.data.c_light_color}</div>
                    <div style={{ color: info.data.c_dark_color }}>{info.data.c_dark_color}</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <span style={{ fontWeight: '500', minWidth: '100px' }}>{t('워터마크')}</span>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span>{info.data.c_watermark}</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <span style={{ fontWeight: '500', minWidth: '100px' }}>{t('모듈')}</span>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span>{info.data.c_modules}</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <span style={{ fontWeight: '500', minWidth: '100px' }}>{t('주소')}</span>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span>
                        {info.data.c_post_code ? `[${info.data.c_post_code}] ` : ''} {info.data.c_address}{' '}
                        {info.data.c_address_detail}
                      </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <span style={{ fontWeight: '500', minWidth: '100px' }}>{t('관리자 아이디')}</span>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span>{info.data.c_admin_id}</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <span style={{ fontWeight: '500', minWidth: '100px' }}>{t('관리자 이름')}</span>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span>{info.data.c_admin_name}</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <span style={{ fontWeight: '500', minWidth: '100px' }}>{t('연락처')}</span>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span>{info.data.c_admin_phone}</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <span style={{ fontWeight: '500', minWidth: '100px' }}>{t('이메일')}</span>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span>{info.data.c_admin_email}</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <span style={{ fontWeight: '500', minWidth: '100px' }}>{t('기간')}</span>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span>
                        {dayjs(info.data.c_start_at).format('YYYY-MM-DD HH:mm')} ~{' '}
                        {dayjs(info.data.c_end_at).format('YYYY-MM-DD HH:mm')}
                      </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <span style={{ fontWeight: '500', minWidth: '100px' }}>{t('상태')}</span>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span>{info.data.c_active === 'Y' ? t('활성화') : t('비활성화')}</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <span style={{ fontWeight: '500', minWidth: '100px' }}>API</span>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span>{info.data.c_api_key || ''}</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <span style={{ fontWeight: '500', minWidth: '100px' }}>{t('유저 제한')}</span>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span>{info.data.c_user_limit}</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <span style={{ fontWeight: '500', minWidth: '100px' }}>{t('트래픽')}</span>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span>{info.data.c_traffic_limit}</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <span style={{ fontWeight: '500', minWidth: '100px' }}>{t('저장공간')}</span>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span>{info.data.c_storage_limit}</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <span style={{ fontWeight: '500', minWidth: '100px' }}>{t('삭제')}</span>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span>
                        {info.data.c_delete_at ? dayjs(info.data.c_delete_at).format('YYYY-MM-DD HH:mm') : ''}
                      </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <span style={{ fontWeight: '500', minWidth: '100px' }}>{t('생성')}</span>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span>{dayjs(info.data.created_at).format('YYYY-MM-DD HH:mm')}</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <span style={{ fontWeight: '500', minWidth: '100px' }}>{t('수정')}</span>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span>{dayjs(info.data.updated_at).format('YYYY-MM-DD HH:mm')}</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </motion.div>
        </Modal>
      )}
    </AnimatePresence>
  );
}

export default CustomerInfo;
