import classNames from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { languageState, useLanguage } from '../../stores/layoutStore';

// components
import Modal from './modal';

// style
import styles from './selector.module.scss';

function Language() {
  const { t } = useTranslation();
  const [language, setLanguage] = useRecoilState(languageState);
  const { changeLanguage } = useLanguage();

  return (
    <AnimatePresence>
      {language.state && (
        <Modal close={() => setLanguage(prev => ({ ...prev, state: false }))}>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            className={classNames(styles.modal, styles.lang)}
          >
            <div className={styles.title}>{t('언어를 선택하십시오.')}</div>
            <table className="table">
              <thead>
                <tr>
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <th></th>
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {!language.list && (
                  <tr>
                    <td colSpan={2}>
                      <div>{t('검색된 목록이 없습니다.')}</div>
                    </td>
                  </tr>
                )}
                {language.list.map(item => (
                  <tr key={item.lang} style={{ backgroundColor: item.selected ? 'var(--hover)' : '' }}>
                    <td>
                      <div>
                        <span>{item.mark}</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        {!item.selected && (
                          <button
                            type="button"
                            className="success"
                            onClick={() => {
                              changeLanguage(item.lang);
                              setLanguage(prev => ({ ...prev, state: false }));
                            }}
                          >
                            <span>{t('선택')}</span>
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </motion.div>
        </Modal>
      )}
    </AnimatePresence>
  );
}

export default Language;
