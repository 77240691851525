import DaumPostcodeEmbed from 'react-daum-postcode';
import { motion, AnimatePresence } from 'framer-motion';
import { useRecoilState } from 'recoil';
import { addressState } from '../../stores/customerStore';

// components
import Modal from '../modal/modal';

// style
import styles from './address.module.scss';

function Address() {
  const [address, setAddress] = useRecoilState(addressState);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleComplete = (data: any) => {
    let fullAddress = data.address;
    let extraAddress = '';
    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }
    setAddress({ state: false, postCode: data.zonecode, address: fullAddress });
  };
  return (
    <AnimatePresence>
      {address.state && (
        <Modal close={() => setAddress(prev => ({ ...prev, state: false }))}>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            className={styles.modal}
          >
            <DaumPostcodeEmbed onComplete={handleComplete} />
          </motion.div>
        </Modal>
      )}
    </AnimatePresence>
  );
}

export default Address;
