import dayjs from 'dayjs';
import queryString from 'query-string';
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Viewer } from '@toast-ui/react-editor';
import { getAnnouncement } from '../../api/customer';

// components
import Page from '../../includes/page';

function AnnouncementDetail() {
  const [cookies] = useCookies(['xclass-theme']);
  const { search } = useLocation();
  const { cn_key } = queryString.parse(search);

  const [title, setTitle] = useState('');
  const [date, setDate] = useState('');
  const [description, setDescription] = useState('');

  const { isFetching: getNoticeLoading } = useQuery<{ data: IAnnouncement }>(
    ['getAnnouncement'],
    () => getAnnouncement(Number(cn_key)),
    {
      refetchOnWindowFocus: false,
      enabled: cn_key !== undefined,
      onSuccess: e => {
        setTitle(e.data.cn_title);
        setDate(e.data.created_at);
        setDescription(e.data.cn_contents);
      },
    },
  );

  return (
    <Page title={title} goBack="/board/notice" isLoading={getNoticeLoading}>
      <div className="etc-info">
        <div className="item">{dayjs(date).format('YYYY-MM-DD HH:mm')}</div>
      </div>
      <div className="viewer">
        {description && <Viewer initialValue={description} theme={cookies['xclass-theme']} />}
      </div>
    </Page>
  );
}

export default AnnouncementDetail;
