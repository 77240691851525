import classNames from 'classnames';
import ko from 'date-fns/locale/ko';
import queryString from 'query-string';
import DatePicker from 'react-datepicker';
import { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getCustomer } from '../../api/customer';
import { useAlert } from '../../stores/alertStore';

// components
import Page from '../../includes/page';
import FormItem from '../../components/basic/formItem';

// style
import styles from './refund.module.scss';

function CustomerRefund() {
  const { t } = useTranslation();
  const [cookies] = useCookies(['xclass-lang', 'xclass-theme']);
  const { search } = useLocation();
  const { c_key } = queryString.parse(search);
  const { alertShow } = useAlert();

  const [startAt, setStartAt] = useState<Date | null>(new Date());
  const [endAt, setEndAt] = useState<Date | null>(new Date());
  const [userLimit, setUserLimit] = useState(0);
  const [trafficLimit, setTrafficLimit] = useState(0);
  const [storageLimit, setStorageLimit] = useState(0);
  const [modules, setModules] = useState<string[]>([]);
  const modulesArray = ['EVALUATION', 'INSPECTION', 'LIVE', 'MEETING', 'SALES', 'SURVEY', 'VOD', 'LEARNINGTALK'];

  const price = {
    price: [
      {
        p_key: 1,
        p_name: 'VOD',
        p_name_kor: 'VOD',
        p_type: 'module',
        p_base_amount: 10,
        p_unit: null,
        p_essential_traffic: true,
        p_essential_storage: true,
      },
      {
        p_key: 2,
        p_name: 'SALES',
        p_name_kor: '세일즈팁',
        p_type: 'module',
        p_base_amount: 3,
        p_unit: null,
        p_essential_traffic: false,
        p_essential_storage: true,
      },
      {
        p_key: 3,
        p_name: 'LEARNINGTALK',
        p_name_kor: '러닝톡',
        p_type: 'module',
        p_base_amount: 5,
        p_unit: null,
        p_essential_traffic: true,
        p_essential_storage: true,
      },
      {
        p_key: 4,
        p_name: 'INSPECTION',
        p_name_kor: '현장점검',
        p_type: 'module',
        p_base_amount: 5,
        p_unit: null,
        p_essential_traffic: false,
        p_essential_storage: true,
      },
      {
        p_key: 5,
        p_name: 'SURVEY',
        p_name_kor: '설문',
        p_type: 'module',
        p_base_amount: 3,
        p_unit: null,
        p_essential_traffic: false,
        p_essential_storage: false,
      },
      {
        p_key: 6,
        p_name: 'EVALUATION',
        p_name_kor: '평가',
        p_type: 'module',
        p_base_amount: 3,
        p_unit: null,
        p_essential_traffic: false,
        p_essential_storage: false,
      },
      {
        p_key: 7,
        p_name: 'MEETING',
        p_name_kor: '회의',
        p_type: 'module',
        p_base_amount: 10,
        p_unit: null,
        p_essential_traffic: true,
        p_essential_storage: true,
      },
      {
        p_key: 8,
        p_name: 'LIVE',
        p_name_kor: '라이브',
        p_type: 'module',
        p_base_amount: 10,
        p_unit: null,
        p_essential_traffic: true,
        p_essential_storage: true,
      },
      {
        p_key: 9,
        p_name: 'traffic',
        p_name_kor: '트래픽',
        p_type: 'limit',
        p_base_amount: 250,
        p_unit: 100,
        p_essential_traffic: false,
        p_essential_storage: false,
      },
      {
        p_key: 10,
        p_name: 'storage',
        p_name_kor: '저장공간',
        p_type: 'limit',
        p_base_amount: 70,
        p_unit: 100,
        p_essential_traffic: false,
        p_essential_storage: false,
      },
      {
        p_key: 11,
        p_name: 'base',
        p_name_kor: '기본요금',
        p_type: 'base',
        p_base_amount: 30,
        p_unit: null,
        p_essential_traffic: false,
        p_essential_storage: false,
      },
    ],
    discount: {
      '100': 5,
      '500': 10,
      '1000': 15,
      '5000': 20,
    },
  };
  const subscribe = {
    subscribe: {
      sb_key: 24,
      sb_user_id: 'jumi1008',
      sb_start_date: '2023-09-19T00:00:00.000Z',
      sb_end_date: '2023-10-19T00:00:00.000Z',
      sb_period: 'month',
      sb_autopay: false,
      sb_user_limit: 10,
      sb_storage_limit: 700,
      sb_traffic_limit: 0,
      sb_have_modules: 'SALES,EVALUATION',
      sb_enable: true,
      sb_customer_code: null,
      sb_demo: false,
    },
    price: 850,
  };

  useEffect(() => {
    if (Number(endAt) < Number(startAt)) setEndAt(startAt);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startAt, endAt]);

  const { isFetching: getCustomerLoading } = useQuery<{ data: ICustomer }>(
    ['customer'],
    () => getCustomer(Number(c_key)),
    {
      refetchOnWindowFocus: false,
      enabled: c_key !== undefined,
      onSuccess: e => {
        setStartAt(e.data.c_start_at ? new Date(e.data.c_start_at) : null);
        setEndAt(e.data.c_end_at ? new Date(e.data.c_end_at) : null);
        setUserLimit(e.data.c_user_limit);
        setTrafficLimit(e.data.c_traffic_limit);
        setStorageLimit(e.data.c_storage_limit);
        setModules(typeof e.data.c_modules === 'string' ? e.data.c_modules.split(',') : []);
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (e: any) => alertShow(e.response.data.message),
    },
  );

  const init = () => {
    setStartAt(new Date());
    setEndAt(new Date());
    setUserLimit(0);
    setTrafficLimit(0);
    setStorageLimit(0);
    setModules([]);
  };
  useEffect(() => init(), [search]);

  const discount = [
    { number: 10, discount: 0 },
    ...Object.entries(price.discount).map(v => ({
      number: v[0],
      discount: v[1],
    })),
  ];
  const number = subscribe.subscribe.sb_user_limit;
  const type = subscribe.subscribe.sb_period;

  const getPriceNoDiscount = (v: any) => {
    return ((v * (100 - discount[0].discount)) / 100) * number * (type === 'year' ? 12 : 1);
  };

  const getPrice = (v: any) => {
    const discount = [
      { number: 10, discount: 0 },
      ...Object.entries(price.discount).map(v => ({
        number: v[0],
        discount: v[1],
      })),
    ];
    if (number < 100) {
      return ((v * (100 - discount[0].discount)) / 100) * number * (type === 'year' ? 10 : 1);
    }
    if (number < 500) {
      return ((v * (100 - discount[1].discount)) / 100) * number * (type === 'year' ? 10 : 1);
    }
    if (number < 1000) {
      return ((v * (100 - discount[2].discount)) / 100) * number * (type === 'year' ? 10 : 1);
    }
    if (number < 5000) {
      return ((v * (100 - discount[3].discount)) / 100) * number * (type === 'year' ? 10 : 1);
    }
    return ((v * (100 - discount[4].discount)) / 100) * number * (type === 'year' ? 10 : 1);
  };

  const getAllPriceNoDiscount = () => {
    let data = getPriceNoDiscount(price.price.find(item => item.p_name === 'base')?.p_base_amount); // 기본요금
    price.price
      .map(item => ({ ...item, checked: true }))
      .forEach(v => {
        if (v.checked && v.p_type === 'module') data += getPriceNoDiscount(v.p_base_amount);
      });
    price.price
      .filter(item => item.p_type === 'limit')
      .forEach(v => {
        if ((v.p_name === 'traffic' && v.p_essential_traffic) || (v.p_name === 'storage' && v.p_essential_storage))
          data += Number(v.p_base_amount * (type === 'year' ? 12 : 1));
      });
    return data;
  };

  const getAllPrice = () => {
    let data = getPrice(price.price.find(item => item.p_name === 'base')?.p_base_amount);
    price.price
      .map(item => ({ ...item, checked: true }))
      .forEach(v => {
        if (v.checked && v.p_type === 'module') data += getPrice(v.p_base_amount);
      });
    price.price
      .filter(item => item.p_type === 'limit')
      .forEach(v => {
        if ((v.p_name === 'traffic' && v.p_essential_traffic) || (v.p_name === 'storage' && v.p_essential_storage))
          data += Number(v.p_base_amount * (type === 'year' ? 10 : 1));
      });
    return data;
  };

  return (
    <Page title="환불" isLoading={getCustomerLoading}>
      <FormItem title="환불 항목" essential>
        <div className="input">
          <select>
            <option value="ALL">{t('결제 이용료 전체 환불')}</option>
            <option value="CHOICE">{t('결제 이용료 부분 환불')}</option>
          </select>
          <div className="arrow"></div>
        </div>
      </FormItem>
      <FormItem title="모듈" essential>
        <div className="list">
          <div
            className={classNames(
              'item',
              modules.length === modulesArray.length &&
                modules.every((value, index) => value === modulesArray[index]) &&
                'active',
            )}
            onClick={() =>
              setModules(prev => {
                if (prev.length !== modulesArray.length) return modulesArray;
                return [];
              })
            }
          >
            <div className="text">{t('전체 선택')}</div>
            <div className="check"></div>
          </div>
        </div>
        <div className="list">
          {modulesArray.map(item => (
            <div
              className={classNames('item', modules.includes(item) && 'active')}
              key={item}
              onClick={() =>
                setModules(prev => (prev.includes(item) ? prev.filter(el => el !== item) : prev.concat(item)))
              }
            >
              <div className="text">
                {t(
                  item === 'EVALUATION'
                    ? '평가'
                    : item === 'INSPECTION'
                    ? '체크리스트'
                    : item === 'LIVE'
                    ? '라이브'
                    : item === 'MEETING'
                    ? '회의'
                    : item === 'SALES'
                    ? '세일즈팁'
                    : item === 'SURVEY'
                    ? '설문'
                    : item === 'LEARNINGTALK'
                    ? '러닝톡톡'
                    : item,
                )}
              </div>
              <div className="check"></div>
            </div>
          ))}
        </div>
      </FormItem>
      <FormItem title="기간" essential>
        <div className="input" style={{ maxWidth: '608px' }}>
          <DatePicker
            selected={startAt}
            onChange={date => setStartAt(date)}
            minDate={null}
            maxDate={endAt}
            selectsStart
            startDate={startAt}
            endDate={endAt}
            locale={cookies['xclass-lang'] === 'ko' ? ko : undefined}
            dateFormat="yyyy-MM-dd"
            disabledKeyboardNavigation
            onChangeRaw={(e: React.FormEvent<HTMLInputElement>) => e.preventDefault()}
          />
          <div style={{ margin: '0 8px', color: 'var(--greyish1)' }}>-</div>
          <DatePicker
            selected={endAt}
            onChange={date => setEndAt(date)}
            maxDate={null}
            selectsEnd
            startDate={startAt}
            endDate={endAt}
            locale={cookies['xclass-lang'] === 'ko' ? ko : undefined}
            dateFormat="yyyy-MM-dd"
            disabledKeyboardNavigation
            onChangeRaw={(e: React.FormEvent<HTMLInputElement>) => e.preventDefault()}
          />
        </div>
      </FormItem>
      <FormItem title="사용자 수" essential>
        <div className="input">
          <input
            type="text"
            placeholder={t('사용자 수') || ''}
            value={userLimit}
            onChange={e => setUserLimit(Number(e.target.value.replace(/[^0-9]/g, '')))}
          />
          <div className="unit">
            <span>{t('명', { count: undefined })}</span>
          </div>
        </div>
      </FormItem>
      <FormItem title="트래픽" essential>
        <div className="input">
          <input
            type="text"
            placeholder={t('트래픽') || ''}
            value={trafficLimit}
            onChange={e => setTrafficLimit(Number(e.target.value.replace(/[^0-9]/g, '')))}
          />
          <div className="unit">
            <span>GB</span>
          </div>
        </div>
      </FormItem>
      <FormItem title="저장공간" essential>
        <div className="input">
          <input
            type="text"
            placeholder={t('저장공간') || ''}
            value={storageLimit}
            onChange={e => setStorageLimit(Number(e.target.value.replace(/[^0-9]/g, '')))}
          />
          <div className="unit">
            <span>GB</span>
          </div>
        </div>
      </FormItem>
      <div className={styles.result}>
        <div className={styles.item}>
          <div className={styles.subject}>
            <span>{t('개별 항목')}</span>
          </div>
          <div className={styles.list}>
            <div className={styles.subitem}>
              <div className={styles.subject}>{t('구독 요금제')}</div>
              <div className={styles.text}>
                {subscribe.subscribe.sb_period === 'month' ? t('월간 플랜') : t('연간 플랜')}
              </div>
            </div>
            {price.price.map(item => (
              <div className={styles.subitem}>
                <div className={styles.subject}>{t(item.p_name_kor)}</div>
                <div className={styles.text}>{item.p_base_amount}</div>
              </div>
            ))}
            <div className={styles.subitem}>
              <div className={styles.subject}>VOD</div>
              <div className={styles.text}>2,000원</div>
            </div>
            <div className={styles.subitem}>
              <div className={styles.subject}>설문조사</div>
              <div className={styles.text}>1,500원</div>
            </div>
          </div>
        </div>
        <hr />
        <div className={styles.item}>
          <div className={styles.subject}>
            <span>{t('최종 환불금액')}</span>
          </div>
          <div className={styles.price}>
            {getAllPriceNoDiscount() > getAllPrice() && (
              <div className={styles.totalEtc}>
                {getAllPriceNoDiscount().toLocaleString('ko')}
                {t('원')}
              </div>
            )}
            <div>
              {getAllPrice().toLocaleString('ko')}
              {t('원')}
            </div>
          </div>
        </div>
      </div>
      <div className="formSubmit">
        <button type="button" className="primary" onClick={() => {}}>
          {t('확인')}
        </button>
      </div>
    </Page>
  );
}

export default CustomerRefund;
