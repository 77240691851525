import classNames from 'classnames';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { useMutation, useQuery } from '@tanstack/react-query';
import { createError, throwError } from '../../api/error';
import { createCategory, deleteCategory, editCategory, getCategoryList } from '../../api/board';
import { useAlert } from '../../stores/alertStore';
import { useConfirm } from '../../stores/confirmStore';
import { categoryEditState } from '../../stores/boardStore';

// components
import Page from '../../includes/page';
import CategoryEdit from '../../components/board/categoryEdit';

function Category() {
  const { t } = useTranslation();
  const { search } = useLocation();
  const { selected } = queryString.parse(search);
  const { alertShow } = useAlert();
  const { confirmShow } = useConfirm();
  const [type, setType] = useState('NOTICE');
  const [data, setData] = useState<ICategory[]>([]);
  useEffect(() => {
    if (typeof selected === 'string') setType(selected);
  }, [selected]);
  const { isFetching: getCategoryListLoading, refetch } = useQuery<{ count: number; data: ICategory[] }>(
    ['categoryList', type],
    () => getCategoryList(type),
    {
      refetchOnWindowFocus: false,
      onSuccess: e => {
        setData(e.data.map(item => ({ ...item, checked: false })));
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (e: any) => alertShow(e.response.data.message),
    },
  );

  const { mutate: create, isLoading: createLoading } = useMutation(createCategory, {
    onSuccess: () => refetch(),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (e: any) => {
      createError({
        type: '[ADMIN] board - createCategory',
        message: `message: "${e.message}",\nresponse: { status: ${e.response.status}, message: "${e.response.data.message}" }`,
      });
      throwError(e);
    },
  });

  const setCategoryEdit = useSetRecoilState(categoryEditState);
  const { mutate: update, isLoading: updateLoading } = useMutation(editCategory, {
    onSuccess: () => refetch(),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (e: any) => {
      createError({
        type: '[ADMIN] board - editCategory',
        message: `message: "${e.message}",\nresponse: { status: ${e.response.status}, message: "${e.response.data.message}" }`,
      });
      throwError(e);
    },
  });

  const { mutate: remove, isLoading: deleteLoading } = useMutation(deleteCategory, {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (e: any) => {
      createError({
        type: '[ADMIN] board - deleteCategory',
        message: `message: "${e.message}",\nresponse: { status: ${e.response.status}, message: "${e.response.data.message}" }`,
      });
      throwError(e);
    },
  });
  const selectDelete = () =>
    confirmShow('선택한 항목을 삭제하시겠습니까?', () => {
      const checked = data.filter(item => item.checked);
      for (let i = 0; i < checked.length; i += 1) remove(checked[i].bc_key);
    });

  useEffect(() => {
    if (!deleteLoading) refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteLoading]);

  const allSelect = () => {
    setData(
      data.map(item => ({
        ...item,
        checked: data.filter(item => item.checked).length !== data.length,
      })),
    );
  };

  return (
    <Page title="커뮤니티카테고리" isLoading={createLoading || updateLoading || deleteLoading}>
      <div className="table-tools">
        <div className="btns">
          <button type="button" className="danger" onClick={allSelect}>
            <span>{t('전체선택')}</span>
          </button>
          <button
            type="button"
            className="primary"
            onClick={() => setCategoryEdit({ state: true, bcKey: NaN, type, name: '' })}
          >
            <span>{t('등록')}</span>
          </button>
          <button
            type="button"
            className={classNames('secondary', data.filter(item => item.checked).length === 0 && 'disabled')}
            onClick={selectDelete}
          >
            <span>{t('선택삭제')}</span>
          </button>
        </div>
        <div className="filter">
          <select value={type} onChange={e => setType(e.target.value)}>
            <option value="NOTICE">{t('공지사항')}</option>
            <option value="FREE">{t('자유게시판')}</option>
            <option value="QNA">Q&A</option>
          </select>
          <div className="arrow"></div>
        </div>
      </div>
      <div className="table-tools">
        <div className="btns"></div>
        {data.filter(item => item.checked).length > 0 && (
          <div className="selected">
            {t('개의 항목을 선택했습니다.', { amount: data.filter(item => item.checked).length })}
          </div>
        )}
      </div>
      <div className="table-container">
        <table className="table">
          <thead>
            <tr>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <th></th>
              <th>{t('유형')}</th>
              <th>{t('이름')}</th>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {getCategoryListLoading && (
              <tr>
                <td colSpan={9}>
                  <div>
                    <div className="lds-spinner">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </td>
              </tr>
            )}
            {!getCategoryListLoading && data.length === 0 && (
              <tr>
                <td colSpan={4}>
                  <div>{t('검색된 목록이 없습니다.')}</div>
                </td>
              </tr>
            )}
            {data.map(item => (
              <tr key={item.bc_key}>
                <td>
                  <div>
                    <div
                      className={classNames(
                        'checkbox',
                        data.filter(ele => ele.bc_key === item?.bc_key)[0]?.checked && 'active',
                      )}
                      onClick={() => {
                        setData(
                          data.map(ele => {
                            if (ele.bc_key === item.bc_key) return { ...ele, checked: !ele.checked };
                            return ele;
                          }),
                        );
                      }}
                    ></div>
                  </div>
                </td>
                <td>
                  <div>
                    <span>
                      {item.bc_type === 'NOTICE' && t('공지사항')}
                      {item.bc_type === 'FREE' && t('자유게시판')}
                      {item.bc_type === 'QNA' && 'Q&A'}
                    </span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>{item.bc_name}</span>
                  </div>
                </td>
                <td>
                  <div>
                    <button
                      type="button"
                      className="primary"
                      onClick={() =>
                        setCategoryEdit({ state: true, bcKey: item.bc_key, type: item.bc_type, name: item.bc_name })
                      }
                    >
                      <span>{t('수정')}</span>
                    </button>
                    <button
                      type="button"
                      className="secondary"
                      onClick={() => confirmShow('삭제하시겠습니까?', () => remove(item.bc_key))}
                    >
                      <span>{t('삭제')}</span>
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <CategoryEdit create={create} update={update} />
    </Page>
  );
}

export default Category;
