import axios from 'axios';
import classNames from 'classnames';
import ko from 'date-fns/locale/ko';
import dayjs from 'dayjs';
import queryString from 'query-string';
import DatePicker from 'react-datepicker';
import { useState, useEffect, useRef } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { useMutation, useQuery } from '@tanstack/react-query';
import { createError, throwError } from '../../api/error';
import { getMemberRole } from '../../api/member';
import { createCustomer, editCustomer, getCustomer, getCustomerTest } from '../../api/customer';
import { useAlert } from '../../stores/alertStore';
import { useConfirm } from '../../stores/confirmStore';
import { addressState } from '../../stores/customerStore';

// components
import Page from '../../includes/page';
import FormItem from '../../components/basic/formItem';
import Address from '../../components/customer/address';
import ColorItem from '../../components/customer/colorItem';

// img
import logoEmpty from '../../assets/img/logo_empty.svg';
import faviconEmpty from '../../assets/img/favicon_empty.svg';

const api = process.env.REACT_APP_API;

function CustomerEdit() {
  const { t } = useTranslation();
  const [cookies] = useCookies(['xclass-lang', 'xclass-theme']);
  const { code } = useParams();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { c_key } = queryString.parse(search);
  const { alertShow } = useAlert();
  const { confirmShow } = useConfirm();

  // 기업코드
  const [companyCode, setCompanyCode] = useState('');
  const [codeTestStart, setCompanyCodeTestStart] = useState(false);
  const [codeTestComplete, setCompanyCodeTestComplete] = useState(false);
  const { isFetching: getCustomerTestLoading } = useQuery<{ data?: number }>(
    ['getCustomerTest', companyCode],
    () => getCustomerTest(companyCode),
    {
      refetchOnWindowFocus: false,
      enabled: codeTestStart,
      onSuccess: e => {
        if (e.data) {
          alertShow('중복된 기업코드가 있습니다.');
          setCompanyCodeTestStart(false);
        } else {
          alertShow('사용가능한 기업코드입니다.');
          setCompanyCodeTestComplete(true);
        }
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (e: any) => alertShow(e.response.data.message),
    },
  );

  const [name, setName] = useState('');
  // 로고
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [uploadFile, setUploadFile] = useState<{ dark: any; light: any }>({ dark: null, light: null });
  const [uploadImg, setUploadImg] = useState<{ dark: string | null; light: string | null }>({
    dark: null,
    light: null,
  });
  const [uploadImgName, setUploadImgName] = useState<{ dark: string; light: string }>({ dark: '', light: '' });
  const imgRefLight = useRef<HTMLImageElement>(null);
  const imgRefDark = useRef<HTMLImageElement>(null);
  const changeLogo = (event: React.ChangeEvent<HTMLInputElement>, type: string) => {
    if (!event?.target?.files) {
      setUploadFile(prev => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const obj: any = { ...prev };
        obj[type] = null;
        return obj;
      });
      setUploadImg(prev => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const obj: any = { ...prev };
        obj[type] = null;
        return obj;
      });
      setUploadImgName(prev => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const obj: any = { ...prev };
        obj[type] = '';
        return obj;
      });
      return;
    }
    const maxSize = 1 * 1024 * 1024;
    if (event.target.files[0].size > maxSize) {
      alertShow('첨부 이미지의 사이즈는 1024KB 이내로 등록 가능합니다.');
      return;
    }
    const getExtension = (fileName: string) => {
      const fileLength = fileName.length;
      const lastDot = fileName.lastIndexOf('.');
      const fileExtension = `.${fileName.substring(lastDot + 1, fileLength)}`;
      return fileExtension.toLowerCase();
    };
    if (
      getExtension(event.target.files[0].name) !== '.png' &&
      getExtension(event.target.files[0].name) !== '.jpg' &&
      getExtension(event.target.files[0].name) !== '.jpeg'
    ) {
      alertShow('이미지는 jpg, png 형식의 파일만 등록할 수 있습니다.');
      return;
    }
    const ele = event.target.files[0];
    setUploadFile(prev => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const obj: any = { ...prev };
      // eslint-disable-next-line prefer-destructuring
      obj[type] = ele;
      return obj;
    });
    setUploadImg(prev => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const obj: any = { ...prev };
      // eslint-disable-next-line prefer-destructuring
      obj[type] = URL.createObjectURL(ele);
      return obj;
    });
    setUploadImgName(prev => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const obj: any = { ...prev };
      // eslint-disable-next-line prefer-destructuring
      obj[type] = ele.name;
      return obj;
    });
    try {
      const reader: FileReader = new FileReader();
      reader.onload = (event: ProgressEvent<FileReader>) => {
        console.log(event);
      };
      reader.onerror = error => {
        console.log(error);
      };
      reader.readAsDataURL(event.target.files[0]);
    } catch (error) {
      console.log(error);
    }
  };
  // 파비콘
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [faviconFile, setFaviconFile] = useState<any>(null);
  const [faviconImg, setFaviconImg] = useState<string | null>(null);
  const [faviconImgName, setFaviconImgName] = useState('');
  const faviconRef = useRef<HTMLImageElement>(null);
  const changeFavicon = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event?.target?.files) {
      setFaviconFile(null);
      setFaviconImg(null);
      setFaviconImgName('');
      return;
    }
    const maxSize = 1 * 1024 * 1024;
    if (event.target.files[0].size > maxSize) {
      alertShow('첨부 이미지의 사이즈는 1024KB 이내로 등록 가능합니다.');
      return;
    }
    const getExtension = (fileName: string) => {
      const fileLength = fileName.length;
      const lastDot = fileName.lastIndexOf('.');
      const fileExtension = `.${fileName.substring(lastDot + 1, fileLength)}`;
      return fileExtension.toLowerCase();
    };
    if (getExtension(event.target.files[0].name) !== '.png' && getExtension(event.target.files[0].name) !== '.ico') {
      alertShow('이미지는 png, ico 형식의 파일만 등록할 수 있습니다.');
      return;
    }
    setFaviconFile(event.target.files[0]);
    setFaviconImg(URL.createObjectURL(event.target.files[0]));
    setFaviconImgName(event.target.files[0].name);
    try {
      const reader: FileReader = new FileReader();
      reader.onload = (event: ProgressEvent<FileReader>) => {
        console.log(event);
      };
      reader.onerror = error => {
        console.log(error);
      };
      reader.readAsDataURL(event.target.files[0]);
    } catch (error) {
      console.log(error);
    }
  };
  // 컬러
  const [customColor, setCustomColor] = useState(false);
  const [color, setColor] = useState({ lightColor: '#0277bd', darkColor: '#0277bd' });
  // 워터마크
  const [role, setRole] = useState<IRole[]>([]);
  const [roleReady, setRoleReady] = useState(false);
  const { isFetching: getMemberRoleLoading } = useQuery<{ data: IRole[] }>(['getMemberRole'], getMemberRole, {
    refetchOnWindowFocus: false,
    onSuccess: e => {
      setRoleReady(true);
      setRole(e.data.map(item => ({ ...item, checked: false })));
    },
  });
  // 주소
  const [address, setAddress] = useRecoilState(addressState);
  const [addressDetail, setAddressDetail] = useState('');
  // 아이디
  const [adminId, setAdminId] = useState('');

  const [adminName, setAdminName] = useState('');
  const [adminPhone, setAdminPhone] = useState('');
  const [adminEmail, setAdminEmail] = useState('');
  const [startAt, setStartAt] = useState<Date | null>(new Date());
  const [endAt, setEndAt] = useState<Date | null>(new Date());
  const [active, setActive] = useState('Y');
  const [userLimit, setUserLimit] = useState(0);
  const [trafficLimit, setTrafficLimit] = useState(0);
  const [storageLimit, setStorageLimit] = useState(0);
  const [modules, setModules] = useState<string[]>([]);
  const modulesArray = ['EVALUATION', 'INSPECTION', 'LIVE', 'MEETING', 'SALES', 'SURVEY', 'VOD', 'LEARNINGTALK'];

  useEffect(() => {
    if (Number(endAt) < Number(startAt)) setEndAt(startAt);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startAt, endAt]);

  const { isFetching: getCustomerLoading } = useQuery<{ data: ICustomer }>(
    ['customer'],
    () => getCustomer(Number(c_key)),
    {
      refetchOnWindowFocus: false,
      enabled: c_key !== undefined && roleReady,
      onSuccess: e => {
        setCompanyCode(e.data.c_code);
        setName(e.data.c_name);
        setUploadFile({ dark: `uploaded_${e.data.c_code}_DARK_LOGO`, light: `uploaded_${e.data.c_code}_LIGHT_LOGO` });
        setUploadImg({
          dark: `${process.env.REACT_APP_UPLOADED_URL}/xclass/image/customer/${e.data.c_code}_DARK_LOGO`,
          light: `${process.env.REACT_APP_UPLOADED_URL}/xclass/image/customer/${e.data.c_code}_LIGHT_LOGO`,
        });
        setUploadImgName({
          dark: `uploaded_${e.data.c_code}_DARK_LOGO`,
          light: `uploaded_${e.data.c_code}_LIGHT_LOGO`,
        });
        setFaviconFile(`uploaded_${e.data.c_favicon_path}`);
        setFaviconImg(`${process.env.REACT_APP_UPLOADED_URL}/xclass/image/customer/${e.data.c_favicon_path}`);
        setFaviconImgName(`uploaded_${e.data.c_favicon_path}`);
        setRole(prev =>
          prev.map(el => (e.data?.c_watermark?.split(',').includes(el.mr_code) ? { ...el, checked: true } : el)),
        );
        setColor({
          lightColor: e.data?.c_light_color || '#0277bd',
          darkColor: e.data?.c_dark_color || '#0277bd',
        });
        setAddress(prev => ({ ...prev, postCode: e.data.c_post_code || '', address: e.data.c_address || '' }));
        setAddressDetail(e.data.c_address_detail || '');
        setAdminId(e.data.c_admin_id || '');
        setAdminName(e.data.c_admin_name || '');
        setAdminPhone(e.data.c_admin_phone || '');
        setAdminEmail(e.data.c_admin_email || '');
        setStartAt(e.data.c_start_at ? new Date(e.data.c_start_at) : null);
        setEndAt(e.data.c_end_at ? new Date(e.data.c_end_at) : null);
        setActive(e.data.c_active);
        setUserLimit(e.data.c_user_limit);
        setTrafficLimit(e.data.c_traffic_limit);
        setStorageLimit(e.data.c_storage_limit);
        setModules(typeof e.data.c_modules === 'string' ? e.data.c_modules.split(',') : []);
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (e: any) => alertShow(e.response.data.message),
    },
  );

  const init = () => {
    setCompanyCode('');
    setName('');
    setUploadFile({ dark: null, light: null });
    setUploadImg({ dark: null, light: null });
    setUploadImgName({ dark: '', light: '' });
    setFaviconFile(null);
    setFaviconImg(null);
    setFaviconImgName('');
    setAddress({ state: false, postCode: '', address: '' });
    setAddressDetail('');
    setAdminId('');
    setAdminName('');
    setAdminPhone('');
    setAdminEmail('');
    setStartAt(new Date());
    setEndAt(new Date());
    setActive('Y');
    setUserLimit(0);
    setTrafficLimit(0);
    setStorageLimit(0);
    setModules([]);
  };
  useEffect(() => {
    init();
    setCompanyCodeTestComplete(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const { isLoading: createLoading, mutate: create } = useMutation(createCustomer, {
    onSuccess: () => navigate(`/${code}/customer`),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (e: any) => {
      createError({
        type: '[ADMIN] customer - createCustomer',
        message: `message: "${e.message}",\nresponse: { status: ${e.response.status}, message: "${e.response.data.message}" }`,
      });
      throwError(e);
    },
  });

  const { isLoading: updateLoading, mutate: update } = useMutation(editCustomer, {
    onSuccess: () => navigate(`/${code}/customer`),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (e: any) => {
      createError({
        type: '[ADMIN] customer - editCustomer',
        message: `message: "${e.message}",\nresponse: { status: ${e.response.status}, message: "${e.response.data.message}" }`,
      });
      throwError(e);
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formData = (_fields: any, _file: any) => {
    const data = new FormData();
    Object.keys(_fields).forEach(key => data.append(key, _fields[key]));
    if (_file) {
      data.append('file', _file);
    }
    return data;
  };

  const [imgComplete, setImgComplete] = useState({ dark: false, light: false });
  const [faviconComplete, setFaviconComplete] = useState(false);
  const complete = () => {
    if (c_key) {
      update({
        c_key: Number(c_key),
        data: {
          code: companyCode,
          name,
          favicon_path: faviconImgName.replaceAll('uploaded_', ''),
          light_color: color.lightColor,
          dark_color: color.darkColor,
          watermark: role
            .filter(item => item.checked)
            .map(el => el.mr_code)
            .join(','),
          post_code: address.postCode ? address.postCode : undefined,
          address: address.address ? address.address : undefined,
          address_detail: addressDetail || undefined,
          admin_id: adminId,
          admin_name: adminName,
          admin_phone: adminPhone || undefined,
          admin_email: adminEmail || undefined,
          start_at: dayjs(startAt).format('YYYY-MM-DD 00:00:00'),
          end_at: dayjs(endAt).format('YYYY-MM-DD 23:59:59'),
          active,
          user_limit: userLimit,
          traffic_limit: trafficLimit,
          storage_limit: storageLimit,
          modules: modules.join(','),
        },
      });
    } else {
      create({
        code: companyCode,
        name,
        favicon_path: faviconImgName.replaceAll('uploaded_', ''),
        light_color: color.lightColor,
        dark_color: color.darkColor,
        watermark: role
          .filter(item => item.checked)
          .map(el => el.mr_code)
          .join(','),
        modules: modules.join(','),
        post_code: address.postCode ? address.postCode : undefined,
        address: address.address ? address.address : undefined,
        address_detail: addressDetail || undefined,
        admin_id: adminId,
        admin_name: adminName,
        admin_phone: adminPhone || undefined,
        admin_email: adminEmail || undefined,
        start_at: dayjs(startAt).format('YYYY-MM-DD 00:00:00'),
        end_at: dayjs(endAt).format('YYYY-MM-DD 23:59:59'),
        active,
        user_limit: userLimit,
        traffic_limit: trafficLimit,
        storage_limit: storageLimit,
      });
    }
  };

  useEffect(() => {
    if (imgComplete.dark && imgComplete.light && faviconComplete) complete();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imgComplete, faviconComplete]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
  const fileUpload = async (url: string, form: any, type: string, mode?: string) => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data = (await axios.post(url, form)) as any;
      if (data.ok || data.status === 204) {
        if (type === 'logo' && mode) {
          setImgComplete(prev => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const obj: any = { ...prev };
            obj[mode] = true;
            return obj;
          });
        }
        if (type === 'favicon') setFaviconComplete(true);
      } else {
        console.log('error');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const uploadLogo = async (mode: string) => {
    try {
      const { data } = await axios.post(
        `${api}/admin/customer/upload_logo`,
        { code: companyCode, type: mode.toUpperCase() },
        { withCredentials: true },
      );
      if (mode === 'light') {
        await setUploadImgName(prev => ({ ...prev, light: data.data.name }));
        await fileUpload(data.data.url.url, formData(data.data.url.fields, uploadFile.light), 'logo', 'light');
      } else if (mode === 'dark') {
        await setUploadImgName(prev => ({ ...prev, dark: data.data.name }));
        await fileUpload(data.data.url.url, formData(data.data.url.fields, uploadFile.dark), 'logo', 'dark');
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(e);
    }
  };

  const uploadFavicon = async () => {
    try {
      const { data } = await axios.post(
        `${api}/admin/customer/upload_favicon`,
        { code: companyCode },
        { withCredentials: true },
      );
      await setFaviconImgName(data.data.name);
      await fileUpload(data.data.url.url, formData(data.data.url.fields, faviconFile), 'favicon');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(e);
    }
  };

  const upload = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (
      c_key &&
      uploadImgName.dark.includes('uploaded_') &&
      uploadImgName.light.includes('uploaded_') &&
      faviconImgName.includes('uploaded_')
    ) {
      update({
        c_key: Number(c_key),
        data: {
          code: companyCode,
          name,
          favicon_path: faviconImgName.replaceAll('uploaded_', ''),
          light_color: color.lightColor,
          dark_color: color.darkColor,
          watermark: role
            .filter(item => item.checked)
            .map(el => el.mr_code)
            .join(','),
          post_code: address.postCode ? address.postCode : undefined,
          address: address.address ? address.address : undefined,
          address_detail: addressDetail || undefined,
          admin_id: adminId,
          admin_name: adminName,
          admin_phone: adminPhone || undefined,
          admin_email: adminEmail || undefined,
          start_at: dayjs(startAt).format('YYYY-MM-DD 00:00:00'),
          end_at: dayjs(endAt).format('YYYY-MM-DD 23:59:59'),
          active,
          user_limit: userLimit,
          traffic_limit: trafficLimit,
          storage_limit: storageLimit,
          modules: modules.join(','),
        },
      });
      return;
    }
    if (c_key && faviconImgName.includes('uploaded_')) {
      if (uploadImgName.dark.includes('uploaded_') && !uploadImgName.light.includes('uploaded_')) {
        setImgComplete(prev => ({ ...prev, dark: true }));
        uploadLogo('light');
      } else if (!uploadImgName.dark.includes('uploaded_') && uploadImgName.light.includes('uploaded_')) {
        setImgComplete(prev => ({ ...prev, light: true }));
        uploadLogo('dark');
      } else if (!uploadImgName.dark.includes('uploaded_') && !uploadImgName.light.includes('uploaded_')) {
        uploadLogo('dark');
        uploadLogo('light');
      }
      return;
    }
    if (
      c_key &&
      !faviconImgName.includes('uploaded_') &&
      uploadImgName.dark.includes('uploaded_') &&
      uploadImgName.light.includes('uploaded_')
    ) {
      setImgComplete({ dark: true, light: true });
      uploadFavicon();
      return;
    }
    uploadLogo('dark');
    uploadLogo('light');
    uploadFavicon();
  };

  return (
    <Page
      title={c_key ? '고객수정' : '고객등록'}
      isLoading={
        getCustomerTestLoading ||
        getMemberRoleLoading ||
        (getCustomerLoading && c_key !== undefined) ||
        createLoading ||
        updateLoading
      }
    >
      <FormItem title="기업코드" essential>
        <div className="input">
          <input
            type="text"
            maxLength={24}
            placeholder={t('기업코드') || ''}
            value={companyCode}
            disabled={c_key !== undefined}
            onChange={e => {
              if (c_key) return;
              setCompanyCode(e.target.value.toUpperCase());
              setCompanyCodeTestStart(false);
              setCompanyCodeTestComplete(false);
            }}
          />
          {!c_key && (
            <button
              type="button"
              className={classNames('info', codeTestComplete && 'disabled')}
              onClick={() => {
                if (/[^A-Z0-9]/.test(companyCode)) {
                  alertShow('기업코드는 공백 없이 영문 대문자와 숫자만 사용할 수 있습니다.');
                  return;
                }
                setCompanyCodeTestStart(false);
                setCompanyCodeTestStart(true);
              }}
            >
              {t('중복검사')}
            </button>
          )}
        </div>
      </FormItem>
      <FormItem title="기업명" essential>
        <div className="input">
          <input
            type="text"
            maxLength={24}
            placeholder={t('기업명') || ''}
            value={name}
            onChange={e => setName(e.target.value)}
          />
        </div>
      </FormItem>
      <FormItem title="기본 로고" essential>
        <div className="file" style={{ display: uploadImg.light ? '' : 'none' }}>
          <div className="uploaded" style={{ backgroundColor: 'white', marginBottom: 'unset' }}>
            <img
              src={uploadImg.light || ''}
              alt="img"
              ref={imgRefLight}
              onError={() => {
                if (imgRefLight.current) imgRefLight.current.src = logoEmpty;
              }}
            />
          </div>
        </div>
        <div className="guide">
          <div className="item">* {t('로고는 256x80 픽셀 규격의 png 파일을 표준으로 합니다.')}</div>
        </div>
        <div className="input">
          <label htmlFor="light-upload" className="btn info">
            <div className="placeholder">{t('업로드')}</div>
            <input type="file" id="light-upload" onChange={e => changeLogo(e, 'light')} accept="image/png" />
          </label>
        </div>
      </FormItem>
      <FormItem title="다크모드 로고" essential>
        <div className="file" style={{ display: uploadImg.dark ? '' : 'none' }}>
          <div
            className="uploaded"
            style={{
              backgroundColor: cookies['xclass-theme'] === 'dark' ? 'var(--background)' : 'var(--greyish1)',
              marginBottom: 'unset',
            }}
          >
            <img
              src={uploadImg.dark || ''}
              alt="img"
              ref={imgRefDark}
              onError={() => {
                if (imgRefDark.current) imgRefDark.current.src = logoEmpty;
              }}
            />
          </div>
        </div>
        <div className="guide">
          <div className="item">* {t('로고는 256x80 픽셀 규격의 png 파일을 표준으로 합니다.')}</div>
        </div>
        <div className="input">
          <label htmlFor="dark-upload" className="btn info">
            <div className="placeholder">{t('업로드')}</div>
            <input type="file" id="dark-upload" onChange={e => changeLogo(e, 'dark')} accept="image/png" />
          </label>
        </div>
      </FormItem>
      <FormItem title="파비콘" essential>
        <div className="file" style={{ display: faviconImg ? '' : 'none' }}>
          <div className="uploaded" style={{ marginBottom: 'unset' }}>
            <img
              src={faviconImg || ''}
              alt="img"
              ref={faviconRef}
              style={{ width: '20px' }}
              onError={() => {
                if (faviconRef.current) faviconRef.current.src = faviconEmpty;
              }}
            />
          </div>
        </div>
        <div className="guide">
          <div className="item">* {t('파비콘은 64x64 픽셀 규격의 ico 파일을 표준으로 합니다.')}</div>
        </div>
        <div className="input">
          <label htmlFor="favicon-upload" className="btn info">
            <div className="placeholder">{t('업로드')}</div>
            <input type="file" id="favicon-upload" onChange={e => changeFavicon(e)} accept=".ico, .png" />
          </label>
        </div>
      </FormItem>
      <FormItem title="테마" essential>
        <div className="switch">
          <div className={classNames('item', !customColor && 'active')} onClick={() => setCustomColor(false)}>
            {t('기본')}
          </div>
          <div className={classNames('item', customColor && 'active')} onClick={() => setCustomColor(true)}>
            {t('커스텀')}
          </div>
        </div>
        <table className="colorTable">
          <thead>
            <tr>
              <th>{t('기본 컬러')}</th>
              <th>{t('다크모드 컬러')}</th>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              {!customColor && <th></th>}
            </tr>
          </thead>
          <tbody>
            {customColor ? (
              <ColorItem
                lightColor={color.lightColor}
                darkColor={color.darkColor}
                color={color}
                setColor={(lightColor, darkColor) => setColor(prev => ({ ...prev, lightColor, darkColor }))}
                custom
              />
            ) : (
              <>
                <ColorItem
                  lightColor="#a50034"
                  darkColor="#ec018c"
                  color={color}
                  setColor={(lightColor, darkColor) => setColor(prev => ({ ...prev, lightColor, darkColor }))}
                />
                <ColorItem
                  lightColor="#e99932"
                  darkColor="#ecb057"
                  color={color}
                  setColor={(lightColor, darkColor) => setColor(prev => ({ ...prev, lightColor, darkColor }))}
                />
                <ColorItem
                  lightColor="#019412"
                  darkColor="#22b534"
                  color={color}
                  setColor={(lightColor, darkColor) => setColor(prev => ({ ...prev, lightColor, darkColor }))}
                />
                <ColorItem
                  lightColor="#0277bd"
                  darkColor="#268ac3"
                  color={color}
                  setColor={(lightColor, darkColor) => setColor(prev => ({ ...prev, lightColor, darkColor }))}
                />
                <ColorItem
                  lightColor="#2a315a"
                  darkColor="#4234bc"
                  color={color}
                  setColor={(lightColor, darkColor) => setColor(prev => ({ ...prev, lightColor, darkColor }))}
                />
              </>
            )}
          </tbody>
        </table>
        {customColor && (
          <div className="guide">
            <div className="item">{t('색상 선택기를 클릭하여 색상을 직접 지정합니다.')}</div>
          </div>
        )}
      </FormItem>
      <FormItem title="워터마크" essential>
        <div className="list">
          {role.map(item => (
            <div
              className={classNames('item', item.checked && 'active')}
              key={item.mr_code}
              onClick={() => setRole(prev => prev.map(el => (item === el ? { ...el, checked: !el.checked } : el)))}
            >
              <div className="text">{t(item.mr_code === 'MEMBER' ? '마이페이지' : item.mr_name)}</div>
              <div className="check"></div>
            </div>
          ))}
        </div>
      </FormItem>
      <FormItem title="모듈" essential>
        <div className="list">
          {modulesArray.map(item => (
            <div
              className={classNames('item', modules.includes(item) && 'active')}
              key={item}
              onClick={() =>
                setModules(prev => (prev.includes(item) ? prev.filter(el => el !== item) : prev.concat(item)))
              }
            >
              <div className="text">
                {t(
                  item === 'EVALUATION'
                    ? '평가'
                    : item === 'INSPECTION'
                    ? '체크리스트'
                    : item === 'LIVE'
                    ? '라이브'
                    : item === 'MEETING'
                    ? '회의'
                    : item === 'SALES'
                    ? '세일즈팁'
                    : item === 'SURVEY'
                    ? '설문'
                    : item === 'LEARNINGTALK'
                    ? '러닝톡톡'
                    : item,
                )}
              </div>
              <div className="check"></div>
            </div>
          ))}
        </div>
      </FormItem>
      <FormItem title="주소">
        <div className="address">
          <input
            type="text"
            placeholder={t('우편번호') || ''}
            readOnly
            value={address.postCode}
            onClick={() => setAddress(prev => ({ ...prev, state: true }))}
          />
          <input
            type="text"
            placeholder={t('주소') || ''}
            readOnly
            value={address.address}
            onClick={() => setAddress(prev => ({ ...prev, state: true }))}
          />
          <input
            type="text"
            placeholder={t('상세주소') || ''}
            value={addressDetail}
            onChange={e => setAddressDetail(e.target.value)}
          />
        </div>
      </FormItem>
      <FormItem title="아이디" essential>
        <div className="input">
          <input
            type="text"
            maxLength={24}
            placeholder={t('아이디') || ''}
            value={adminId}
            onChange={e => setAdminId(e.target.value)}
          />
        </div>
      </FormItem>
      <FormItem title="이름" essential>
        <div className="input">
          <input
            type="text"
            maxLength={24}
            placeholder={t('이름') || ''}
            value={adminName}
            onChange={e => setAdminName(e.target.value)}
          />
        </div>
      </FormItem>
      <FormItem title="연락처">
        <div className="input">
          <input
            type="text"
            maxLength={16}
            placeholder={t('연락처') || ''}
            value={adminPhone}
            onChange={e => setAdminPhone(e.target.value.replace(/[^0-9]/g, ''))}
          />
        </div>
      </FormItem>
      <FormItem title="이메일">
        <div className="input">
          <input
            type="text"
            maxLength={24}
            placeholder={t('이메일') || ''}
            value={adminEmail}
            onChange={e => setAdminEmail(e.target.value)}
          />
        </div>
      </FormItem>
      <FormItem title="기간" essential>
        <div className="input" style={{ maxWidth: '608px' }}>
          <DatePicker
            selected={startAt}
            onChange={date => setStartAt(date)}
            minDate={null}
            maxDate={endAt}
            selectsStart
            startDate={startAt}
            endDate={endAt}
            locale={cookies['xclass-lang'] === 'ko' ? ko : undefined}
            dateFormat="yyyy-MM-dd"
            disabledKeyboardNavigation
            onChangeRaw={(e: React.FormEvent<HTMLInputElement>) => e.preventDefault()}
          />
          <div style={{ margin: '0 8px', color: 'var(--greyish1)' }}>-</div>
          <DatePicker
            selected={endAt}
            onChange={date => setEndAt(date)}
            maxDate={null}
            selectsEnd
            startDate={startAt}
            endDate={endAt}
            locale={cookies['xclass-lang'] === 'ko' ? ko : undefined}
            dateFormat="yyyy-MM-dd"
            disabledKeyboardNavigation
            onChangeRaw={(e: React.FormEvent<HTMLInputElement>) => e.preventDefault()}
          />
        </div>
      </FormItem>
      <FormItem title="상태" essential>
        <div className="switch">
          <div
            className={classNames('item', active === 'Y' && 'active')}
            onClick={() => setActive(prev => (prev === 'Y' ? 'N' : 'Y'))}
          >
            {t('활성화')}
          </div>
          <div
            className={classNames('item', active === 'N' && 'active')}
            onClick={() => setActive(prev => (prev === 'Y' ? 'N' : 'Y'))}
          >
            {t('비활성화')}
          </div>
        </div>
      </FormItem>
      <FormItem title="사용자 수" essential>
        <div className="input">
          <input
            type="text"
            placeholder={t('사용자 수') || ''}
            value={userLimit}
            onChange={e => setUserLimit(Number(e.target.value.replace(/[^0-9]/g, '')))}
          />
          <div className="unit">
            <span>{t('명', { count: undefined })}</span>
          </div>
        </div>
      </FormItem>
      <FormItem title="트래픽" essential>
        <div className="input">
          <input
            type="text"
            placeholder={t('트래픽') || ''}
            value={trafficLimit}
            onChange={e => setTrafficLimit(Number(e.target.value.replace(/[^0-9]/g, '')))}
          />
          <div className="unit">
            <span>GB</span>
          </div>
        </div>
      </FormItem>
      <FormItem title="저장공간" essential>
        <div className="input">
          <input
            type="text"
            placeholder={t('저장공간') || ''}
            value={storageLimit}
            onChange={e => setStorageLimit(Number(e.target.value.replace(/[^0-9]/g, '')))}
          />
          <div className="unit">
            <span>GB</span>
          </div>
        </div>
      </FormItem>
      <div className="formSubmit">
        <button
          type="button"
          className="primary"
          onClick={() => {
            if (!companyCode) {
              alertShow('기업코드를 입력하십시오.');
              return;
            }
            if (!c_key && !codeTestComplete) {
              alertShow('기업코드 중복검사를 하십시오.');
              return;
            }
            if (!name) {
              alertShow('기업명을 입력하십시오.');
              return;
            }
            if (!uploadImgName) {
              alertShow('로고를 등록하십시오.');
              return;
            }
            if (!faviconImgName) {
              alertShow('파비콘을 등록하십시오.');
              return;
            }
            if (!adminId) {
              alertShow('아이디를 입력하십시오.');
              return;
            }
            if (!adminName) {
              alertShow('이름을 입력하십시오.');
              return;
            }
            if (startAt && endAt && startAt >= endAt) {
              alertShow('기간의 시작일과 종료일을 확인하십시오.');
              return;
            }
            confirmShow(c_key ? t('수정하시겠습니까?') : t('등록하시겠습니까?'), upload);
          }}
        >
          {c_key ? t('수정') : t('등록')}
        </button>
      </div>
      <Address />
    </Page>
  );
}

export default CustomerEdit;
