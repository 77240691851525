import axios from 'axios';
import { createError, throwError } from './error';

const api = process.env.REACT_APP_API;

// 세일즈팁 카테고리 그룹 생성
export const createSalestipCategoryGroup = async (name: string) => {
  await axios.post(`${api}/salestip/category/group`, { name }, { withCredentials: true });
};

// 세일즈팁 카테고리 그룹 수정
export const editSalestipCategoryGroup = async (element: { key: number; name: string }) => {
  await axios.patch(`${api}/salestip/category/group/${element.key}`, { name: element.name }, { withCredentials: true });
};

// 세일즈팁 카테고리 그룹 삭제
export const deleteSalestipCategoryGroup = async (key: number) => {
  await axios.delete(`${api}/salestip/category/group/${key}`, { withCredentials: true });
};

// 세일즈팁 카테고리 그룹 목록 조회
export const getSalestipCategoryGroupList = async () => {
  try {
    const { data } = await axios.get(`${api}/salestip/category/group`, { withCredentials: true });
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    createError({
      type: '[ADMIN] salestip - getSalestipCategoryGroupList',
      message: `message: "${error.message}",\nresponse: { status: ${error.response.status}, message: "${error.response.data.message}" }`,
    });
    throwError(error);
    return null;
  }
};

// 세일즈팁 카테고리 생성
export const createSalestipCategory = async (element: { key: number; name: string }) => {
  await axios.post(`${api}/salestip/category`, { ...element }, { withCredentials: true });
};

// 세일즈팁 카테고리 수정
export const editSalestipCategory = async (element: { key: number; name: string }) => {
  await axios.patch(`${api}/salestip/category/${element.key}`, { ...element }, { withCredentials: true });
};

// 세일즈팁 카테고리 삭제
export const deleteSalestipCategory = async (key: number) => {
  await axios.delete(`${api}/salestip/category/${key}`, { withCredentials: true });
};

// 세일즈팁 카테고리 조회
export const getSalestipCategoryList = async (groupKeys: number[]) => {
  try {
    const { data } = await axios.get(`${api}/salestip/category?groupKeys=${groupKeys.join(',')}`, {
      withCredentials: true,
    });
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    createError({
      type: '[ADMIN] salestip - getSalestipCategoryList',
      message: `message: "${error.message}",\nresponse: { status: ${error.response.status}, message: "${error.response.data.message}" }`,
    });
    throwError(error);
    return null;
  }
};

// 세일즈팁 생성
export const createSalestip = async (element: ISEdit) => {
  await axios.post(`${api}/salestip`, { ...element }, { withCredentials: true });
};

// 세일즈팁 수정
export const editSalestip = async (element: { key: number; data: ISEdit }) => {
  await axios.patch(`${api}/salestip/${element.key}`, { ...element.data }, { withCredentials: true });
};

// 세일즈팁 삭제
export const deleteSalestip = async (key: number) => {
  await axios.delete(`${api}/salestip/${key}`, { withCredentials: true });
};

// 세일즈팁 목록 조회
export const getSalestipList = async (element: { page: number; categoryKeys: number[] }) => {
  try {
    const { data } = await axios.get(
      `${api}/salestip/withgroupkey?${
        element.categoryKeys.length > 0 ? `categoryKeys=${element.categoryKeys.join(',')}` : ''
      }&page=${element.page}&limit=10&type=ADMIN`,
      { withCredentials: true },
    );
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    createError({
      type: '[ADMIN] salestip - getSalestipList',
      message: `message: "${error.message}",\nresponse: { status: ${error.response.status}, message: "${error.response.data.message}" }`,
    });
    throwError(error);
    return null;
  }
};

// 세일즈팁 조회
export const getSalestip = async (key: number) => {
  try {
    const { data } = await axios.get(`${api}/salestip/detail/${key}`, { withCredentials: true });
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    createError({
      type: '[ADMIN] salestip - getSalestip',
      message: `message: "${error.message}",\nresponse: { status: ${error.response.status}, message: "${error.response.data.message}" }`,
    });
    throwError(error);
    return null;
  }
};

// 세일즈팁 댓글 생성
export const createSalestipComment = async (element: { key: number; comment: string }) => {
  await axios.post(`${api}/salestip/comment`, { ...element }, { withCredentials: true });
};

// 세일즈팁 댓글 수정
export const editSalestipComment = async (element: { key: number; data: { key: number; comment: string } }) => {
  await axios.patch(`${api}/salestip/comment/${element.key}`, { ...element.data }, { withCredentials: true });
};

// 세일즈팁 댓글 삭제
export const deleteSalestipComment = async (key: number) => {
  await axios.delete(`${api}/salestip/comment/${key}`, { withCredentials: true });
};

// 세일즈팁 댓글 목록 조회
export const getSalestipCommentList = async (element: { key: number; page: number }) => {
  try {
    const { data } = await axios.get(`${api}/salestip/comment?key=${element.key}&page=${element.page}&limit=10`, {
      withCredentials: true,
    });
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    createError({
      type: '[ADMIN] salestip - getSalestipCommentList',
      message: `message: "${error.message}",\nresponse: { status: ${error.response.status}, message: "${error.response.data.message}" }`,
    });
    throwError(error);
    return null;
  }
};
