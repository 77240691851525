import dayjs from 'dayjs';
import queryString from 'query-string';
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useQuery } from '@tanstack/react-query';
import { Viewer } from '@toast-ui/react-editor';
import { getSalestip } from '../../api/salestip';
import { useAlert } from '../../stores/alertStore';

// components
import Page from '../../includes/page';

// style
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

function SalestipDetail() {
  const { t } = useTranslation();
  const [cookies] = useCookies(['xclass-theme']);
  const { search } = useLocation();
  const { key } = queryString.parse(search);
  const { alertShow } = useAlert();

  const [title, setTitle] = useState('');
  const [name, setName] = useState('');
  const [date, setDate] = useState('');
  const [description, setDescription] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [img, setImg] = useState<string[]>([]);

  const { isFetching: getSalestipLoading } = useQuery<{ data: ISalestipDetail }>(
    ['getSalestip'],
    () => getSalestip(Number(key)),
    {
      refetchOnWindowFocus: false,
      enabled: key !== undefined,
      onSuccess: e => {
        setTitle(e.data.title);
        setName(e.data.creatorName);
        setDate(e.data.createdAt);
        setDescription(e.data.content);
        setImg(e.data.urls?.map(item => `${process.env.REACT_APP_UPLOADED_URL}/${item.url}`) || []);
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (e: any) => alertShow(e.response.data.message),
    },
  );

  return (
    <Page title={title} goBack={-1} isLoading={getSalestipLoading}>
      <div className="etc-info">
        <div className="item">
          <span>{t('작성자')}: </span>
          {name}
        </div>
        <div className="item">{dayjs(date).format('YYYY-MM-DD HH:mm')}</div>
      </div>
      <div className="viewer">
        {description && <Viewer initialValue={description} theme={cookies['xclass-theme']} />}
        <div className="slider">
          <Swiper navigation pagination={{ clickable: true }} modules={[Navigation, Pagination]}>
            {img.map(item => (
              <SwiperSlide key={item}>
                {item.includes('.mp4') || item.includes('.mkv') || item.includes('webm') ? (
                  // eslint-disable-next-line jsx-a11y/media-has-caption
                  <video src={item} controls></video>
                ) : (
                  <img src={item} alt="img" />
                )}
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </Page>
  );
}

export default SalestipDetail;
