import axios from 'axios';
import { createError, throwError } from './error';

const api = process.env.REACT_APP_API;

// 카테고리 생성
export const createCategory = async (element: { type: string; name: string }) => {
  await axios.post(`${api}/admin/board/category`, { ...element }, { withCredentials: true });
};

// 카테고리 수정
export const editCategory = async (element: { bc_key: number; type: string; name: string }) => {
  const { bc_key, type, name } = element;
  await axios.patch(`${api}/admin/board/category/${bc_key}`, { type, name }, { withCredentials: true });
};

// 카테고리 삭제
export const deleteCategory = async (bc_key: number) => {
  await axios.delete(`${api}/admin/board/category/${bc_key}`, { withCredentials: true });
};

// 카테고리 목록 조회
export const getCategoryList = async (type: string) => {
  try {
    const { data } = await axios.get(`${api}/admin/board/category/list?type=${type}`, { withCredentials: true });
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    createError({
      type: '[ADMIN] board - getCategoryList',
      message: `message: "${error.message}",\nresponse: { status: ${error.response.status}, message: "${error.response.data.message}" }`,
    });
    throwError(error);
    return null;
  }
};

// 자유게시판 사진 업로드 url 생성
export const createFreePicture = async (order: { count: number }) => {
  await axios.post(`${api}/admin/board/free/upload`, { ...order }, { withCredentials: true });
};

// 자유게시판 생성
export const createFree = async (element: {
  category: number;
  title: string;
  contents: string;
  path1: string;
  path2: string;
  path3: string;
}) => {
  await axios.post(`${api}/admin/board/free`, { ...element }, { withCredentials: true });
};

// 자유게시판 수정
export const editFree = async (element: {
  bf_key: number;
  data: {
    category: number;
    title: string;
    contents: string;
    path1: string;
    path2: string;
    path3: string;
  };
}) => {
  await axios.patch(`${api}/admin/board/free/${element.bf_key}`, { ...element.data }, { withCredentials: true });
};

// 자유게시판 삭제
export const deleteFree = async (bf_key: number) => {
  await axios.delete(`${api}/admin/board/free/${bf_key}`, { withCredentials: true });
};

// 자유게시판 목록 조회
export const getFreeList = async (element: { page: number; category: number }) => {
  try {
    const { data } = await axios.get(
      `${api}/admin/board/free/list?${
        // eslint-disable-next-line no-restricted-globals
        element.category !== 0 ? `category=${element.category}&` : ''
      }page=${element.page}&limit=10`,
      { withCredentials: true },
    );
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    createError({
      type: '[ADMIN] board - getFreeList',
      message: `message: "${error.message}",\nresponse: { status: ${error.response.status}, message: "${error.response.data.message}" }`,
    });
    throwError(error);
    return null;
  }
};

// 자유게시판 조회
export const getFree = async (bf_key: number) => {
  try {
    const { data } = await axios.get(`${api}/admin/board/free/${bf_key}`, { withCredentials: true });
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    createError({
      type: '[ADMIN] board - getFree',
      message: `message: "${error.message}",\nresponse: { status: ${error.response.status}, message: "${error.response.data.message}" }`,
    });
    throwError(error);
    return null;
  }
};

// 공지사항 생성
export const createNotice = async (element: {
  category: number;
  title: string;
  contents: string;
  path1: string;
  path2: string;
  path3: string;
}) => {
  await axios.post(`${api}/admin/board/notice`, { ...element }, { withCredentials: true });
};

// 공지사항 수정
export const editNotice = async (element: {
  bn_key: number;
  data: {
    category: number;
    title: string;
    contents: string;
    path1: string;
    path2: string;
    path3: string;
  };
}) => {
  await axios.patch(`${api}/admin/board/notice/${element.bn_key}`, { ...element.data }, { withCredentials: true });
};

// 공지사항 삭제
export const deleteNotice = async (bn_key: number) => {
  await axios.delete(`${api}/admin/board/notice/${bn_key}`, { withCredentials: true });
};

// 공지사항 목록 조회
export const getNoticeList = async (element: { page: number; category: number }) => {
  try {
    const { data } = await axios.get(
      `${api}/admin/board/notice/list?${
        // eslint-disable-next-line no-restricted-globals
        element.category !== 0 ? `category=${element.category}&` : ''
      }page=${element.page}&limit=10`,
      { withCredentials: true },
    );
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    createError({
      type: '[ADMIN] board - getNoticeList',
      message: `message: "${error.message}",\nresponse: { status: ${error.response.status}, message: "${error.response.data.message}" }`,
    });
    throwError(error);
    return null;
  }
};

// 공지사항 조회
export const getNotice = async (bn_key: number) => {
  try {
    const { data } = await axios.get(`${api}/admin/board/notice/${bn_key}`, { withCredentials: true });
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    createError({
      type: '[ADMIN] board - getNotice',
      message: `message: "${error.message}",\nresponse: { status: ${error.response.status}, message: "${error.response.data.message}" }`,
    });
    throwError(error);
    return null;
  }
};

// Q&A 삭제
export const deleteQna = async (bq_key: number) => {
  await axios.delete(`${api}/admin/board/qna/${bq_key}`, { withCredentials: true });
};

// Q&A 목록 조회
export const getQnaList = async (element: { page: number; category: number }) => {
  try {
    const { data } = await axios.get(
      `${api}/admin/board/qna/list?${
        // eslint-disable-next-line no-restricted-globals
        element.category !== 0 ? `category=${element.category}&` : ''
      }page=${element.page}&limit=10`,
      { withCredentials: true },
    );
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    createError({
      type: '[ADMIN] board - getQnaList',
      message: `message: "${error.message}",\nresponse: { status: ${error.response.status}, message: "${error.response.data.message}" }`,
    });
    throwError(error);
    return null;
  }
};

// Q&A 조회
export const getQna = async (bq_key: number) => {
  try {
    const { data } = await axios.get(`${api}/admin/board/qna/${bq_key}`, { withCredentials: true });
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    createError({
      type: '[ADMIN] board - getQna',
      message: `message: "${error.message}",\nresponse: { status: ${error.response.status}, message: "${error.response.data.message}" }`,
    });
    throwError(error);
    return null;
  }
};

// Q&A 답변 등록
export const setQnaAnswer = async (element: { bq_key: number; answer: string }) => {
  await axios.post(
    `${api}/admin/board/qna/answer/${element.bq_key}`,
    { answer: element.answer },
    { withCredentials: true },
  );
};
