import dayjs from 'dayjs';
import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { memberInfoState, useMemberInfo } from '../../stores/memberStore';

// components
import Modal from '../modal/modal';

// style
import styles from '../modal/selector.module.scss';

function MemberInfo() {
  const { t } = useTranslation();
  const info = useRecoilValue(memberInfoState);
  const { setMemberInfo } = useMemberInfo();

  const transRole = (e: string) => {
    let result = '';
    switch (e) {
      case 'MEMBER':
        result = t('회원');
        break;
      case 'SURVEY':
        result = t('설문');
        break;
      case 'INSPECTION':
        result = t('체크리스트');
        break;
      case 'SALES':
        result = t('세일즈팁');
        break;
      case 'MEETING':
        result = t('회의');
        break;
      case 'LIVE':
        result = t('라이브');
        break;
      case 'VOD':
        result = 'VOD';
        break;
      case 'BOARD':
        result = t('커뮤니티');
        break;
      case 'EVALUATION':
        result = t('평가');
        break;
      case 'LEARNINGTALK':
        result = t('러닝톡톡');
        break;
      default:
        result = t('기타');
        break;
    }
    return result;
  };

  return (
    <AnimatePresence>
      {info.state && (
        <Modal
          close={() =>
            setMemberInfo({
              state: false,
              data: {
                m_key: 0,
                mg_code: '',
                mr_code: '',
                mo_key: 0,
                m_id: '',
                m_name: '',
                m_gender: '',
                m_email: '',
                m_last_login_at: '',
                m_delete_at: '',
                created_at: '',
                updated_at: '',
                mo_name: '',
              },
            })
          }
        >
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            className={styles.modal}
          >
            <div className={styles.title}>{t('회원정보')}</div>
            <table className="table">
              <tbody>
                <tr>
                  <td>
                    <div>
                      <span style={{ fontWeight: '500', minWidth: '100px' }}>{t('조직')}</span>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span>{info.data.mo_name}</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <span style={{ fontWeight: '500', minWidth: '100px' }}>{t('권한')}</span>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span>
                        {info.data.mr_code
                          ?.split(',')
                          .map((item, idx, array) =>
                            idx === array.length - 1 ? transRole(item) : `${transRole(item)}, `,
                          )}
                      </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <span style={{ fontWeight: '500', minWidth: '100px' }}>{t('아이디')}</span>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span>{info.data.m_id}</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <span style={{ fontWeight: '500', minWidth: '100px' }}>{t('이름')}</span>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span>{info.data.m_name}</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <span style={{ fontWeight: '500', minWidth: '100px' }}>{t('성별')}</span>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span>{info.data.m_gender}</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <span style={{ fontWeight: '500', minWidth: '100px' }}>{t('이메일')}</span>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span>{info.data.m_email}</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <span style={{ fontWeight: '500', minWidth: '100px' }}>{t('마지막 접속')}</span>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span>
                        {info.data.m_last_login_at && dayjs(info.data.m_last_login_at).format('YYYY-MM-DD HH:mm')}
                      </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <span style={{ fontWeight: '500', minWidth: '100px' }}>{t('삭제')}</span>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span>
                        {info.data.m_last_login_at && dayjs(info.data.m_delete_at).format('YYYY-MM-DD HH:mm')}
                      </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <span style={{ fontWeight: '500', minWidth: '100px' }}>{t('생성')}</span>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span>{dayjs(info.data.created_at).format('YYYY-MM-DD HH:mm')}</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <span style={{ fontWeight: '500', minWidth: '100px' }}>{t('수정')}</span>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span>{dayjs(info.data.updated_at).format('YYYY-MM-DD HH:mm')}</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </motion.div>
        </Modal>
      )}
    </AnimatePresence>
  );
}

export default MemberInfo;
