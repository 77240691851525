import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { categorySelectorState } from '../../stores/salestipStore';

// style
import styles from './categorySelector.module.scss';

import icClose from '../../assets/img/icons/ic_close.svg';

function SalestipCategoryChoice({
  category,
  categoryKeys,
  setCategoryKeys,
}: {
  category: ISCG[];
  categoryKeys: number[];
  // eslint-disable-next-line react/require-default-props
  setCategoryKeys: (key: number) => void;
}) {
  const { t } = useTranslation();
  const { code } = useParams();
  const navigate = useNavigate();

  const setCategorySelector = useSetRecoilState(categorySelectorState);

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <span>{t('카테고리')}</span>
      </div>
      <div className={styles.list}>
        {category.length === 0 && (
          <div className={styles.item}>
            <div className={styles.empty} onClick={() => navigate(`/${code}/salestip/category`)}>
              {t('카테고리를 등록하십시오.')}
            </div>
          </div>
        )}
        {category.map(item => (
          <div key={item.key} className={styles.item}>
            <div className={styles.subject}>
              <span>{item.name}</span>
            </div>
            <div className={styles.sub}>
              {item.children?.map(ele => (
                <div
                  key={ele.key}
                  className={classNames(styles.item, categoryKeys.includes(ele.key) && styles.active)}
                  onClick={() => {
                    setCategoryKeys(ele.key);
                    setCategorySelector(prev => ({ ...prev, page: 1 }));
                  }}
                >
                  <span>{ele.name}</span>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      {categoryKeys.length > 0 && (
        <div className={styles.tag}>
          {categoryKeys.map(item => (
            <div key={item} className={styles.item}>
              <span>
                {
                  category
                    .map(e => e.children)
                    .flat()
                    .find(el => el?.key === item)?.name
                }
              </span>
              <img
                src={icClose}
                alt="icon"
                onClick={() => {
                  setCategoryKeys(item);
                  setCategorySelector(prev => ({ ...prev, page: 1 }));
                }}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default SalestipCategoryChoice;
