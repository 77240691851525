import axios from 'axios';
import { createError, throwError } from './error';

const api = process.env.REACT_APP_API;

// 카테고리 생성
export const createCategory = async (name: string) => {
  await axios.post(`${api}/admin/evaluation/category`, { name }, { withCredentials: true });
};

// 카테고리 수정
export const editCategory = async (element: { ec_key: number; name: string }) => {
  const { ec_key, name } = element;
  await axios.patch(`${api}/admin/evaluation/category/${ec_key}`, { name }, { withCredentials: true });
};

// 카테고리 삭제
export const deleteCategory = async (ec_key: number) => {
  await axios.delete(`${api}/admin/evaluation/category/${ec_key}`, { withCredentials: true });
};

// 카테고리 목록 조회
export const getCategoryList = async () => {
  try {
    const { data } = await axios.get(`${api}/admin/evaluation/category/list`, { withCredentials: true });
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    createError({
      type: '[ADMIN] evaluation - getCategoryList',
      message: `message: "${error.message}",\nresponse: { status: ${error.response.status}, message: "${error.response.data.message}" }`,
    });
    throwError(error);
    return null;
  }
};

// 평가문제 생성
export const createEvaluationQuestion = async (element: IEvaluationQuestionEdit) => {
  await axios.post(`${api}/admin/evaluation/question`, element, { withCredentials: true });
};

// 평가문제 수정
export const editEvaluationQuestion = async (element: { eq_key: number; data: IEvaluationQuestionEdit }) => {
  const { eq_key, data } = element;
  await axios.patch(`${api}/admin/evaluation/question/${eq_key}`, { ...data }, { withCredentials: true });
};

// 평가문제 삭제
export const deleteEvaluationQuestion = async (eq_key: number) => {
  await axios.delete(`${api}/admin/evaluation/question/${eq_key}`, { withCredentials: true });
};

// 평가문제 목록 조회
export const getEvaluationQuetionList = async (page: number) => {
  try {
    const { data } = await axios.get(`${api}/admin/evaluation/question/list?page=${page}&limit=10`, {
      withCredentials: true,
    });
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    createError({
      type: '[ADMIN] evaluation - getEvaluationQuetionList',
      message: `message: "${error.message}",\nresponse: { status: ${error.response.status}, message: "${error.response.data.message}" }`,
    });
    throwError(error);
    return null;
  }
};

// 평가문제 조회
export const getEvaluationQuestion = async (eq_key: number) => {
  try {
    const { data } = await axios.get(`${api}/admin/evaluation/question/${eq_key}`, { withCredentials: true });
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    createError({
      type: '[ADMIN] evaluation - getEvaluationQuestion',
      message: `message: "${error.message}",\nresponse: { status: ${error.response.status}, message: "${error.response.data.message}" }`,
    });
    throwError(error);
    return null;
  }
};

// 평가 생성
export const createEvaluation = async (element: IEvaluationEdit) => {
  await axios.post(`${api}/admin/evaluation`, { ...element }, { withCredentials: true });
};

// 평가 수정
export const editEvaluation = async (element: { e_key: number; data: IEvaluationEdit }) => {
  const { e_key, data } = element;
  await axios.patch(`${api}/admin/evaluation/${e_key}`, { ...data }, { withCredentials: true });
};

// 평가 삭제
export const deleteEvaluation = async (e_key: number) => {
  await axios.delete(`${api}/admin/evaluation/${e_key}`, { withCredentials: true });
};

// 평가 목록 조회
export const getEvaluationList = async (page: number) => {
  try {
    const { data } = await axios.get(`${api}/admin/evaluation/list?page=${page}&limit=10`, { withCredentials: true });
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    createError({
      type: '[ADMIN] evaluation - getEvaluationList',
      message: `message: "${error.message}",\nresponse: { status: ${error.response.status}, message: "${error.response.data.message}" }`,
    });
    throwError(error);
    return null;
  }
};

// 평가 조회
export const getEvaluation = async (e_key: number) => {
  try {
    const { data } = await axios.get(`${api}/admin/evaluation/${e_key}`, { withCredentials: true });
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    createError({
      type: '[ADMIN] evaluation - getEvaluation',
      message: `message: "${error.message}",\nresponse: { status: ${error.response.status}, message: "${error.response.data.message}" }`,
    });
    throwError(error);
    return null;
  }
};

// 평가 기록 목록 조회
export const getHistoryList = async (element: { e_key: number; page: number }) => {
  try {
    const { data } = await axios.get(
      `${api}/admin/evaluation/history/list/${element.e_key}?page=${element.page}&limit=10`,
      {
        withCredentials: true,
      },
    );
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    createError({
      type: '[ADMIN] evaluation - getHistoryList',
      message: `message: "${error.message}",\nresponse: { status: ${error.response.status}, message: "${error.response.data.message}" }`,
    });
    throwError(error);
    return null;
  }
};

// 평가 기록 조회
export const getHistory = async (eh_key: number) => {
  try {
    const { data } = await axios.get(`${api}/admin/evaluation/history/${eh_key}`, { withCredentials: true });
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    createError({
      type: '[ADMIN] evaluation - getHistory',
      message: `message: "${error.message}",\nresponse: { status: ${error.response.status}, message: "${error.response.data.message}" }`,
    });
    throwError(error);
    return null;
  }
};

// 평가 통계
export const getEvaluationAnalysis = async (e_key: number) => {
  try {
    const { data } = await axios.get(`${api}/admin/evaluation/log/${e_key}`, { withCredentials: true });
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    createError({
      type: '[ADMIN] evaluation - getEvaluationAnalysis',
      message: `message: "${error.message}",\nresponse: { status: ${error.response.status}, message: "${error.response.data.message}" }`,
    });
    throwError(error);
    return null;
  }
};
