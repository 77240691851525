import dayjs from 'dayjs';

export const editDateFormat = (date: string | number | Date | dayjs.Dayjs | null | undefined, status?: string) => {
  const dateDayjs = dayjs(date).format('YYYY-MM-DD HH:mm:ss');
  if (status === 'start') {
    return dayjs(dayjs(dateDayjs).format('YYYY-MM-DD 00:00:00')).subtract(9, 'hour').format('YYYY-MM-DD HH:mm:ss');
  }
  if (status === 'end') {
    return dayjs(dayjs(dateDayjs).format('YYYY-MM-DD 11:59:59')).subtract(9, 'hour').format('YYYY-MM-DD HH:mm:ss');
  }
  return dayjs(date).subtract(9, 'hour').format('YYYY-MM-DD HH:mm:ss');
};
