import { useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// style
import styles from './content.module.scss';

function Content({ children }: { children: React.ReactNode }) {
  const { pathname } = useLocation();
  const contentRef = useRef<HTMLDivElement>(null);
  useEffect(
    () =>
      window.addEventListener('message', ({ data }) => {
        if (data.type === 'contentScollTop') contentRef.current?.scrollTo(0, 0);
      }),
    [],
  );
  useEffect(() => contentRef.current?.scrollTo(0, 0), [pathname]);
  return (
    <div className={styles.container} ref={contentRef}>
      {children}
    </div>
  );
}

export default Content;
