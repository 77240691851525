import { AnimatePresence, motion } from 'framer-motion';

import styles from './modalLoading.module.scss';

function ModalLoading({ isLoading }: { isLoading: boolean }) {
  return (
    <AnimatePresence>
      {isLoading && (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className={styles.container}>
          <div className={styles.inner}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default ModalLoading;
