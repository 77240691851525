import classNames from 'classnames';
import { useEffect, useRef } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { foldState } from '../stores/layoutStore';
import { decrypt } from '../assets/util/crypto';

// components
import AsideItem from '../components/aside/asideItem';

// style
import styles from './aside.module.scss';

// img
import {
  icCustomer,
  icCustomerActive,
  icNotification,
  icNotificationActive,
  icError,
  icErrorActive,
  icInquiry,
  icInquiryActive,
  icLive,
  icLiveActive,
  icMeeting,
  icMeetingActive,
  icMember,
  icMemberActive,
  icInspection,
  icInspectionActive,
  icSurvey,
  icSurveyActive,
  icEvaluation,
  icEvaluationActive,
  icContents,
  icContentsActive,
  icVideo,
  icVideoActive,
  icCommunity,
  icCommunityActive,
  icLearn,
  icLearnActive,
  icAlarm,
  icAlarmActive,
  icDemo,
  icDemoActive,
} from '../components/aside/icon';

function Aside() {
  const { t } = useTranslation();
  const { code } = useParams();
  const navigate = useNavigate();
  const link = (path: string) => navigate(`/${code}${path}`);
  const { pathname } = useLocation();

  const asideRef = useRef<HTMLDivElement>(null);
  const asideScroll = (e: number) =>
    setTimeout(() => asideRef.current?.scrollTo({ top: e, left: 0, behavior: 'smooth' }), 200);

  useEffect(
    () =>
      window.addEventListener('message', ({ data }) => {
        if (data.type === 'asideScroll') asideScroll(data.message);
      }),
    [],
  );
  const fold = useRecoilValue(foldState);
  const [cookies] = useCookies(['XID']);
  const isModuleInclude = (module: string) => decrypt(cookies.XID).c_modules?.includes(module);
  const isRoleInclude = (role: string) => decrypt(cookies.XID).mr_code?.includes(role);
  const isPathInclude = (path: string) => pathname.replace(`/${code}`, '').includes(path);
  const isIncludes = (module: string, path: string) => {
    const moduleInclude = isModuleInclude(module);
    const roleInclude = isRoleInclude(module);
    const pathInclude = isPathInclude(path);
    return (!moduleInclude || !roleInclude) && pathInclude;
  };
  const isActive = (link: string) => pathname === `/${code}${link}`;
  useEffect(() => {
    if (decrypt(cookies.XID).mg_code !== 'SUPER' && isPathInclude('/customer')) link('/');
    if (decrypt(cookies.XID).mg_code !== 'SUPER' && isPathInclude('/announcement')) link('/');
    if (decrypt(cookies.XID).mg_code !== 'SUPER' && isPathInclude('/enquiry')) link('/');
    if (decrypt(cookies.XID).mg_code !== 'SUPER' && isActive('/error')) link('/');
    if (decrypt(cookies.XID).mg_code === 'SUPER' && isPathInclude('/member')) link('/');
    if (decrypt(cookies.XID).mg_code === 'MANAGER' && !isRoleInclude('MEMBER') && isPathInclude('/member')) link('/');
    if (isIncludes('LIVE', '/live')) link('/');
    if (isIncludes('MEETING', '/meeting')) link('/');
    if (isIncludes('INSPECTION', '/inspection')) link('/');
    if (isIncludes('SURVEY', '/survey')) link('/');
    if (isIncludes('EVALUATION', '/evaluation')) link('/');
    if (isIncludes('SALES', '/salestip')) link('/');
    if (isIncludes('VOD', '/vod')) link('/');
    if (decrypt(cookies.XID).mg_code === 'SUPER' && isPathInclude('/board')) link('/');
    if (decrypt(cookies.XID).mg_code === 'MANAGER' && !isRoleInclude('BOARD') && isPathInclude('/board')) link('/');
    if (isIncludes('LEARNINGTALK', '/learningtalk')) link('/');
    if (decrypt(cookies.XID).mg_code === 'SUPER' && isPathInclude('/push')) link('/');
    if (decrypt(cookies.XID).mg_code === 'MANAGER' && !isRoleInclude('PUSH') && isPathInclude('/push')) link('/');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <div className={classNames(styles.container, fold && styles.hide)} ref={asideRef}>
      {decrypt(cookies.XID).mg_code === 'SUPER' ? (
        <>
          <AsideItem
            title={t('고객관리')}
            icon={icCustomer}
            iconActive={icCustomerActive}
            active={isPathInclude('/customer')}
          >
            <div
              className={classNames(styles.item, isActive('/customer') && styles.active)}
              onClick={() => link('/customer')}
            >
              <span>{t('고객목록')}</span>
            </div>
            <div
              className={classNames(styles.item, isActive('/customer/edit') && styles.active)}
              onClick={() => link('/customer/edit')}
            >
              <span>{t('고객등록')}</span>
            </div>
          </AsideItem>
          <AsideItem
            title={t('공지사항관리')}
            icon={icNotification}
            iconActive={icNotificationActive}
            active={isPathInclude('/announcement')}
          >
            <div
              className={classNames(styles.item, isActive('/announcement') && styles.active)}
              onClick={() => link('/announcement')}
            >
              <span>{t('공지사항목록')}</span>
            </div>
            <div
              className={classNames(styles.item, isActive('/announcement/edit') && styles.active)}
              onClick={() => link('/announcement/edit')}
            >
              <span>{t('공지사항등록')}</span>
            </div>
          </AsideItem>
          <AsideItem
            title={t('문의사항목록')}
            icon={icInquiry}
            iconActive={icInquiryActive}
            active={isActive('/enquiry') || isActive('/enquiry/detail')}
            link="/enquiry"
          ></AsideItem>
          <AsideItem title={t('데모신청관리')} icon={icDemo} iconActive={icDemoActive} active={isPathInclude('/demo')}>
            <div className={classNames(styles.item, isActive('/demo') && styles.active)} onClick={() => link('/demo')}>
              <span>{t('데모신청목록')}</span>
            </div>
            <div
              className={classNames(styles.item, isActive('/demo/history') && styles.active)}
              onClick={() => link('/demo/history')}
            >
              <span>{t('이전데모신청목록')}</span>
            </div>
          </AsideItem>
          <AsideItem
            title={t('오류목록')}
            icon={icError}
            iconActive={icErrorActive}
            active={isActive('/error')}
            link="/error"
          ></AsideItem>
        </>
      ) : (
        <>
          {(decrypt(cookies.XID).mg_code === 'ADMIN' ||
            (decrypt(cookies.XID).mg_code === 'MANAGER' && isRoleInclude('MEMBER'))) && (
            <AsideItem
              title={t('회원관리')}
              icon={icMember}
              iconActive={icMemberActive}
              active={isPathInclude('/member')}
            >
              <div
                className={classNames(styles.item, isActive('/member') && styles.active)}
                onClick={() => link('/member')}
              >
                <span>{t('회원목록')}</span>
              </div>
              <div
                className={classNames(styles.item, isActive('/member/edit') && styles.active)}
                onClick={() => link('/member/edit')}
              >
                <span>{t('회원등록')}</span>
              </div>
              <div
                className={classNames(styles.item, isActive('/member/organization') && styles.active)}
                onClick={() => link('/member/organization')}
              >
                <span>{t('회원조직')}</span>
              </div>
            </AsideItem>
          )}
          {isModuleInclude('LIVE') && isRoleInclude('LIVE') && (
            <AsideItem title={t('라이브관리')} icon={icLive} iconActive={icLiveActive} active={isPathInclude('/live')}>
              <div
                className={classNames(styles.item, isActive('/live') && styles.active)}
                onClick={() => link('/live')}
              >
                <span>{t('라이브목록')}</span>
              </div>
              <div
                className={classNames(styles.item, isActive('/live/edit') && styles.active)}
                onClick={() => link('/live/edit')}
              >
                <span>{t('라이브등록')}</span>
              </div>
            </AsideItem>
          )}
          {isModuleInclude('MEETING') && isRoleInclude('MEETING') && (
            <AsideItem
              title={t('회의관리')}
              icon={icMeeting}
              iconActive={icMeetingActive}
              active={isPathInclude('/meeting')}
            >
              <div
                className={classNames(styles.item, isActive('/meeting') && styles.active)}
                onClick={() => link('/meeting')}
              >
                <span>{t('회의목록')}</span>
              </div>
              <div
                className={classNames(styles.item, isActive('/meeting/edit') && styles.active)}
                onClick={() => link('/meeting/edit')}
              >
                <span>{t('회의등록')}</span>
              </div>
            </AsideItem>
          )}
          {isModuleInclude('INSPECTION') && isRoleInclude('INSPECTION') && (
            <AsideItem
              title={t('체크리스트관리')}
              icon={icInspection}
              iconActive={icInspectionActive}
              active={isPathInclude('/inspection')}
            >
              <div
                className={classNames(styles.item, isActive('/inspection') && styles.active)}
                onClick={() => link('/inspection')}
              >
                <span>{t('체크리스트목록')}</span>
              </div>
              <div
                className={classNames(styles.item, isActive('/inspection/edit') && styles.active)}
                onClick={() => link('/inspection/edit')}
              >
                <span>{t('체크리스트등록')}</span>
              </div>
            </AsideItem>
          )}
          {isModuleInclude('SURVEY') && isRoleInclude('SURVEY') && (
            <AsideItem
              title={t('설문조사관리')}
              icon={icSurvey}
              iconActive={icSurveyActive}
              active={isPathInclude('/survey')}
            >
              <div
                className={classNames(styles.item, isActive('/survey') && styles.active)}
                onClick={() => link('/survey')}
              >
                <span>{t('설문조사목록')}</span>
              </div>
              <div
                className={classNames(styles.item, isActive('/survey/edit') && styles.active)}
                onClick={() => link('/survey/edit')}
              >
                <span>{t('설문조사등록')}</span>
              </div>
            </AsideItem>
          )}
          {isModuleInclude('EVALUATION') && isRoleInclude('EVALUATION') && (
            <AsideItem
              title={t('평가관리')}
              icon={icEvaluation}
              iconActive={icEvaluationActive}
              active={isPathInclude('/evaluation')}
            >
              <div
                className={classNames(styles.item, isActive('/evaluation') && styles.active)}
                onClick={() => link('/evaluation')}
              >
                <span>{t('평가목록')}</span>
              </div>
              <div
                className={classNames(styles.item, isActive('/evaluation/edit') && styles.active)}
                onClick={() => link('/evaluation/edit')}
              >
                <span>{t('평가등록')}</span>
              </div>
              <div
                className={classNames(styles.item, isActive('/evaluation/question') && styles.active)}
                onClick={() => link('/evaluation/question')}
              >
                <span>{t('평가문제목록')}</span>
              </div>
              <div
                className={classNames(styles.item, isActive('/evaluation/question/edit') && styles.active)}
                onClick={() => link('/evaluation/question/edit')}
              >
                <span>{t('평가문제등록')}</span>
              </div>
              <div
                className={classNames(styles.item, isActive('/evaluation/category') && styles.active)}
                onClick={() => link('/evaluation/category')}
              >
                <span>{t('평가카테고리')}</span>
              </div>
            </AsideItem>
          )}
          {isModuleInclude('SALES') && isRoleInclude('SALES') && (
            <AsideItem
              title={t('세일즈팁관리')}
              icon={icContents}
              iconActive={icContentsActive}
              active={isPathInclude('/salestip')}
            >
              <div
                className={classNames(styles.item, isActive('/salestip') && styles.active)}
                onClick={() => link('/salestip')}
              >
                <span>{t('세일즈팁목록')}</span>
              </div>
              <div
                className={classNames(styles.item, isActive('/salestip/edit') && styles.active)}
                onClick={() => link('/salestip/edit')}
              >
                <span>{t('세일즈팁등록')}</span>
              </div>
              <div
                className={classNames(styles.item, isActive('/salestip/category') && styles.active)}
                onClick={() => link('/salestip/category')}
              >
                <span>{t('세일즈팁카테고리')}</span>
              </div>
            </AsideItem>
          )}
          {isModuleInclude('VOD') && isRoleInclude('VOD') && (
            <AsideItem title={t('VOD관리')} icon={icVideo} iconActive={icVideoActive} active={isPathInclude('/vod')}>
              <div className={classNames(styles.item, isActive('/vod') && styles.active)} onClick={() => link('/vod')}>
                <span>{t('VOD목록')}</span>
              </div>
              <div
                className={classNames(styles.item, isActive('/vod/edit') && styles.active)}
                onClick={() => link('/vod/edit')}
              >
                <span>{t('VOD등록')}</span>
              </div>
            </AsideItem>
          )}
          {(decrypt(cookies.XID).mg_code === 'ADMIN' ||
            (decrypt(cookies.XID).mg_code === 'MANAGER' && isRoleInclude('BOARD'))) && (
            <AsideItem
              title={t('커뮤니티관리')}
              icon={icCommunity}
              iconActive={icCommunityActive}
              active={isPathInclude('/board')}
            >
              <div
                className={classNames(styles.item, isActive('/board') && styles.active)}
                onClick={() => link('/board')}
              >
                <span>{t('커뮤니티카테고리')}</span>
              </div>
              <div
                className={classNames(styles.item, isActive('/board/free') && styles.active)}
                onClick={() => link('/board/free')}
              >
                <span>{t('자유게시판목록')}</span>
              </div>
              <div
                className={classNames(styles.item, isActive('/board/free/edit') && styles.active)}
                onClick={() => link('/board/free/edit')}
              >
                <span>{t('자유게시판등록')}</span>
              </div>
              <div
                className={classNames(styles.item, isActive('/board/notice') && styles.active)}
                onClick={() => link('/board/notice')}
              >
                <span>{t('공지사항목록')}</span>
              </div>
              <div
                className={classNames(styles.item, isActive('/board/notice/edit') && styles.active)}
                onClick={() => link('/board/notice/edit')}
              >
                <span>{t('공지사항등록')}</span>
              </div>
              <div
                className={classNames(styles.item, isActive('/board/qna') && styles.active)}
                onClick={() => link('/board/qna')}
              >
                <span>{t('Q&A목록')}</span>
              </div>
            </AsideItem>
          )}
          {isModuleInclude('LEARNINGTALK') && isRoleInclude('LEARNINGTALK') && (
            <AsideItem
              title={t('러닝톡톡')}
              icon={icLearn}
              iconActive={icLearnActive}
              active={isPathInclude('/learningtalk')}
            >
              <div
                className={classNames(styles.item, isActive('/learningtalk') && styles.active)}
                onClick={() => link('/learningtalk')}
              >
                <span>{t('러닝톡톡신고목록')}</span>
              </div>
            </AsideItem>
          )}
          {(decrypt(cookies.XID).mg_code === 'ADMIN' ||
            (decrypt(cookies.XID).mg_code === 'MANAGER' && isRoleInclude('PUSH'))) && (
            <AsideItem title={t('푸시알림')} icon={icAlarm} iconActive={icAlarmActive} active={isPathInclude('/push')}>
              <div
                className={classNames(styles.item, isActive('/push') && styles.active)}
                onClick={() => link('/push')}
              >
                <span>{t('푸시알림기록')}</span>
              </div>
              <div
                className={classNames(styles.item, isActive('/push/edit') && styles.active)}
                onClick={() => link('/push/edit')}
              >
                <span>{t('푸시알림등록')}</span>
              </div>
            </AsideItem>
          )}
        </>
      )}
    </div>
  );
}

export default Aside;
