import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

// style
import styles from './page.module.scss';

function Page({
  title,
  children,
  goBack,
  btn,
  isLoading,
}: {
  title: string;
  children: React.ReactNode | null;
  // eslint-disable-next-line react/require-default-props
  goBack?: string | number;
  // eslint-disable-next-line react/require-default-props
  btn?: { name: string; link: string };
  // eslint-disable-next-line react/require-default-props
  isLoading?: boolean;
}) {
  const { code } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>
          <span>{t(title)}</span>
        </div>
        {goBack && (
          <button
            type="button"
            className="secondary"
            onClick={() => (goBack === -1 ? navigate(-1) : navigate(`/${code}${goBack}`))}
          >
            {t('목록')}
          </button>
        )}
        {btn && (
          <button type="button" className="dark" onClick={() => navigate(`/${btn.link}`)}>
            {t(btn.name)}
          </button>
        )}
      </div>
      <div className={styles.content}>{children}</div>
      <AnimatePresence>
        {isLoading && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className={styles.loading}
          >
            <div className={styles.inner}>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default Page;
