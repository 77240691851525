import axios from 'axios';
import ko from 'date-fns/locale/ko';
import queryString from 'query-string';
import DatePicker from 'react-datepicker';
import { useState, useEffect, useRef } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useMutation, useQuery } from '@tanstack/react-query';
import { createError, throwError } from '../../api/error';
import { getOrgList } from '../../api/member';
import { createMeeting, editMeeting, getMeeting } from '../../api/meeting';
import { useAlert } from '../../stores/alertStore';
import { useConfirm } from '../../stores/confirmStore';
import { loadingState } from '../../stores/loadingStore';
import { memberList, memberOrgMultyState } from '../../stores/memberStore';
import { flatOrg } from '../../assets/util/flatOrg';

// components
import Page from '../../includes/page';
import FormItem from '../../components/basic/formItem';
import MemberList from '../../components/member/memberList';
import InspectionOrg from '../../components/inspection/org';
import { editDateFormat } from '../../assets/util/dateFormat';

const api = process.env.REACT_APP_API;

function MeetingEdit() {
  const { t } = useTranslation();
  const [cookies] = useCookies(['xclass-lang', 'xclass-cname']);
  const { code } = useParams();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { mt_key } = queryString.parse(search);
  const { alertShow } = useAlert();
  const { confirmShow } = useConfirm();

  // 강사선택
  const [list, setList] = useRecoilState(memberList);
  // 제목
  const [title, setTitle] = useState('');
  const [contents, setContents] = useState('');
  const [pin, setPin] = useState('');
  // 섬네일
  const [thumbnail, setThumbnail] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [uploadFile, setUploadFile] = useState<any>(null);
  const [uploadImg, setUploadImg] = useState<string | null>(null);
  const [uploadImgName, setUploadImgName] = useState('');
  const imgRef = useRef<HTMLImageElement>(null);
  const changeThumb = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event?.target?.files) {
      setUploadFile(null);
      setUploadImg(null);
      setUploadImgName('');
      return;
    }
    const maxSize = 1 * 1024 * 1024;
    if (event.target.files[0].size > maxSize) {
      alertShow('첨부 이미지의 사이즈는 1024KB 이내로 등록 가능합니다.');
      return;
    }
    const getExtension = (fileName: string) => {
      const fileLength = fileName.length;
      const lastDot = fileName.lastIndexOf('.');
      const fileExtension = `.${fileName.substring(lastDot + 1, fileLength)}`;
      return fileExtension.toLowerCase();
    };
    if (
      getExtension(event.target.files[0].name) !== '.png' &&
      getExtension(event.target.files[0].name) !== '.jpg' &&
      getExtension(event.target.files[0].name) !== '.jpeg'
    ) {
      alertShow('이미지는 jpg, png 형식의 파일만 등록할 수 있습니다.');
      return;
    }
    setUploadFile(event.target.files[0]);
    setUploadImg(URL.createObjectURL(event.target.files[0]));
    setUploadImgName(event.target.files[0].name);
    try {
      const reader: FileReader = new FileReader();
      reader.onload = (event: ProgressEvent<FileReader>) => {
        console.log(event);
      };
      reader.onerror = error => {
        console.log(error);
      };
      reader.readAsDataURL(event.target.files[0]);
    } catch (error) {
      console.log(error);
    }
  };
  // 공개시간
  const [openTime, setOpenTime] = useState<Date | null>(new Date());
  const [closeTime, setCloseTime] = useState<Date | null>(new Date());
  useEffect(() => {
    if (Number(closeTime) < Number(openTime)) setCloseTime(openTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openTime, closeTime]);
  // 방송시간
  const [startTime, setStartTime] = useState<Date | null>(new Date());
  const [endTime, setEndTime] = useState<Date | null>(new Date());
  useEffect(() => {
    if (Number(endTime) < Number(startTime)) setEndTime(startTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTime, endTime]);
  const [beforeEnterTime, setBeforeEnterTime] = useState(0);
  const [maxUser, setMaxUser] = useState(100);
  // 조직
  const [orgAleady, setOrgAleady] = useState(false);
  const [memberOrg, setMemberOrg] = useRecoilState(memberOrgMultyState);
  const { isFetching: orgLoading } = useQuery<{ data: IOrg }>(['org'], getOrgList, {
    refetchOnWindowFocus: false,
    onSuccess: e => {
      setMemberOrg(prev => ({ ...prev, data: e.data }));
      setOrgAleady(true);
    },
  });

  const { isFetching: getMeetingLoading } = useQuery<{ data: IMeeting }>(
    ['getMeeting'],
    () => getMeeting(Number(mt_key)),
    {
      refetchOnWindowFocus: false,
      enabled: mt_key !== undefined && orgAleady,
      onSuccess: e => {
        setList(prev => ({
          ...prev,
          selected: {
            ...prev.selected,
            m_key: e.data.member.m_key,
            m_id: e.data.member.m_id,
            m_name: e.data.member.m_name,
          },
        }));
        setTitle(e.data.mt_title);
        setContents(e.data.mt_contents);
        setPin(e.data.mt_pin || '');
        setThumbnail(e.data.mt_thumbnail);
        setUploadFile(`uploaded_${e.data.mt_thumbnail}`);
        setUploadImg(`${process.env.REACT_APP_UPLOADED_URL}/${e.data.mt_thumbnail}`);
        setUploadImgName(`uploaded_${e.data.mt_thumbnail}`);
        setOpenTime(new Date(e.data.mt_open_time));
        setCloseTime(new Date(e.data.mt_close_time));
        setStartTime(new Date(e.data.mt_start_time));
        setEndTime(new Date(e.data.mt_end_time));
        setBeforeEnterTime(e.data.mt_before_enter_time);
        setMaxUser(e.data.mt_max_user);
        setMemberOrg(prev => ({ ...prev, selected: e.data.organizations.map(el => el.mo_key) }));
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (e: any) => alertShow(e.response.data.message),
    },
  );

  // 초기화
  const init = () => {
    setList({
      state: false,
      selected: {
        m_key: 0,
        mg_code: '',
        mr_code: '',
        mo_key: 0,
        m_id: '',
        m_name: '',
        m_gender: '',
        m_email: '',
        m_last_login_at: '',
        m_delete_at: '',
        created_at: '',
        updated_at: '',
        mo_name: '',
      },
      data: [],
    });
    setTitle('');
    setContents('');
    setPin('');
    setThumbnail('');
    setUploadFile(null);
    setUploadImg(null);
    setUploadImgName('');
    setOpenTime(new Date());
    setCloseTime(new Date());
    setStartTime(new Date());
    setEndTime(new Date());
    setBeforeEnterTime(0);
    setMaxUser(100);
    setMemberOrg({
      state: false,
      selected: [],
      data: { mo_key: 0 },
    });
  };
  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const { mutate: create } = useMutation(createMeeting, {
    onSuccess: () => {
      setLoading(false);
      navigate(`/${code}/meeting`);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (e: any) => {
      setLoading(false);
      createError({
        type: '[ADMIN] meeting - createMeeting',
        message: `message: "${e.message}",\nresponse: { status: ${e.response.status}, message: "${e.response.data.message}" }`,
      });
      throwError(e);
    },
  });

  const { mutate: update } = useMutation(editMeeting, {
    onSuccess: () => {
      setLoading(false);
      navigate(`/${code}/meeting`);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (e: any) => {
      setLoading(false);
      createError({
        type: '[ADMIN] meeting - editMeeting',
        message: `message: "${e.message}",\nresponse: { status: ${e.response.status}, message: "${e.response.data.message}" }`,
      });
      throwError(e);
    },
  });

  const setLoading = useSetRecoilState(loadingState);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formData = (_fields: any, _file: any) => {
    const data = new FormData();
    Object.keys(_fields).forEach(key => data.append(key, _fields[key]));
    if (_file) {
      data.append('file', _file);
    }
    return data;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fileUpload = async (thumbnail: string, url: string, form: any) => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data = (await axios.post(url, form)) as any;
      if (data.ok || data.status === 204) {
        console.log(data);
        if (mt_key) {
          update({
            mt_key: Number(mt_key),
            data: {
              instructor: list.selected.m_key,
              title,
              contents,
              pin: !pin ? undefined : pin,
              thumbnail,
              openTime: editDateFormat(openTime || new Date()),
              closeTime: editDateFormat(closeTime || new Date()),
              startTime: editDateFormat(startTime || new Date()),
              endTime: editDateFormat(endTime || new Date()),
              beforeEnterTime,
              maxUser,
              organizationKeys: memberOrg.selected,
            },
          });
        } else {
          create({
            instructor: list.selected.m_key,
            title,
            contents,
            pin: !pin ? undefined : pin,
            thumbnail,
            openTime: editDateFormat(openTime || new Date()),
            closeTime: editDateFormat(closeTime || new Date()),
            startTime: editDateFormat(startTime || new Date()),
            endTime: editDateFormat(endTime || new Date()),
            beforeEnterTime,
            maxUser,
            organizationKeys: memberOrg.selected,
          });
        }
      } else {
        console.log('error');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const uploadMeetingThumbnail = async () => {
    try {
      const { data } = await axios.post(`${api}/admin/meeting/upload_thumbnail`, {}, { withCredentials: true });
      await fileUpload(data.data.name, data.data.url.url, formData(data.data.url.fields, uploadFile));
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      setLoading(false);
      console.log(e);
    }
  };

  const upload = () => {
    setLoading(true);
    if (mt_key && uploadImgName.includes('uploaded_')) {
      update({
        mt_key: Number(mt_key),
        data: {
          instructor: list.selected.m_key,
          title,
          contents,
          pin: !pin ? undefined : pin,
          thumbnail,
          openTime: editDateFormat(openTime || new Date()),
          closeTime: editDateFormat(closeTime || new Date()),
          startTime: editDateFormat(startTime || new Date()),
          endTime: editDateFormat(endTime || new Date()),
          beforeEnterTime,
          maxUser,
          organizationKeys: memberOrg.selected,
        },
      });
      return;
    }
    uploadMeetingThumbnail();
  };

  return (
    <Page title={mt_key ? '회의수정' : '회의등록'} isLoading={orgLoading || getMeetingLoading}>
      <FormItem title="강사" essential>
        <div className="input">
          <input
            type="text"
            placeholder={t('강사') || ''}
            value={list.selected.m_name}
            readOnly
            className="combo"
            onClick={() => setList(prev => ({ ...prev, state: true }))}
          />
          <div className="arrow"></div>
        </div>
      </FormItem>
      <FormItem title="제목" essential>
        <div className="input">
          <input type="text" maxLength={50} placeholder="제목" value={title} onChange={e => setTitle(e.target.value)} />
        </div>
      </FormItem>
      <FormItem title="내용" essential>
        <div className="input">
          <textarea
            placeholder={t('내용') || ''}
            maxLength={200}
            value={contents}
            onChange={e => setContents(e.target.value)}
          ></textarea>
          <div className="count">{contents.length} / 200</div>
        </div>
      </FormItem>
      {/* <FormItem title="PIN">
        <div className="input">
          <input type="text" maxLength={24} placeholder="PIN" value={pin} onChange={e => setPin(e.target.value)} />
        </div>
      </FormItem> */}
      <FormItem title="섬네일" essential>
        <div className="file">
          <div className="uploaded" style={{ display: uploadImg ? '' : 'none' }}>
            <img src={uploadImg || ''} alt="img" ref={imgRef} />
          </div>
          <label htmlFor="img-upload">
            {uploadImgName === '' ? (
              <div className="placeholder">
                <span>{t('섬네일 업로드 (용량 1024KB 이내의 jpg, png 파일)')}</span>
              </div>
            ) : (
              <div className="text">
                <span>{uploadImgName}</span>
              </div>
            )}
            <input type="file" id="img-upload" onChange={e => changeThumb(e)} accept="image/*" />
          </label>
        </div>
      </FormItem>
      <FormItem title="공개시간" essential>
        <div className="input" style={{ maxWidth: '608px' }}>
          <DatePicker
            selected={openTime}
            onChange={date => setOpenTime(date)}
            minDate={null}
            maxDate={closeTime}
            selectsStart
            startDate={openTime}
            endDate={closeTime}
            locale={cookies['xclass-lang'] === 'ko' ? ko : undefined}
            dateFormat="yyyy-MM-dd hh:mm aa"
            timeInputLabel={`${t('시각')}:`}
            showTimeInput
            disabledKeyboardNavigation
            onChangeRaw={(e: React.FormEvent<HTMLInputElement>) => e.preventDefault()}
          />
          <div style={{ margin: '0 8px', color: 'var(--greyish1)' }}>-</div>
          <DatePicker
            selected={closeTime}
            onChange={date => setCloseTime(date)}
            maxDate={null}
            selectsEnd
            startDate={openTime}
            endDate={closeTime}
            locale={cookies['xclass-lang'] === 'ko' ? ko : undefined}
            dateFormat="yyyy-MM-dd hh:mm aa"
            timeInputLabel={`${t('시각')}:`}
            showTimeInput
            disabledKeyboardNavigation
            onChangeRaw={(e: React.FormEvent<HTMLInputElement>) => e.preventDefault()}
          />
        </div>
      </FormItem>
      <FormItem title="진행기간" essential>
        <div className="input" style={{ maxWidth: '608px' }}>
          <DatePicker
            selected={startTime}
            onChange={date => setStartTime(date)}
            minDate={null}
            maxDate={endTime}
            selectsStart
            startDate={startTime}
            endDate={endTime}
            locale={cookies['xclass-lang'] === 'ko' ? ko : undefined}
            dateFormat="yyyy-MM-dd hh:mm aa"
            timeInputLabel={`${t('시각')}:`}
            showTimeInput
            disabledKeyboardNavigation
            onChangeRaw={(e: React.FormEvent<HTMLInputElement>) => e.preventDefault()}
          />
          <div style={{ margin: '0 8px', color: 'var(--greyish1)' }}>-</div>
          <DatePicker
            selected={endTime}
            onChange={date => setEndTime(date)}
            maxDate={null}
            selectsEnd
            startDate={startTime}
            endDate={endTime}
            locale={cookies['xclass-lang'] === 'ko' ? ko : undefined}
            dateFormat="yyyy-MM-dd hh:mm aa"
            timeInputLabel={`${t('시각')}:`}
            showTimeInput
            disabledKeyboardNavigation
            onChangeRaw={(e: React.FormEvent<HTMLInputElement>) => e.preventDefault()}
          />
        </div>
      </FormItem>
      <FormItem title="입장시간" essential>
        <div className="input">
          <select value={beforeEnterTime} onChange={e => setBeforeEnterTime(Number(e.target.value))}>
            <option value={0}>{t('시작시간 분 전부터', { minutes: 0 })}</option>
            <option value={5}>{t('시작시간 분 전부터', { minutes: 5 })}</option>
            <option value={15}>{t('시작시간 분 전부터', { minutes: 15 })}</option>
            <option value={30}>{t('시작시간 분 전부터', { minutes: 30 })}</option>
            <option value={60}>{t('시작시간 분 전부터', { minutes: 60 })}</option>
          </select>
          <div className="arrow"></div>
        </div>
      </FormItem>
      <FormItem title="참가인원" essential>
        <div className="input">
          <input
            type="text"
            placeholder={t('참가인원') || ''}
            value={maxUser}
            onChange={e => {
              setMaxUser(Number(e.target.value.replace(/[^0-9]/g, '')));
              if (Number(e.target.value) > 10000) setMaxUser(10000);
            }}
          />
        </div>
      </FormItem>
      <FormItem title="조직" essential>
        <div className="input">
          <input
            type="text"
            placeholder={t('조직') || ''}
            value={
              flatOrg(memberOrg.data)
                .concat({ mo_key: memberOrg.data.mo_key })
                .filter(ele => memberOrg.selected.includes(ele.mo_key)).length > 0
                ? flatOrg(memberOrg.data)
                    .concat({ mo_key: memberOrg.data.mo_key })
                    .filter(ele => memberOrg.selected.includes(ele.mo_key))
                    .map(el => el.mo_name)
                    .join(', ').length === 0
                  ? cookies['xclass-cname'] || ''
                  : flatOrg(memberOrg.data)
                      .concat({ mo_key: memberOrg.data.mo_key })
                      .filter(ele => memberOrg.selected.includes(ele.mo_key))
                      .map(el => el.mo_name)
                      .join(', ')
                : ''
            }
            readOnly
            className="combo"
            onClick={() => setMemberOrg(prev => ({ ...prev, state: true }))}
          />
          <div className="arrow"></div>
        </div>
      </FormItem>
      <div className="formSubmit">
        <button
          type="button"
          className="primary"
          onClick={() => {
            if (!list.selected.m_name) {
              alertShow('강사를 선택하십시오.');
              return;
            }
            if (!title) {
              alertShow('제목을 입력하십시오.');
              return;
            }
            if (!contents) {
              alertShow('내용을 입력하십시오.');
              return;
            }
            if (!uploadImgName) {
              alertShow('섬네일을 등록하십시오.');
              return;
            }
            if (openTime && closeTime && openTime >= closeTime) {
              alertShow('공개시간을 확인하십시오.');
              return;
            }
            if (startTime && endTime && startTime >= endTime) {
              alertShow('방송시간을 확인하십시오.');
              return;
            }
            if (memberOrg.selected.length === 0) {
              alertShow('조직을 선택하십시오.');
              return;
            }
            confirmShow(mt_key ? t('수정하시겠습니까?') : t('등록하시겠습니까?'), upload);
          }}
        >
          {mt_key ? t('수정') : t('등록')}
        </button>
      </div>
      <MemberList />
      <InspectionOrg />
    </Page>
  );
}

export default MeetingEdit;
