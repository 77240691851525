import classNames from 'classnames';

// components
import Modal from '../modal/modal';

// style
import styles from '../modal/selector.module.scss';

function ImgUploadLoading({ children }: { children: React.ReactNode | null }) {
  return (
    <Modal>
      <div className={classNames(styles.modal, styles.lang)}>{children}</div>
    </Modal>
  );
}

export default ImgUploadLoading;
