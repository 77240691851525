import { saveAs } from 'file-saver';
import { useCookies } from 'react-cookie';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { utils, write } from 'xlsx';
import { useQuery } from '@tanstack/react-query';
import { getOrgList } from '../../api/member';
import { memberOrgState, orgUploadModal } from '../../stores/memberStore';
import ExcelOrgUpload from '../../components/member/excelOrgUpload';

// components
import Page from '../../includes/page';
import OrgTree from '../../components/member/orgTree';

function MemberOrganization() {
  const { t } = useTranslation();
  const [cookies] = useCookies(['xclass-cname']);
  const [memberOrg, setMemberOrg] = useRecoilState(memberOrgState);
  const { isFetching: orgLoading, refetch } = useQuery<{ data: IOrg }>(['org'], getOrgList, {
    refetchOnWindowFocus: false,
    onSuccess: e => {
      setMemberOrg(prev => ({ ...prev, data: e.data }));
    },
  });

  const setModal = useSetRecoilState(orgUploadModal);

  // 양식 다운로드
  const fileDownload = () => {
    const data = [
      [`${cookies['xclass-cname']} (${t('기업명')})`],
      [undefined, `${cookies['xclass-cname']}-1`],
      [undefined, undefined, `${cookies['xclass-cname']}-1-1`],
      [undefined, undefined, `${cookies['xclass-cname']}-1-2`],
      [undefined, `${cookies['xclass-cname']}-2`],
      [undefined, `${cookies['xclass-cname']}-3`],
      [undefined, `${cookies['xclass-cname']}-4`],
    ];
    const workbook = utils.book_new();
    const sheet = utils.aoa_to_sheet(data);
    utils.book_append_sheet(workbook, sheet);
    const wbout = write(workbook, { bookType: 'xlsx', type: 'array' });
    const excelBlob = new Blob([wbout], { type: 'application/octet-stream' });
    saveAs(excelBlob, 'organization.xlsx');
  };

  return (
    <Page title="회원조직" isLoading={orgLoading}>
      <div className="table-tools">
        <div className="btns"></div>
        <div className="btns">
          <button type="button" className="warning" onClick={fileDownload}>
            <span>{t('양식 다운로드')}</span>
          </button>
          <button type="button" className="info" onClick={() => setModal(true)}>
            <span>{t('엑셀 등록')}</span>
          </button>
        </div>
      </div>
      <div className="orgContainer">
        <OrgTree item={memberOrg.data} refetch={refetch} />
      </div>
      <ExcelOrgUpload refresh={refetch} />
    </Page>
  );
}

export default MemberOrganization;
