import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { useMutation } from '@tanstack/react-query';
import { logout } from '../api/member';
import { useAlert } from '../stores/alertStore';
import { foldState, languageState } from '../stores/layoutStore';
import { decrypt } from '../assets/util/crypto';
import { getCookie } from '../assets/util/getCookie';
import { existImg } from '../assets/util/existImg';
import UseOnClickOutside from '../assets/util/UseOnClickOutside';

// components
import Language from '../components/modal/language';

// style
import styles from './header.module.scss';

// img
import faviconEmpty from '../assets/img/favicon_empty.svg';
import logo from '../assets/img/logo.svg';
import logoDark from '../assets/img/logo_dark.svg';
import logoEmpty from '../assets/img/logo_empty.svg';
import {
  icMenu,
  icOpenNew,
  icModeDark,
  icModeLight,
  icSettings,
  icLanguage,
  icAccount,
  icLogout,
  icMore,
} from '../components/aside/icon';

function Header() {
  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies([
    'xclass-theme',
    'xclass-cname',
    'xclass-clight',
    'xclass-cdark',
    'xclass-cwatermark',
    'XID',
  ]);
  const { pathname } = useLocation();
  const { code } = useParams();
  const navigate = useNavigate();

  const logoRef = useRef<HTMLImageElement>(null);

  const { alertTransShow } = useAlert();
  const setFold = useSetRecoilState(foldState);
  const setLanguage = useSetRecoilState(languageState);

  const { mutate: logoutMutate } = useMutation(logout, {
    onSuccess: () => {
      navigate(`/${code}/login`);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: () => {
      if (pathname === `/${code}/login`) return;
      window.location.href = `/${code}/login`;
    },
  });

  useEffect(
    () =>
      window.addEventListener('message', ({ data }) => {
        if (data.type === 'logout' && pathname !== `/${code}/login`) {
          navigate(`/${code}/login`);
          logoutMutate();
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (code?.toUpperCase() !== code) navigate(`/${code?.toUpperCase()}`);
    if (
      pathname !== `/${code}/login` &&
      pathname !== '/404' &&
      code?.toUpperCase() !== 'SUPER' &&
      code?.toUpperCase() !== getCookie('xclass-ccode')?.toUpperCase()
    ) {
      // eslint-disable-next-line no-return-assign
      alertTransShow(
        [
          { message: '요청하신 페이지를 찾을 수 없습니다.' },
          {
            message: '고객님의 기업코드는 입니다.',
            messageKey: 'code',
            messageVar: decrypt(cookies.XID).mg_code === 'SUPER' ? 'SUPER' : `${getCookie('xclass-ccode')}`,
          },
        ],
        // eslint-disable-next-line no-return-assign
        () =>
          (window.location.href = `/${decrypt(cookies.XID).mg_code === 'SUPER' ? 'SUPER' : getCookie('xclass-ccode')}`),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const setCompanyInit = (light?: string, dark?: string, title?: string) => {
    const setColor = (color: string) => {
      if (color && color !== 'null') document.documentElement.style.setProperty('--primary', color);
    };
    if (cookies['xclass-theme'] === 'light') {
      setColor(light || cookies['xclass-clight']);
    } else if (cookies['xclass-theme'] === 'dark') {
      setColor(dark || cookies['xclass-cdark']);
    } else if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      setCookie('xclass-theme', cookies['xclass-theme'] === 'dark' ? 'light' : 'dark', {
        domain: process.env.NODE_ENV === 'production' ? '.xclass.co.kr' : '.xcast.co.kr',
        path: '/',
      });
      setColor(dark || cookies['xclass-cdark']);
    } else {
      setCookie('xclass-theme', cookies['xclass-theme'] === 'dark' ? 'light' : 'dark', {
        domain: process.env.NODE_ENV === 'production' ? '.xclass.co.kr' : '.xcast.co.kr',
        path: '/',
      });
      setColor(light || cookies['xclass-clight']);
    }
    if (code?.toUpperCase() !== 'SUPER') {
      const htmlTitle = document.querySelector('title') as HTMLElement;
      htmlTitle.innerHTML = `${title || cookies['xclass-cname']} - Admin`;
      const customFavicon = `${
        process.env.REACT_APP_UPLOADED_URL
      }/xclass/image/customer/${code?.toUpperCase()}_FAVICON`;
      existImg(customFavicon, (result: boolean) => {
        if (result) {
          document.querySelector('#favicon')?.setAttribute('href', customFavicon);
          return;
        }
        document.querySelector('#favicon')?.setAttribute('href', faviconEmpty);
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setCompanyInit(), []);

  const [moreOpen, setMoreOpen] = useState(false);
  const moreRef = useRef<HTMLDivElement>(null);
  UseOnClickOutside(moreRef, () => setMoreOpen(false));
  return (
    <>
      <div className={styles.container} data-theme={cookies['xclass-theme']}>
        <div className={styles.inner}>
          <div className={styles.contents}>
            <div className={styles.start}>
              <object className={styles.menu} onClick={() => setFold(prev => !prev)}>
                {icMenu()}
              </object>
              <div className={styles.logo}>
                {code?.toUpperCase() !== 'SUPER' ? (
                  <img
                    src={`${process.env.REACT_APP_UPLOADED_URL}/xclass/image/customer/${code?.toUpperCase()}_${
                      cookies['xclass-theme'] === 'dark' ? 'DARK' : 'LIGHT'
                    }_LOGO`}
                    alt="logo"
                    onClick={() => navigate(`/${code}`)}
                    onError={() => {
                      if (logoRef.current) logoRef.current.src = logoEmpty;
                    }}
                    ref={logoRef}
                  />
                ) : (
                  <img
                    src={cookies['xclass-theme'] === 'dark' ? logoDark : logo}
                    alt="logo"
                    onClick={() => navigate(`/${code}`)}
                  />
                )}
              </div>
              {code?.toUpperCase() !== 'SUPER' && (
                <div className={styles.btn} onClick={() => window.open(`${process.env.REACT_APP_USER}/${code}`)}>
                  <object>{icOpenNew()}</object>
                  <span>{t('사용자 페이지')}</span>
                </div>
              )}
            </div>
            <div className={styles.center}></div>
            <div className={styles.end}>
              <object
                className={styles.icon}
                onClick={() => {
                  setCookie('xclass-theme', cookies['xclass-theme'] === 'dark' ? 'light' : 'dark', {
                    domain: process.env.NODE_ENV === 'production' ? '.xclass.co.kr' : '.xcast.co.kr',
                    path: '/',
                  });
                  if (cookies['xclass-clight'] && cookies['xclass-clight'] !== 'null')
                    document.documentElement.style.setProperty(
                      '--primary',
                      cookies['xclass-theme'] === 'dark' ? cookies['xclass-clight'] : cookies['xclass-cdark'],
                    );
                }}
              >
                {cookies['xclass-theme'] === 'light' ? icModeDark() : icModeLight()}
              </object>
              {code?.toUpperCase() !== 'SUPER' && decrypt(cookies.XID).mg_code === 'ADMIN' && (
                <object className={styles.icon} onClick={() => navigate(`/${code}/settings`)}>
                  {icSettings()}
                </object>
              )}
              <object className={styles.icon} onClick={() => setLanguage(prev => ({ ...prev, state: true }))}>
                {icLanguage()}
              </object>
              <div className={classNames(styles.btn, styles.change)} onClick={() => navigate(`/${code}/account`)}>
                <span>{t('회원정보 변경')}</span>
              </div>
              <div className={styles.btn} onClick={() => logoutMutate()}>
                <object>{icLogout()}</object>
                <span>{t('로그아웃')}</span>
              </div>
            </div>
            <div className={styles.mobile}>
              <object
                className={styles.icon}
                style={{ pointerEvents: moreOpen ? 'none' : 'auto' }}
                onClick={() => {
                  if (!moreOpen) setMoreOpen(prev => !prev);
                }}
              >
                {icMore()}
              </object>
            </div>
          </div>
        </div>
        <div className={styles.more} ref={moreRef}>
          {moreOpen && (
            <>
              <div
                className={styles.item}
                onClick={() => {
                  window.open(`${process.env.REACT_APP_USER}/${code}`);
                  setMoreOpen(false);
                }}
              >
                {icOpenNew()}
                <span>{t('사용자 페이지')}</span>
              </div>
              <div
                className={styles.item}
                onClick={() => {
                  setCookie('xclass-theme', cookies['xclass-theme'] === 'dark' ? 'light' : 'dark', {
                    domain: process.env.NODE_ENV === 'production' ? '.xclass.co.kr' : '.xcast.co.kr',
                    path: '/',
                  });
                  if (cookies['xclass-clight'] && cookies['xclass-clight'] !== 'null')
                    document.documentElement.style.setProperty(
                      '--primary',
                      cookies['xclass-theme'] === 'dark' ? cookies['xclass-clight'] : cookies['xclass-cdark'],
                    );
                }}
              >
                {cookies['xclass-theme'] === 'light' ? icModeDark() : icModeLight()}
                <span>{cookies['xclass-theme'] === 'light' ? t('다크모드') : t('라이트모드')}</span>
              </div>
              {code?.toUpperCase() !== 'SUPER' && decrypt(cookies.XID).mg_code === 'ADMIN' && (
                <div
                  className={styles.item}
                  onClick={() => {
                    navigate(`/${code}/settings`);
                    setMoreOpen(false);
                  }}
                >
                  {icSettings()}
                  <span>{t('설정')}</span>
                </div>
              )}
              <div
                className={styles.item}
                onClick={() => {
                  setLanguage(prev => ({ ...prev, state: true }));
                  setMoreOpen(false);
                }}
              >
                {icLanguage()}
                <span>{t('언어')}</span>
              </div>
              <div
                className={styles.item}
                onClick={() => {
                  navigate(`/${code}/account`);
                  setMoreOpen(false);
                }}
              >
                {icAccount()}
                <span>{t('회원정보 변경')}</span>
              </div>
              <div
                className={styles.item}
                onClick={() => {
                  setMoreOpen(false);
                  logoutMutate();
                }}
              >
                {icLogout()}
                <span>{t('로그아웃')}</span>
              </div>
            </>
          )}
        </div>
      </div>
      <Language />
    </>
  );
}

export default Header;
