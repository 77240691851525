import axios from 'axios';
import classNames from 'classnames';
import { useState, useEffect, useRef } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { useMutation, useQuery } from '@tanstack/react-query';
import { createError, throwError } from '../api/error';
import { codeValidation, editCustomer } from '../api/customer';
import { getMemberRole } from '../api/member';
import { useAlert } from '../stores/alertStore';
import { useConfirm } from '../stores/confirmStore';
import { loadingState } from '../stores/loadingStore';

// components
import Page from '../includes/page';
import FormItem from '../components/basic/formItem';
import Address from '../components/customer/address';
import ColorItem from '../components/customer/colorItem';

// img
import logoEmpty from '../assets/img/logo_empty.svg';
import faviconEmpty from '../assets/img/favicon_empty.svg';
import { decrypt } from '../assets/util/crypto';

const api = process.env.REACT_APP_API;

function Settings() {
  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies(['xclass-theme', 'xclass-clight', 'xclass-cwatermark', 'XID']);
  const { code } = useParams();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { alertShow } = useAlert();
  const { confirmShow } = useConfirm();

  const [cKey, setCKey] = useState(0);
  // 로고
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [uploadFile, setUploadFile] = useState<{ dark: any; light: any }>({ dark: null, light: null });
  const [uploadImg, setUploadImg] = useState<{ dark: string | null; light: string | null }>({
    dark: null,
    light: null,
  });
  const [uploadImgName, setUploadImgName] = useState<{ dark: string; light: string }>({ dark: '', light: '' });
  const imgRefLight = useRef<HTMLImageElement>(null);
  const imgRefDark = useRef<HTMLImageElement>(null);
  const changeLogo = (event: React.ChangeEvent<HTMLInputElement>, type: string) => {
    if (!event?.target?.files) {
      setUploadFile(prev => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const obj: any = { ...prev };
        obj[type] = null;
        return obj;
      });
      setUploadImg(prev => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const obj: any = { ...prev };
        obj[type] = null;
        return obj;
      });
      setUploadImgName(prev => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const obj: any = { ...prev };
        obj[type] = '';
        return obj;
      });
      return;
    }
    const maxSize = 1 * 1024 * 1024;
    if (event.target.files[0].size > maxSize) {
      alertShow('첨부 이미지의 사이즈는 1024KB 이내로 등록 가능합니다.');
      return;
    }
    const getExtension = (fileName: string) => {
      const fileLength = fileName.length;
      const lastDot = fileName.lastIndexOf('.');
      const fileExtension = `.${fileName.substring(lastDot + 1, fileLength)}`;
      return fileExtension.toLowerCase();
    };
    if (
      getExtension(event.target.files[0].name) !== '.png' &&
      getExtension(event.target.files[0].name) !== '.jpg' &&
      getExtension(event.target.files[0].name) !== '.jpeg'
    ) {
      alertShow('이미지는 jpg, png 형식의 파일만 등록할 수 있습니다.');
      return;
    }
    const ele = event.target.files[0];
    setUploadFile(prev => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const obj: any = { ...prev };
      // eslint-disable-next-line prefer-destructuring
      obj[type] = ele;
      return obj;
    });
    setUploadImg(prev => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const obj: any = { ...prev };
      // eslint-disable-next-line prefer-destructuring
      obj[type] = URL.createObjectURL(ele);
      return obj;
    });
    setUploadImgName(prev => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const obj: any = { ...prev };
      // eslint-disable-next-line prefer-destructuring
      obj[type] = ele.name;
      return obj;
    });
    try {
      const reader: FileReader = new FileReader();
      reader.onload = (event: ProgressEvent<FileReader>) => {
        console.log(event);
      };
      reader.onerror = error => {
        console.log(error);
      };
      reader.readAsDataURL(event.target.files[0]);
    } catch (error) {
      console.log(error);
    }
  };
  // 파비콘
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [faviconFile, setFaviconFile] = useState<any>(null);
  const [faviconImg, setFaviconImg] = useState<string | null>(null);
  const [faviconImgName, setFaviconImgName] = useState('');
  const faviconRef = useRef<HTMLImageElement>(null);
  const changeFavicon = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event?.target?.files) {
      setFaviconFile(null);
      setFaviconImg(null);
      setFaviconImgName('');
      return;
    }
    const maxSize = 1 * 1024 * 1024;
    if (event.target.files[0].size > maxSize) {
      alertShow('첨부 이미지의 사이즈는 1024KB 이내로 등록 가능합니다.');
      return;
    }
    const getExtension = (fileName: string) => {
      const fileLength = fileName.length;
      const lastDot = fileName.lastIndexOf('.');
      const fileExtension = `.${fileName.substring(lastDot + 1, fileLength)}`;
      return fileExtension.toLowerCase();
    };
    if (getExtension(event.target.files[0].name) !== '.png' && getExtension(event.target.files[0].name) !== '.ico') {
      alertShow('이미지는 png, ico 형식의 파일만 등록할 수 있습니다.');
      return;
    }
    setFaviconFile(event.target.files[0]);
    setFaviconImg(URL.createObjectURL(event.target.files[0]));
    setFaviconImgName(event.target.files[0].name);
    try {
      const reader: FileReader = new FileReader();
      reader.onload = (event: ProgressEvent<FileReader>) => {
        console.log(event);
      };
      reader.onerror = error => {
        console.log(error);
      };
      reader.readAsDataURL(event.target.files[0]);
    } catch (error) {
      console.log(error);
    }
  };
  // 컬러
  const [customColor, setCustomColor] = useState(false);
  const [color, setColor] = useState({ lightColor: '#0277bd', darkColor: '#0277bd' });
  // 워터마크
  const [role, setRole] = useState<IRole[]>([]);
  const [roleReady, setRoleReady] = useState(false);
  const { isFetching: getMemberRoleLoading } = useQuery<{ data: IRole[] }>(['getMemberRole'], getMemberRole, {
    refetchOnWindowFocus: false,
    onSuccess: e => {
      setRoleReady(true);
      setRole(e.data.map(item => ({ ...item, checked: false })));
    },
  });

  const { isFetching: codeValidationLoading } = useQuery<{ data?: CodeValidation }>(
    ['codeValidation'],
    () => codeValidation(code || ''),
    {
      refetchOnWindowFocus: false,
      enabled: code !== undefined && roleReady,
      onSuccess: e => {
        setCKey(e.data?.c_key || 0);
        setUploadFile({ dark: `uploaded_${code}_DARK_LOGO`, light: `uploaded_${code}_LIGHT_LOGO` });
        setUploadImg({
          dark: `${process.env.REACT_APP_UPLOADED_URL}/xclass/image/customer/${code}_DARK_LOGO`,
          light: `${process.env.REACT_APP_UPLOADED_URL}/xclass/image/customer/${code}_LIGHT_LOGO`,
        });
        setUploadImgName({ dark: `uploaded_${code}_DARK_LOGO`, light: `uploaded_${code}_LIGHT_LOGO` });
        setFaviconFile(`uploaded_${code}_FAVICON`);
        setFaviconImg(`${process.env.REACT_APP_UPLOADED_URL}/xclass/image/customer/${code}_FAVICON`);
        setFaviconImgName(`uploaded_${code}_FAVICON`);
        setRole(prev =>
          prev.map(el => (e.data?.c_watermark.split(',').includes(el.mr_code) ? { ...el, checked: true } : el)),
        );
        setCustomColor(
          !(e.data?.c_light_color === '#a50034' && e.data?.c_dark_color === '#ec018c') &&
            !(e.data?.c_light_color === '#e99932' && e.data?.c_dark_color === '#ecb057') &&
            !(e.data?.c_light_color === '#019412' && e.data?.c_dark_color === '#22b534') &&
            !(e.data?.c_light_color === '#0277bd' && e.data?.c_dark_color === '#268ac3') &&
            !(e.data?.c_light_color === '#2a315a' && e.data?.c_dark_color === '#4234bc'),
        );
        setColor({
          lightColor: e.data?.c_light_color || '#0277bd',
          darkColor: e.data?.c_dark_color || '#0277bd',
        });
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (e: any) => alertShow(e.response.data.message),
    },
  );

  const init = () => {
    setCKey(0);
    setUploadFile({ dark: null, light: null });
    setUploadImg({ dark: null, light: null });
    setUploadImgName({ dark: '', light: '' });
    setFaviconFile(null);
    setFaviconImg(null);
    setFaviconImgName('');
  };
  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const { mutate: update } = useMutation(editCustomer, {
    onSuccess: () => {
      setLoading(false);
      if (cookies['xclass-clight'] && cookies['xclass-clight'] !== 'null')
        document.documentElement.style.setProperty(
          '--primary',
          cookies['xclass-theme'] === 'dark' ? color.darkColor : color.lightColor,
        );
      setCookie(
        'xclass-cwatermark',
        role
          .filter(item => item.checked)
          .map(el => el.mr_name)
          .join(','),
      );
      navigate(`/${code}`);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (e: any) => {
      setLoading(false);
      createError({
        type: '[ADMIN] customer - editCustomer',
        message: `message: "${e.message}",\nresponse: { status: ${e.response.status}, message: "${e.response.data.message}" }`,
      });
      throwError(e);
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formData = (_fields: any, _file: any) => {
    const data = new FormData();
    Object.keys(_fields).forEach(key => data.append(key, _fields[key]));
    if (_file) {
      data.append('file', _file);
    }
    return data;
  };

  const [imgComplete, setImgComplete] = useState({ dark: false, light: false });
  const [faviconComplete, setFaviconComplete] = useState(false);
  const complete = () =>
    update({
      c_key: cKey,
      data: {
        favicon_path: faviconImgName.replaceAll('uploaded_', ''),
        light_color: color.lightColor,
        dark_color: color.darkColor,
        watermark: role
          .filter(item => item.checked)
          .map(el => el.mr_code)
          .join(','),
      },
    });

  useEffect(() => {
    if (imgComplete.dark && imgComplete.light && faviconComplete) {
      complete();
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imgComplete, faviconComplete]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fileUpload = async (url: string, form: any, type: string, mode?: string) => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const data = (await axios.post(url, form)) as any;
      if (data.ok || data.status === 204) {
        if (type === 'logo' && mode) {
          setImgComplete(prev => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const obj: any = { ...prev };
            obj[mode] = true;
            return obj;
          });
        }
        if (type === 'favicon') setFaviconComplete(true);
      } else {
        console.log('error');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const uploadLogo = async (mode: string) => {
    try {
      const { data } = await axios.post(
        `${api}/admin/customer/upload_logo`,
        { code, type: mode.toUpperCase() },
        { withCredentials: true },
      );
      if (mode === 'light') {
        await setUploadImgName(prev => ({ ...prev, light: data.data.name }));
        await fileUpload(data.data.url.url, formData(data.data.url.fields, uploadFile.light), 'logo', 'light');
      } else if (mode === 'dark') {
        await setUploadImgName(prev => ({ ...prev, dark: data.data.name }));
        await fileUpload(data.data.url.url, formData(data.data.url.fields, uploadFile.dark), 'logo', 'dark');
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(e);
    }
  };

  const uploadFavicon = async () => {
    try {
      const { data } = await axios.post(`${api}/admin/customer/upload_favicon`, { code }, { withCredentials: true });
      await setFaviconImgName(data.data.name);
      await fileUpload(data.data.url.url, formData(data.data.url.fields, faviconFile), 'favicon');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(e);
    }
  };

  const upload = () => {
    setLoading(true);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (
      uploadImgName.dark.includes('uploaded_') &&
      uploadImgName.light.includes('uploaded_') &&
      faviconImgName.includes('uploaded_')
    ) {
      update({
        c_key: cKey,
        data: {
          favicon_path: faviconImgName.replaceAll('uploaded_', ''),
          light_color: color.lightColor,
          dark_color: color.darkColor,
          watermark: role
            .filter(item => item.checked)
            .map(el => el.mr_code)
            .join(','),
        },
      });
      return;
    }
    if (faviconImgName.includes('uploaded_')) {
      setFaviconComplete(true);
      if (uploadImgName.dark.includes('uploaded_') && !uploadImgName.light.includes('uploaded_')) {
        setImgComplete(prev => ({ ...prev, dark: true }));
        uploadLogo('light');
      } else if (!uploadImgName.dark.includes('uploaded_') && uploadImgName.light.includes('uploaded_')) {
        setImgComplete(prev => ({ ...prev, light: true }));
        uploadLogo('dark');
      } else if (!uploadImgName.dark.includes('uploaded_') && !uploadImgName.light.includes('uploaded_')) {
        uploadLogo('dark');
        uploadLogo('light');
      }
      return;
    }
    if (
      !faviconImgName.includes('uploaded_') &&
      uploadImgName.dark.includes('uploaded_') &&
      uploadImgName.light.includes('uploaded_')
    ) {
      setImgComplete({ dark: true, light: true });
      uploadFavicon();
      return;
    }
    uploadLogo('dark');
    uploadLogo('light');
    uploadFavicon();
  };

  const setLoading = useSetRecoilState(loadingState);

  return (
    <Page title="설정" isLoading={getMemberRoleLoading || codeValidationLoading}>
      <FormItem title="기본 로고" essential>
        <div className="file">
          <div
            className="uploaded"
            style={{ display: uploadImg.light ? '' : 'none', backgroundColor: 'white', marginBottom: 'unset' }}
          >
            <img
              src={uploadImg.light || ''}
              alt="img"
              ref={imgRefLight}
              onError={() => {
                if (imgRefLight.current) imgRefLight.current.src = logoEmpty;
              }}
            />
          </div>
        </div>
        <div className="guide">
          <div className="item">* {t('로고는 256x80 픽셀 규격의 png 파일을 표준으로 합니다.')}</div>
        </div>
        <div className="input">
          <label htmlFor="light-upload" className="btn info">
            <div className="placeholder">{t('업로드')}</div>
            <input type="file" id="light-upload" onChange={e => changeLogo(e, 'light')} accept="image/png" />
          </label>
        </div>
      </FormItem>
      <FormItem title="다크모드 로고" essential>
        <div className="file">
          <div
            className="uploaded"
            style={{
              display: uploadImg.dark ? '' : 'none',
              backgroundColor: cookies['xclass-theme'] === 'dark' ? 'var(--background)' : 'var(--greyish1)',
              marginBottom: 'unset',
            }}
          >
            <img
              src={uploadImg.dark || ''}
              alt="img"
              ref={imgRefDark}
              onError={() => {
                if (imgRefDark.current) imgRefDark.current.src = logoEmpty;
              }}
            />
          </div>
        </div>
        <div className="guide">
          <div className="item">* {t('로고는 256x80 픽셀 규격의 png 파일을 표준으로 합니다.')}</div>
        </div>
        <div className="input">
          <label htmlFor="dark-upload" className="btn info">
            <div className="placeholder">{t('업로드')}</div>
            <input type="file" id="dark-upload" onChange={e => changeLogo(e, 'dark')} accept="image/png" />
          </label>
        </div>
      </FormItem>
      <FormItem title="파비콘" essential>
        <div className="file">
          <div className="uploaded" style={{ display: faviconImg ? '' : 'none', marginBottom: 'unset' }}>
            <img
              src={faviconImg || ''}
              alt="img"
              ref={faviconRef}
              style={{ width: '20px' }}
              onError={() => {
                if (faviconRef.current) faviconRef.current.src = faviconEmpty;
              }}
            />
          </div>
        </div>
        <div className="guide">
          <div className="item">* {t('파비콘은 64x64 픽셀 규격의 ico 파일을 표준으로 합니다.')}</div>
        </div>
        <div className="input">
          <label htmlFor="favicon-upload" className="btn info">
            <div className="placeholder">{t('업로드')}</div>
            <input type="file" id="favicon-upload" onChange={e => changeFavicon(e)} accept=".ico, .png" />
          </label>
        </div>
      </FormItem>
      <FormItem title="테마" essential>
        <div className="switch">
          <div className={classNames('item', !customColor && 'active')} onClick={() => setCustomColor(false)}>
            {t('기본')}
          </div>
          <div className={classNames('item', customColor && 'active')} onClick={() => setCustomColor(true)}>
            {t('커스텀')}
          </div>
        </div>
        <table className="colorTable">
          <thead>
            <tr>
              <th>{t('기본 컬러')}</th>
              <th>{t('다크모드 컬러')}</th>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              {!customColor && <th></th>}
            </tr>
          </thead>
          <tbody>
            {customColor ? (
              <ColorItem
                lightColor={color.lightColor}
                darkColor={color.darkColor}
                color={color}
                setColor={(lightColor, darkColor) => setColor(prev => ({ ...prev, lightColor, darkColor }))}
                custom
              />
            ) : (
              <>
                <ColorItem
                  lightColor="#a50034"
                  darkColor="#ec018c"
                  color={color}
                  setColor={(lightColor, darkColor) => setColor(prev => ({ ...prev, lightColor, darkColor }))}
                />
                <ColorItem
                  lightColor="#e99932"
                  darkColor="#ecb057"
                  color={color}
                  setColor={(lightColor, darkColor) => setColor(prev => ({ ...prev, lightColor, darkColor }))}
                />
                <ColorItem
                  lightColor="#019412"
                  darkColor="#22b534"
                  color={color}
                  setColor={(lightColor, darkColor) => setColor(prev => ({ ...prev, lightColor, darkColor }))}
                />
                <ColorItem
                  lightColor="#0277bd"
                  darkColor="#268ac3"
                  color={color}
                  setColor={(lightColor, darkColor) => setColor(prev => ({ ...prev, lightColor, darkColor }))}
                />
                <ColorItem
                  lightColor="#2a315a"
                  darkColor="#4234bc"
                  color={color}
                  setColor={(lightColor, darkColor) => setColor(prev => ({ ...prev, lightColor, darkColor }))}
                />
              </>
            )}
          </tbody>
        </table>
        {customColor && (
          <div className="guide">
            <div className="item">{t('색상 선택기를 클릭하여 색상을 직접 지정합니다.')}</div>
          </div>
        )}
      </FormItem>
      <FormItem title="워터마크" essential>
        <div className="list">
          {role
            .filter(item => decrypt(cookies.XID).c_modules.concat(',BOARD').includes(item.mr_code))
            .map(item => (
              <div
                className={classNames('item', item.checked && 'active')}
                key={item.mr_code}
                onClick={() => setRole(prev => prev.map(el => (item === el ? { ...el, checked: !el.checked } : el)))}
              >
                <div className="text">{t(item.mr_code === 'MEMBER' ? '마이페이지' : item.mr_name)}</div>
                <div className="check"></div>
              </div>
            ))}
        </div>
      </FormItem>
      <div className="formSubmit">
        <button
          type="button"
          className="primary"
          onClick={() => {
            if (!uploadImgName) {
              alertShow('로고를 등록하십시오.');
              return;
            }
            if (!faviconImgName) {
              alertShow('파비콘을 등록하십시오.');
              return;
            }
            // if (!watermark) {
            //   alertShow('워터마크를 입력하십시오.');
            //   return;
            // }
            confirmShow('수정하시겠습니까?', upload);
          }}
        >
          {t('수정')}
        </button>
      </div>
      <Address />
    </Page>
  );
}

export default Settings;
