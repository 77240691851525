import axios from 'axios';
import { createError, throwError } from './error';

const api = process.env.REACT_APP_API;

// Replay 수정
export const editReplay = async (element: { key: number; data: IReplayEdit }) => {
  await axios.patch(`${api}/replay/${element.key}`, { ...element.data }, { withCredentials: true });
};

// Replay 삭제
export const deleteReplay = async (key: number) => {
  await axios.delete(`${api}/replay/${key}`, { withCredentials: true });
};

// Replay 목록 조회
export const getReplayList = async (element: { str: string; page: number; live?: number }) => {
  try {
    const { data } = await axios.get(
      `${api}/replay?str=${element.str}&page=${element.page}&limit=10${element.live ? `&live=${element.live}` : ''}`,
      {
        withCredentials: true,
      },
    );
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    createError({
      type: '[ADMIN] Replay - getReplayList',
      message: `message: "${error.message}",\nresponse: { status: ${error.response.status}, message: "${error.response.data.message}" }`,
    });
    throwError(error);
    return null;
  }
};

// Replay 조회
export const getReplay = async (key: number) => {
  try {
    const { data } = await axios.get(`${api}/replay/${key}`, { withCredentials: true });
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    createError({
      type: '[ADMIN] Replay - getReplay',
      message: `message: "${error.message}",\nresponse: { status: ${error.response.status}, message: "${error.response.data.message}" }`,
    });
    throwError(error);
    return null;
  }
};

// Replay 댓글 등록
export const createReplayComment = async (element: { key: number; comment: string }) => {
  await axios.post(`${api}/replay/comment`, { ...element }, { withCredentials: true });
};

// Replay 댓글 수정
export const editReplayComment = async (element: { key: number; comment: string }) => {
  await axios.patch(`${api}/replay/comment/${element.key}`, { comment: element.comment }, { withCredentials: true });
};

// Replay 댓글 삭제
export const deleteReplayComment = async (key: number) => {
  await axios.delete(`${api}/replay/comment/${key}`, { withCredentials: true });
};

// Replay 댓글 목록 조회
export const getReplayComment = async (element: { key: number; page: number }) => {
  try {
    const { data } = await axios.get(`${api}/replay/comment?key=${element.key}&page=${element.page}&limit=10`, {
      withCredentials: true,
    });
    return data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    createError({
      type: '[ADMIN] Replay - getReplayComment',
      message: `message: "${error.message}",\nresponse: { status: ${error.response.status}, message: "${error.response.data.message}" }`,
    });
    throwError(error);
    return null;
  }
};
