import { motion, AnimatePresence } from 'framer-motion';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { useQuery } from '@tanstack/react-query';
import { getEvaluationQuetionList } from '../../api/evaluation';
import { useAlert } from '../../stores/alertStore';
import { questionListState } from '../../stores/evaluationStore';

// components
import Modal from '../modal/modal';

// style
import styles from '../modal/selector.module.scss';
import Pagination from '../../includes/pagination';

function EvaluationQuestionList() {
  const { t } = useTranslation();
  const { alertShow } = useAlert();
  const [questionList, setQuestionList] = useRecoilState(questionListState);
  const [count, setCount] = useState(0);
  const [data, setData] = useState<IEvaluationQuestion[]>([]);
  const [page, setPage] = useState(1);

  const { isFetching } = useQuery<{ count: number; data: IEvaluationQuestion[] }>(
    ['getEvaluationQuetionList', page],
    () => getEvaluationQuetionList(page),
    {
      refetchOnWindowFocus: false,
      onSuccess: e => {
        setCount(e.count);
        setData(e.data);
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (e: any) => alertShow(e.response.data.message),
    },
  );

  return (
    <AnimatePresence>
      {questionList.state && (
        <Modal close={() => setQuestionList(prev => ({ ...prev, state: false }))}>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            className={styles.modal}
          >
            <div className={styles.header}>
              <div className={styles.title}>{t('문제를 선택하십시오.')}</div>
              <button
                type="button"
                className="primary"
                onClick={() => setQuestionList(prev => ({ ...prev, state: false }))}
              >
                {t('확인')}
              </button>
            </div>
            <table className="table">
              <thead>
                <tr>
                  <th>{t('유형')}</th>
                  <th>{t('제목')}</th>
                  <th>{t('난이도')}</th>
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {isFetching && (
                  <tr>
                    <td colSpan={9}>
                      <div>
                        <div className="lds-spinner">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
                {!isFetching && data.length === 0 && (
                  <tr>
                    <td colSpan={4}>
                      <div>{t('검색된 목록이 없습니다.')}</div>
                    </td>
                  </tr>
                )}
                {data.map(item => (
                  <tr key={item.eq_key}>
                    <td>
                      <div>
                        <span>
                          {item.eq_type === 'MC' ? t('객관식') : item.eq_type === 'SA' ? t('주관식') : item.eq_type}
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className="title">
                        <span>{item.eq_title}</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>{item.eq_difficulty === 1 ? t('하') : item.eq_difficulty === 2 ? t('중') : t('상')}</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <button
                          type="button"
                          className={
                            questionList.data.find(el => (el.eq_key || el.questionKey) === item.eq_key)
                              ? 'warning'
                              : 'success'
                          }
                          onClick={() => {
                            if (questionList.data.find(el => (el.eq_key || el.questionKey) === item.eq_key)) {
                              setQuestionList(prev => ({
                                ...prev,
                                data: prev.data.filter(el => (el.eq_key || el.questionKey) !== item.eq_key),
                              }));
                            } else {
                              setQuestionList(prev => ({
                                ...prev,
                                data: prev.data.concat({
                                  questionKey: item.eq_key,
                                  title: item.eq_title,
                                  type: item.eq_type,
                                  difficulty: item.eq_difficulty,
                                  score: 0,
                                }),
                              }));
                            }
                          }}
                        >
                          <span>
                            {questionList.data.find(el => (el.eq_key || el.questionKey) === item.eq_key)
                              ? t('해제')
                              : t('선택')}
                          </span>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Pagination total={count || 0} movePage={e => setPage(e)} />
          </motion.div>
        </Modal>
      )}
    </AnimatePresence>
  );
}

export default EvaluationQuestionList;
