import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useRecoilState } from 'recoil';
import { memberOrgMultyState } from '../../stores/memberStore';

// style
import styles from '../member/orgItem.module.scss';

// eslint-disable-next-line react/require-default-props
function OrgItem({ item, parentKey }: { item: IOrg; parentKey?: number }) {
  const [cookies] = useCookies(['xclass-cname']);
  const [memberOrg, setMemberOrg] = useRecoilState(memberOrgMultyState);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (!item.mo_name) setOpen(true);
    if (memberOrg.selected.filter(el => item.children?.map(e => e.mo_key).includes(el)).length > 0) setOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (parentKey && memberOrg.selected.includes(parentKey) && memberOrg.selected.includes(item.mo_key))
      setMemberOrg(prev => ({ ...prev, selected: prev.selected.filter(el => el !== parentKey) }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberOrg]);

  return (
    <div className={styles.container}>
      <div className={styles.org}>
        <div className={classNames(styles.arrow, open && styles.active)} onClick={() => setOpen(prev => !prev)}></div>
        <div className={styles.inner} onClick={() => setOpen(prev => !prev)}>
          <div className={styles.name}>{item.mo_name || cookies['xclass-cname'] || ''}</div>
        </div>
        <div
          className={classNames(
            styles.select,
            memberOrg.selected.includes(item.mo_key) && styles.active,
            !memberOrg.selected.includes(item.mo_key) &&
              parentKey &&
              memberOrg.selected.includes(parentKey) &&
              styles.selected,
          )}
          onClick={() => {
            setMemberOrg(prev => {
              if (memberOrg.selected.includes(item.mo_key))
                return { ...prev, selected: prev.selected.filter(el => el !== item.mo_key) };
              return {
                ...prev,
                selected: prev.selected
                  .concat(item.mo_key)
                  .filter(el => !item.children?.map(e => e.mo_key).includes(el)),
              };
            });
          }}
        ></div>
      </div>
      {open && (
        <div className={styles.children}>
          {item.children?.map(child => (
            <OrgItem item={child} key={child.mo_key} parentKey={item.mo_key} />
          ))}
        </div>
      )}
    </div>
  );
}

export default OrgItem;
