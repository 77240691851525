import dayjs from 'dayjs';
import queryString from 'query-string';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getDemoList, updateDemo } from '../../api/customer';
import { createError, throwError } from '../../api/error';
import { useAlert } from '../../stores/alertStore';
import { useConfirm } from '../../stores/confirmStore';

// components
import Page from '../../includes/page';

// img
import Pagination from '../../includes/pagination copy';

function Demo() {
  const { t } = useTranslation();
  const { confirmShow } = useConfirm();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { page } = queryString.parse(search);
  const { alertShow } = useAlert();
  const [count, setCount] = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [data, setData] = useState<any[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { isFetching: getDemoListLoading, refetch } = useQuery<{ count: number; data: any[] }>(
    ['getDemoList', page],
    () => getDemoList(page === undefined ? 1 : Number(page)),
    {
      refetchOnWindowFocus: false,
      onSuccess: e => {
        setCount(e.count);
        setData(e.data.map(item => ({ ...item, checked: false })));
        if (e.count > 0 && e.data.length === 0 && page !== undefined && Number(page) > 1) navigate('');
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (e: any) => alertShow(e.response.data.message),
    },
  );
  // todo
  const { mutate: update, isLoading: updateDemoLoading } = useMutation(updateDemo, {
    onSuccess: () => refetch(),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (e: any) => {
      createError({
        type: '[ADMIN] customer - updateDemo',
        message: `message: "${e.message}",\nresponse: { status: ${e.response.status}, message: "${e.response.data.message}" }`,
      });
      throwError(e);
    },
  });

  return (
    <Page title="데모신청목록" isLoading={updateDemoLoading}>
      <div className="table-tools"></div>
      <div className="table-container">
        <table className="table">
          <thead>
            <tr>
              <th>{t('아이디')}</th>
              <th>{t('고객사')}</th>
              <th>{t('이름')}</th>
              <th>{t('연락처')}</th>
              <th>{t('이메일')}</th>
              <th>{t('직원수')}</th>
              <th>{t('업종')}</th>
              <th>{t('문의사항')}</th>
              <th>{t('날짜')}</th>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {getDemoListLoading && (
              <tr>
                <td colSpan={9}>
                  <div>
                    <div className="lds-spinner">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </td>
              </tr>
            )}
            {!getDemoListLoading && data.length === 0 && (
              <tr>
                <td colSpan={8}>
                  <div>{t('검색된 목록이 없습니다.')}</div>
                </td>
              </tr>
            )}
            {data.map(item => (
              <tr key={item.d_key}>
                <td>
                  <div>
                    <span>{item.d_user_id}</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span
                    // className="link"
                    // onClick={() => navigate(`/${code}/enquiry/detail?e_key=${item.d_key}`)}
                    >
                      {item.d_company}
                    </span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>{item.d_name}</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>{item.d_tel}</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>{item.d_email}</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>{item.d_emp_count}</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>{item.d_sector}</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>{item.d_question}</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>{dayjs(item.created_at).format('YYYY-MM-DD HH:mm')}</span>
                  </div>
                </td>
                <td>
                  <div>
                    {/* todo */}
                    <button
                      type="button"
                      className="success"
                      onClick={() => {
                        confirmShow(t('데모신청을 승인하시겠습니까?'), () =>
                          update({ userId: item.d_user_id, accept: true }),
                        );
                      }}
                    >
                      <span>{t('승인')}</span>
                    </button>
                    <button
                      type="button"
                      className="secondary"
                      onClick={() => {
                        confirmShow(t('데모신청을 승인하시겠습니까?'), () =>
                          update({ userId: item.d_user_id, accept: false }),
                        );
                      }}
                    >
                      <span>{t('거절')}</span>
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        total={count || 0}
        curr={page === undefined ? 1 : Number(page)}
        movePage={e => navigate(`?page=${e}`)}
      />
    </Page>
  );
}

export default Demo;
