import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

// style
import styles from './pageNotFound.module.scss';

// img
import img404 from '../../assets/img/images/img_404.svg';

function PageNotFound() {
  const { t } = useTranslation();
  const [cookies] = useCookies(['xclass-theme']);
  const navigate = useNavigate();
  const { code } = useParams();
  return (
    <div className={styles.container} data-theme={cookies['xclass-theme']}>
      <div className={styles.body}>
        <div className={styles.code}>
          <img src={img404} alt="img" />
        </div>
        <div className={styles.message}>
          <div className={styles.title}>{t('요청하신 페이지를 찾을 수 없습니다.')}</div>
          {t(
            '찾으려는 페이지의 주소가 잘못 입력 되었거나, 주소의 변경 혹은 삭제로 인해 이용하실 수 없습니다. 올바른 URL 주소를 입력하셨는지 확인해 주시기 바랍니다.',
          )}
        </div>
        <div className={styles.btn} onClick={() => navigate(`/${code}`)}>
          <span>{t('홈으로')}</span>
        </div>
      </div>
    </div>
  );
}

export default PageNotFound;
