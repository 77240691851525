import classNames from 'classnames';
import Chart from 'react-apexcharts';
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

// style
import styles from './evaluationHistoryItem.module.scss';

// img
import imgOWhite from '../../assets/img/images/img_o_white.svg';
import imgXWhite from '../../assets/img/images/img_x_white.svg';
import { icFullscreen, icFullscreenExit } from '../aside/icon';

function AnalysisItem({ item, idx }: { item: IEvaluationQuestionDetail; idx: number }) {
  const { t } = useTranslation();
  const [cookies] = useCookies(['xclass-theme']);

  const [titleImgFull, setTitleImgFull] = useState(false);
  const [imgFull, setImgFull] = useState<number | null>(null);

  return (
    <div className={styles.container}>
      <div className={styles.question}>
        <div className={styles.num}>
          <span>{idx}</span>
        </div>
        {item.eq_type === 'MC' && <div className={classNames(styles.type, styles.mc)}>{t('객관식')}</div>}
        {item.eq_type === 'OX' && <div className={classNames(styles.type, styles.ox)}>OX</div>}
        {item.eq_type === 'SA' && <div className={classNames(styles.type, styles.sa)}>{t('주관식')}</div>}
        <div className={styles.text}>
          <span>{item.eq_title}</span>
        </div>
      </div>
      {(item.eq_image_path || item.eq_video_path) && (
        <div className={classNames(styles.media, titleImgFull && styles.full)}>
          {item.eq_image_path && (
            <>
              <img src={`${process.env.REACT_APP_UPLOADED_URL}/${item.eq_image_path}`} alt="img" />
              <object className={styles.fullscreen} onClick={() => setTitleImgFull(prev => !prev)}>
                {titleImgFull ? icFullscreenExit('#ffffff') : icFullscreen('#ffffff')}
              </object>
            </>
          )}
          {item.eq_video_path && (
            <video
              src={`${process.env.REACT_APP_UPLOADED_URL}/${item.eq_video_path}`}
              playsInline
              controls
              muted
            ></video>
          )}
        </div>
      )}
      <div className={styles.content}>
        <span>{item.eq_contents}</span>
      </div>
      {item.eq_type === 'SA' && (
        <div className={styles.subjective}>
          <div className={styles.answer} style={{ color: 'var(--success)' }}>
            {item.evaluationQuestionAnswerList && (
              <>
                {item.evaluationQuestionAnswerList[0]?.eqa_text
                  ? item.evaluationQuestionAnswerList[0]?.eqa_text.replaceAll('|', ', ')
                  : ''}
                &nbsp;&nbsp;&nbsp;
                <u style={{ color: 'var(--primary)' }}>
                  {t('명', { count: item.evaluationQuestionAnswerList[0]?.count || 0 })}
                </u>
              </>
            )}
          </div>
        </div>
      )}
      {item.eq_type === 'MC' && (
        <div className={styles.single}>
          {item.evaluationQuestionAnswerList?.map(ele => (
            <div className={styles.item} key={ele.eqa_key}>
              <div className={classNames(styles.radio, ele.eqa_correct === 'Y' && styles.right)}></div>
              {ele.eqa_image_path ? (
                <>
                  <div className={classNames(styles.box, ele.eqa_correct === 'Y' && styles.right)}>
                    <div className={classNames(styles.img, imgFull === ele.eqa_key && styles.full)}>
                      <img src={`${process.env.REACT_APP_UPLOADED_URL}/${ele.eqa_image_path}`} alt="img" />
                      <object
                        className={styles.fullscreen}
                        onClick={() => setImgFull(prev => (prev === ele.eqa_key ? null : ele.eqa_key || null))}
                      >
                        {imgFull === ele.eqa_key ? icFullscreenExit('#ffffff') : icFullscreen('#ffffff')}
                      </object>
                    </div>
                    <div className={styles.text}>
                      <span>{ele.eqa_text}</span>
                    </div>
                  </div>
                  <div className={styles['box-count']}>
                    <u style={{ color: ele.eqa_correct === 'Y' ? 'var(--primary)' : '' }}>
                      {t('명', { count: ele.count || 0 })}
                    </u>
                  </div>
                </>
              ) : (
                <div className={classNames(styles.item, ele.eqa_correct === 'Y' && styles.right)}>
                  <span>
                    {ele.eqa_text}&nbsp;&nbsp;&nbsp;
                    <u style={{ color: ele.eqa_correct === 'Y' ? 'var(--primary)' : '' }}>
                      {t('명', { count: ele.count || 0 })}
                    </u>
                  </span>
                </div>
              )}
            </div>
          ))}
          {item.evaluationQuestionAnswerList?.reduce(
            (accumulator, currentItem) => accumulator + (currentItem.count || 0),
            0,
          ) ? (
            <div className={styles.chart}>
              <Chart
                type="donut"
                series={item.evaluationQuestionAnswerList?.map(el => el.count || 0)}
                options={{
                  labels: item.evaluationQuestionAnswerList?.map(el => el.eqa_text || ''),
                  theme: {
                    mode: cookies['xclass-theme'] === 'dark' ? 'dark' : 'light',
                  },
                }}
              />
            </div>
          ) : (
            ''
          )}
        </div>
      )}
      {item.eq_type === 'OX' && (
        <div className={styles.oxAnalysis}>
          {item.evaluationQuestionAnswerList?.map((ele, idx) => (
            <div className={styles.item} key={ele.eqa_key}>
              <div className={styles.gauge}>
                <div
                  className={classNames(styles.inner, ele.eqa_correct === 'Y' && styles.active)}
                  style={{
                    height: `calc(100% * ${(ele.count || 0) / ((item.count_y || 0) + (item.count_n || 0))})`,
                  }}
                >
                  <img src={idx === 0 ? imgOWhite : imgXWhite} alt="icon" />
                </div>
              </div>
              <span style={{ color: ele.eqa_correct === 'Y' ? 'var(--primary)' : '' }}>
                {t('명', { count: ele.count || 0 })}
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default AnalysisItem;
