import classNames from 'classnames';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { createError, throwError } from '../../api/error';
import { deleteEvaluationQuestion, getEvaluationQuetionList } from '../../api/evaluation';
import { useAlert } from '../../stores/alertStore';
import { useConfirm } from '../../stores/confirmStore';
import { evalPreviewStore } from '../../stores/evaluationZustandStore';

// components
import Page from '../../includes/page';
import QuestionPreview from '../../components/evaluation/questionPreview';

// img
import Pagination from '../../includes/pagination copy';

function EvaluationQuestion() {
  const { t } = useTranslation();
  const { code } = useParams();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { page } = queryString.parse(search);
  const { alertShow } = useAlert();
  const { confirmShow } = useConfirm();
  const [count, setCount] = useState(0);
  const [data, setData] = useState<IEvaluationQuestion[]>([]);
  const { isFetching: getEvaluationQuetionListLoading, refetch } = useQuery<{
    count: number;
    data: IEvaluationQuestion[];
  }>(['getEvaluationQuetionList', page], () => getEvaluationQuetionList(page === undefined ? 1 : Number(page)), {
    refetchOnWindowFocus: false,
    onSuccess: e => {
      setCount(e.count);
      setData(e.data.map(item => ({ ...item, checked: false })));
      if (e.count > 0 && e.data.length === 0 && page !== undefined && Number(page) > 1) navigate('');
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (e: any) => alertShow(e.response.data.message),
  });
  const { mutate: remove, isLoading: deleteLoading } = useMutation(deleteEvaluationQuestion, {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (e: any) => {
      createError({
        type: '[ADMIN] evaluation - deleteEvaluation',
        message: `message: "${e.message}",\nresponse: { status: ${e.response.status}, message: "${e.response.data.message}" }`,
      });
      throwError(e);
    },
  });

  const selectDelete = () =>
    confirmShow('선택한 항목을 삭제하시겠습니까?', () => {
      const checked = data.filter(item => item.checked);
      for (let i = 0; i < checked.length; i += 1) remove(checked[i].eq_key);
    });

  useEffect(() => {
    if (!deleteLoading) refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteLoading]);

  const allSelect = () =>
    setData(
      data.map(item => ({
        ...item,
        checked: data.filter(item => item.checked).length !== data.length,
      })),
    );

  const { setPreviewKey } = evalPreviewStore();

  return (
    <Page title="평가문제목록" isLoading={deleteLoading}>
      <div className="table-tools">
        <div className="btns">
          <button type="button" className="danger" onClick={allSelect}>
            <span>{t('전체선택')}</span>
          </button>
          <button type="button" className="primary" onClick={() => navigate(`/${code}/evaluation/question/edit`)}>
            <span>{t('등록')}</span>
          </button>
          <button
            type="button"
            className={classNames('secondary', data.filter(item => item.checked).length === 0 && 'disabled')}
            onClick={selectDelete}
          >
            <span>{t('선택삭제')}</span>
          </button>
        </div>
        {data.filter(item => item.checked).length > 0 && (
          <div className="selected">
            {t('개의 항목을 선택했습니다.', { amount: data.filter(item => item.checked).length })}
          </div>
        )}
      </div>
      <div className="table-container">
        <table className="table">
          <thead>
            <tr>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <th></th>
              <th>{t('유형')}</th>
              <th>{t('제목')}</th>
              <th>{t('난이도')}</th>
              <th>{t('작성자')}</th>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {getEvaluationQuetionListLoading && (
              <tr>
                <td colSpan={9}>
                  <div>
                    <div className="lds-spinner">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </td>
              </tr>
            )}
            {!getEvaluationQuetionListLoading && data.length === 0 && (
              <tr>
                <td colSpan={6}>
                  <div>{t('검색된 목록이 없습니다.')}</div>
                </td>
              </tr>
            )}
            {data.map(item => (
              <tr key={item.eq_key}>
                <td>
                  <div>
                    <div
                      className={classNames(
                        'checkbox',
                        data.filter(ele => ele.eq_key === item?.eq_key)[0]?.checked && 'active',
                      )}
                      onClick={() => {
                        setData(
                          data.map(ele => {
                            if (ele.eq_key === item.eq_key) return { ...ele, checked: !ele.checked };
                            return ele;
                          }),
                        );
                      }}
                    ></div>
                  </div>
                </td>
                <td>
                  <div>
                    <span>
                      {item.eq_type === 'MC' ? t('객관식') : item.eq_type === 'SA' ? t('주관식') : item.eq_type}
                    </span>
                  </div>
                </td>
                <td>
                  <div className="title">
                    <span>{item.eq_title}</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>{item.eq_difficulty === 1 ? t('하') : item.eq_difficulty === 2 ? t('중') : t('상')}</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>{item.m_name}</span>
                  </div>
                </td>
                <td>
                  <div>
                    <button type="button" className="info" onClick={() => setPreviewKey(item.eq_key)}>
                      <span>{t('미리보기')}</span>
                    </button>
                    <button
                      type="button"
                      className="primary"
                      onClick={() => navigate(`/${code}/evaluation/question/edit?eq_key=${item.eq_key}`)}
                    >
                      <span>{t('수정')}</span>
                    </button>
                    <button
                      type="button"
                      className="secondary"
                      onClick={() => confirmShow('삭제하시겠습니까?', () => remove(item.eq_key))}
                    >
                      <span>{t('삭제')}</span>
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        total={count || 0}
        curr={page === undefined ? 1 : Number(page)}
        movePage={e => navigate(`?page=${e}`)}
      />
      <QuestionPreview />
    </Page>
  );
}

export default EvaluationQuestion;
