import { atom, useRecoilState, useSetRecoilState } from 'recoil';

export const categoryState = atom<{ state: boolean; key: number; name: string; category: ISC[] }>({
  key: 'scategory',
  default: {
    state: false,
    key: 0,
    name: '',
    category: [],
  },
});

export function useCategory() {
  const setCategoryState = useSetRecoilState(categoryState);

  const setCategoryModal = (toggle: boolean) => setCategoryState(prev => ({ ...prev, state: toggle }));

  const setCategoryName = (name: string) =>
    setCategoryState(prev => ({
      ...prev,
      name,
    }));

  const setCategoryItemEnable = (key: number, selected: boolean) =>
    setCategoryState(prev => ({
      ...prev,
      category: prev.category.map(item => (item.key === key ? { ...item, selected } : item)),
    }));

  const setCategoryItem = (key: number, name: string) =>
    setCategoryState(prev => ({
      ...prev,
      category: prev.category.map(item => (item.key === key ? { ...item, name } : item)),
    }));

  return { setCategoryModal, setCategoryName, setCategoryItemEnable, setCategoryItem };
}

export const categorySelectorState = atom<{
  state: boolean;
  data: ISCG[];
  page?: number;
  groupKey?: { key: number; name: string };
  categoryKey: { key: number; name: string; group: number }[];
}>({
  key: 'scategorySelector',
  default: { state: false, data: [], page: 1, categoryKey: [] },
});

export const imgState = atom<{
  imageMaps: IImageMaps[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  imgFile: { file: any[]; img: any[]; imgName: any[] };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateImageOrders?: any;
}>({
  key: 'sImg',
  default: {
    imageMaps: [],
    imgFile: { file: [], img: [], imgName: [] },
  },
});

export function useImg() {
  const [img, setImg] = useRecoilState(imgState);

  const initFile = () => setImg({ imageMaps: [], imgFile: { file: [], img: [], imgName: [] } });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setFile = (file: any, img: any, imgName: any) =>
    setImg(prev => ({
      ...prev,
      imgFile: {
        file: prev.imgFile.file.concat(file),
        img: prev.imgFile.img.concat(img),
        imgName: prev.imgFile.imgName.concat(imgName),
      },
    }));

  const setFileDelete = (idx: number) => {
    if (img.imgFile.imgName[idx].includes('uploaded_')) {
      const imageMaps = img.imageMaps.filter(el => Object.values(el)[0] !== img.imgFile.file[idx].split('_')[2]);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const updateImageOrders: any = { ...img.updateImageOrders };
      img.imgFile.file.forEach((_: string, index: number) => {
        const uioKey = Number(img.imgFile.file[index].split('_')[1]);
        if (idx === index) updateImageOrders[uioKey] = -1;
        else updateImageOrders[uioKey] = index + 1;
      });
      setImg(prev => ({
        ...prev,
        imageMaps,
        updateImageOrders,
      }));
    }
    setImg(prev => ({
      ...prev,
      imgFile: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        file: prev.imgFile.file.filter((_: any, index: number) => idx !== index),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        img: prev.imgFile.img.filter((_: any, index: number) => idx !== index),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        imgName: prev.imgFile.imgName.filter((_: any, index: number) => idx !== index),
      },
    }));
  };

  const setFileMove = (idx: number, direction: string) => {
    if (idx === 0 && direction === 'prev') return;
    if (idx === img.imgFile.file.length - 1 && direction === 'next') return;
    const temp = [img.imgFile.file[idx], img.imgFile.img[idx], img.imgFile.imgName[idx]];
    const pre = [img.imgFile.file[idx - 1], img.imgFile.img[idx - 1], img.imgFile.imgName[idx - 1]];
    const next = [img.imgFile.file[idx + 1], img.imgFile.img[idx + 1], img.imgFile.imgName[idx + 1]];
    if (direction === 'prev') {
      if (img.imgFile.imgName[idx].includes('uploaded_') || img.imgFile.imgName[idx - 1].includes('uploaded_')) {
        const tempImg = img.imgFile.file
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .filter((item: any) => item.includes('uploaded'))
          .map((item: string) => item.split('_')[2])[idx];
        const preImg = img.imgFile.file
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .filter((item: any) => item.includes('uploaded'))
          .map((item: string) => item.split('_')[2])[idx - 1];

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const imageMaps: any[] = [];
        img.imageMaps.forEach((item: IImageMaps, indx: number) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const obj: any = {};
          obj[indx] = indx === idx ? preImg : indx === idx - 1 ? tempImg : Object.values(item)[0];
          imageMaps.push(obj);
        });

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const updateImageOrders: any = { ...img.updateImageOrders };
        img.imgFile.file.forEach((_: string, index: number) => {
          const uioKey = Number(img.imgFile.file[index].split('_')[1]);
          if (idx === index) updateImageOrders[uioKey] = index;
          else if (idx - 1 === index) updateImageOrders[uioKey] = index + 2;
          else updateImageOrders[uioKey] = index + 1;
        });

        setImg(prev => ({
          ...prev,
          imageMaps,
          updateImageOrders,
        }));
      }

      setImg(prev => ({
        ...prev,
        imgFile: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          file: prev.imgFile.file.map((el: any, index: number) =>
            index === idx ? pre[0] : index === idx - 1 ? temp[0] : el,
          ),
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          img: prev.imgFile.img.map((el: any, index: number) =>
            index === idx ? pre[1] : index === idx - 1 ? temp[1] : el,
          ),
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          imgName: prev.imgFile.imgName.map((el: any, index: number) =>
            index === idx ? pre[2] : index === idx - 1 ? temp[2] : el,
          ),
        },
      }));
    }
    if (direction === 'next') {
      if (img.imgFile.imgName[idx].includes('uploaded_') || img.imgFile.imgName[idx + 1].includes('uploaded_')) {
        const tempImg = img.imgFile.file
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .filter((item: any) => item.includes('uploaded'))
          .map((item: string) => item.split('_')[2])[idx];
        const nextImg = img.imgFile.file
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .filter((item: any) => item.includes('uploaded'))
          .map((item: string) => item.split('_')[2])[idx + 1];

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const imageMaps: any[] = [];
        img.imageMaps.forEach((item: IImageMaps, indx: number) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const obj: any = {};
          obj[indx] = indx === idx ? nextImg : indx === idx + 1 ? tempImg : Object.values(item)[0];
          imageMaps.push(obj);
        });

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const updateImageOrders: any = { ...img.updateImageOrders };
        img.imgFile.file.forEach((_: string, index: number) => {
          const uioKey = Number(img.imgFile.file[index].split('_')[1]);
          if (idx === index) updateImageOrders[uioKey] = index + 2;
          else if (idx + 1 === index) updateImageOrders[uioKey] = index;
          else updateImageOrders[uioKey] = index + 1;
        });

        setImg(prev => ({
          ...prev,
          imageMaps,
          updateImageOrders,
        }));
      }

      setImg(prev => ({
        ...prev,
        imgFile: {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          file: prev.imgFile.file.map((el: any, index: number) =>
            index === idx ? next[0] : index === idx + 1 ? temp[0] : el,
          ),
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          img: prev.imgFile.img.map((el: any, index: number) =>
            index === idx ? next[1] : index === idx + 1 ? temp[1] : el,
          ),
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          imgName: prev.imgFile.imgName.map((el: any, index: number) =>
            index === idx ? next[2] : index === idx + 1 ? temp[2] : el,
          ),
        },
      }));
    }
  };

  const setImgAdd = (img: IImageMaps) =>
    setImg(prev => ({
      ...prev,
      imageMaps: prev.imageMaps.concat(img).sort((a, b) => Number(Object.keys(a)[0]) - Number(Object.keys(b)[0])),
    }));

  return { initFile, setFile, setFileDelete, setFileMove, setImgAdd };
}
