import dayjs from 'dayjs';
import queryString from 'query-string';
import { motion, AnimatePresence } from 'framer-motion';
import { useState, useRef } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Editor, Viewer } from '@toast-ui/react-editor';
import { createError, throwError } from '../../api/error';
import { getQna, setQnaAnswer } from '../../api/board';
import { useAlert } from '../../stores/alertStore';
import { useConfirm } from '../../stores/confirmStore';

// components
import Page from '../../includes/page';
import FormItem from '../../components/basic/formItem';

function QnaDetail() {
  const { t } = useTranslation();
  const [cookies] = useCookies(['xclass-theme']);
  const { search } = useLocation();
  const { bq_key } = queryString.parse(search);
  const { alertShow } = useAlert();
  const { confirmShow } = useConfirm();

  const [title, setTitle] = useState('');
  const [date, setDate] = useState('');
  const [description, setDescription] = useState('');
  // 이미지
  const [img, setImg] = useState<{ path1: string; path2: string; path3: string }>({
    path1: '',
    path2: '',
    path3: '',
  });

  const { isFetching: getQnaLoading, refetch } = useQuery<{ data: IQna }>(['getQna'], () => getQna(Number(bq_key)), {
    refetchOnWindowFocus: false,
    enabled: bq_key !== undefined,
    onSuccess: e => {
      setTitle(e.data.bq_title);
      setDate(e.data.updated_at);
      setDescription(e.data.bq_question);
      setImg({
        path1: e.data.bq_path_1 ? `${process.env.REACT_APP_UPLOADED_URL}/${e.data.bq_path_1}` : '',
        path2: e.data.bq_path_2 ? `${process.env.REACT_APP_UPLOADED_URL}/${e.data.bq_path_2}` : '',
        path3: e.data.bq_path_3 ? `${process.env.REACT_APP_UPLOADED_URL}/${e.data.bq_path_3}` : '',
      });
      editorRef.current.getInstance().setHTML(e.data.bq_answer);
    },
  });

  // 답변
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const editorRef = useRef<any>(null);

  const { mutate: upload, isLoading: uploadLoading } = useMutation(setQnaAnswer, {
    onSuccess: () => {
      alertShow('답변이 등록되었습니다.');
      refetch();
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (e: any) => {
      createError({
        type: '[ADMIN] board - deleteNotice',
        message: `message: "${e.message}",\nresponse: { status: ${e.response.status}, message: "${e.response.data.message}" }`,
      });
      throwError(e);
    },
  });

  const [selectedId, setSelectedId] = useState<string | null>(null);
  return (
    <>
      <Page title={title} goBack="/board/qna" isLoading={uploadLoading || getQnaLoading}>
        <div className="etc-info">
          <div className="item"></div>
          <div className="item">{dayjs(date).format('YYYY-MM-DD HH:mm')}</div>
        </div>
        <div className="viewer">
          {description && <Viewer initialValue={description} theme={cookies['xclass-theme']} />}
          <div className="imgUpload">
            {img.path1 && (
              <div className="item">
                <motion.div layoutId={img.path1} className="zoom" onClick={() => setSelectedId(img.path1)}>
                  <img src={img.path1} alt="img" />
                </motion.div>
              </div>
            )}
            {img.path2 && (
              <div className="item">
                <motion.div layoutId={img.path2} className="zoom" onClick={() => setSelectedId(img.path2)}>
                  <img src={img.path2} alt="img" />
                </motion.div>
              </div>
            )}
            {img.path3 && (
              <div className="item">
                <motion.div layoutId={img.path3} className="zoom" onClick={() => setSelectedId(img.path3)}>
                  <img src={img.path3} alt="img" />
                </motion.div>
              </div>
            )}
          </div>
        </div>
        <FormItem title="답변 등록">
          <div className="input"></div>
        </FormItem>
        <Editor
          previewStyle="vertical"
          height="400px"
          useCommandShortcut
          ref={editorRef}
          initialEditType="wysiwyg"
          placeholder={t('답변을 입력하십시오.') || ''}
          toolbarItems={[
            ['heading', 'bold', 'italic', 'strike'],
            ['hr', 'quote'],
            ['ul', 'ol', 'task', 'indent', 'outdent'],
            [
              'table',
              // 'image',
              'link',
            ],
            ['code', 'codeblock'],
          ]}
          theme={cookies['xclass-theme']}
        />
        <div className="formSubmit">
          <button
            type="button"
            className="primary"
            onClick={() => {
              if (
                !editorRef.current
                  ?.getInstance()
                  .getHTML()
                  .replace(/<[^>]*>?/g, '')
                  .replaceAll(' ', '')
              ) {
                alertShow('내용을 입력하십시오.');
                return;
              }
              confirmShow('답변을 등록하겠습니까?', () =>
                upload({ bq_key: Number(bq_key), answer: editorRef.current?.getInstance().getHTML() }),
              );
            }}
          >
            {t('등록')}
          </button>
        </div>
      </Page>
      <AnimatePresence>
        {selectedId && (
          <motion.div
            animate="visible"
            exit="hidden"
            className="imgZoom"
            layoutId={selectedId}
            onClick={() => setSelectedId(null)}
          >
            <img src={selectedId} alt="img" />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}

export default QnaDetail;
