import dayjs from 'dayjs';
import queryString from 'query-string';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getSurveyResponseList } from '../../api/survey';
import { useAlert } from '../../stores/alertStore';

// components
import Page from '../../includes/page';
import Pagination from '../../includes/pagination copy';

function SurveyResponse() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { code } = useParams();
  const { surveyKey, page } = queryString.parse(search);

  const { alertShow } = useAlert();
  const [data, setData] = useState<ISurveyResponse[]>([]);
  const [total, setTotal] = useState(0);
  const { isFetching: getLoading } = useQuery<{
    count: number;
    data: ISurveyResponse[];
  }>(
    ['survey', page],
    () => getSurveyResponseList({ surveyKey: Number(surveyKey), page: page === undefined ? 1 : Number(page) }),
    {
      refetchOnWindowFocus: false,
      onSuccess: e => {
        setData(e.data.map(item => ({ ...item, checked: false })));
        setTotal(e.count);
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (e: any) => alertShow(e.response.data.message),
    },
  );

  return (
    <Page title="설문응답목록">
      <div className="table-tools"></div>
      <div className="table-container">
        <table className="table">
          <thead>
            <tr>
              <th>{t('이름')}</th>
              <th>{t('응답시간')}</th>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {getLoading && (
              <tr>
                <td colSpan={9}>
                  <div>
                    <div className="lds-spinner">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </td>
              </tr>
            )}
            {!getLoading && data.length === 0 && (
              <tr>
                <td colSpan={4}>
                  <div>{t('검색된 목록이 없습니다.')}</div>
                </td>
              </tr>
            )}
            {data.map(item => (
              <tr key={item.survey_response_key}>
                <td>
                  <div>
                    <span>{item.svrs_user_name}</span>
                  </div>
                </td>
                <td>
                  <div>
                    <span>{dayjs(item.svrs_response_time).format('YYYY-MM-DD')}</span>
                  </div>
                </td>
                <td>
                  <div>
                    <button
                      type="button"
                      className="primary"
                      onClick={() =>
                        navigate(`/${code}/survey/responseDetail?surveyResponseKey=${item.survey_response_key}`)
                      }
                    >
                      <span>{t('확인')}</span>
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        total={total || 0}
        curr={page === undefined ? 1 : Number(page)}
        movePage={e => navigate(`?surveyKey=${Number(surveyKey)}&page=${e}`)}
      />
    </Page>
  );
}

export default SurveyResponse;
