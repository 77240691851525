import { motion, AnimatePresence } from 'framer-motion';
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useQuery } from '@tanstack/react-query';
import { getMemberList, getOrgList } from '../../api/member';
import { useAlert } from '../../stores/alertStore';
import { memberList, memberOrgState, useMemberList } from '../../stores/memberStore';

// components
import Modal from '../modal/modal';
import Pagination from '../../includes/pagination';
import OrgItem from './orgItem';
import ModalLoading from '../modal/modalLoading';

// style
import styles from '../modal/selector.module.scss';

// img
import { icFilter, icFilterActive } from '../aside/icon';

function MemberList() {
  const { t } = useTranslation();
  const [cookies] = useCookies(['xclass-lang']);
  const { alertShow } = useAlert();
  const [filter, setFilter] = useState(false);
  const [idValue, setIdValue] = useState('');
  const [name, setName] = useState('');
  const [memberOrg, setMemberOrg] = useRecoilState(memberOrgState);
  const { isFetching: orgLoading } = useQuery<{ data: IOrg }>(['org'], getOrgList, {
    refetchOnWindowFocus: false,
    onSuccess: e => {
      setMemberOrg(prev => ({ ...prev, data: e.data }));
    },
  });
  const list = useRecoilValue(memberList);
  const { setMemberListModal, setMemberSelect } = useMemberList();
  const [count, setCount] = useState(0);
  const [data, setData] = useState<IMember[]>([]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState(true);
  const { isFetching: getMemberListLoading } = useQuery<{ count: number; data: IMember[] }>(
    ['memberList', { id: idValue, name, organizationKey: memberOrg.selected, page }],
    () => getMemberList({ id: idValue, name, organizationKey: memberOrg.selected, page }),
    {
      enabled: search,
      refetchOnWindowFocus: false,
      onSuccess: e => {
        setSearch(false);
        setCount(e.count);
        setData(e.data);
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (e: any) => alertShow(e.response.data.message),
    },
  );

  return (
    <AnimatePresence>
      {list.state && (
        <Modal close={() => setMemberListModal(false)}>
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            className={styles.modal}
          >
            <div className={styles.header}>
              <div className={styles.title}>{t('회원목록')}</div>
              <div className={styles.btn}>
                <object onClick={() => setFilter(prev => !prev)}>{filter ? icFilterActive() : icFilter()}</object>
              </div>
            </div>
            {filter && (
              <div className={styles.filter}>
                <div className={styles.subject}>
                  <span>{t('상세검색')}</span>
                </div>
                <div className={styles.input}>
                  <div className={styles.label}>{t('아이디')}</div>
                  <input type="text" value={idValue} onChange={e => setIdValue(e.target.value)} />
                </div>
                <div className={styles.input}>
                  <div className={styles.label}>{t('이름')}</div>
                  <input type="text" value={name} onChange={e => setName(e.target.value)} />
                </div>
                <div className={styles.org}>
                  <div className={styles.subject}>
                    <span>{t('조직')}</span>
                  </div>
                  <OrgItem item={memberOrg.data} />
                </div>
                <div className={styles.btns}>
                  <button type="button" className="info" onClick={() => setSearch(true)}>
                    {t('검색')}
                  </button>
                </div>
              </div>
            )}
            <table className="table">
              <thead>
                <tr>
                  <th>{t('아이디')}</th>
                  <th>{t('이름')}</th>
                  <th>{t('회원등급')}</th>
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {getMemberListLoading && (
                  <tr>
                    <td colSpan={9}>
                      <div>
                        <div className="lds-spinner">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
                {!getMemberListLoading && data.length === 0 && (
                  <tr>
                    <td colSpan={4}>
                      <div>{t('검색된 목록이 없습니다.')}</div>
                    </td>
                  </tr>
                )}
                {data.map(item => (
                  <tr key={item.m_key}>
                    <td>
                      <div>
                        <span>{item.m_id}</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>{item.m_name}</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>
                          {item.mg_code === 'SUPER' && (cookies['xclass-lang'] === 'ko' ? '최고관리자' : 'Super Admin')}
                          {item.mg_code === 'ADMIN' && (cookies['xclass-lang'] === 'ko' ? '관리자' : 'Admin')}
                          {item.mg_code === 'MANAGER' && (cookies['xclass-lang'] === 'ko' ? '운영자' : 'Manager')}
                          {item.mg_code === 'MEMBER' && (cookies['xclass-lang'] === 'ko' ? '회원' : 'Member')}
                        </span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <button
                          type="button"
                          className="primary"
                          onClick={() => {
                            setMemberSelect(item);
                            setMemberListModal(false);
                          }}
                        >
                          <span>{t('선택')}</span>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Pagination
              total={count || 0}
              movePage={e => {
                setPage(e);
                setSearch(true);
              }}
            />
            <ModalLoading isLoading={orgLoading} />
          </motion.div>
        </Modal>
      )}
    </AnimatePresence>
  );
}

export default MemberList;
